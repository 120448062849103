import { Button, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    returnButton: {
      width: 'fit-content',
      minWidth: 'unset',
      [theme.breakpoints.down('sm')]: {
        padding: '0.5rem',
        marginBottom: '1.25rem',
        marginRight: '1rem',
      },
    },
  }),
);

function ReturnButton() {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={() => navigate(-1)}
      className={classes.returnButton}
    >
      {isMobile ? <>&#8592;</> : 'Volver'}
    </Button>
  );
}

export default ReturnButton;
