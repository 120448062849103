import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Dispatch, SetStateAction } from 'react';
import { FiltersComponent } from '../../components/FiltersComponent';
import Search from '../../components/FiltersComponent/Search';
import { Filters as FiltersIcon } from '../../components/Icons/Filters';
import { Filters } from '../../store/config/types';

interface LinksFilterLayoutProps {
  openFilters: boolean;
  setOpenFilters: (open: boolean) => void;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  searchLinks: () => void;
  closeFiltersDialog: () => void;
  loadFilters: (filters: Filters) => void;
  filtersState: Filters;
}

function LinksFilterLayout({
  openFilters,
  setOpenFilters,
  searchTerm,
  setSearchTerm,
  searchLinks,
  closeFiltersDialog,
  loadFilters,
  filtersState,
}: LinksFilterLayoutProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return isDesktop ? (
    <div className="links-filters-container">
      <FiltersComponent
        hasDateFilter
        hasCollaboratorFilter
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filtersState}
        setFilters={loadFilters}
        triggerSearch={searchLinks}
      />
    </div>
  ) : (
    <>
      <div className="links-search-container">
        <Search
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          triggerSearch={searchLinks}
          placeholder="Buscar por descripción, referencia o importe"
        />
        <Button variant="outlined" color="secondary" onClick={() => setOpenFilters(true)}>
          <FiltersIcon />
        </Button>
      </div>
      <Dialog open={openFilters} fullWidth maxWidth="sm" onClose={closeFiltersDialog} className="dialog">
        <DialogTitle classes={{ root: 'dialog-title' }}>
          <CloseIcon fontSize="default" className="icon" onClick={closeFiltersDialog} />
        </DialogTitle>
        <DialogContent classes={{ root: 'dialog-content' }}>
          <Typography variant="h4">Filtrar por</Typography>
          <FiltersComponent
            hasDateFilter
            hasCollaboratorFilter
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filters={filtersState}
            setFilters={loadFilters}
            triggerSearch={searchLinks}
            closeDialog={closeFiltersDialog}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LinksFilterLayout;
