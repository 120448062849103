import * as Yup from 'yup';

const requiredString = 'Campo requerido';
const passwordFormatString = 'La contraseña debe tener al menos una mayúscula, una minúscula y un número';
const mailString = 'Formato de mail inválido';
const passwordLength = 6;
const passwordLengthErrorString = `La contraseña debe tener al menos ${passwordLength} caracteres`;
const phoneString = 'El teléfono solo puede incluir números';
const cellphoneFormatString = 'El celular solo puede incluir números';
const numberString = 'Debe ser un número mayor a cero';
const BANRED_ISSUER_ID = '23';
const REDPAGOS_ISSUER_ID = '19';
const ISSUERS_WITHOUT_ACQUIRER = [BANRED_ISSUER_ID, REDPAGOS_ISSUER_ID];

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
const businessNameRegex = /^[a-zA-Z_-]*$/;
const phoneRegex = /^[0-9]*$/;
const cellphoneRegex = /^[0-9]*$/;
const businessTextRegex = /^((?!(empresa|EMPRESA)).)*$/;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
  password: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .min(passwordLength, passwordLengthErrorString),
});

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
});

const RegisterUserSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
  password: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .min(passwordLength, passwordLengthErrorString),
  repeatPassword: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
  names: Yup.string()
    .required(requiredString)
    .min(3, 'Este campo debe tener al menos 3 caracteres.')
    .max(18, 'Este campo no debe tener más de 18 caracteres.'),
  surnames: Yup.string()
    .required(requiredString)
    .min(3, 'Este campo debe tener al menos 3 caracteres.')
    .max(18, 'Este campo no debe tener más de 18 caracteres.'),
  telephoneNo: Yup.string().required(requiredString).matches(phoneRegex, phoneString),
});

const ChangeEmailSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
  actualPassword: Yup.string().matches(passwordRegex, passwordFormatString).required(requiredString),
  password: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .notOneOf([Yup.ref('actualPassword'), null], 'La contraseña debe ser distinta a la ya existente')
    .min(passwordLength, passwordLengthErrorString),
  repeatPassword: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
});

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().matches(passwordRegex, passwordFormatString).required(requiredString),
  password: Yup.string()
    .min(passwordLength, passwordLengthErrorString)
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .notOneOf([Yup.ref('actualPassword'), null], 'La contraseña debe ser distinta a la ya existente'),
  repeatPassword: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
});

const ChangeUserPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(passwordLength, passwordLengthErrorString)
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString),
  repeatPassword: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
});

const ProfileSchema = Yup.object().shape({
  names: Yup.string().required(requiredString).min(3, 'Este campo debe tener al menos 3 caracteres.'),
  surnames: Yup.string().required(requiredString),
  email: Yup.string().email(mailString).required(requiredString),
});

const UserSchema = Yup.object().shape({
  names: Yup.string().required(requiredString),
  surnames: Yup.string().required(requiredString),
  email: Yup.string().email(mailString).required(requiredString),
  password: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .min(passwordLength, passwordLengthErrorString),
  repeatPassword: Yup.string()
    .matches(passwordRegex, passwordFormatString)
    .required(requiredString)
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
});

const LinkSchema = Yup.object().shape({
  value: Yup.number()
    .typeError(numberString)
    .min(1, 'El número debe ser mayor a cero')
    .required(requiredString),
});

const BusinessSchema = Yup.object().shape({
  prettyName: Yup.string()
    .min(3, 'El nombre debe tener al menos 3 caracteres.')
    .max(50, 'El nombre debe tener máximo 50 caracteres.')
    .required(requiredString),
  urlName: Yup.string()
    .min(3, 'El nombre de la url debe tener al menos 3 caracteres.')
    .max(50, 'El nombre de la url debe tener máximo 50 caracteres.')
    .matches(businessNameRegex, 'Formato del nombre incorrecto')
    .matches(businessTextRegex, 'No puede utilizarse la palabra empresa')
    .required(requiredString),
  legalId: Yup.string()
    .required(requiredString)
    .min(12, 'El RUT debe contener 12 caracteres.')
    .max(12, 'El RUT debe contener 12 caracteres.'),
  description: Yup.string().required(requiredString),
  vat: Yup.number().not([99], 'Debes seleccionar un valor'),
});

const EditBusinessSchema = Yup.object().shape({
  prettyName: Yup.string()
    .min(3, 'El nombre debe tener al menos 3 caracteres.')
    .max(50, 'El nombre debe tener máximo 50 caracteres.')
    .required(requiredString),
  vat: Yup.number().not([99], 'Debes seleccionar un valor'),
});

const CommerceSchema = Yup.object().shape({
  issuer: Yup.string().required(requiredString),
  acquirer: Yup.string().when('issuer', {
    is: (issuer: string) => !ISSUERS_WITHOUT_ACQUIRER.includes(issuer),
    then: Yup.string().required(requiredString),
  }),
  softDescriptor: Yup.string().required(requiredString),
  /*   accountNumber: Yup.string().when('issuer', {
    is: (issuer: string) => issuer === BANRED_ISSUER_ID,
    then: Yup.string().required(requiredString),
  }), */
});

const QuickLinkSchema = Yup.object().shape({
  currency: Yup.number().required(requiredString),
  amount: Yup.number().typeError(numberString),
});

const OnTheFlySchema = Yup.object().shape({
  amount: Yup.number().typeError(numberString).required(requiredString),
});

const ShareLinkSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
});

const AddReferenceSchema = Yup.object().shape({
  reference: Yup.string().required(requiredString),
});

const ContactSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  email: Yup.string().email(mailString).required(requiredString),
  message: Yup.string().required(requiredString),
});

const ShareByWhatsAppSchema = Yup.object().shape({
  phone: Yup.string().required(requiredString).matches(cellphoneRegex, cellphoneFormatString),
});

const ResendConfirmationSchema = Yup.object().shape({
  email: Yup.string().email(mailString),
});

const schemas = {
  LoginSchema,
  ResetPasswordSchema,
  RegisterUserSchema,
  ChangeEmailSchema,
  ChangePasswordSchema,
  ChangeUserPasswordSchema,
  ProfileSchema,
  UserSchema,
  LinkSchema,
  BusinessSchema,
  EditBusinessSchema,
  CommerceSchema,
  QuickLinkSchema,
  ShareLinkSchema,
  AddReferenceSchema,
  OnTheFlySchema,
  ContactSchema,
  ShareByWhatsAppSchema,
  ResendConfirmationSchema,
};

export default schemas;
