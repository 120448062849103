import { Commerce, CommercesState } from '../store/config/types';

export const issuerHelper = {
  getIssuersList,
};

function getIssuersList(commerces: CommercesState, commerce: Commerce | null) {
  return commerces?.issuers?.map((i) => {
    const existsCommerce =
      commerces.commerces &&
      commerces.commerces.filter((c) => c.issuerId && c.issuerId === Number(i.id)).length > 0;

    return {
      id: i.id,
      value: i.issuer,
      disabled: commerce ? false : existsCommerce,
      paymentProcessors: i.paymentProcessors,
    };
  });
}
