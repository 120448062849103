import { Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PaymentStatusEnum } from '../../store/config/enums';
import { Payment, PaymentsState, RootState } from '../../store/config/types';
import { EmptyMessage } from '../EmptyMessage';
import { Loading } from '../Loading';
import { ScrollTopButton } from '../ScrollTopButton';
import ColoredCardsWithTotals from '../TotalsAccordion/ColoredCardsWithTotals';
import PaymentCard from './PaymentCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coloredCard: {
      marginBottom: '1rem',
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      alignItems: 'flex-start',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    divider: {
      margin: '1rem 0',
    },
    scrollButton: {
      position: 'fixed',
      bottom: '1rem',
      right: '1rem',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      width: '2.375rem',
      height: '2.375rem',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      zIndex: 1000,
    },
    subtitle: {
      paddingTop: '1rem',
    },
  }),
);

interface PaymentsTableProps {
  maxItems?: number;
  loadNextPage?: () => void;
}

function PaymentsCards({ maxItems, loadNextPage }: PaymentsTableProps) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const payments: PaymentsState = useSelector((state: RootState) => state.payments);

  const isDashboard = location.pathname === '/dashboard';

  const handleOnClick = (row: Payment) => {
    navigate(`/payments/${row.id}`, { state: { from: location.pathname } });
  };

  const loadMore = () => {
    if (loadNextPage) {
      loadNextPage();
    }
  };

  let filteredPayments = payments?.payments;

  if (isDashboard) {
    filteredPayments =
      payments?.dashboardPayments
        ?.filter((payment) => payment?.status === PaymentStatusEnum.APPROVED)
        .slice(0, maxItems) || null;
  }

  return (
    <div className="payments-list">
      {!isDashboard && (
        <>
          <ColoredCardsWithTotals />
          <Typography variant="h6" className={classes.subtitle}>
            Historial de pagos
          </Typography>
        </>
      )}
      {payments.loadingPayments ? (
        <Loading />
      ) : filteredPayments && filteredPayments.length > 0 ? (
        <InfiniteScroll
          hasMore={payments.currentPage < payments.pageCount}
          next={loadMore}
          dataLength={payments.payments ? payments.payments.length : 0}
          loader={payments.loadingPayments && loadNextPage && <Loading />}
          className={classes.divider}
        >
          {filteredPayments.map((payment) => (
            <div key={payment.id} onClick={() => handleOnClick(payment)}>
              <PaymentCard payment={payment} />
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <EmptyMessage
          title={!payments?.dashboardPayments ? 'Aún no tienes pagos.' : 'No se encontraron pagos'}
          message={
            !payments?.dashboardPayments
              ? 'Podrás visualizarlos aquí cuando recibas algún pago.'
              : 'No se encontraron pagos con los filtros seleccionados.'
          }
        />
      )}
      {!isDashboard && <ScrollTopButton />}
    </div>
  );
}

export default PaymentsCards;
