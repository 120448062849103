import { Dispatch } from 'redux';
import { notificationsService } from '../../services/notifications.service';
import {
  RESENDING_NOTIFICATION,
  RESEND_NOTIFICATION_ERROR,
  RESEND_NOTIFICATION_SUCCESS,
  ResendNotificationActionTypes,
  SENDING_NOTIFICATION,
  SEND_NOTIFICATION_ERROR,
  SEND_NOTIFICATION_SUCCESS,
  SendNotificationActionTypes,
} from '../config/ActionTypes';
import { NotificationsResponse } from '../config/types';

export const sendNotification = (paymentId: number) => (dispatch: Dispatch<SendNotificationActionTypes>) => {
  dispatch(sendingNotification());

  return notificationsService.sendNotification(paymentId).then(
    (response) => {
      dispatch(sendNotificationSuccess(response!));
    },
    (error) => {
      dispatch(sendNotificationError(error));
    },
  );
};

const sendingNotification = (): SendNotificationActionTypes => ({
  type: SENDING_NOTIFICATION,
});

const sendNotificationSuccess = (
  notificationsRespone: NotificationsResponse,
): SendNotificationActionTypes => ({
  type: SEND_NOTIFICATION_SUCCESS,
  notification: notificationsRespone,
});

const sendNotificationError = (error: string): SendNotificationActionTypes => ({
  type: SEND_NOTIFICATION_ERROR,
  error,
});

export const resendNotification =
  (notificationId: string) => (dispatch: Dispatch<ResendNotificationActionTypes>) => {
    dispatch(resendingNotification(notificationId));

    return notificationsService.resendNotification(notificationId).then(
      (response) => {
        dispatch(resendNotificationSuccess());
      },
      (error) => {
        dispatch(resendNotificationError(error));
      },
    );
  };

const resendingNotification = (notificationId: string): ResendNotificationActionTypes => ({
  type: RESENDING_NOTIFICATION,
  notificationId,
});

const resendNotificationSuccess = (): ResendNotificationActionTypes => ({
  type: RESEND_NOTIFICATION_SUCCESS,
});

const resendNotificationError = (error: string): ResendNotificationActionTypes => ({
  type: RESEND_NOTIFICATION_ERROR,
  error,
});
