import { LinkIssuer } from '../store/config/types';

function verifyValidation(date: Date | string): boolean {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const now = new Date();
  return dateObj < now;
}

function verifyCommerces(commercesDtos: LinkIssuer[] | undefined): boolean {
  return commercesDtos?.length === 0;
}

export const linkHelper = { verifyValidation, verifyCommerces };
