import { Typography } from '@material-ui/core';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/plinks-error.json';

interface ErrorMessageProps {
  message: string;
}

function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <div className="error-message">
      <Lottie height="8rem" width="8rem" animationData={errorAnimation} loop={false} />
      <Typography variant="h6">{message}</Typography>
    </div>
  );
}

export default ErrorMessage;
