import variables from '../../styles/_variables.module.scss';

function NavigationBorder() {
  return (
    <svg
      width="4"
      height="32"
      viewBox="0 0 4 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="navigation-border"
    >
      <path
        d="M4 1.23077C4 0.551034 3.10457 0 2 0C0.89543 0 0 0.551034 0 1.23077V30.7692C0 31.449 0.89543 32 2 32C3.10457 32 4 31.449 4 30.7692V1.23077Z"
        fill={variables.plexoBlue}
      />
    </svg>
  );
}

export default NavigationBorder;
