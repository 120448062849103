import { Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserTypeEnum } from '../../store/config/enums';
import { getOptionsFromRole } from './navigationOptions';

function getTabOfPath(path: string, fromRoute?: string) {
  // THis workaround is to keep the active tab when the user navigates to the payment detail page
  if (path.includes('/payment')) {
    if (fromRoute === '/dashboard') {
      return 0;
    } else {
      return 3;
    }
  }

  switch (path) {
    case '/dashboard':
      return 0;

    case '/links':
      return 1;

    case '/new-permanent-link':
      return 1;

    case '/one-use-links':
      return 2;

    case '/new-one-use-link':
      return 2;

    case '/manage-clients':
      return 5;

    case '/verifications':
      return 6;

    case '/hidden':
      return 99;

    default:
      return 99;
  }
}
interface DesktopNavigatonProps {
  role?: UserTypeEnum;
}

function DesktopNavigation({ role }: DesktopNavigatonProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const fromRoute = location.state?.from || '/payments';
  const [value, setValue] = useState<number>(getTabOfPath(location.pathname));
  const options = getOptionsFromRole(role);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(getTabOfPath(location.pathname, fromRoute));
  }, [fromRoute, location.pathname]);

  return (
    <div className="header-options">
      <Tabs value={value} onChange={handleChange} classes={{ indicator: 'indicator' }}>
        {options &&
          options.map((o, index) => {
            return (
              <Tab
                key={index}
                disableRipple={true}
                component="span"
                label={
                  <button className="option">
                    {o.icon}
                    {o.name}
                  </button>
                }
                onClick={() => navigate(o.route)}
                value={o.value}
                classes={{ selected: 'selected-option', root: o.name === 'Hidden' ? 'hidden-option' : '' }}
              />
            );
          })}
      </Tabs>
    </div>
  );
}

export default DesktopNavigation;
