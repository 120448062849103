import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Form, Formik } from 'formik';
import schemas from '../../../data/schemas';
import { FormTextField } from '../../forms/FormTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    dialogActions: {
      padding: '2rem 0',
    },
  }),
);

interface DialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  url: string;
}

interface Values {
  phone: string;
}

function ShareByWhatsAppDialog({ open, setOpen, url }: DialogProps) {
  const classes = useStyles();
  const message = 'Paga+tu+compra+con+Plexo+Links+haciendo+clic+aquí:';

  const closeDialog = () => {
    setOpen(false);
  };

  const openWhatsAppLink = (values: Values) => {
    let phone = values.phone.trim();
    phone = phone.startsWith('09') ? `598${phone.slice(1)}` : phone;
    const whatsAppLink = `https://api.whatsapp.com/send/?phone=${phone}&text=${message}+${url}&app_absent=0`;
    window.open(whatsAppLink);
    closeDialog();
  };
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      className="dialog"
      maxWidth="xs"
      classes={{ paper: classes.container }}
    >
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" component="p">
          Compartir por WhatsApp
        </Typography>
        <Formik
          initialValues={{
            phone: '',
          }}
          onSubmit={openWhatsAppLink}
          validationSchema={schemas.ShareByWhatsAppSchema}
        >
          <Form className="form">
            <Field
              name="phone"
              component={FormTextField}
              type="text"
              placeholder="Celular"
              className="form-row"
            />
            <DialogActions className={classes.dialogActions}>
              <Button type="submit" variant="contained" color="primary" fullWidth disableElevation>
                Enviar link
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default ShareByWhatsAppDialog;
