import { createStyles, Theme, makeStyles, Typography } from '@material-ui/core';
import Lottie from 'lottie-react';
import alertAnimation from '../../assets/plinks-alert.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '2rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

function Forbidden() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Lottie height="12rem" width="12rem" animationData={alertAnimation} loop={false} />
      <Typography variant="h4">No tienes permisos para acceder a la página solicitada</Typography>
    </div>
  );
}

export default Forbidden;
