import colors from '../../../styles/_variables.module.scss';

function Cancel() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2086_11386" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="17.38" height="17.38" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2086_11386)">
        <path
          d="M6.08291 12.311L8.68991 9.70395L11.2969 12.311L12.3107 11.2971L9.70374 8.69012L12.3107 6.08312L11.2969 5.06929L8.68991 7.67629L6.08291 5.06929L5.06908 6.08312L7.67607 8.69012L5.06908 11.2971L6.08291 12.311ZM8.68991 15.9318C7.68814 15.9318 6.74673 15.7417 5.86566 15.3615C4.98459 14.9813 4.21818 14.4654 3.56643 13.8136C2.91468 13.1619 2.39871 12.3954 2.01852 11.5144C1.63834 10.6333 1.44824 9.69188 1.44824 8.69012C1.44824 7.68836 1.63834 6.74694 2.01852 5.86587C2.39871 4.9848 2.91468 4.21839 3.56643 3.56664C4.21818 2.91489 4.98459 2.39892 5.86566 2.01874C6.74673 1.63855 7.68814 1.44846 8.68991 1.44846C9.69167 1.44846 10.6331 1.63855 11.5142 2.01874C12.3952 2.39892 13.1616 2.91489 13.8134 3.56664C14.4651 4.21839 14.9811 4.9848 15.3613 5.86587C15.7415 6.74694 15.9316 7.68836 15.9316 8.69012C15.9316 9.69188 15.7415 10.6333 15.3613 11.5144C14.9811 12.3954 14.4651 13.1619 13.8134 13.8136C13.1616 14.4654 12.3952 14.9813 11.5142 15.3615C10.6331 15.7417 9.69167 15.9318 8.68991 15.9318ZM8.68991 14.4835C10.3072 14.4835 11.6771 13.9222 12.7996 12.7998C13.922 11.6773 14.4832 10.3074 14.4832 8.69012C14.4832 7.07282 13.922 5.70293 12.7996 4.58048C11.6771 3.45802 10.3072 2.89679 8.68991 2.89679C7.0726 2.89679 5.70272 3.45802 4.58026 4.58048C3.4578 5.70293 2.89658 7.07282 2.89658 8.69012C2.89658 10.3074 3.4578 11.6773 4.58026 12.7998C5.70272 13.9222 7.0726 14.4835 8.68991 14.4835Z"
          fill={colors.slateBlue}
        />
      </g>
    </svg>
  );
}

export default Cancel;
