import { Button, Theme, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../styles/theme';
import { ScreenTitle } from '../ScreenTitle';
import PaymentDetailActions from './PaymentDetailActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: '2rem',
      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: '0.4375rem 0.75rem',
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1.5rem',
      },
    },
  }),
);

interface PaymentDetailHeaderProps {
  cancelPayment: (plexoReferenceId: string) => void;
  sendNotification: (paymentId: number) => void;
  resendNotification: (notificationId: string) => void;
}

function PaymentDetailHeader({
  cancelPayment,
  sendNotification,
  resendNotification,
}: PaymentDetailHeaderProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.screenTitle}>
      <ScreenTitle title="Detalle de pago">
        {!isMobile && (
          <PaymentDetailActions
            cancelPayment={cancelPayment}
            sendNotification={sendNotification}
            resendNotification={resendNotification}
          />
        )}
      </ScreenTitle>
      {isMobile && (
        <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
          Volver
        </Button>
      )}
    </div>
  );
}

export default PaymentDetailHeader;
