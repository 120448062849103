import variables from '../../styles/_variables.module.scss';

function Close() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        d="M14.4728 12.3959C14.6273 12.5299 14.7524 12.6939 14.8404 12.8778C14.9283 13.0616 14.9772 13.2614 14.984 13.4647C14.9909 13.6681 14.9554 13.8706 14.88 14.0598C14.8046 14.2491 14.6907 14.4209 14.5454 14.5648C14.4002 14.7086 14.2267 14.8214 14.0356 14.8961C13.8446 14.9708 13.6401 15.0059 13.4348 14.9992C13.2295 14.9924 13.0278 14.944 12.8422 14.8569C12.6566 14.7698 12.491 14.6459 12.3557 14.4928L7.41593 9.60014L2.47615 14.4928C2.18878 14.7355 1.8196 14.8621 1.4422 14.8473C1.06481 14.8325 0.706906 14.6773 0.439837 14.4128C0.172767 14.1483 0.0161428 13.7938 0.0011799 13.42C-0.013783 13.0462 0.114015 12.6806 0.359102 12.3959L5.29888 7.5033L0.359102 2.61066C0.114015 2.32603 -0.013783 1.96037 0.0011799 1.58658C0.0161428 1.21278 0.172767 0.858295 0.439837 0.593774C0.706906 0.329254 1.06481 0.17413 1.4422 0.15931C1.8196 0.14449 2.18878 0.27107 2.47615 0.513818L7.41593 5.41484L12.3557 0.522201C12.4902 0.366035 12.6558 0.239126 12.8422 0.149455C13.0286 0.059785 13.2318 0.00929894 13.4388 0.00116778C13.6459 -0.00696337 13.8524 0.0274367 14.0454 0.102209C14.2384 0.176981 14.4136 0.290501 14.5602 0.435632C14.7067 0.580763 14.8213 0.754367 14.8968 0.945509C14.9723 1.13665 15.007 1.3412 14.9988 1.54629C14.9906 1.75137 14.9396 1.95255 14.8491 2.13717C14.7586 2.3218 14.6304 2.48586 14.4728 2.61905L9.53298 7.51168L14.4728 12.3959Z"
        fill={variables.primary}
      />
    </svg>
  );
}

export default Close;
