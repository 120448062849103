import { SVGProps } from 'react';
import variables from '../../styles/_variables.module.scss';

function Question(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 10.7097C7.50116 10.7097 7.09677 11.1141 7.09677 11.6129C7.09677 12.1117 7.50116 12.5161 8 12.5161C8.49884 12.5161 8.90323 12.1117 8.90323 11.6129C8.90323 11.1141 8.49884 10.7097 8 10.7097ZM8.24742 9.93548H7.73129C7.51752 9.93548 7.34419 9.76216 7.34419 9.54839V9.5361C7.34419 7.26697 9.84226 7.48387 9.84226 6.07132C9.84226 5.42564 9.26932 4.77419 7.98935 4.77419C7.04923 4.77419 6.56145 5.08545 6.07932 5.69974C5.95326 5.86039 5.72274 5.89313 5.55519 5.77639L5.13152 5.48123C4.95006 5.35481 4.91023 5.10152 5.04619 4.92713C5.7309 4.04884 6.54329 3.48387 7.98935 3.48387C9.6771 3.48387 11.1326 4.44358 11.1326 6.07132C11.1326 8.24597 8.63452 8.13097 8.63452 9.5361V9.54839C8.63452 9.76216 8.46119 9.93548 8.24742 9.93548ZM8 1.03226C11.8265 1.03226 14.9677 4.13145 14.9677 8C14.9677 11.8481 11.8513 14.9677 8 14.9677C4.15342 14.9677 1.03226 11.8528 1.03226 8C1.03226 4.15474 4.14845 1.03226 8 1.03226ZM8 0C3.58203 0 0 3.58332 0 8C0 12.4193 3.58203 16 8 16C12.418 16 16 12.4193 16 8C16 3.58332 12.418 0 8 0Z"
        fill={variables.slateBlue}
      />
    </svg>
  );
}

export default Question;
