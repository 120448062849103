import { IconButton, InputAdornment, InputBase, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from '../../styles/_variables.module.scss';
import { Cancel } from '../Icons/Cancel';

const useStyles = makeStyles({
  searchInput: {
    backgroundColor: styles.white,
    padding: '0.2rem 0rem',
    borderRadius: '6px',
    border: `1px solid ${styles.softGrey}`,
  },
  icon: {
    fill: styles.slateBlue,
    margin: '0 0.75rem 0 1.4375rem',
  },
});

interface SearchProps {
  hasClearButton?: boolean;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  triggerSearch: () => void;
  placeholder?: string;
}

function Search({
  searchTerm,
  setSearchTerm,
  triggerSearch,
  hasClearButton = true,
  placeholder = 'Buscar',
}: SearchProps) {
  const [typingTimer, setTypingTimer] = useState<number | undefined>(undefined);

  const classes = useStyles();

  const search = () => {
    if (searchTerm === '') {
      triggerSearch();
    } else {
      window.clearTimeout(typingTimer);
      setTypingTimer(window.setTimeout(triggerSearch, 1000));
    }
  };

  const searchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <InputBase
      className={classes.searchInput}
      startAdornment={
        <Tooltip title="El término de búsqueda debe tener al menos 3 caracteres">
          <SearchIcon className={classes.icon} />
        </Tooltip>
      }
      endAdornment={
        hasClearButton && (
          <InputAdornment position="end" className="cancel-search" onClick={() => setSearchTerm('')}>
            <IconButton aria-label="reset">
              <Cancel />
            </IconButton>
          </InputAdornment>
        )
      }
      placeholder={placeholder}
      fullWidth
      onChange={searchTermChange}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      value={searchTerm}
    />
  );
}

export default Search;
