import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { LinkToPay } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';
import { ArrowDown } from '../../Icons/ArrowDown';
import PaymentDetails from './PaymentDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionRoot: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '&.Mui-expanded': {
        margin: 0,
      },
      '&.MuiAccordion-root:before': {
        display: 'none',
      },
    },
    accordionSummary: {
      width: '100%',
      marginTop: '1.25rem',
      backgroundColor: '#F5F6FA',
      '& .MuiAccordionSummary-content': {
        margin: 0,
        padding: 0,
      },
      '& p': {
        color: styles.slateBlue,
        fontWeight: 600,
        fontSize: '0.75rem',
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '3rem',
      },
    },
    accordionDetails: {
      flexDirection: 'column',
      padding: '0 1rem',
    },
  }),
);

interface AccordionLinkDetailsProps {
  linkToPay: LinkToPay;
}

function AccordionLinkDetails({ linkToPay }: AccordionLinkDetailsProps) {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordionRoot}>
      <div className={classes.accordionSummary}>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <p>Detalle del pago</p>
        </AccordionSummary>
      </div>
      <AccordionDetails className={classes.accordionDetails}>
        <PaymentDetails linkToPay={linkToPay} />
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionLinkDetails;
