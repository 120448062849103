import {
  Button,
  CircularProgress,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: '10rem',
      minHeight: '2.75rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1.25rem',
      },
    },
  }),
);

interface SubmitButtonProps {
  loading: boolean;
  disabled?: boolean;
  formId: string;
  className?: string;
  label?: string;
}

function SubmitButton({ loading, formId, className, label, disabled }: SubmitButtonProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      form={formId}
      fullWidth={isMobile}
      className={`${classes.button} ${className}`}
      disabled={disabled || loading}
    >
      {loading ? (
        <CircularProgress size={20} thickness={6} color="inherit" />
      ) : (
        `${label || 'Guardar cambios'}`
      )}
    </Button>
  );
}

export default SubmitButton;
