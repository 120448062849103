import values from '../../data/values';
import {
  ADD_DASHBOARD_PAYMENTS,
  ADD_PAYMENTS,
  BEGINNING_PAYMENT,
  BEGIN_PAYMENT_ERROR,
  BEGIN_PAYMENT_SUCCESS,
  CANCELLING_PAYMENT,
  CANCEL_PAYMENT_ERROR,
  CANCEL_PAYMENT_SUCCESS,
  CLEAR_INSTRUMENT,
  CONFIRMING_PAYMENT,
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_SUCCESS,
  FETCHING_PAYMENTS,
  FETCH_PAYMENTS_ERROR,
  GETTING_PAYMENT,
  GET_PAYMENT_ERROR,
  GET_PAYMENT_SUCCESS,
  LOAD_PAYMENT_FILTERS,
  LOGOUT,
  LogoutActionTypes,
  PaymentDataTypes,
  REGISTER_CALLBACK_FAILED,
  TOGGLE_RESET_CLIENT_FILTER,
  TRANSACTION_CALLBACK,
  VISA_REDIRECTED,
} from '../config/ActionTypes';
import { Currency, DateSpan, PaymentStatusEnum, PaymentsColumnSortEnum, SortEnum } from '../config/enums';
import { Payment, PaymentsState } from '../config/types';

export const initialState: PaymentsState = {
  loadingPayments: false,
  payments: null,
  dashboardPayments: null,
  paymentsTotals: {
    oneTimeOnlyTotals: {
      uruguayanPesoAmount: 0,
      uruguayanPesoCount: 0,
      dollarAmount: 0,
      dollarCount: 0,
    },
    approvedTotals: {
      uruguayanPesoAmount: 0,
      uruguayanPesoCount: 0,
      dollarAmount: 0,
      dollarCount: 0,
    },
    rejectedTotals: {
      uruguayanPesoAmount: 0,
      uruguayanPesoCount: 0,
      dollarAmount: 0,
      dollarCount: 0,
    },
  },
  currentPage: 1,
  pageCount: 1,
  pageSize: values.paymentPageLimit,
  rowCount: 0,
  firstRowOnPage: 0,
  lastRowOnPage: 0,
  filters: {
    currency: { pesos: false, dollars: false },
    sortDirection: SortEnum.DESC,
    columnSort: PaymentsColumnSortEnum.Date,
    dateSpan: DateSpan.LAST7DAYS,
    issuer: undefined,
    collaborator: undefined,
  },
  paymentsErrorMessage: null,
  beginningPayment: false,
  plexoUrl: null,
  paymentId: null,
  beginPaymentErrorMessage: null,
  gettingPayment: false,
  transactionPayment: null,
  selectedPayment: null,
  issuer: null,
  getPaymentErrorMessage: null,
  confirmingPayment: false,
  confirmPaymentSuccess: false,
  confirmedPayment: null,
  confirmPaymentErrorMessage: null,
  cancellingPayment: false,
  cancelPaymentSuccess: false,
  cancelPaymentErrorMessage: null,
  paymentUrl: null,
  resetClientFilter: false,
};

export function paymentsReducer(
  state = initialState,
  action: PaymentDataTypes | LogoutActionTypes,
): PaymentsState {
  switch (action.type) {
    case FETCHING_PAYMENTS:
      return {
        ...state,
        loadingPayments: true,
        paymentsErrorMessage: null,
      };

    case ADD_PAYMENTS:
      let payments = [];
      if (action.paymentResponse?.payments?.results) {
        if (state.payments) payments = state.payments.concat(action.paymentResponse.payments.results);
        else payments = action.paymentResponse.payments.results;
      } else {
        payments = state.payments ?? [];
      }

      return {
        ...state,
        loadingPayments: false,
        payments: payments,
        paymentsTotals: {
          oneTimeOnlyTotals: action.paymentResponse?.oneTimeOnlyTotals,
          approvedTotals: action.paymentResponse?.approvedTotals,
          rejectedTotals: action.paymentResponse?.rejectedTotals,
        },
        currentPage: action.paymentResponse?.payments?.currentPage ?? 1,
        pageCount: action.paymentResponse?.payments.pageCount,
        pageSize: action.paymentResponse?.payments.pageSize ?? values.paymentPageLimit,
        rowCount: action.paymentResponse ? action.paymentResponse.payments.rowCount : 0,
        firstRowOnPage: action.paymentResponse?.payments.firstRowOnPage,
        lastRowOnPage: action.paymentResponse?.payments.lastRowOnPage,
        paymentsErrorMessage: null,
      };

    case ADD_DASHBOARD_PAYMENTS:
      return {
        ...state,
        loadingPayments: false,
        dashboardPayments: action.paymentResponse?.payments.results,
        paymentsErrorMessage: null,
      };

    case FETCH_PAYMENTS_ERROR:
      return {
        ...state,
        loadingPayments: false,
        payments: null,
        paymentsErrorMessage: action.error,
      };

    case LOAD_PAYMENT_FILTERS:
      return {
        ...state,
        filters: action.filters,
        loadingPayments: true,
        payments: null,
        currentPage: 1,
      };

    case BEGINNING_PAYMENT:
      return {
        ...state,
        beginningPayment: true,
        plexoUrl: null,
        beginPaymentErrorMessage: null,
      };

    case BEGIN_PAYMENT_SUCCESS:
      return {
        ...state,
        beginningPayment: false,
        plexoUrl: action.confirmPaymentResponse.plexoUrl,
        paymentId: action.confirmPaymentResponse.paymentId,
        beginPaymentErrorMessage: null,
        paymentUrl: action.paymentUrl,
      };

    case BEGIN_PAYMENT_ERROR:
      return {
        ...state,
        beginningPayment: false,
        plexoUrl: null,
        beginPaymentErrorMessage: action.error,
      };

    case GETTING_PAYMENT:
      return {
        ...state,
        gettingPayment: true,
        issuer: null,
        getPaymentErrorMessage: null,
      };

    case GET_PAYMENT_SUCCESS:
      return {
        ...state,
        gettingPayment: false,
        selectedPayment: action.paymentResponse,
        getPaymentErrorMessage: null,
      };

    case GET_PAYMENT_ERROR:
      return {
        ...state,
        gettingPayment: false,
        issuer: null,
        getPaymentErrorMessage: action.error,
      };

    case CLEAR_INSTRUMENT:
      return {
        ...state,
        selectedPayment: null,
      };

    case CONFIRMING_PAYMENT:
      return {
        ...state,
        confirmingPayment: true,
        confirmPaymentSuccess: false,
        confirmPaymentErrorMessage: null,
      };

    case CONFIRM_PAYMENT_SUCCESS:
      return {
        ...state,
        confirmingPayment: false,
        confirmPaymentSuccess: true,
        confirmedPayment: action.response,
        confirmPaymentErrorMessage: null,
      };

    case CONFIRM_PAYMENT_ERROR:
      return {
        ...state,
        confirmingPayment: false,
        confirmPaymentSuccess: false,
        confirmPaymentErrorMessage: action.error,
      };

    case CANCELLING_PAYMENT:
      return {
        ...state,
        cancelPaymentSuccess: false,
        cancelPaymentErrorMessage: null,
        cancellingPayment: true,
      };

    case CANCEL_PAYMENT_SUCCESS:
      let newTotals = state.paymentsTotals;
      let newPayments =
        state.payments &&
        state.payments?.map((payment: Payment) => {
          if (action.plexoReferenceId === payment.plexoReferenceId) {
            if (payment.amount.currency === Currency.DOLAR) {
              newTotals.approvedTotals.dollarAmount -= payment.amount.value;
              newTotals.approvedTotals.dollarCount--;
              newTotals.rejectedTotals.dollarAmount += payment.amount.value;
              newTotals.rejectedTotals.dollarCount++;
            } else {
              newTotals.approvedTotals.uruguayanPesoAmount -= payment.amount.value;
              newTotals.approvedTotals.uruguayanPesoCount--;
              newTotals.rejectedTotals.uruguayanPesoAmount += payment.amount.value;
              newTotals.rejectedTotals.uruguayanPesoCount++;
            }

            return {
              ...payment,
              status: PaymentStatusEnum.CANCELLED,
            };
          } else {
            return payment;
          }
        });

      let newPaymentsDashboardPayments =
        state.dashboardPayments &&
        state.dashboardPayments?.map((payment: Payment) => {
          if (action.plexoReferenceId === payment.plexoReferenceId) {
            if (payment.amount.currency === Currency.DOLAR) {
              newTotals.approvedTotals.dollarAmount -= payment.amount.value;
              newTotals.approvedTotals.dollarCount--;
              newTotals.rejectedTotals.dollarAmount += payment.amount.value;
              newTotals.rejectedTotals.dollarCount++;
            } else {
              newTotals.approvedTotals.uruguayanPesoAmount -= payment.amount.value;
              newTotals.approvedTotals.uruguayanPesoCount--;
              newTotals.rejectedTotals.uruguayanPesoAmount += payment.amount.value;
              newTotals.rejectedTotals.uruguayanPesoCount++;
            }

            return {
              ...payment,
              status: PaymentStatusEnum.CANCELLED,
            };
          } else {
            return payment;
          }
        });

      return {
        ...state,
        cancelPaymentSuccess: true,
        cancelPaymentErrorMessage: null,
        cancellingPayment: false,
        dashboardPayments: newPaymentsDashboardPayments,
        payments: newPayments,
        paymentsTotals: newTotals,
      };

    case CANCEL_PAYMENT_ERROR:
      return {
        ...state,
        cancelPaymentSuccess: false,
        cancelPaymentErrorMessage: action.error,
        cancellingPayment: false,
      };

    case VISA_REDIRECTED:
      return {
        ...state,
        confirmingPayment: true,
      };

    case REGISTER_CALLBACK_FAILED:
      return {
        ...state,
        confirmingPayment: false,
        confirmPaymentSuccess: false,
        //confirmPaymentErrorMessage: action.response,
      };

    case TRANSACTION_CALLBACK:
      return {
        ...state,
        confirmingPayment: false,
        confirmPaymentSuccess:
          action.response.isSuccess &&
          (action.response.status === PaymentStatusEnum.APPROVED ||
            action.response.status === PaymentStatusEnum.PENDING),
        confirmedPayment: action.response.paymentInfo
          ? {
              amountValue: action.response.paymentInfo.amount.value,
              currency: action.response.paymentInfo.amount.currency,
              errorCode: action.response.errorCode,
              installments: action.response.paymentInfo.installments,
              isSuccess: action.response.isSuccess,
              message: action.response.message,
              plexoReferenceId: action.response.paymentInfo.plexoReferenceId,
              validationErrors: action.response.validationErrors,
              plexoResultText: action.response.paymentInfo.plexoResultText,
            }
          : null,
        transactionPayment: action.response.paymentInfo ?? null,
        confirmPaymentErrorMessage: action.response.message
          ? action.response.message
          : action.response.status === PaymentStatusEnum.REJECTED ||
            action.response.status === PaymentStatusEnum.CANCELLED
          ? 'Pago fallido'
          : null,
      };

    case TOGGLE_RESET_CLIENT_FILTER:
      return {
        ...state,
        resetClientFilter: !state.resetClientFilter,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
