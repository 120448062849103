import { IconProps } from '../../../data/icon.type';
import colors from '../../../styles/_variables.module.scss';

function ArrowUp({ color, className }: IconProps) {
  color = color ?? colors.white;
  className = className ?? '';

  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.862171 6.54692L1.16217 6.84692C1.25891 6.94347 1.39 6.99769 1.52667 6.99769C1.66334 6.99769 1.79443 6.94347 1.89117 6.84692L5.48217 3.24392L5.48217 19.4009C5.48217 19.5378 5.53654 19.669 5.6333 19.7658C5.73007 19.8626 5.86132 19.9169 5.99817 19.9169H6.42817C6.56502 19.9169 6.69627 19.8626 6.79304 19.7658C6.88981 19.669 6.94417 19.5378 6.94417 19.4009L6.94417 3.24392L10.5312 6.85092C10.6279 6.94747 10.759 7.00169 10.8957 7.00169C11.0323 7.00169 11.1634 6.94747 11.2602 6.85092L11.5602 6.55092C11.6567 6.45418 11.7109 6.32309 11.7109 6.18642C11.7109 6.04975 11.6567 5.91866 11.5602 5.82192L6.57817 0.810922C6.48143 0.714378 6.35034 0.660156 6.21367 0.660156C6.077 0.660156 5.94591 0.714378 5.84917 0.810922L0.863171 5.81792C0.766494 5.91453 0.712092 6.04554 0.711905 6.18222C0.711717 6.31889 0.765759 6.45005 0.862171 6.54692Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowUp;
