import { Link, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Loading } from '../../components/Loading';
import { ScreenTitle } from '../../components/ScreenTitle';
import { SubmitButton } from '../../components/SubmitButton';
import { ChangeEmailDialog } from '../../components/dialogs/ChangeEmailDialog';
import { ChangePasswordDialog } from '../../components/dialogs/ChangePasswordDialog';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { updateAccount } from '../../store/action_creators/auth.actions';
import { Status, UserTypeEnum } from '../../store/config/enums';
import { Account, RootState } from '../../store/config/types';
interface Values {
  names: string;
  surnames: string;
  email: string;
}

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const theme = useTheme();
  const [emailOpen, setEmailOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [updatingAccount, setUpdatingAccount] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const submitProfile = (values: Values, helpers: FormikHelpers<Values>) => {
    const account: Account = {
      userId: auth.account!.userId,
      businessId: auth.account!.business.id!,
      names: values.names,
      surnames: values.surnames,
      telephoneNo: auth.account!.telephoneNo,
      type: auth.account!.type,
      email: values.email,
    };

    setUpdatingAccount(true);
    dispatch(updateAccount(account));
  };

  const closeSnack = () => {
    setUpdatingAccount(false);
  };

  const submitButton = <SubmitButton formId="profileForm" loading={updatingAccount} />;

  useEffect(() => {
    if (auth.account && auth.account.type === UserTypeEnum.COLLABORATOR) {
      navigate('/dashboard');
    }
  }, [auth.account, navigate]);

  return (
    <div className="screen-container profile">
      <ScreenTitle title="Perfil">{!isMobile && submitButton}</ScreenTitle>
      <Paper elevation={2} className="profile-paper">
        {auth.account ? (
          <Formik
            initialValues={{
              names: auth.account.names,
              surnames: auth.account.surnames,
              email: auth.account.email,
            }}
            validationSchema={schemas.ProfileSchema}
            onSubmit={submitProfile}
          >
            <Form className="form" id="profileForm">
              <Field
                className="form-row"
                name="names"
                component={FormTextField}
                type="text"
                placeholder="Nombres"
              />
              <Field
                className="form-row"
                name="surnames"
                component={FormTextField}
                type="text"
                placeholder="Apellidos"
              />
              <Field
                className="form-row"
                name="email"
                component={FormTextField}
                type="text"
                placeholder="Correo electrónico"
                disabled
              />
              <div className="links">
                <Link onClick={() => setPasswordOpen(true)}>
                  <u>Cambiar contraseña</u>
                </Link>
              </div>
              <div className="actions-row">{isMobile && submitButton}</div>
            </Form>
          </Formik>
        ) : (
          <Loading />
        )}
        <ChangeEmailDialog open={emailOpen} setOpen={setEmailOpen} />
        <ChangePasswordDialog open={passwordOpen} setOpen={setPasswordOpen} />
        <CustomSnackbar
          open={updatingAccount && (auth.updateAccountSuccess || auth.updateAccountErrorMessage !== null)}
          message={
            auth.updateAccountSuccess
              ? 'Se actualizaron los datos de perfil correctamente'
              : auth.updateAccountErrorMessage!
          }
          handleClose={closeSnack}
          type={auth.updateAccountSuccess ? Status.SUCCESS : Status.ERROR}
        />
      </Paper>
    </div>
  );
}

export default Profile;
