import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import BoltIcon from '../../assets/BoltIcon';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontWeight: Number(styles.normalTextWeight),
      height: 'fit-content',
      marginRight: '1rem',
      '& .MuiButton-startIcon': {
        color: styles.white,
        marginRight: 0,
      },
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
  }),
);

function OnTheFlyButton() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Button
      disableElevation
      className={classes.button}
      color="secondary"
      variant="contained"
      startIcon={<BoltIcon color={styles.white} transparent />}
      onClick={() => navigate('/on-the-fly-payments')}
    >
      Pago rápido
    </Button>
  );
}

export default OnTheFlyButton;
