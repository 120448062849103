import { Button, CircularProgress, Paper, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { ScreenTitle } from '../../components/ScreenTitle';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { resetPassword } from '../../store/action_creators/auth.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { Status } from '../../store/config/enums';
import { ResetPasswordRequest, RootState } from '../../store/config/types';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  resetPassword: (resetPasswordRequest: ResetPasswordRequest) =>
    dispatch(resetPassword(resetPasswordRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Values {
  email: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
      width: '50%',
    },
    secondaryButton: {
      width: '27%',
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
        width: '48%',
      },
    },
  }),
);

function ResetPassword({ auth, resetPassword }: PropsFromRedux) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [resetingPassword, setResetingPassword] = useState<boolean>(false);

  const submitLogin = (values: Values, helpers: FormikHelpers<Values>) => {
    const request: ResetPasswordRequest = {
      email: values.email,
    };

    setResetingPassword(true);
    resetPassword(request);
  };

  const closeSnack = () => {
    setResetingPassword(false);
  };

  return (
    <div className="screen-container profile">
      <Paper elevation={2}>
        <ScreenTitle title="Reiniciar contraseña" />
        <Formik
          initialValues={{ email: '' }}
          validationSchema={schemas.ResetPasswordSchema}
          onSubmit={submitLogin}
        >
          {() => (
            <Form className="form">
              <Field
                className="form-row"
                name="email"
                component={FormTextField}
                type="text"
                placeholder="Correo electrónico"
              />
              <div className="many-actions-row">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate('/login')}
                  className={`${classes.button} ${classes.secondaryButton}`}
                >
                  Volver
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={`${classes.button} ${classes.primaryButton}`}
                  disabled={resetingPassword}
                >
                  {resetingPassword ? <CircularProgress size={28} color="inherit" /> : 'Reiniciar'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <CustomSnackbar
          open={resetingPassword && (auth.resetPasswordSuccess || auth.resetPasswordErrorMessage !== null)}
          message={
            auth.resetPasswordSuccess
              ? 'Se ha enviado un mail con los pasos para reiniciar tu contraseña'
              : 'Ocurrió un error al reiniciar tu contraseña'
          }
          handleClose={closeSnack}
          type={auth.resetPasswordSuccess ? Status.SUCCESS : Status.ERROR}
        />
      </Paper>
    </div>
  );
}

export default connector(ResetPassword);
