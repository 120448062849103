import * as signalR from '@microsoft/signalr';
import * as ActionTypes from '../config/ActionTypes';
import { urlHelper } from '../../helpers/urlHelper';
import { Middleware } from 'redux';
import { RootState, TransactionStatus } from '../config/types';
import { registerCallbackFailed, transactionCallback } from '../action_creators/payments.actions';

const startSignalRConnection = async (
  connection: signalR.HubConnection,
  paymentId: string,
  message: string,
) => {
  return await connection
    .start()
    .then(() => {
      connection.send(message, paymentId);
      return connection;
    })
    .catch((error) => {
      console.log('signalR error: ' + error);
    });
};

const signalRMiddleware: Middleware<
  {}, // legacy type parameter added to satisfy interface signature
  RootState
> = (store) => (next) => (action) => {
  const connectionHub = urlHelper.getHubUrl();
  const protocol = new signalR.JsonHubProtocol();
  //const transport = signalR.HttpTransportType.WebSockets;

  const options = {
    //transport,
  };

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .configureLogging(signalR.LogLevel.Critical)
    .withAutomaticReconnect()
    .withHubProtocol(protocol)
    .build();

  //connection.onclose(() => setTimeout(startSignalRConnection(connection), 5000));

  connection.on('RegisterCallback', (success: boolean) => {
    console.log('RegisterCallback received: ' + success);
    if (!success) {
      next(registerCallbackFailed(success));
    }
  });

  connection.on('TransactionCallback', (transaction: TransactionStatus) => {
    console.log('TransactionCallback received');
    console.log(transaction);
    connection.send('PlexoDisconnect');
    connection.stop();
    next(transactionCallback(transaction));
  });

  if (action.type === ActionTypes.BEGIN_PAYMENT_SUCCESS) {
    let paymentId = action.confirmPaymentResponse.paymentId;

    startSignalRConnection(connection, paymentId, 'PlexoInit');
  }

  if (action.type === ActionTypes.VISA_REDIRECTED) {
    // got paymentId from query string
    console.log('VISA REDIRECTED, starting signalR connection');
    startSignalRConnection(connection, action.paymentId, 'VisaStatus');
  }

  return next(action);
};

export default signalRMiddleware;
