import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ArrowUp } from '../Icons/ArrowUp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollButton: {
      position: 'fixed',
      bottom: '1rem',
      right: '1rem',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      width: '2.375rem',
      height: '2.375rem',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      zIndex: 1000,
    },
    hidden: {
      display: 'none',
    },
  }),
);

function ScrollTopButton() {
  const classes = useStyles();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className={`${classes.scrollButton} ${!showButton && classes.hidden}`}
      onClick={handleButtonClick}
    >
      <ArrowUp />
    </button>
  );
}

export default ScrollTopButton;
