import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClientUsersTable } from '../../components/ClientUsersTable';
import Search from '../../components/FiltersComponent/Search';
import { ScreenTitle } from '../../components/ScreenTitle';
import { fetchAllUsers } from '../../store/action_creators/collaborators.actions';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '2rem 0',
      maxWidth: '60rem',
      margin: '0 auto',
    },
    screenTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%',
      marginBottom: '1rem',
    },
    button: {
      color: styles.slateBlue,
    },
    search: {
      margin: '1rem 0',
    },
    centeredCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        margin: '0 0 0 0.25rem',
      },
    },
    iconButton: {
      padding: '0.5rem 0',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  }),
);

function ManageClientUsers() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { client } = location.state;
  const [searchTerm, setSearchTerm] = useState<string>('');

  const triggerSearch = () => {
    if (searchTerm.length > 3 || searchTerm.length === 0) {
      dispatch(fetchAllUsers(client.id, searchTerm));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.screenTitle}>
        <ScreenTitle title={`Usuarios de ${client.prettyName}`}>
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            onClick={() => navigate('/manage-clients', { state: { client: client } })}
          >
            Volver
          </Button>
        </ScreenTitle>
      </div>
      <div className={classes.search}>
        <Search
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          triggerSearch={triggerSearch}
          placeholder="Buscar por nombre de usuario"
        />
      </div>
      <ClientUsersTable selectedClient={client} isForDashboard={false} />
    </div>
  );
}

export default ManageClientUsers;
