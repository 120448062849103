import { Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { Loading } from '../Loading';
import EmptyLinksMessage from './EmptyLinksMessage';
import LinkListRow from './LinkListRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '1.125rem 1.25rem',
      display: 'flex',
      flexDirection: 'column',
      width: '40%',
      '& div:last-child': {
        marginBottom: 0,
      },
      gap: '0.625rem',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: '1rem',
      },
    },
    title: {
      fontSize: '1.0625rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      color: styles.black,
      marginBottom: '0.5rem',
    },
  }),
);

function DashboardLinkList() {
  const classes = useStyles();
  const { links } = useSelector((state: RootState) => state);
  const dashboardLinks = links.dashboardLinks;

  return (
    <Paper className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Links de los últimos 7 días
      </Typography>
      {links.loadingLinks ? (
        <Loading />
      ) : dashboardLinks && dashboardLinks.length > 0 ? (
        dashboardLinks?.map((link) => <LinkListRow key={link.id} link={link} />)
      ) : (
        <EmptyLinksMessage />
      )}
    </Paper>
  );
}

export default DashboardLinkList;
