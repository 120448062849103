import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ScreenTitle } from '../../components/ScreenTitle';
import { IssuersForm } from '../../components/forms/IssuersForm';
import { RootState } from '../../store/config/types';

function RequestIssuers() {
  const auth = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.account) navigate(-1);
  }, [auth.account, navigate]);

  return (
    <div className="screen-container profile">
      <ScreenTitle title="Códigos de comercio" />
      <IssuersForm />
    </div>
  );
}

export default RequestIssuers;
