import { Button, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import network from '../../assets/network@2x.png';
import phone from '../../assets/phone.png';

function Home() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  if (cookies.get('token') !== undefined) {
    cookies.remove('token', {
      path: '/',
    });
    window.location.reload();
  }

  return (
    <div className="home-content">
      <div className="texts">
        <Typography className="title" variant="h1">
          <span className="green-text">Genera y gestiona</span>
          <br /> links de un uso o <br />
          de múltiples usos.
        </Typography>
        <Typography className="subtitle" variant="h3" component="h2">
          El link de pago es la herramienta más adaptable <br />y rápida para gestionar tus cobranzas online.
        </Typography>
        <img src={network} alt="network" />
      </div>
      <div className="home-buttons">
        <Button
          variant="contained"
          onClick={() => navigate('/login')}
          className="contained-home-button"
          color="primary"
          fullWidth
        >
          Ingresar a mi cuenta
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate('/register')}
          className="outlined-home-button"
          disableElevation
          fullWidth
        >
          Registrarme
        </Button>
      </div>
      <div className="image">
        <img src={phone} alt="phone" />
      </div>
    </div>
  );
}

export default Home;
