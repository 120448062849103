import { Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import EditIcon from '../../assets/EditIcon';
import { Collaborator } from '../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    span: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface UpdateCollaboratorButtonProps {
  collaborator: Collaborator;
}

function UpdateCollaboratorButton({ collaborator }: UpdateCollaboratorButtonProps) {
  const navigate = useNavigate();
  const classes = useStyles();

  const updateCollaborator = (collaborator: Collaborator) => {
    navigate('/user', { state: { collaborator } });
  };

  return (
    <Tooltip title="Editar" placement="top" enterDelay={500}>
      <span onClick={() => updateCollaborator(collaborator)} className={classes.span}>
        <EditIcon />
      </span>
    </Tooltip>
  );
}

export default UpdateCollaboratorButton;
