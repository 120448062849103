import { Collaborator } from '../../store/config/types';
import { CustomSwitch } from '../CustomSwitch';
import { StatusPill } from '../StatusPill';
import UpdateButton from './UpdateButton';

interface UserCardProps {
  collaborator: Collaborator;
  setEnablingCollaborator: (value: React.SetStateAction<boolean>) => void;
  handleEnableCollaborator: (collaborator: Collaborator) => void;
}

function UserCard({ collaborator, setEnablingCollaborator, handleEnableCollaborator }: UserCardProps) {
  return (
    <div className="user-card">
      <div className="row">
        <div>
          <span>{collaborator.names}</span>
          <span> {collaborator.surnames}</span>
        </div>
        <StatusPill
          label={collaborator.emailConfirmed ? 'Verificado' : 'Pendiente de verif'}
          color={collaborator.emailConfirmed ? 'success' : 'error'}
        />
      </div>
      <div className="horizontal-divider" />
      <div className="row">
        <span>{collaborator.email}</span>
        <div className="actions">
          <UpdateButton collaborator={collaborator} />
          <div className="vertical-divider" />
          <CustomSwitch
            color="primary"
            defaultChecked={collaborator.active}
            onClick={(e) => {
              setEnablingCollaborator(true);
              handleEnableCollaborator(collaborator);
            }}
            disabled={!collaborator.emailConfirmed}
          />
        </div>
      </div>
    </div>
  );
}

export default UserCard;
