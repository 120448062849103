import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import schemas from '../../../data/schemas';
import { changeCredentials } from '../../../store/action_creators/auth.actions';
import { AppActions } from '../../../store/config/ActionTypes';
import { Status } from '../../../store/config/enums';
import { ChangeCredentialsRequest, RootState } from '../../../store/config/types';
import { CustomSnackbar } from '../../CustomSnackbar';
import PasswordField from '../../PasswordField/PasswordField';
import { FormTextField } from '../../forms/FormTextField';

interface ChangeEmailDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  changeCredentials: (changeCredentialsRequest: ChangeCredentialsRequest) =>
    dispatch(changeCredentials(changeCredentialsRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & ChangeEmailDialogProps;

interface Values {
  email: string;
  currentPassword: string;
  password: string;
  repeatPassword: string;
}

function ChangeEmailDialog({ auth, changeCredentials, open, setOpen }: PropsType) {
  const [changingCredentials, setChangingCredentials] = useState<boolean>(false);

  const closeDialog = () => {
    setOpen(false);
  };

  const submitEmail = (values: Values, helpers: FormikHelpers<Values>) => {
    const changeCredentialsRequest: ChangeCredentialsRequest = {
      userId: 'id',
      email: values.email,
      oldPassword: values.currentPassword,
      newPassword: values.password,
    };

    setChangingCredentials(true);
    changeCredentials(changeCredentialsRequest);
  };

  const closeSnack = () => {
    setChangingCredentials(false);
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <Formik
        initialValues={{ email: '', currentPassword: '', password: '', repeatPassword: '' }}
        validationSchema={schemas.ChangeEmailSchema}
        onSubmit={submitEmail}
      >
        <Form className="form">
          <DialogContent classes={{ root: 'dialog-content' }}>
            <Typography variant="h4">Cambiar correo electrónico</Typography>
            <Field
              className="form-row"
              name="email"
              component={FormTextField}
              type="text"
              placeholder="Nuevo correo electrónico"
            />
            <Typography classes={{ root: 'plain-text' }} variant="subtitle2">
              Para cambiar el correo electrónico necesitamos que también ingreses una nueva contraseña.
            </Typography>
            <PasswordField inputName="currentPassword" placeholder="Contraseña actual" />
            <PasswordField inputName="password" placeholder="Nueva contraseña" />
            <PasswordField inputName="repeatPassword" placeholder="Repetir nueva contraseña" />
          </DialogContent>
          <DialogActions classes={{ root: 'dialog-actions' }}>
            <Button type="submit" color="primary" variant="contained" fullWidth>
              Modificar correo
            </Button>
          </DialogActions>
          <CustomSnackbar
            open={
              changingCredentials &&
              (auth.changeCredentialsSuccess || auth.changeCredentialsErrorMessage !== null)
            }
            message={
              auth.changeCredentialsSuccess
                ? 'Se actualizó el mail correctamente'
                : auth.changeCredentialsErrorMessage!
            }
            handleClose={closeSnack}
            type={auth.changeCredentialsSuccess ? Status.SUCCESS : Status.ERROR}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default connector(ChangeEmailDialog);
