import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Link,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import schemas from '../../../data/schemas';
import { authService } from '../../../services/auth.service';
import { registerUser } from '../../../store/action_creators/auth.actions';
import { AppActions } from '../../../store/config/ActionTypes';
import { RootState, UserRequest } from '../../../store/config/types';
import variables from '../../../styles/_variables.module.scss';
import { CustomSnackbar } from '../../CustomSnackbar';
import PasswordField from '../../PasswordField/PasswordField';
import { FormTextField } from '../FormTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      padding: '0.2rem',
      paddingLeft: 0,
      color: variables.shadeGrey,
      [theme.breakpoints.down('md')]: {
        marginRight: '0.4375rem',
        padding: 0,
      },
    },
    title: {
      color: variables.slateBlue,
      fontSize: '1.0625rem',
      fontWeight: +variables.semiBoldTextWeight,
    },
    text: {
      color: variables.slateBlue,
      fontSize: '0.9375rem',
      [theme.breakpoints.down('md')]: {
        alignSelf: 'center',
        textWrap: 'balance',
      },
    },
    backButton: {
      marginRight: '0.75rem',
    },
    divider: {
      height: '1px',
      width: '100%',
      margin: '1.875rem auto',
    },
    login: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.1875rem',
    },
  }),
);

interface UserValues {
  names: string;
  surnames: string;
  telephoneNo: string;
  email: string;
  password: string;
  repeatPassword: string;
}

interface UserFormProps {
  setEmail?: Dispatch<SetStateAction<string>>;
  goBack?: () => void;
}

interface LooseObject {
  [key: string]: any;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  registerUser: (user: UserRequest) => dispatch(registerUser(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & UserFormProps;

function UserForm({ auth, registerUser, setEmail, goBack }: PropsType) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [creating, setCreating] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [validatedEmail, setValidatedEmail] = useState<string>('');
  const [validating, setValidating] = useState<boolean>(false);
  const [mailHasError, setMailHasError] = useState<boolean>(false);

  const submitUser = (values: UserValues) => {
    const user: UserRequest = {
      names: values.names,
      surnames: values.surnames,
      telephoneNo: values.telephoneNo,
      email: values.email,
      password: values.password,
    };

    if (setEmail) {
      setEmail(values.email);
    }

    setCreating(true);
    registerUser(user);
  };

  const closeSnack = () => {
    setCreating(false);

    if (auth.changeCredentialsSuccess) {
      navigate('/dashboard');
    }
  };

  const validEmail = (email: string) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validate = async (values: UserValues) => {
    let mailValue = values.email;
    let errors: LooseObject = {};

    if (mailValue !== '' && validEmail(mailValue) && mailValue !== validatedEmail && !validating) {
      setValidating(true);
      setValidatedEmail(mailValue);

      await authService.checkEmailAvailability(mailValue).then(
        (response) => {
          setValidating(false);
          setMailHasError(false);
        },
        (error) => {
          setValidating(false);
          setMailHasError(true);
          errors.email = 'El email ya existe en la plataforma';
          return errors;
        },
      );
    } else if (mailHasError) {
      errors.email = 'El email ya existe en la plataforma';
      return errors;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{
          names: '',
          surnames: '',
          telephoneNo: '',
          email: '',
          password: '',
          repeatPassword: '',
        }}
        onSubmit={submitUser}
        validate={validate}
        validateOnChange={false}
        validationSchema={schemas.RegisterUserSchema}
      >
        <Form className="form">
          <Paper elevation={2}>
            <Typography className={classes.title}>Información de acceso</Typography>
            <Field
              className="row-field"
              name="names"
              component={FormTextField}
              type="text"
              placeholder="Nombres"
            />
            <Field
              className="row-field"
              name="surnames"
              component={FormTextField}
              type="text"
              placeholder="Apellidos"
            />
            <Field
              className="row-field"
              name="telephoneNo"
              component={FormTextField}
              type="text"
              placeholder="Teléfono"
            />
            <Field
              className="row-field"
              name="email"
              component={FormTextField}
              type="text"
              placeholder="Email"
            />
            <PasswordField inputName="password" inputStyles="row-field" buttonStyles="register-eye-button" />
            <div className="row-field texts-row">
              <Typography variant="body2" className={classes.text}>
                La contraseña debe tener al menos una mayúscula, una minúscula y un número.
              </Typography>
            </div>
            <PasswordField
              inputName="repeatPassword"
              placeholder="Repetir contraseña"
              inputStyles="row-field"
              buttonStyles="register-eye-button"
            />
            <div className="form-row">
              <div className="texts-row">
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  color="primary"
                />
                <p className={classes.text}>
                  Al registrarte, aceptas los{' '}
                  <Link href="/terms-and-conditions" rel="noopener" target="_blank">
                    términos y condiciones
                  </Link>
                </p>
              </div>
              <Divider className={classes.divider} orientation="horizontal" />
              <div className={classes.login}>
                <p className={classes.text}>
                  ¿Ya tienes una cuenta? <Link href="/login">Ingresar</Link>
                </p>
              </div>
            </div>
          </Paper>
          <div className="button-row-field">
            <Button className={classes.backButton} variant="outlined" color="secondary" onClick={goBack}>
              Volver
            </Button>
            <Button disabled={!checked} type="submit" color="primary" variant="contained" disableElevation>
              {creating ? <CircularProgress size={28} color="inherit" /> : 'Verificar email'}
            </Button>
          </div>
        </Form>
      </Formik>
      <CustomSnackbar
        open={creating && (auth.registerUserSuccess || auth.registerUserErrorMessage !== null)}
        message={
          auth.registerUserSuccess ? 'Se creó el usuario correctamente' : auth.registerUserErrorMessage!
        }
        handleClose={closeSnack}
        type={auth.registerUserSuccess ? 0 : 1}
      />
    </>
  );
}

export default connector(UserForm);
