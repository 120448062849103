import {
  Account,
  Business,
  ChangeCredentialsRequest,
  ChangeUserPasswordRequest,
  ConfirmEmailRequest,
  ConfirmResetPasswordRequest,
  ConfirmResetPasswordSuccessResponse,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  ResetPasswordRequest,
  TokenResponse,
  UnconfirmedUsersResponse,
  User,
  UserCredentials,
} from '../store/config/types';
import { get, post, put } from './base.service';
import { endpoints } from './endpoints';

export const authService = {
  login,
  resetPassword,
  confirmResetPassword,
  changeUserPassword,
  registerUser,
  validateRegister,
  registerBusiness,
  getAccount,
  updateAccount,
  modifyCredentials,
  checkEmailAvailability,
  resendConfirmation,
  getUnconfirmedUsers,
  verifyUser,
};

async function login({ user }: LoginRequest): Promise<LoginResponse | undefined> {
  return await post<LoginResponse>(`${endpoints['login']}`, user)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<string> {
  return await post<string>(`${endpoints['resetPassword']}`, resetPasswordRequest)
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw error;
    });
}

async function confirmResetPassword({
  id,
  token,
}: ConfirmResetPasswordRequest): Promise<ConfirmResetPasswordSuccessResponse | undefined> {
  return await get<ConfirmResetPasswordSuccessResponse | undefined>(
    `${endpoints['confirmResetPassword']}?id=${id}&token=${token}`,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function changeUserPassword({ userId, newPassword }: ChangeUserPasswordRequest): Promise<string> {
  return await put<string>(`${endpoints['changeUserPassword']}/${userId}`, { newPassword })
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw error;
    });
}

async function registerUser({ user }: RegisterRequest): Promise<UserCredentials | undefined> {
  return await post<UserCredentials>(`${endpoints['registerUser']}`, user)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function validateRegister({ userId, token }: ConfirmEmailRequest): Promise<TokenResponse | undefined> {
  return await get<TokenResponse>(`${endpoints['confirmEmail']}?userId=${userId}&token=${token}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function registerBusiness(business: Business): Promise<Business | undefined> {
  return await post<Business>(`${endpoints['registerBusiness']}`, business)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function getAccount(): Promise<User | undefined> {
  return await get<User>(`${endpoints['account']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateAccount(account: Account): Promise<User | undefined> {
  return await put<User>(`${endpoints['account']}`, account)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function modifyCredentials(changeCredentialsRequest: ChangeCredentialsRequest): Promise<string> {
  return await put<string>(
    `${endpoints['modifyCredentials']}/${changeCredentialsRequest.userId}`,
    changeCredentialsRequest,
  )
    .then((response) => {
      return response.stringBody!;
    })
    .catch((error) => {
      throw error;
    });
}

async function checkEmailAvailability(email: string): Promise<boolean | undefined> {
  return await get<boolean>(`${endpoints['emailVerification']}?email=${email}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function resendConfirmation(userId: string): Promise<string | undefined> {
  return await post<string>(`${endpoints['resendConfirmation']}`, { userId })
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function getUnconfirmedUsers(searchTerm?: string): Promise<UnconfirmedUsersResponse | undefined> {
  return await get<UnconfirmedUsersResponse>(
    `${endpoints['unconfirmed']}${searchTerm ? `/?keyword=${searchTerm}` : ''}`,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function verifyUser(userId: string): Promise<string | undefined> {
  return await put<string>(`${endpoints['verify']}/${userId}`, {})
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
