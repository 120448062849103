import { Link, Theme, createStyles, makeStyles } from '@material-ui/core';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { csvHeaders } from '../../data/csv';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { linkTypeEnumHelper } from '../../helpers/linkTypeEnumHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { paymentsService } from '../../services/payments.service';
import styles from '../../styles/_variables.module.scss';
import { CsvFile } from '../Icons/CsvFile';
import { PdfFile } from '../Icons/PdfFile';
import {
  AuthState,
  CSVPayment,
  FetchPaymentsRequest,
  Payment,
  PaymentsState,
} from './../../store/config/types';
import { PDFPayments } from './../PDFPayments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noWrap: {
      whiteSpace: 'nowrap',
      textDecoration: 'none !important',
    },
    csvLink: {
      textDecoration: 'none',
      margin: '0.3rem',
      marginRight: '0.6rem',
    },
    exportButton: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: styles.white,
      alignItems: 'center',
      gap: '0.4375rem',
      padding: '0.5625rem 1.1875rem',
      border: `1px solid ${styles.borderButtonSecondary}`,
      borderRadius: '4px',
      '& p': {
        margin: 0,
        padding: 0,
        color: styles.textButtonSecondary,
        textDecoration: 'none',
      },
      '&:hover': {
        backgroundColor: styles.borderButtonSecondary,
      },
    },
  }),
);

interface ExportPaymentsProps {
  auth: AuthState;
  payments: PaymentsState;
  businessId: number;
}

function ExportPayments({ auth, payments, businessId }: ExportPaymentsProps) {
  const classes = useStyles();
  const [loadingPayments, setLoadingPayments] = useState<boolean | null>(null);
  const [allLocalPayments, setAllLocalPayments] = useState<Payment[]>([]);
  const [hasClickedCSV, setHasClickedCSV] = useState<boolean>(false);
  const [hasClickedPDF, setHasClickedPDF] = useState<boolean>(false);
  const csvRef = useRef<any>();

  const fetchAllPayments = useCallback(() => {
    const request: FetchPaymentsRequest = {
      page: 1,
      limit: 1000,
      ...payments.filters,
    };

    paymentsService
      .fetchPayments(request, businessId)
      .then(
        (transactions) => {
          if (transactions) setAllLocalPayments(transactions.payments.results!);
        },
        (error) => setAllLocalPayments([]),
      )
      .finally(() => setLoadingPayments(false));
  }, [businessId, payments.filters]);

  const onClickHandler = (fileType: 'CSV' | 'PDF') => {
    setLoadingPayments(true);
    if (fileType === 'CSV') setHasClickedCSV(true);
    else if (fileType === 'PDF') setHasClickedPDF(true);
  };

  const downloadPDF = useCallback(async () => {
    const blob = await pdf(
      <PDFPayments data={allLocalPayments} business={auth.account ? auth.account.business : null} />,
    ).toBlob();
    saveAs(blob, 'Pagos');
  }, [allLocalPayments, auth.account]);

  useEffect(() => {
    if (loadingPayments) {
      fetchAllPayments();
    }
  }, [fetchAllPayments, loadingPayments]);

  useEffect(() => {
    if (allLocalPayments.length > 0 && loadingPayments === false) {
      if (hasClickedCSV) {
        csvRef.current.link.click();
        setHasClickedCSV(false);
      }
      if (hasClickedPDF) {
        downloadPDF();
        setHasClickedPDF(false);
      }
    }
  }, [allLocalPayments.length, downloadPDF, hasClickedCSV, hasClickedPDF, loadingPayments]);

  const buildCSVPayments = (payments: Payment[]): CSVPayment[] => {
    return payments.map((payment) => {
      return {
        status: paymentStatusEnumHelper.getStatusText(payment.status),
        date: dateHelper.toShorterNiceString(payment.date),
        description: payment?.description || '-',
        linkType: linkTypeEnumHelper.toNiceString(payment.linkType),
        issuerName: payment.issuerName,
        cardType: payment.cardType || '-',
        amount: `${currencyHelper.getCurrencyToken(
          payment.taxedAmount ? payment.taxedAmount.currency : payment.amount.currency,
        )} ${numberHelper.getNumberWithDots(payment.amount.value)}`,
        referencePayment: payment?.referencePayment || '-',
        clientComments: payment?.clientComments || '-',
        maskedPan: payment.maskedPan.slice(-4),
        installments: payment.installments,
        authorization: payment.authorization || '-',
        ticket: payment.ticket || '-',
      };
    });
  };

  return (
    <>
      <Link className={classes.noWrap} onClick={() => onClickHandler('CSV')}>
        <div className={classes.exportButton}>
          <CsvFile color={styles.slateBlue} />
          <p>{hasClickedCSV ? 'Cargando...' : 'Exportar a CSV'}</p>
        </div>
      </Link>
      <CSVLink
        data={buildCSVPayments(allLocalPayments)}
        filename="Pagos.csv"
        headers={csvHeaders}
        className={classes.csvLink}
        ref={csvRef}
      />
      <Link className={classes.noWrap} onClick={() => onClickHandler('PDF')}>
        <div className={classes.exportButton}>
          <PdfFile color={styles.slateBlue} />
          <p>{hasClickedPDF ? 'Cargando...' : 'Exportar a PDF'}</p>
        </div>
      </Link>
    </>
  );
}

export default ExportPayments;
