import { Theme, createStyles, makeStyles } from '@material-ui/core';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningContainter: {
      marginTop: '1.5625rem',
      padding: '0.8125rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: styles.warningBackground,
      borderRadius: '3px',
      border: `1px solid ${styles.warningBorder}`,
    },
    text: {
      fontSize: '0.875rem',
      margin: 0,
      color: styles.warning,
      '& svg': {
        marginRight: '0.375rem',
      },
    },
  }),
);
interface WarningProps {
  text: string;
  icon: any;
}

function Warning({ text, icon }: WarningProps) {
  const classes = useStyles();

  return (
    <div className={classes.warningContainter}>
      <p className={classes.text}>
        <span>{icon}</span>
        {text}
      </p>
    </div>
  );
}

export default Warning;
