import { Chip, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

export interface StatusPillProps {
  label: string | JSX.Element;
  color: 'success' | 'warning' | 'error' | 'info';
  isCard?: boolean;
  customStyles?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.4rem',
      border: 'none',
      borderRadius: '1rem',
      width: 'fit-content',
      fontSize: '0.875rem',
      height: 'auto',
      margin: 0,
    },
    fullWidth: {
      width: '100%',
    },
  }),
);

function StatusPill({ label, color, isCard, customStyles }: StatusPillProps) {
  const theme = useTheme();
  const classes = useStyles();

  const colorValue = theme.palette[color].dark;
  const backgroundColor = theme.palette[color].light;
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery('(min-width: 1280px) and (max-width: 1510px)');

  return (
    <Chip
      label={label}
      className={`${classes.root} ${isTablet && !isCard ? classes.fullWidth : ''}`}
      style={{
        margin: isDesktop && !isCard ? '0 auto' : undefined,
        color: colorValue,
        backgroundColor: backgroundColor,
        ...customStyles,
      }}
    />
  );
}

export default StatusPill;
