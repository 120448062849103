import colors from '../../../styles/_variables.module.scss';

function Check() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="table-action-icon"
    >
      <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke={colors.borderButtonSecondary} />
      <path
        d="M22.1092 12.0263L21.7232 11.6773C21.693 11.6379 21.6541 11.606 21.6095 11.584C21.5649 11.5621 21.5159 11.5506 21.4662 11.5506C21.4166 11.5506 21.3675 11.5621 21.323 11.584C21.2784 11.606 21.2395 11.6379 21.2092 11.6773L12.1782 20.5553L9.00025 17.4163C8.97103 17.3758 8.93234 17.343 8.88754 17.3209C8.84274 17.2987 8.7932 17.2879 8.74325 17.2893C8.69329 17.2879 8.64375 17.2987 8.59895 17.3209C8.55415 17.343 8.51546 17.3758 8.48625 17.4163L8.10025 17.7653C8.03579 17.8464 8.00055 17.9468 8.00025 18.0503C7.99846 18.0973 8.00642 18.1441 8.02364 18.1878C8.04085 18.2315 8.06693 18.2712 8.10025 18.3043L11.9252 22.0773C11.9589 22.1171 12.0007 22.1491 12.048 22.171C12.0952 22.193 12.1467 22.2043 12.1987 22.2043C12.2508 22.2043 12.3023 22.193 12.3495 22.171C12.3968 22.1491 12.4386 22.1171 12.4722 22.0773L22.1132 12.5653C22.1466 12.5322 22.1726 12.4925 22.1899 12.4488C22.2071 12.4051 22.215 12.3583 22.2132 12.3113C22.2118 12.2073 22.1751 12.1069 22.1092 12.0263ZM12.0322 15.9903C12.065 16.0298 12.106 16.0615 12.1524 16.0833C12.1988 16.1051 12.2495 16.1164 12.3007 16.1164C12.352 16.1164 12.4026 16.1051 12.4491 16.0833C12.4955 16.0615 12.5365 16.0298 12.5692 15.9903L19.5692 9.00734C19.6013 8.97396 19.6263 8.93441 19.6426 8.89109C19.6589 8.84777 19.6663 8.80159 19.6642 8.75534C19.6649 8.65307 19.6315 8.55349 19.5692 8.47234L19.1902 8.12534C19.1607 8.08639 19.1225 8.05481 19.0787 8.03306C19.0349 8.01132 18.9867 8 18.9377 8C18.8888 8 18.8406 8.01132 18.7968 8.03306C18.753 8.05481 18.7148 8.08639 18.6852 8.12534L12.2852 14.4783L9.99425 12.1993C9.96468 12.1604 9.9265 12.1288 9.8827 12.1071C9.83889 12.0853 9.79065 12.074 9.74175 12.074C9.69284 12.074 9.6446 12.0853 9.6008 12.1071C9.55699 12.1288 9.51881 12.1604 9.48925 12.1993L9.11025 12.5463C9.048 12.6275 9.01457 12.7271 9.01525 12.8293C9.01314 12.8756 9.02048 12.9218 9.03681 12.9651C9.05315 13.0084 9.07813 13.048 9.11025 13.0813L12.0322 15.9903Z"
        fill={colors.slateBlue}
      />
    </svg>
  );
}

export default Check;
