import {
  Button,
  Divider,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Payment, PaymentsState, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { EmptyMessage } from '../EmptyMessage';
import { Loading } from '../Loading';
import PaymentCardMobile from '../PaymentsCards/PaymentCard';
import PaymentCardDesktop from './PaymentCardDesktop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      color: styles.black,
      fontWeight: +styles.semiBoldTextWeight,
      fontSize: '1.0625rem',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '8.125rem',
      },
    },
    payments: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1.125rem 1.25rem',
      width: '60%',
      [theme.breakpoints.up('md')]: {
        '& > div:last-child > div > hr': {
          display: 'none',
        },
        '& > div:last-child > div > div': {
          paddingBottom: '0',
        },
      },
      [theme.breakpoints.down('md')]: {
        padding: '1rem',
        width: '100%',
        '& > div:last-child > div': {
          marginBottom: '0',
        },
      },
    },
    paymentsHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
      [theme.breakpoints.down('md')]: {
        gap: '2rem',
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
      fontSize: '0.875rem',
    },
    contentContainer: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    paymentsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    emptyMessageContainer: {
      height: 'calc(100% - 4rem)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '1.2rem',
    },
    showAllPaymentsButton: {
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        maxWidth: '8.125rem',
      },
      '& button': {
        minHeight: 0,
        minWidth: 0,
        margin: '0 auto',
        padding: '0.2rem 1.7rem',
        [theme.breakpoints.down('md')]: {
          margin: 0,
          alignSelf: 'baseline',
        },
      },
    },
    lastPaymentCard: {
      marginBottom: '2rem',
      '& hr:last-child': {
        display: 'none',
      },
    },
  }),
);

function DashboardPayments() {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const payments: PaymentsState = useSelector((state: RootState) => state.payments);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const paymentDesktopQty = 7;
  const paymentMobileQty = 4;

  const viewAllPaymentsButton = (
    <div className={classes.showAllPaymentsButton}>
      <Button variant="outlined" color="secondary" onClick={() => navigate('/payments')}>
        Ver todos
      </Button>
    </div>
  );

  const handleOnClick = (row: Payment) => {
    navigate(`/payments/${row.id}`, { state: { from: location.pathname } });
  };

  const filteredPayments =
    payments?.dashboardPayments?.slice(0, isMobile ? paymentMobileQty : paymentDesktopQty) || null;

  return (
    <Paper elevation={1} className={classes.payments}>
      <div className={classes.paymentsHeader}>
        <Typography variant="h5" className={classes.subtitle}>
          Pagos de los últimos 7 días
        </Typography>
        {isMobile && viewAllPaymentsButton}
      </div>
      {payments.loadingPayments ? (
        <Loading />
      ) : filteredPayments && filteredPayments.length > 0 ? (
        <div className={classes.contentContainer}>
          <div className={classes.paymentsContainer}>
            {filteredPayments.map((payment, index) => (
              <div
                key={payment.id}
                onClick={() => isMobile && handleOnClick(payment)}
                className={`${
                  !isMobile && index === filteredPayments.length - 1 ? classes.lastPaymentCard : ''
                }`}
              >
                {isMobile ? (
                  <PaymentCardMobile payment={payment} isDashboard />
                ) : (
                  <>
                    <PaymentCardDesktop payment={payment} />
                    {index < filteredPayments.length - 1 && <Divider />}
                  </>
                )}
              </div>
            ))}
          </div>
          {!isMobile && viewAllPaymentsButton}
        </div>
      ) : (
        <div className={classes.emptyMessageContainer}>
          <EmptyMessage
            title="Aún no tienes pagos."
            message="Podrás visualizarlos aquí cuando recibas alguno."
          />
        </div>
      )}
    </Paper>
  );
}

export default DashboardPayments;
