import { Theme, Typography, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactNode } from 'react';
import logo from '../../assets/logo-blue.svg';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '0 1rem',
        alignItems: 'center',
      },
    },
    informationContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '1rem',
      minWidth: '38.5rem',
      [theme.breakpoints.down('sm')]: {
        gap: '0.5rem',
        width: '100%',
        minWidth: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: '5%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '50%',
      },
    },
    informationTitle: {
      paddingTop: '1rem',
      textAlign: 'left',
      fontSize: '3.8125rem',
      color: styles.secondary,
      [theme.breakpoints.down('lg')]: {
        maxWidth: '36.875rem',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '0',
        textAlign: 'center',
        fontSize: '2rem',
      },
    },
    informationSubtitle: {
      textAlign: 'left',
      fontSize: '1.125rem',
      color: styles.secondary,
      fontWeight: Number(styles.normalTextWeight),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        textWrap: 'wrap',
        padding: '0 2rem',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 1rem',
      },
    },
    detailTitle: {
      fontSize: '1.125rem',
      color: styles.slateBlue,
      fontWeight: Number(styles.semiBoldTextWeight),
    },
    rowTitle: {
      fontSize: '0.875rem',
      color: styles.slateBlue,
      fontWeight: Number(styles.semiBoldTextWeight),
    },
    logo: {
      width: 'auto',
      height: '4.6875rem',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    animation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
      '& div': {
        width: '18rem',
        [theme.breakpoints.down('sm')]: {
          width: '14rem',
        },
      },
    },
  }),
);

interface LandingLayoutProps {
  title: string;
  subtitle?: string | JSX.Element;
  children?: ReactNode;
  content?: JSX.Element;
  animation?: JSX.Element;
}

function LandingLayout({ title, subtitle, content, animation, children }: LandingLayoutProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <div className={classes.container}>
        <div className={classes.informationContainer}>
          <img src={logo} alt="logo" className={classes.logo} />
          {!isDesktop && animation && <div className={classes.animation}>{animation}</div>}
          <Typography variant="h2" className={classes.informationTitle}>
            {title}
          </Typography>
          <Typography variant="subtitle2" className={classes.informationSubtitle}>
            {subtitle}
          </Typography>
          {children}
          {animation && content}
        </div>
        {isDesktop && animation && <div className={classes.animation}>{animation}</div>}
        {!animation && content}
      </div>
    </>
  );
}

export default LandingLayout;
