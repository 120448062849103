import { Button } from '@material-ui/core';
import Lottie from 'lottie-react';
import { Component, ErrorInfo, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import errorAnimation from '../../assets/plinks-error.json';
import { urlHelper } from '../../helpers/urlHelper';
import { LandingLayout } from '../Landing';

interface Props {
  children: ReactNode;
  navigate: (path: string) => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    console.log(_);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    const errorAnimationData = <Lottie animationData={errorAnimation} loop={false} />;

    const handleOnclick = () => {
      if (urlHelper.isPublicPage(window.location.pathname)) {
        this.props.navigate('/');
      } else {
        this.props.navigate('/dashboard');
      }
      window.location.reload();
    };

    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <div className="screen-container">
            <LandingLayout
              title="Ocurrió un error inesperado"
              animation={errorAnimationData}
              children={
                <>
                  <p>Por favor vuelve a intentarlo más tarde</p>
                  <Button onClick={handleOnclick} color="primary" variant="contained" disableElevation>
                    Volver al inicio
                  </Button>
                </>
              }
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function ErrorBoundaryWithNavigate(props: Omit<Props, 'navigate'>) {
  const navigate = useNavigate();
  return <ErrorBoundary {...props} navigate={navigate} />;
}

export default ErrorBoundaryWithNavigate;
