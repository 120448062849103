import { Button } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BusinessIssuer, LinkIssuer } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import IssuerCard from './IssuerCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: styles.secondaryMain,
      fontSize: '1.125rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      marginBottom: '21px',
    },
    button: {
      marginTop: '1rem',
      alignSelf: 'flex-end',
      justifySelf: 'flex-end',
      width: '40%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }),
);

interface IssuerListProps {
  issuers: BusinessIssuer[] | LinkIssuer[];
  selectedIssuer: BusinessIssuer | null;
  disabled: boolean;
  clickAction: (issuer: BusinessIssuer) => void;
  setStep: (step: number) => void;
  hasButton?: boolean;
}

function IssuersCardList({
  issuers,
  selectedIssuer,
  disabled,
  clickAction,
  setStep,
  hasButton,
}: IssuerListProps) {
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.title}>Elige el medio de pago</h1>
      {issuers.map((issuer) => {
        return (
          <IssuerCard
            key={issuer.id}
            issuer={issuer}
            selectedIssuer={selectedIssuer}
            clickAction={clickAction}
          />
        );
      })}
      {hasButton && (
        <Button
          className={classes.button}
          onClick={() => setStep(2)}
          variant="contained"
          color="primary"
          disabled={!selectedIssuer}
        >
          Continuar
        </Button>
      )}
    </>
  );
}

export default IssuersCardList;
