import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/_variables.module.scss';
import { ChevronLeft } from '../Icons/ChevronLeft';
import { ChevronRight } from '../Icons/ChevronRight';

interface CustomPaginationProps {
  total: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& .Mui-disabled.activePage': {
      color: colors.white,
    },
  },
  chevronButton: {
    padding: 0,
    width: '2.25rem',
    minWidth: '2.25rem',
    '& .MuiButton-label': {
      width: '2.25rem',
    },
  },
  pageButton: {
    minWidth: '36px',
    color: colors.slateBlue,
  },
  activePage: {
    backgroundColor: theme.palette.primary.main,
    '&.Mui-disabled': {
      color: `${colors.white}`,
    },
  },
  ellipsis: {
    padding: theme.spacing(1),
  },
}));

const range = (from: number, to: number, step = 1): number[] => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

function CustomPagination({ total, itemsPerPage, currentPage, onPageChange }: CustomPaginationProps) {
  const classes = useStyles();

  const handlePrevious = () => {
    onPageChange(Math.max(1, currentPage - 1));
  };

  const handleNext = () => {
    onPageChange(Math.min(total, currentPage + 1));
  };

  const paginationItems = () => {
    const pages = [];
    const totalNumbers = 5;
    const totalBlocks = totalNumbers + 2;

    if (total > totalBlocks) {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(total - 1, currentPage + 2);
      pages.push(1);
      if (startPage > 2) {
        pages.push('...');
      }
      const rangePages = range(startPage, endPage);
      pages.push(...rangePages);
      if (endPage < total - 1) {
        pages.push('...');
      }
      pages.push(total);
    } else {
      pages.push(...range(1, total));
    }

    return pages;
  };

  const paginationButtons = paginationItems().map((page) => {
    if (page === '...') {
      return (
        <span key={page} className={classes.ellipsis}>
          {page}
        </span>
      );
    }
    return (
      <Button
        key={page}
        onClick={() => onPageChange(page as number)}
        disabled={currentPage === page}
        className={`${classes.pageButton} ${currentPage === page ? classes.activePage : ''}`}
      >
        {page}
      </Button>
    );
  });

  return (
    <div className={classes.pagination}>
      <Button onClick={handlePrevious} disabled={currentPage === 1} className={classes.chevronButton}>
        <ChevronLeft />
      </Button>
      {paginationButtons}
      <Button onClick={handleNext} disabled={currentPage === total} className={classes.chevronButton}>
        <ChevronRight />
      </Button>
    </div>
  );
}

export default CustomPagination;
