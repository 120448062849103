import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import schemas from '../../../data/schemas';
import { changeCredentials, changeUserPassword } from '../../../store/action_creators/auth.actions';
import { Status, UserTypeEnum } from '../../../store/config/enums';
import {
  ChangeCredentialsRequest,
  ChangeUserPasswordRequest,
  Collaborator,
  RootState,
} from '../../../store/config/types';
import { CustomSnackbar } from '../../CustomSnackbar';
import PasswordField from '../../PasswordField/PasswordField';
import { SubmitButton } from '../../SubmitButton';

interface ChangePasswordDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  collaborator?: Collaborator | null;
}

export interface Values {
  currentPassword: string;
  password: string;
  repeatPassword: string;
}

function ChangePasswordDialog({ open, setOpen, collaborator }: ChangePasswordDialogProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state: RootState) => state.auth);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const isUpdatingOwnPassword =
    location.pathname === '/profile' || auth.account?.type === UserTypeEnum.COLLABORATOR;

  const closeDialog = () => {
    setOpen(false);
  };

  const submitPassword = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      if (!isUpdatingOwnPassword && collaborator) {
        const ChangeUserPasswordRequest: ChangeUserPasswordRequest = {
          userId: collaborator.userId,
          newPassword: values.password,
        };
        setSubmitting(true);
        dispatch(changeUserPassword(ChangeUserPasswordRequest));
      } else {
        const changeCredentialsRequest: ChangeCredentialsRequest = {
          userId: auth.account.userId,
          email: auth.account.email,
          oldPassword: values.currentPassword,
          newPassword: values.password,
        };
        setSubmitting(true);
        dispatch(changeCredentials(changeCredentialsRequest));
      }
    }
  };

  const closeSnack = () => {
    setSubmitting(false);

    if (auth.changeCredentialsSuccess || auth.changeUserPasswordSuccess) {
      closeDialog();
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="xs" className="dialog change-password">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <Formik
        initialValues={{ currentPassword: '', password: '', repeatPassword: '' }}
        validationSchema={
          isUpdatingOwnPassword ? schemas.ChangePasswordSchema : schemas.ChangeUserPasswordSchema
        }
        onSubmit={submitPassword}
      >
        <Form className="form" id="changePassword">
          <DialogContent classes={{ root: 'dialog-content' }}>
            <Typography variant="h4">Cambiar contraseña</Typography>
            {isUpdatingOwnPassword && (
              <PasswordField inputName="currentPassword" placeholder="Contraseña actual" />
            )}
            <PasswordField inputName="password" placeholder="Nueva contraseña" />
            <PasswordField inputName="repeatPassword" placeholder="Repetir nueva contraseña" />
          </DialogContent>
          <DialogActions classes={{ root: 'dialog-actions' }}>
            <SubmitButton
              className="button-full-width"
              label="Modificar contraseña"
              loading={submitting}
              formId="changePassword"
            />
          </DialogActions>
          <CustomSnackbar
            open={
              submitting &&
              (auth.changeCredentialsSuccess ||
                auth.changeCredentialsErrorMessage !== null ||
                auth.changeUserPasswordSuccess ||
                auth.changeUserPasswordErrorMessage !== null)
            }
            message={
              auth.changeCredentialsSuccess || auth.changeUserPasswordSuccess
                ? 'Se actualizó la contraseña correctamente'
                : auth.changeCredentialsErrorMessage! || auth.changeUserPasswordErrorMessage!
            }
            handleClose={closeSnack}
            type={
              auth.changeCredentialsSuccess || auth.changeUserPasswordSuccess ? Status.SUCCESS : Status.ERROR
            }
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

export default ChangePasswordDialog;
