import { AppBar, Button, Link } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import whiteLogo from '../../assets/logo-white.svg';
import logo from '../../assets/logo.svg';
import questionCircle from '../../assets/question-circle.svg';

interface PublicHeaderProps {
  isHome?: boolean;
}

function PublicHeader({ isHome = false }: PublicHeaderProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const isRegister =
    location.pathname.includes('verify') ||
    location.pathname.includes('register') ||
    location.pathname.includes('first-payment-method') ||
    location.pathname.includes('request-issuers');

  const redirectTo = (path: string) => {
    navigate(path);
  };

  return (
    <AppBar elevation={0} className="header" color={isHome ? 'secondary' : 'primary'}>
      <div className="header-content">
        <img
          src={isHome ? whiteLogo : logo}
          className={isHome ? 'public-img' : 'logo'}
          alt="logo"
          onClick={() => !isRegister && redirectTo('/')}
        />
        {!isHome && !location.pathname.includes('/faqs') && !location.pathname.includes('/user-faqs') ? (
          <Link href="/faqs" target="_blank">
            <Button variant="outlined" color="secondary" className="help">
              <img src={questionCircle} alt="question" className="icon" />
              Ayuda
            </Button>
          </Link>
        ) : (
          !location.pathname.includes('/faqs') &&
          !location.pathname.includes('/user-faqs') && (
            <div className="home-header-options">
              <Button
                id="home-header-register"
                variant="outlined"
                onClick={() => redirectTo('/register')}
                className="outlined-home-button"
                disableElevation
              >
                Registrarme
              </Button>
              <Button
                variant="contained"
                onClick={() => redirectTo('/login')}
                className="contained-home-button"
                color="primary"
              >
                Ingresar a mi cuenta
              </Button>
            </div>
          )
        )}
      </div>
    </AppBar>
  );
}

export default PublicHeader;
