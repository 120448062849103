import Cookies from 'universal-cookie';
import { errorHelper } from '../helpers/errorHelper';

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
  stringBody?: string;
}

const cookies = new Cookies();

async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);
  await response.text().then((body) => {
    try {
      response.parsedBody = JSON.parse(body);
    } catch (ex) {
      response.stringBody = body;
    }
  });

  if (!response.ok) {
    if (response.status === 401) {
      cookies.remove('token');
      window.location.pathname = '/login';
    }
    if (response.parsedBody !== undefined && 'errorCode' in response.parsedBody) {
      const code = (response.parsedBody as unknown as { errorCode: string })['errorCode'];
      const message = (response.parsedBody as unknown as { message: string })['message'];
      let error =
        Number(code) < 1200 ? errorHelper.getErrorString(Number(code), message) : `Error ${code}: ${message}`;
      throw error;
    }

    throw new Error(response.statusText);
  }

  return response;
}

export async function get<T>(
  path: string,
  args: RequestInit = {},
  headers: Record<string, string> = {},
): Promise<HttpResponse<T>> {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${new Cookies().get('token')}`,
  };

  const mergedHeaders = new Headers({ ...defaultHeaders, ...headers });

  const requestOptions: RequestInit = {
    method: 'get',
    headers: mergedHeaders,
    ...args,
  };

  return await http<T>(new Request(path, requestOptions));
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('token')}`,
    }),
    body: JSON.stringify(body),
  },
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function postForm<T>(
  path: string,
  body: FormData,
  args: RequestInit = {
    method: 'post',
    headers: new Headers({
      Authorization: `Bearer ${cookies.get('token')}`,
    }),
    body,
  },
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'put',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('token')}`,
    }),
    body: JSON.stringify(body),
  },
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function putForm<T>(
  path: string,
  body: FormData,
  args: RequestInit = {
    method: 'put',
    headers: new Headers({
      Authorization: `Bearer ${cookies.get('token')}`,
    }),
    body,
  },
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function httpDelete<T>(
  path: string,
  args: RequestInit = {
    method: 'delete',
    headers: new Headers({
      Authorization: `Bearer ${cookies.get('token')}`,
    }),
  },
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}
