import styles from '../styles/_variables.module.scss';

function DeleteIcon({ disabled }: { disabled?: boolean }) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Button/ Icon/  delete">
        <rect
          id="Rectangle 165"
          x="0.5"
          y="0.501465"
          width="29"
          height="29"
          rx="3.5"
          fill="white"
          stroke={styles.borderButtonSecondary}
          className={`table-action-icon${disabled ? '-disabled' : ''}`}
        />
        <path
          id="trash"
          d="M21.207 9.77746H18.322L17.389 8.53446C17.265 8.36908 17.1042 8.23482 16.9194 8.14231C16.7345 8.0498 16.5307 8.00158 16.324 8.00146H14.1C13.894 8.0022 13.6909 8.05072 13.5068 8.1432C13.3227 8.23569 13.1626 8.36962 13.039 8.53446L12.107 9.77746H9.22201C9.19269 9.77746 9.16365 9.78327 9.13659 9.79455C9.10952 9.80583 9.08496 9.82237 9.06432 9.84319C9.04368 9.86402 9.02737 9.88873 9.01633 9.91589C9.00529 9.94306 8.99974 9.97214 9.00001 10.0015V10.4455C9.00001 10.5043 9.0234 10.5608 9.06503 10.6024C9.10666 10.6441 9.16313 10.6675 9.22201 10.6675H9.74601L10.667 20.9965C10.6986 21.3262 10.8519 21.6324 11.0971 21.8552C11.3423 22.078 11.6617 22.2015 11.993 22.2015H18.435C18.7674 22.2013 19.0877 22.0769 19.3331 21.8526C19.5784 21.6284 19.7311 21.3205 19.761 20.9895L20.682 10.6605H21.206C21.2649 10.6605 21.3214 10.6371 21.363 10.5954C21.4046 10.5538 21.428 10.4973 21.428 10.4385V10.0015C21.4283 9.97223 21.4228 9.94323 21.4118 9.91613C21.4008 9.88903 21.3846 9.86436 21.364 9.84355C21.3435 9.82273 21.3191 9.80618 21.2921 9.79484C21.2652 9.7835 21.2362 9.7776 21.207 9.77746ZM13.75 9.06646C13.7911 9.0123 13.8439 8.9682 13.9046 8.93753C13.9652 8.90686 14.0321 8.89042 14.1 8.88946H16.324C16.3929 8.88977 16.4608 8.906 16.5224 8.93688C16.5839 8.96775 16.6376 9.01245 16.679 9.06746L17.212 9.77746H13.217L13.75 9.06646ZM18.877 20.9135C18.8679 21.0241 18.8175 21.1272 18.7358 21.2023C18.654 21.2773 18.547 21.3188 18.436 21.3185H11.993C11.882 21.3188 11.775 21.2773 11.6932 21.2023C11.6115 21.1272 11.5611 21.0241 11.552 20.9135L10.637 10.6645H19.792L18.877 20.9135Z"
          fill={styles.slateBlue}
          className={`${disabled ? 'table-action-icon-disabled' : ''}`}
        />
      </g>
    </svg>
  );
}

export default DeleteIcon;
