import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode.react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { numberHelper } from '../../../helpers/numberHelper';
import useWindowDimensions from '../../../helpers/windowDimensionsHelper';
import { Amount, RootState } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';
import PdfQr from './PdfQr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: 0,
      paddingBottom: '1.3125rem',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    closeContainer: {
      display: 'flex',
      justifyContent: 'flex-end',

      '& *': {
        cursor: 'pointer',
        fill: styles.iconColor,
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      margin: '0 0.625rem',
      fontSize: '1.0625rem',
      lineHeight: '1.3',
      fontWeight: Number(styles.semiBoldTextWeight),
    },
    descriptionText: {
      maxWidth: '18.625rem',
      margin: '0.7813rem 0.625rem 0',
      color: styles.slateBlue,
    },
    icon: {
      cursor: 'pointer',
    },
    qrCode: {
      display: 'flex',
      justifyContent: 'center',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    amountText: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0 0.625rem 1.125rem',
      fontSize: '1rem',
      color: styles.textButtonSecondary,
    },
  }),
);

const calculateQrWidth = (windowWidth: number): number => {
  // 64 for margins, 48 for paddings
  return windowWidth - 64 - 48;
};

interface QrCodeDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  url: string;
  description?: string;
  price?: Amount;
}

function QrCodeDialog({ open, setOpen, url, description, price }: QrCodeDialogProps) {
  const auth = useSelector((state: RootState) => state.auth);
  const [hasClicked, setHasClicked] = useState<boolean | null>(null);

  const classes = useStyles();
  const closeDialog = () => {
    setOpen(false);
  };
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const downloadPDF = useCallback(async () => {
    const base64Image: string = (document.querySelector('canvas') as HTMLCanvasElement).toDataURL();
    const blob = await pdf(
      <PdfQr url={base64Image} description={description} price={price} business={auth.account?.business} />,
    ).toBlob();
    saveAs(blob, `${auth.account?.business.urlName}_QR`);
    setHasClicked(false);
  }, [auth, description, price]);

  useEffect(() => {
    if (hasClicked) {
      downloadPDF();
    }
  }, [downloadPDF, hasClicked]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      className="dialog"
      maxWidth="sm"
      classes={{ paper: classes.container }}
    >
      <DialogTitle className={classes.closeContainer}>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.titleContainer}>
          <Typography variant="h4" component="p" className={classes.title}>
            {description
              ? description
              : price
              ? `${price.value} ${currencyHelper.getCurrencyName(price.currency)}`
              : 'Código QR'}
          </Typography>
        </div>
        {url && (
          <div>
            <p className={classes.descriptionText}>
              Muestra este QR a quien te pagará.
              <br />
              Al capturarlo con su teléfono móvil, tu cliente podrá navegar hacia la página de pago.
            </p>
            <div className={classes.qrCode}>
              <QRCode includeMargin={true} value={url} size={isMobile ? calculateQrWidth(width) : 300} />
            </div>
            {!!price?.value && (
              <p className={classes.amountText}>
                <span className={classes.noWrap}>Importe</span>{' '}
                <span className={classes.noWrap}>
                  {currencyHelper.getCurrencyToken(price.currency)}{' '}
                  {numberHelper.getNumberWithDots(price.value)}
                </span>
              </p>
            )}
            <Button variant="outlined" color="secondary" fullWidth onClick={() => setHasClicked(true)}>
              Descargar QR
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default QrCodeDialog;
