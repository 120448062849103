import { Button, Theme, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { LinkTypeEnum } from '../../store/config/enums';
import { Link } from '../../store/config/types';
import { theme } from '../../styles/theme';
import { ScreenTitle } from '../ScreenTitle';
import LinkActions from './LinkActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: '2rem',
      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: '0.4375rem 0.75rem',
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1.5rem',
      },
    },
  }),
);

interface NewLinkHeaderProps {
  setClickedCopy: React.Dispatch<React.SetStateAction<boolean | null>>;
  linkType: LinkTypeEnum;
  link: Link;
  updatingLink: boolean;
  creatingLink: boolean;
  clickedCopy: boolean | null;
}

function NewLinkHeader({
  linkType,
  link,
  setClickedCopy,
  clickedCopy,
  updatingLink,
  creatingLink,
}: NewLinkHeaderProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPermantent = linkType === LinkTypeEnum.PERMANENT;
  const screenTitle = link ? 'Editar Link' : isPermantent ? 'Nuevo link multiuso' : 'Nuevo link de 1 uso';

  return (
    <div className={classes.screenTitle}>
      <ScreenTitle title={screenTitle}>
        {!isMobile && (
          <LinkActions
            setClickedCopy={setClickedCopy}
            link={link}
            creatingLink={creatingLink}
            updatingLink={updatingLink}
            clickedCopy={clickedCopy}
          />
        )}
      </ScreenTitle>
      {isMobile && (
        <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
          Volver
        </Button>
      )}
    </div>
  );
}

export default NewLinkHeader;
