import { Button, Link, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactNode } from 'react';
import emailSentImg from '../../assets/EmailSent.png';
import Logo from '../../assets/logo.svg';
import questionCircle from '../../assets/question-circle.svg';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: '12%',
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        width: '70vw',
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
      '& img': {
        maxWidth: '50%',
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
          marginRight: '-33%',
          marginBottom: '-10%',
        },
      },
    },
    texts: {
      width: '40%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        textAlign: 'center',
      },
      '& img': {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      '& h1': {
        fontWeight: Number(styles.normalTextWeight),
        color: styles.deepBlue,
        fontSize: '3.75rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '2rem',
        },
      },
      '& p': {
        color: styles.veryDarkBlue,
        fontWeight: Number(styles.normalTextWeight),
        fontSize: '1rem',
      },
      '& span': {
        color: styles.primary,
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    help: {
      position: 'absolute',
      right: 34,
      top: 30,
    },
    icon: {
      marginRight: '0.3125rem',
    },
  }),
);

interface EmailSentProps {
  text: ReactNode;
}

function EmailSent({ text }: EmailSentProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  return (
    <>
      <Link href="/faqs" target="_blank" className={classes.help}>
        {!isMobile && (
          <Button variant="outlined" color="secondary">
            <img src={questionCircle} alt="question" className={classes.icon} />
            Ayuda
          </Button>
        )}
      </Link>
      <div className={`${classes.container} landing email-sent`}>
        <div className={classes.texts}>
          <img src={Logo} alt="Plexo Links logo" />
          <h1>¡Email enviado!</h1>
          {text}
          {/* TODO: onClick resend email */}
        </div>
        <img src={emailSentImg} alt="" />
      </div>
    </>
  );
}
export default EmailSent;
