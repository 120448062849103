import {
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { DateSpan } from '../../store/config/enums';
import { PaymentsFilters } from '../../store/config/types';
import DateRangeDialog from './DateRangeDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('sm')]: {
        maxHeight: '50vh',
        overflow: 'auto',
      },
    },
  }),
);
interface DateFiltersProps {
  filters: PaymentsFilters | null;
  setFilters: (filters: PaymentsFilters) => void;
  isPaymentFilter?: boolean;
}

function DateFilters({ filters, setFilters, isPaymentFilter }: DateFiltersProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDateChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (Number(event.target.value) !== DateSpan.CUSTOM) {
      setFilters({ ...filters, dateSpan: Number(event.target.value) });
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className="filter-container">
      <FormControl component="fieldset">
        <FormLabel component="legend" classes={{ root: 'form-label' }} focused={false}>
          {`Fecha de ${isPaymentFilter ? 'pago' : 'creación'}`}
        </FormLabel>
        <FormGroup classes={{ root: 'group' }}>
          <Select
            displayEmpty
            input={<OutlinedInput />}
            className="select"
            onChange={handleDateChange}
            value={filters?.dateSpan !== undefined ? filters.dateSpan : DateSpan.LAST7DAYS}
            MenuProps={{
              classes: { paper: classes.paper },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            <MenuItem disabled value="select">
              <em>Selecciona</em>
            </MenuItem>
            <MenuItem value={DateSpan.ALL} className="select-element">
              Todas
            </MenuItem>
            <MenuItem value={DateSpan.TODAY} className="select-element">
              Hoy
            </MenuItem>
            <MenuItem value={DateSpan.LAST7DAYS} className="select-element">
              Últimos 7 días
            </MenuItem>
            <MenuItem value={DateSpan.LAST15DAYS} className="select-element">
              Últimos 15 días
            </MenuItem>
            <MenuItem value={DateSpan.LAST30DAYS} className="select-element">
              Últimos 30 días
            </MenuItem>
            <MenuItem value={DateSpan.CUSTOM} onClick={() => setIsOpen(true)}>
              {filters && filters.dateSpan === DateSpan.CUSTOM
                ? `${moment(filters.from).format('DD/MM/YYYY')} - ${moment(filters.to).format('DD/MM/YYYY')}`
                : `Rango`}
            </MenuItem>
          </Select>
        </FormGroup>
      </FormControl>
      <DateRangeDialog isOpen={isOpen} setIsOpen={setIsOpen} filters={filters} setFilters={setFilters} />
    </div>
  );
}

export default DateFilters;
