import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from '../../../styles/_variables.module.scss';

const useDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingBottom: 0,
    },
    icon: {
      cursor: 'pointer',
      userSelect: 'none',
      color: theme.palette.grey[500],
    },
    content: {
      padding: '2rem 1rem',
      paddingTop: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    message: {
      fontWeight: 'bold',
    },
    actions: {
      marginTop: '1.4rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.6875rem',
      width: '100%',
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        maxWidth: '7.25rem',
        width: '100%',
      },
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
      color: styles.white,
      '&:hover': {
        backgroundColor: styles.white,
        color: theme.palette.error.main,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        color: styles.white,
        opacity: 0.5,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '11.25rem',
      },
    },
  }),
);

interface DeleteDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  message: string;
  deleteAction(): void;
  hasCancelButton?: boolean;
  deleteButtonText?: string;
  deleteDisabled?: boolean;
}

function DeleteDialog({
  open,
  setOpen,
  message,
  deleteAction,
  deleteButtonText,
  hasCancelButton = true,
  deleteDisabled,
}: DeleteDialogProps) {
  const classes = useDialogStyles();

  const handleDelete = () => {
    deleteAction();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog className="dialog" fullWidth maxWidth="xs" open={open} onClose={closeDialog}>
      <DialogTitle className={classes.title}>
        <CloseIcon fontSize="default" className={classes.icon} onClick={closeDialog} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="h5" className={classes.message}>
          {message}
        </Typography>
        <div className={classes.actions}>
          {hasCancelButton && (
            <Button
              disabled={deleteDisabled}
              color="secondary"
              variant="outlined"
              onClick={closeDialog}
              className={classes.button}
            >
              Cancelar
            </Button>
          )}
          <Button
            className={`${classes.deleteButton} ${classes.button}`}
            variant="contained"
            disabled={deleteDisabled}
            onClick={handleDelete}
            disableElevation
          >
            {deleteDisabled ? <CircularProgress size={28} color="inherit" /> : deleteButtonText ?? 'Eliminar'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteDialog;
