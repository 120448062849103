import { Currency } from '../store/config/enums';

export const currencyHelper = {
  getCurrencyToken,
  getCurrencyName,
  mapCurrency,
  isValidCurrency,
  formatCurrencyToNumber,
};

function getCurrencyToken(currency: Currency): string {
  if (currency === Currency.PESO) {
    return '$';
  } else {
    return 'USD';
  }
}

function getCurrencyName(currency: Currency, urlFriendly: boolean = false): string {
  if (currency === Currency.PESO) {
    return urlFriendly ? 'pesos' : 'Pesos';
  } else {
    return urlFriendly ? 'dolares' : 'Dólares';
  }
}

type EnumType = { [s: number]: string };

function mapCurrency(enumerable: EnumType, fn: Function): any[] {
  // get all the members of the enum
  let enumMembers: any[] = Object.keys(enumerable).map((key: any) => enumerable[key]);

  // we are only interested in the numeric identifiers as these represent the values
  let enumValues: number[] = enumMembers.filter((v) => typeof v === 'number');

  // now map through the enum values
  return enumValues.map((m) => fn(m));
}

function isValidCurrency(currency: number) {
  return currency === Currency.PESO || currency === Currency.DOLAR;
}

function formatCurrencyToNumber(currency: string): string {
  const normalizedCurrencyName = currency.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

  if (normalizedCurrencyName === 'pesos') {
    return Currency.PESO.toString();
  }

  if (normalizedCurrencyName === 'dolares') {
    return Currency.DOLAR.toString();
  }

  return currency;
}
