import { Divider, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import colors from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      gap: 1.5,
      alignItems: 'center',
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    divider: {
      margin: '0.5rem',
    },
    stepText: {
      fontSize: '0.8125rem',
      color: colors.textButtonSecondary,
    },
  }),
);

interface RegisterHeaderProps {
  step: number;
  goBack?: () => void;
  disableBackButton?: boolean;
}

export default function RegisterHeader({ step }: RegisterHeaderProps) {
  const classes = useStyles();

  return (
    <div className={classes.flexBetween}>
      <div className={classes.flex}>
        <Typography variant="h2">Crea tu cuenta</Typography>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Typography className={classes.stepText}>{`Paso ${step} de 2`}</Typography>
      </div>
    </div>
  );
}
