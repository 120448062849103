import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FormDateField } from '../../components/forms/FormDateField';
import { csvNotificationHeaders } from '../../data/csv';
import { currencyHelper } from '../../helpers/currencyHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { businessService } from '../../services/business.service';
import { CSVPaymentNotificationReport, PaymentNotificationReport } from '../../store/config/types';
import variables from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadNotificationsContainer: {
      padding: '1.6rem 2rem',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginTop: '1rem',
      },
    },
    downloadNotificationsForm: {
      color: variables.slateBlue,
      display: 'flex',
      flexDirection: 'column',
      gap: '1.25rem',
      alignItems: 'flex-start',
    },
    dateContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1.25rem',
      alignItems: 'center',
      width: '100%',
      '& div': {
        margin: 0,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    button: {
      height: '3.5rem',
      minWidth: '12rem',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100%',
      },
    },
  }),
);

interface DownloadProps {
  fromDate: Moment;
  toDate: Moment;
}

export default function NotificationReport() {
  const currentDate = moment();
  const past7daysDate = moment().subtract(7, 'd');
  const classes = useStyles();
  const csvRef = useRef<any>();
  const [notificationsToDownload, setNotificationsToDownload] = useState<CSVPaymentNotificationReport[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldDownload, setShouldDownload] = useState<boolean>(false);

  useEffect(() => {
    if (shouldDownload && !isLoading) {
        csvRef.current.link.click();
        setShouldDownload(false);
    }
  }, [isLoading, notificationsToDownload, shouldDownload]);

  const handleDownloadWebhookReport = async ({ fromDate, toDate }: DownloadProps) => {
    setIsLoading(true);
    const notifications = await businessService.downloadNotificationsReport(fromDate, toDate);

    const buildNotifications = (
      notifications: PaymentNotificationReport[] | undefined,
    ): CSVPaymentNotificationReport[] => {
      return notifications
        ? notifications?.map((notification: PaymentNotificationReport) => {
            return {
              httpCodeResponse: notification.httpCodeResponse,
              created: notification.created.toLocaleString(),
              plexoTransactionId: notification.metadata.PlexoTransactionId,
              externalId: notification.metadata.ExternalId,
              installments: notification.metadata.Installments,
              issuerName: notification.metadata.Instrument.IssuerName,
              maskedPan: notification.metadata.Instrument.Name.slice(-4),
              value: notification.metadata.Amount.Value,
              currency: currencyHelper.getCurrencyToken(notification.metadata.Amount.Currency),
              status: paymentStatusEnumHelper.getStatusText(notification.metadata.Status),
              date: notification.metadata.Date.toLocaleString(),
            };
          })
        : [];
    };

    const csvData = buildNotifications(notifications?.notificationsDto);

    setNotificationsToDownload(csvData);
    setIsLoading(false);
    setShouldDownload(true);
  };

  return (
    <Paper className={classes.downloadNotificationsContainer}>
      <Formik
        initialValues={{
          fromDate: past7daysDate,
          toDate: currentDate,
        }}
        onSubmit={handleDownloadWebhookReport}
      >
        <Form className={classes.downloadNotificationsForm}>
          <Typography variant="body1">Reporte de Webhooks enviados</Typography>
          <Box className={classes.dateContainer}>
            <Field name="fromDate" component={FormDateField} placeholder="Desde:" />
            <Field name="toDate" component={FormDateField} placeholder="Hasta:" />
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {!isLoading ? 'Generar Reporte' : <CircularProgress size={18} />}
              <CSVLink
                data={notificationsToDownload}
                filename="Notificaciones.csv"
                headers={csvNotificationHeaders}
                ref={csvRef}
              />
            </Button>
          </Box>
        </Form>
      </Formik>
    </Paper>
  );
}
