import { Theme, createStyles, makeStyles } from '@material-ui/core';
import Logo from '../../assets/logo.png';
import MaintenanceImg from '../../assets/maintenance.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#F4F7FC',
      paddingLeft: '12%',
      paddingRight: '3%',
      display: 'flex',
      width: '100%',
      height: '100vh',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        padding: '2.5rem 0 0 0',
        width: '100%',
        flexDirection: 'column',
      },
    },
    decoration: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        maxHeight: 'fit-content',
        alignSelf: 'center',
      },
    },
    texts: {
      alignSelf: 'center',
      width: '40%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& h1': {
        fontFamily: 'Ubuntu',
        fontWeight: 700,
        color: '#081E50',
        fontSize: '3.375rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '2.0625rem',
          padding: '0 3rem',
          margin: '1.875rem 0 0.875rem 0',
        },
        '& span': {
          color: '#00B2B8',
        },
      },
      '& p': {
        color: '#081E50',
        fontFamily: 'Ubuntu',
        fontWeight: 400,
        fontSize: '1.25rem',
        lineHeight: '2rem',
        [theme.breakpoints.down('md')]: {
          padding: '0 3rem',
        },
        margin: 0,
        '&:last-child': {
          margin: '0.875rem 0',
        },
      },
      '& a': {
        color: '#00B2B8',
      },
    },
    logo: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '30%',
      },
    },
  }),
);

function Maintenance() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.texts}>
        <img className={classes.logo} src={Logo} alt="Plexo Links logo" />
        <h1>
          ¡Estamos en proceso de <span>actualización!</span>
        </h1>
        <p>Una nueva versión de nuestra plataforma estará disponible muy pronto. </p>
        <p>
          Si tienes alguna duda, <a href="mailto:soportelinks@plexo.com.uy">escríbenos</a> para ayudarte.
        </p>
      </div>
      <img className={classes.decoration} src={MaintenanceImg} alt="" />
    </div>
  );
}
export default Maintenance;
