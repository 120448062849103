import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import generator from 'generate-password';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { updateClientSecret } from '../../store/action_creators/business.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { Status } from '../../store/config/enums';
import { ClientSecretRequest, RootState } from '../../store/config/types';
import colors from '../../styles/_variables.module.scss';
import { CustomSnackbar } from '../CustomSnackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    button: {
      margin: '1rem',
      width: 200,
      fontSize: '1rem',
    },
    clientSecret: {
      padding: '0.3rem 1rem',
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.grey['200'],
    },
    title: {
      padding: '0.3rem',
      paddingLeft: '1.4rem',
      '& h2': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    root: {
      width: '100%',
    },
    icon: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    helperText: {
      margin: 0,
      marginTop: '0.4rem',
      color: colors.slateBlue,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  }),
);

interface ClientSecretProps {
  secret: string;
  setSecret(value: string): void;
  creating?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  business: state.business,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  updateClientSecret: (clientSecretRequest: ClientSecretRequest) =>
    dispatch(updateClientSecret(clientSecretRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & ClientSecretProps;

function ClientSecret({ auth, business, updateClientSecret, secret, setSecret, creating }: PropsType) {
  const classes = useStyles();
  const [updating, setUpdating] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const generateSecretText = () => {
    return generator.generate({ length: 12, numbers: true, uppercase: true });
  };

  const generateSecret = () => {
    setSecret(generateSecretText());
  };

  const submitSecret = () => {
    if (auth.account) {
      if (creating) {
        setOpen(false);
      } else {
        setUpdating(true);

        const clientSecretRequest: ClientSecretRequest = {
          businessId: auth.account.business.id!,
          clientSecret: secret,
        };

        updateClientSecret(clientSecretRequest);
      }
    }
  };

  const closeSnack = () => {
    setUpdating(false);
  };

  return (
    <>
      <TextField
        margin="normal"
        classes={{ root: classes.root }}
        variant="outlined"
        label="Client secret"
        type="text"
        disabled
        value={secret}
        InputProps={{
          endAdornment: (
            <Tooltip title="Cambiar client secret">
              <InputAdornment position="end">
                <VpnKeyIcon color="primary" onClick={() => setOpen(true)} classes={{ root: classes.icon }} />
              </InputAdornment>
            </Tooltip>
          ),
        }}
      />
      <FormHelperText classes={{ contained: classes.helperText, root: classes.helperText }}>
        Este valor funcionará como llave de seguridad para los usuarios avanzados que deseen integrar
        funcionalidades a través de servicios web
      </FormHelperText>
      <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
        <DialogTitle className={classes.title}>
          Generar client secret
          <IconButton onClick={() => setOpen(false)} className={classes.icon}>
            <CloseIcon fill={colors.iconColor} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography>El nuevo client secret será</Typography>
          <div className={classes.clientSecret}>
            <label>{secret}</label>
            <IconButton onClick={generateSecret} className={classes.icon}>
              <AutorenewIcon fill={colors.iconColor} />
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={() => setOpen(false)}
            className={classes.button}
            color="secondary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button onClick={submitSecret} className={classes.button} color="primary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={
          updating && (business.updateClientSecretSuccess || business.updateClientSecretErrorMessage !== null)
        }
        message={
          business.updateClientSecretSuccess
            ? 'Se actualizó el client secret correctamente'
            : business.updateClientSecretErrorMessage!
        }
        handleClose={closeSnack}
        type={business.updateClientSecretSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default connector(ClientSecret);
