import { Button, CircularProgress, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientSecret } from '../../components/ClientSecret';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { CustomSwitch } from '../../components/CustomSwitch';
import { Loading } from '../../components/Loading';
import { ScreenTitle } from '../../components/ScreenTitle';
import { FormTextField } from '../../components/forms/FormTextField';
import { updateBusiness } from '../../store/action_creators/business.actions';
import { Status } from '../../store/config/enums';
import { AuthState, BusinessState, RootState, UpdateBusinessRequest } from '../../store/config/types';
import NotificationReport from './NotificationReport';

interface Values {
  email: string;
  clientId: string;
  url: string;
}

function Configuration() {
  const dispatch = useDispatch();
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const business: BusinessState = useSelector((state: RootState) => state.business);
  const [updating, setUpdating] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [fastPaymentsEnabled, setFastPaymentsEnabled] = useState<boolean>(false);
  const [emailsEnabled, setEmailsEnabled] = useState<boolean>(false);
  const [secret, setSecret] = useState<string>('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!initialized && auth.account) {
      setInitialized(true);
      setFastPaymentsEnabled(auth.account.business.acceptsOnTheFly ?? false);
      setEmailsEnabled(auth.account.business.mailNotificationsEnabled ?? false);
      setSecret(auth.account.business.clientSecret ?? '');
    }
  }, [initialized, auth.account]);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFastPaymentsEnabled(event.target.checked);
  };

  const handleEmailSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailsEnabled(event.target.checked);
  };

  const submitBusiness = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      setUpdating(true);

      const updatedBusiness: UpdateBusinessRequest = {
        businessId: auth.account.business.id!,
        urlName: auth.account.business.urlName,
        prettyName: auth.account.business.prettyName,
        legalId: auth.account.business.legalId,
        acceptsOnTheFly: fastPaymentsEnabled,
        softDescriptor: auth.account.business.softDescriptor,
        vatRate: auth.account.business.vatRate!,
        sendNotification: emailsEnabled,
        mailForNotifications: values.email,
        urlForSystemNotifications: values.url,
        clientId: values.clientId,
        clientSecret: secret,
      };

      dispatch(updateBusiness(updatedBusiness));
    }
  };

  const closeSnack = () => {
    setUpdating(false);
  };

  return (
    <div className="screen-container configuration">
      <ScreenTitle
        title="Configuración"
        formId={!isMobile ? 'business-config-form' : undefined}
        loading={updating}
      />
      {auth.account ? (
        <>
          <Formik
            initialValues={{
              email: auth.account.business.mailForNotifications,
              clientId: auth.account.business.clientId,
              url: auth.account.business.urlForSystemNotifications,
            }}
            onSubmit={submitBusiness}
          >
            <Form className="form" id="business-config-form">
              <Paper elevation={3}>
                <div className="business-config">
                  <Typography variant="body1">Habilitar pagos rápidos</Typography>
                  <CustomSwitch checked={fastPaymentsEnabled} onChange={handleSwitchChange} />
                </div>
              </Paper>
              <Paper elevation={3}>
                <div className="business-config">
                  <Typography variant="body1">Habilitar envío de correos</Typography>
                  <CustomSwitch checked={emailsEnabled} onChange={handleEmailSwitchChange} />
                </div>
                {emailsEnabled && (
                  <>
                    <Typography className="helper-text">
                      Recibirás notificaciones sobre los pagos realizados.
                    </Typography>
                    <Field
                      name="email"
                      component={FormTextField}
                      type="text"
                      placeholder="Correo del destinatario"
                      className="email-field"
                    />
                  </>
                )}
              </Paper>
              <Typography variant="h5" className="advanced-config-subtitle">
                Configuración avanzada
              </Typography>
              <Paper elevation={2} className="advanced-config">
                <Field
                  name="clientId"
                  component={FormTextField}
                  disabled
                  type="text"
                  placeholder="Client ID"
                />
                <ClientSecret secret={secret} setSecret={setSecret} />
                <Field
                  name="url"
                  component={FormTextField}
                  type="text"
                  placeholder="URL para notificación de pagos"
                />
              </Paper>
              {isMobile && (
                <Button type="submit" color="primary" variant="contained" fullWidth disabled={updating}>
                  {updating ? <CircularProgress size={18} color="primary" /> : 'Guardar cambios'}
                </Button>
              )}
            </Form>
          </Formik>
          <NotificationReport />
        </>
      ) : (
        <Loading />
      )}
      <CustomSnackbar
        open={updating && (business.updateBusinessSuccess || business.updateBusinessErrorMessage !== null)}
        message={
          business.updateBusinessSuccess
            ? 'Se actualizó la empresa correctamente'
            : business.updateBusinessErrorMessage!
        }
        handleClose={closeSnack}
        type={business.updateBusinessSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default Configuration;
