import {
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { SortEnum } from '../../store/config/enums';
import { PaymentsFilters } from '../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('sm')]: {
        maxHeight: '50vh',
        overflow: 'auto',
      },
    },
  }),
);
interface CurrencyFiltersProps {
  filters: PaymentsFilters | null;
  setFilters: (filters: PaymentsFilters) => void;
  hasDateFilters?: boolean;
}

function CurrencyFilters({ filters, setFilters, hasDateFilters }: CurrencyFiltersProps) {
  const classes = useStyles();

  const handleCurrencyChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const targetValue = String(event.target.value);
    const currency = {
      pesos: false,
      dollars: false,
    };

    if (
      (filters?.currency?.pesos || filters?.currency?.dollars) &&
      !(filters.currency?.pesos && filters.currency?.dollars)
    ) {
      filters.sortDirection = SortEnum.DESC;
    }

    let newLinksFilters: PaymentsFilters = {
      ...filters,
      currency: { ...currency, [targetValue]: true },
    };

    setFilters(newLinksFilters);
  };

  const getSelectedCurrency = () => {
    if (filters?.currency?.pesos && filters?.currency?.dollars) {
      return '';
    } else if (filters?.currency?.pesos) {
      return 'pesos';
    } else if (filters?.currency?.dollars) {
      return 'dollars';
    }
    return '';
  };

  return (
    <div className="filter-container">
      <FormControl component="fieldset">
        <FormLabel component="legend" classes={{ root: 'form-label' }} focused={false}>
          Moneda
        </FormLabel>
        <FormGroup classes={{ root: 'group' }}>
          <Select
            displayEmpty
            input={<OutlinedInput />}
            className="select"
            onChange={handleCurrencyChange}
            value={getSelectedCurrency()}
            MenuProps={{
              classes: { paper: classes.paper },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            <MenuItem disabled value="select">
              <em>Selecciona</em>
            </MenuItem>
            <MenuItem value="">Todas</MenuItem>
            <MenuItem value="pesos">Pesos</MenuItem>
            <MenuItem value="dollars">Dólares</MenuItem>
          </Select>
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default CurrencyFilters;
