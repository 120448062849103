import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MultiUseLinkIcon from '../../assets/MultiUseLinkIcon';
import OneTimeLinkIcon from '../../assets/OneTimeLinkIcon';
import DashboardLinkList from '../../components/Dashboard/DashboardLinkList';
import DashboardPayments from '../../components/Dashboard/DashboardPayments';
import { Loading } from '../../components/Loading';
import { fetchLinks } from '../../store/action_creators/links.actions';
import { DateSpan, LinkTypeEnum, PaymentsColumnSortEnum, SortEnum } from '../../store/config/enums';
import { PaymentsFilters } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { ScreenTitle } from './../../components/ScreenTitle';
import { fetchPayments } from './../../store/action_creators/payments.actions';
import { RootState } from './../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsDiv: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
      },
    },
    buttonSeparation: {
      width: '2rem',
    },
    button: {
      fontWeight: Number(styles.normalTextWeight),
      width: '100%',
      '& .MuiButton-startIcon': {
        color: styles.white,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: '0.4rem',
      },
      '&:hover': {
        '& .MuiButton-startIcon': {
          '& path': {
            fill: styles.primary,
          },
        },
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
      fontSize: '0.875rem',
    },
    containerDiv: {
      display: 'flex',
      gap: '1.1875rem',
      minHeight: '35.4rem',
      '& > *': {
        marginTop: '2rem',
        [theme.breakpoints.down('md')]: {
          marginTop: '0',
        },
      },
      '& > div:first-child': {
        [theme.breakpoints.down('md')]: {
          marginTop: '2rem',
        },
      },
      '& > div:last-child': {
        [theme.breakpoints.down('md')]: {
          marginBottom: '2rem',
        },
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    linkList: {
      marginTop: '0',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'auto',
      },
    },
  }),
);

function Dashboard() {
  const dispatch = useDispatch();
  const { auth, payments, links } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const classes = useStyles();
  const filters = useMemo(
    () => ({
      dateSpan: DateSpan.LAST7DAYS,
      columnSort: PaymentsColumnSortEnum.Date,
      sortDirection: SortEnum.DESC,
    }),
    [],
  );

  const page = 1;
  const pageLimit = 7;
  const businessId = auth?.account?.business?.id ?? -1;

  useEffect(() => {
    const dashboardPaymentsFilters: PaymentsFilters = {
      dateSpan: DateSpan.LAST7DAYS,
      currency: { pesos: true, dollars: true },
      columnSort: PaymentsColumnSortEnum.Date,
      sortDirection: SortEnum.DESC,
    };
    if (
      businessId !== -1 &&
      !payments.loadingPayments &&
      payments.dashboardPayments === null &&
      payments.paymentsErrorMessage === null
    ) {
      dispatch(fetchPayments(dashboardPaymentsFilters, page, pageLimit, businessId, true));
    }
  }, [
    businessId,
    dispatch,
    payments.dashboardPayments,
    payments.loadingPayments,
    payments.payments,
    payments.paymentsErrorMessage,
  ]);

  useEffect(() => {
    if (
      businessId !== -1 &&
      !links.loadingLinks &&
      links.dashboardLinks === null &&
      links.linksErrorMessage === null
    ) {
      dispatch(fetchLinks(filters, page, pageLimit, businessId, LinkTypeEnum.ALL, true, true, true));
    }
  }, [businessId, dispatch, links.dashboardLinks, links.linksErrorMessage, links.loadingLinks, filters]);

  if (links.loadingLinks || payments.loadingPayments) return <Loading />;

  return (
    <div className="screen-container dashboard">
      <ScreenTitle
        title="Tablero"
        children={
          links.dashboardLinks?.length! > 0 ? (
            <div className={classes.buttonsDiv}>
              <Button
                disableElevation
                className={classes.button}
                color="primary"
                variant="contained"
                startIcon={<OneTimeLinkIcon color={styles.white} transparent />}
                onClick={() => navigate('/new-one-use-link')}
              >
                Crear link de 1 uso
              </Button>
              <div className={classes.buttonSeparation} />
              <Button
                disableElevation
                className={classes.button}
                color="primary"
                variant="contained"
                startIcon={<MultiUseLinkIcon color={styles.white} transparent />}
                onClick={() => navigate('/new-permanent-link')}
              >
                Crear link multiuso
              </Button>
            </div>
          ) : (
            ''
          )
        }
      />
      <div className={classes.containerDiv}>
        <DashboardPayments />
        <DashboardLinkList />
      </div>
    </div>
  );
}

export default Dashboard;
