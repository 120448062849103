import {
  Button,
  CircularProgress,
  Divider,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useNotificationId from '../../hooks/useNotificationId';
import { getPayment } from '../../store/action_creators/payments.actions';
import { PaymentStatusEnum, Status } from '../../store/config/enums';
import { AuthState, NotificationsState, Payment, PaymentsState, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { theme } from '../../styles/theme';
import { CustomSnackbar } from '../CustomSnackbar';
import { Loading } from '../Loading';
import { DeleteDialog } from '../dialogs/DeleteDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: '0 0 0 0.8rem',
      backgroundColor: styles.slateBlue,
      opacity: '0.2',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 'max-content',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        gap: '0.75rem',
        width: '100%',
        margin: '1.5rem 0 0.5rem 0',
      },
    },
    button: {
      color: styles.textButtonSecondary,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0.5rem 0',
      },
    },
    buttonPrimary: {
      marginLeft: '0.8rem',
      '& span': {
        '& a': {
          color: styles.white,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    deleteButton: {
      minWidth: '6rem',
      backgroundColor: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
      color: styles.white,
      marginLeft: '0.75rem',
      '&:hover': {
        backgroundColor: styles.white,
        color: theme.palette.error.main,
        [theme.breakpoints.down('sm')]: {
          backgroundColor: theme.palette.error.main,
          border: `1px solid ${theme.palette.error.main}`,
          color: styles.white,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        color: styles.white,
        opacity: 0.5,
        minWidth: '9rem',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
          backgroundColor: `${theme.palette.error.main} !important`,
          opacity: 0.5,
        },
      },
    },
    resendButton: {
      minWidth: '11.25rem',
      color: styles.white,
      marginLeft: '0.75rem',
      '&.Mui-disabled': {
        height: '100%',
      },
    },
    buttonMobile: {
      width: '100%',
      color: styles.white,
      marginLeft: 0,
    },
  }),
);

interface PaymentDetailActionsProps {
  cancelPayment: (plexoReferenceId: string) => void;
  sendNotification: (paymentId: number) => void;
  resendNotification: (notificationId: string) => void;
}

function PaymentDetailActions({
  cancelPayment,
  sendNotification,
  resendNotification,
}: PaymentDetailActionsProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const paymentState: PaymentsState = useSelector((state: RootState) => state.payments);
  const payment: Payment | null = paymentState.selectedPayment;
  const notificationId = useNotificationId();

  const notifications: NotificationsState = useSelector((state: RootState) => state.notifications);
  const [deletingPayment, setDeletingPayment] = useState<boolean>(false);
  const [sendingNotification, setSendingNotification] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [lastCompletedAction, setLastCompletedAction] = useState<'resend' | 'cancel'>();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const businessUrlForSystemNotifications = auth.account?.business?.urlForSystemNotifications;
  const sendingOrResendingNotification =
    notifications.resendingNotification || notifications.sendingNotification;
  const sendNotificationFinished =
    notifications.sendNotificationSuccess || notifications.sendNotificationErrorMessage !== null;
  const resendNotificationFinished =
    notifications.resendNotificationSuccess || notifications.resendNotificationErrorMessage !== null;
  const sendOrResendNotificationSucces =
    notifications.resendNotificationSuccess || notifications.sendNotificationSuccess;
  const cancelPaymentFinished =
    paymentState.cancelPaymentSuccess || paymentState.cancelPaymentErrorMessage !== null;
  const cancelPaymentSucces = paymentState.cancelPaymentSuccess;
  const allSuccess = sendOrResendNotificationSucces || cancelPaymentSucces;

  if (!payment) {
    return <Loading />;
  }

  const cancelTransaction = () => {
    setDeletingPayment(true);
    cancelPayment(payment.plexoReferenceId);
    setLastCompletedAction('cancel');
  };

  const closeSnack = () => {
    setDeletingPayment(false);
    setSendingNotification(false);

    if (paymentState.cancelPaymentSuccess) {
      dispatch(getPayment(payment.id.toString(), auth.account?.business?.id ?? undefined));
      setDeleteDialogOpen(false);
    }
  };

  const submitResendNotification = () => {
    setSendingNotification(true);
    setLastCompletedAction('resend');

    if (notificationId) {
      resendNotification(notificationId);
    } else {
      sendNotification(payment.id);
    }
  };

  const snackbarMessage = () => {
    switch (lastCompletedAction) {
      case 'cancel':
        return cancelPaymentSucces ? 'Pago cancelado correctamente' : 'Error al cancelar el pago';
      case 'resend':
        return sendOrResendNotificationSucces
          ? 'Notificación reenviada correctamente'
          : 'Error al reenviar notificación';
      default:
        return 'Ocurrió un error';
    }
  };

  return (
    <div className={classes.buttonsContainer}>
      {!isMobile && (
        <>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(-1)}
            className={classes.button}
          >
            Volver
          </Button>
        </>
      )}
      {payment.cancellable && payment.status !== PaymentStatusEnum.CANCELLED && (
        <>
          {!isMobile && <Divider className={classes.divider} orientation="vertical" flexItem />}
          <Button
            variant="contained"
            disableElevation
            onClick={() => setDeleteDialogOpen(true)}
            className={`${classes.deleteButton} ${!isMobile ? classes.deleteButton : classes.buttonMobile}`}
            disabled={deletingPayment}
          >
            {deletingPayment ? <CircularProgress size={20} color="inherit" /> : 'Cancelar Pago'}
          </Button>
        </>
      )}
      {businessUrlForSystemNotifications && (
        <>
          {!payment.cancellable && !isMobile && (
            <Divider className={classes.divider} orientation="vertical" flexItem />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={submitResendNotification}
            className={`${classes.button} ${!isMobile ? classes.resendButton : classes.buttonMobile}`}
            disabled={sendingNotification}
          >
            {sendingOrResendingNotification ? (
              <CircularProgress size={26} color="inherit" />
            ) : (
              'Reenviar Notificación'
            )}
          </Button>
        </>
      )}
      <CustomSnackbar
        open={
          (deletingPayment && cancelPaymentFinished) ||
          (sendingNotification && (resendNotificationFinished || sendNotificationFinished))
        }
        message={snackbarMessage()}
        handleClose={closeSnack}
        type={allSuccess ? Status.SUCCESS : Status.ERROR}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        message="¿Estás seguro que deseas cancelar el pago?"
        deleteAction={cancelTransaction}
        deleteButtonText="Cancelar pago"
        deleteDisabled={deletingPayment}
      />
    </div>
  );
}

export default PaymentDetailActions;
