import variables from '../../styles/_variables.module.scss';

function EyeSlashIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.535" height="17.228" viewBox="0 0 21.535 17.228">
      <path
        id="eye-slash"
        d="M21.439,16.327.779.058A.269.269,0,0,0,.4.1L.064.521A.269.269,0,0,0,.106.9l20.66,16.269a.269.269,0,0,0,.379-.042l.336-.421A.269.269,0,0,0,21.439,16.327ZM10.773,3.229A4.312,4.312,0,0,1,15.08,7.536a4.253,4.253,0,0,1-.518,2l.856.673a5.258,5.258,0,0,0-.4-5.952,9.922,9.922,0,0,1,4.369,4.352,10.086,10.086,0,0,1-2.28,2.933l.852.671A11.2,11.2,0,0,0,20.347,9.1a1.089,1.089,0,0,0,0-.982,10.793,10.793,0,0,0-9.574-5.969,10.473,10.473,0,0,0-4.378.957l1.54,1.211A4.28,4.28,0,0,1,10.773,3.229ZM12.821,8.17a2.125,2.125,0,0,0-2.048-2.787c-.034,0-.064.008-.1.01a1.518,1.518,0,0,1-.008,1.083ZM5.5,6.516a5.192,5.192,0,0,0-.109,1.021A5.363,5.363,0,0,0,13,12.425l-1-.784a4.249,4.249,0,0,1-1.234.2A4.312,4.312,0,0,1,6.466,7.536c0-.082.02-.159.024-.24ZM10.773,14A9.7,9.7,0,0,1,2.159,8.613,9.935,9.935,0,0,1,4.457,5.7l-.87-.685A11.188,11.188,0,0,0,1.2,8.122a1.089,1.089,0,0,0,0,.982,10.789,10.789,0,0,0,9.572,5.969,10.473,10.473,0,0,0,4.378-.957l-.984-.774A9.414,9.414,0,0,1,10.773,14Z"
        transform="translate(-0.005 0.001)"
        fill={variables.iconColor}
      />
    </svg>
  );
}

export default EyeSlashIcon;
