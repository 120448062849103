import { Currency, ValidUntil } from '../../store/config/enums';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';

const options = {
  currency: [
    {
      id: Currency.PESO,
      value: `${currencyHelper.getCurrencyName(Currency.PESO)}`,
    },
    {
      id: Currency.DOLAR,
      value: `${currencyHelper.getCurrencyName(Currency.DOLAR)}`,
    },
  ],
  vatRate: [
    {
      id: 0,
      value: '0%',
    },
    {
      id: 10,
      value: '10%',
    },
    {
      id: 22,
      value: '22%',
    },
  ],
  validUntil: [
    {
      id: ValidUntil.NONE,
      value: dateHelper.getValidUntilName(ValidUntil.NONE),
    },
    {
      id: ValidUntil.HALFHOUR,
      value: dateHelper.getValidUntilName(ValidUntil.HALFHOUR),
    },
    {
      id: ValidUntil.ONEHOUR,
      value: dateHelper.getValidUntilName(ValidUntil.ONEHOUR),
    },
    {
      id: ValidUntil.TWOHOURS,
      value: dateHelper.getValidUntilName(ValidUntil.TWOHOURS),
    },
    {
      id: ValidUntil.DAYEND,
      value: dateHelper.getValidUntilName(ValidUntil.DAYEND),
    },
    {
      id: ValidUntil.MONTHEND,
      value: dateHelper.getValidUntilName(ValidUntil.MONTHEND),
    },
    {
      id: ValidUntil.CUSTOM,
      value: dateHelper.getValidUntilName(ValidUntil.CUSTOM),
    },
  ],
};

export default options;
