import variables from '../../styles/_variables.module.scss';

function EyeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 17.75 11.833">
      <path
        id="eye"
        d="M8.875,70.9a1.972,1.972,0,1,0,0-3.944c-.031,0-.058.007-.088.009a1.464,1.464,0,0,1-1.875,1.875c0,.031-.009.058-.009.088A1.972,1.972,0,0,0,8.875,70.9Zm8.768-1.436A9.884,9.884,0,0,0,8.875,64,9.885,9.885,0,0,0,.108,69.467a1,1,0,0,0,0,.9,9.884,9.884,0,0,0,8.768,5.467,9.885,9.885,0,0,0,8.768-5.467,1,1,0,0,0,0-.9ZM8.875,64.986A3.944,3.944,0,1,1,4.931,68.93,3.944,3.944,0,0,1,8.875,64.986Zm0,9.861a8.883,8.883,0,0,1-7.889-4.93,9.084,9.084,0,0,1,4-3.985,4.923,4.923,0,1,0,8.82,3,4.885,4.885,0,0,0-1.041-3,9.084,9.084,0,0,1,4,3.985A8.882,8.882,0,0,1,8.875,74.847Z"
        transform="translate(-0.001 -64)"
        fill={variables.iconColor}
      />
    </svg>
  );
}

export default EyeIcon;
