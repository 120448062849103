import { Theme, createStyles, makeStyles } from '@material-ui/core';
import Lottie from 'lottie-react';
import loadAnimation from '../../assets/loading.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface LoadingProps {
  height?: string;
  width?: string;
}

function Loading({ height = '12rem', width = '12rem' }: LoadingProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Lottie style={{ height, width }} animationData={loadAnimation} loop />
    </div>
  );
}

export default Loading;
