import { Hidden, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { OnTheFlyPaymentsActions } from '../../components/OnTheFlyPayments';
import { ScreenTitle } from '../../components/ScreenTitle';
import { QrCodeDialog } from '../../components/dialogs/QRCodeDialog';
import { FormSelectField } from '../../components/forms/FormSelectField';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { currencyHelper } from '../../helpers/currencyHelper';
import { Currency, Status } from '../../store/config/enums';
import { Amount, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '37.5rem',
      margin: '0 auto',
      padding: '1.5rem 1.6875rem 2.1875rem',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        padding: '1rem',
        flexDirection: 'reverese-column',
      },
    },
    screenTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: '2rem',
      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: '0.4375rem 0.75rem',
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1.5rem',
      },
    },
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      gap: '1rem',
    },
    descriptiveText: {
      fontSize: '0.875rem',
      color: styles.slateBlue,
    },
    buttonsContainer: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1.5rem',
    },
  }),
);

export interface OnTheFlyPaymentValues {
  currency: number;
  amount: string;
}

function OnTheFlyPayments() {
  const classes = useStyles();
  const authState = useSelector((state: RootState) => state.auth);
  const businessName = authState?.account?.business?.urlName ?? '';

  const [openQrDialog, setOpeQrDialog] = useState<boolean>(false);
  const [url, setUrl] = useState(`${window.location.origin}/${businessName}`);
  const [linkCreated, setLinkCreated] = useState<boolean>(false);
  const [amount, setAmount] = useState<Amount | undefined>();
  const currencies = currencyHelper.mapCurrency(Currency, (i: Currency) => {
    return {
      id: i,
      value: currencyHelper.getCurrencyToken(i),
    };
  });

  const submit = (values: OnTheFlyPaymentValues, helpers: FormikHelpers<OnTheFlyPaymentValues>) => {
    const amount = values.amount;
    const currency = currencyHelper.getCurrencyName(values.currency, true);
    const path = `${window.location.origin}/${businessName}/${currency}${!!amount ? `/${amount}` : ''}`;
    setLinkCreated(true);
    navigator.clipboard.writeText(path);
  };

  const handleOnClickViewQR = (values: OnTheFlyPaymentValues) => {
    const amount = values.amount;
    const currency = currencyHelper.getCurrencyName(values.currency, true);
    const path = `${window.location.origin}/${businessName}/${currency}${!!amount ? `/${amount}` : ''}`;
    setAmount({ value: +amount, currency: values.currency });
    openDialogAndSetUrl(true, path);
  };

  const closeSnack = () => {
    setLinkCreated(false);
  };

  const openDialogAndSetUrl = (setOpen: boolean, newUrl: string) => {
    setUrl(newUrl ?? url);
    setOpeQrDialog(setOpen);
  };

  return (
    <div className={classes.container}>
      <Formik
        initialValues={{
          currency: Currency.PESO,
          amount: '',
        }}
        validationSchema={schemas.QuickLinkSchema}
        onSubmit={submit}
      >
        {({ values }) => (
          <Form id="quick-link">
            <div className={classes.screenTitle}>
              <ScreenTitle title="Pagos rápidos">
                <Hidden smDown>
                  <OnTheFlyPaymentsActions values={values} handleOnClickViewQR={handleOnClickViewQR} />
                </Hidden>
              </ScreenTitle>
            </div>
            <Paper className={classes.container} elevation={0}>
              <div className={classes.formControl}>
                <Typography variant="body1" className={classes.descriptiveText}>
                  Para usar tu pago rápido, simplemente compártelo o muestra el QR a tu cliente. Puedes
                  especificar el importe ahora o dejar que quien realice el pago lo indique.
                </Typography>
                <Field name="currency" component={FormSelectField} label="Moneda" options={currencies} />
                <Field name="amount" component={FormTextField} type="tel" placeholder="Importe" />
              </div>
            </Paper>
            <Hidden mdUp>
              <div className={classes.buttonsContainer}>
                <OnTheFlyPaymentsActions values={values} handleOnClickViewQR={handleOnClickViewQR} />
              </div>
            </Hidden>
          </Form>
        )}
      </Formik>
      <QrCodeDialog
        open={openQrDialog}
        setOpen={setOpeQrDialog}
        url={url}
        description={`Pagos rápidos${businessName && ` de ${businessName}`}`}
        price={amount}
      />
      <CustomSnackbar
        open={linkCreated}
        message={'Link copiado al portapapeles'}
        handleClose={closeSnack}
        type={Status.SUCCESS}
      />
    </div>
  );
}

export default OnTheFlyPayments;
