import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/config/types';

interface GoBackDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function GoBackDialog({ open, setOpen }: GoBackDialogProps) {
  const link = useSelector((state: RootState) => state.links);
  const business = useSelector((state: RootState) => state.business.business);

  const handleClose = () => setOpen(false);

  return (
    <Dialog className="payment-go-back" fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle className="title">
        <CloseIcon className="close-icon" onClick={handleClose} />
      </DialogTitle>
      <DialogContent className="content">
        <h2 className="modal-title">¿Estás seguro de que deseas volver al comienzo del flujo?</h2>
        <span className="text"> Perderás todas las selecciones realizadas hasta el momento.</span>
        <div className="actions">
          <Button className="cancel-button" color="secondary" variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <a className="close-button" href={link.linkToPay?.url || `/${business?.urlName}`}>
            Si, volver
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default GoBackDialog;
