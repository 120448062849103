import { Button, useMediaQuery } from '@material-ui/core';
import { OnTheFlyPaymentValues } from '../../screens/OnTheFly/OnTheFlyPayments';
import { theme } from '../../styles/theme';

interface OnTheFlyPaymentsActionsProps {
  values: OnTheFlyPaymentValues;
  handleOnClickViewQR: (values: OnTheFlyPaymentValues) => void;
}

function OnTheFlyPaymentsActions({ values, handleOnClickViewQR }: OnTheFlyPaymentsActionsProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        onClick={() => handleOnClickViewQR(values)}
        fullWidth={isMobile}
      >
        Generar QR
      </Button>
      <Button type="submit" variant="contained" color="primary" size="large" fullWidth={isMobile}>
        Compartir url
      </Button>
    </>
  );
}

export default OnTheFlyPaymentsActions;
