function BankIcon() {
  return (
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 11.4999C0 5.14869 5.1487 0 11.4999 0H12.5952C18.9464 0 24.0951 5.14869 24.0951 11.4999C24.0951 17.8512 18.9464 22.9999 12.5952 22.9999H11.4999C5.1487 22.9999 0 17.8512 0 11.4999Z"
        fill="#7E84A3"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip0_7160_3953)">
        <path
          d="M18.0693 7.51355L12.0947 5.43024C12.0135 5.39878 11.928 5.38306 11.8426 5.38306C11.7571 5.38306 11.6717 5.39878 11.5905 5.43024L5.61587 7.51355C5.54748 7.54001 5.48855 7.58736 5.44695 7.64927C5.40535 7.71118 5.38306 7.78471 5.38306 7.86003V8.71376C5.38306 8.91822 5.54365 9.08383 5.74192 9.08383H17.9433C18.1415 9.08383 18.3021 8.91822 18.3021 8.71376V7.86003C18.3021 7.70576 18.2093 7.56767 18.0693 7.51355ZM17.5844 8.34368H6.10078V8.12579L11.8426 6.12321L17.5844 8.12533V8.34368ZM18.1227 16.4854H17.5844V15.0051C17.5844 14.5964 17.2172 14.2649 16.7642 14.2649H16.5078V9.82399H15.7901V14.2649H13.6369V9.82399H12.9192V14.2649H10.766V9.82399H10.0483V14.2649H7.8951V9.82399H7.17737V14.2649H6.92101C6.46794 14.2649 6.10078 14.5964 6.10078 15.0051V16.4854H5.56249C5.46335 16.4854 5.38306 16.5682 5.38306 16.6704V17.0405C5.38306 17.1427 5.46335 17.2255 5.56249 17.2255H18.1227C18.2218 17.2255 18.3021 17.1427 18.3021 17.0405V16.6704C18.3021 16.5682 18.2218 16.4854 18.1227 16.4854ZM16.8667 16.4854H6.81851V15.0347C6.83421 15.0227 6.86808 15.0051 6.92101 15.0051H16.7642C16.8173 15.0051 16.851 15.0227 16.8667 15.0347V16.4854Z"
          fill="#5A607F"
        />
      </g>
      <defs>
        <clipPath id="clip0_7160_3953">
          <rect width="12.9191" height="11.8425" fill="white" transform="translate(5.38306 5.38306)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BankIcon;
