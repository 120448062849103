import { linkHelper } from '../../helpers/linkHelper';
import { Link } from '../../store/config/types';
import { StatusPill } from '../StatusPill';
import { StatusPillProps } from '../StatusPill/StatusPill';

interface LinkStatusPillProps {
  link: Link;
}

function LinkStatusPill({ link }: LinkStatusPillProps) {
  const getStatusLabel = (date: Date | null): string => {
    return date && linkHelper.verifyValidation(date) ? 'Vencido' : 'Activo';
  };

  const getStatusColor = (date: Date | null): StatusPillProps['color'] => {
    return date && linkHelper.verifyValidation(date) ? 'info' : 'success';
  };

  return (
    <StatusPill
      label={getStatusLabel(link?.validUntil || null)}
      color={getStatusColor(link?.validUntil || null)}
      isCard
    />
  );
}

export default LinkStatusPill;
