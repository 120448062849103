import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useState } from 'react';
import { DateSpan } from '../../store/config/enums';
import { PaymentsFilters } from '../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: '100%',
      textAlign: 'center',
    },
    dialog: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    picker: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    from: {
      marginTop: '0.6rem',
      marginRight: '0.6rem',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    to: {
      marginTop: '0.6rem',
      marginLeft: '0.6rem',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
  }),
);

interface DateRangeDialogProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  filters: PaymentsFilters | null;
  setFilters: (filters: PaymentsFilters) => void;
}

function DateRangeDialog({ isOpen, setIsOpen, filters, setFilters }: DateRangeDialogProps) {
  const classes = useStyles();
  const [fromDate, setFromDate] = useState<string>(moment().toISOString());
  const [toDate, setToDate] = useState<string>(moment().toISOString());

  const closeDialog = () => {
    setFilters({ ...filters, dateSpan: DateSpan.ALL });
    setIsOpen(false);
  };

  const handleFromChange = (e: any) => {
    setFromDate(moment(e).startOf('day').toISOString());
  };

  const handleToChange = (e: any) => {
    setToDate(moment(e).endOf('day').toISOString());
  };

  const submitRange = () => {
    setIsOpen(false);
    setFilters({ ...filters, dateSpan: DateSpan.CUSTOM, from: fromDate, to: toDate });
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <Typography variant="h4" className={classes.title}>
          Selecciona un rango de fechas
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialog }}>
        <div className={classes.picker}>
          <Typography variant="h5">Desde</Typography>
          <div className={classes.from}>
            <DatePicker
              variant="static"
              format="d MMM yyyy"
              maxDate={toDate}
              disableFuture
              value={fromDate}
              onChange={handleFromChange}
            />
          </div>
        </div>
        <div className={classes.picker}>
          <Typography variant="h5">Hasta</Typography>
          <div className={classes.to}>
            <DatePicker
              variant="static"
              format="d MMM yyyy"
              disableFuture
              value={toDate}
              onChange={handleToChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions classes={{ root: 'date-dialog-actions' }}>
        <Button variant="text" onClick={closeDialog}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={submitRange}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateRangeDialog;
