import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import PasswordField from '../../components/PasswordField/PasswordField';
import { ScreenTitle } from '../../components/ScreenTitle';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { login } from '../../store/action_creators/auth.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { RootState, UserCredentials } from '../../store/config/types';
import variables from '../../styles/_variables.module.scss';
import { CustomSnackbar } from './../../components/CustomSnackbar';
import { Status } from './../../store/config/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: '1.75rem 0',
    },
    registerText: {
      textAlign: 'center',
      fontWeight: 400,
    },
    link: {
      color: variables.primary,
      fontWeight: 400,
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
        color: variables.primaryMidtone,
      },
    },
    passwordField: {
      position: 'relative',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  login: (user: UserCredentials) => dispatch(login(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Values {
  email: string;
  password: string;
}

function Login({ auth, login }: PropsFromRedux) {
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  const classes = useStyles();

  const submitLogin = (values: Values, helpers: FormikHelpers<Values>) => {
    const user: UserCredentials = {
      email: values.email,
      password: values.password,
    };

    setLoggingIn(true);
    login(user);
  };

  const closeSnack = () => {
    setLoggingIn(false);
  };

  return (
    <div className="screen-container profile">
      <Paper elevation={2}>
        <ScreenTitle title="Iniciar sesión" />
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={schemas.LoginSchema}
          onSubmit={submitLogin}
        >
          {({ values, errors }) => (
            <Form className="form">
              <Field
                className="form-row"
                name="email"
                component={FormTextField}
                type="text"
                placeholder="Correo electrónico"
                value={values['email']}
              />
              <PasswordField inputName="password" />
              <div className="many-actions-row-login">
                <Link to="/reset-password">Reiniciar contraseña</Link>
                <Button type="submit" color="primary" variant="contained" disabled={loggingIn}>
                  {loggingIn ? <CircularProgress size={28} color="inherit" /> : 'Ingresar'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Divider className={`${classes.divider} divider`} />
        <div className="sign-in-toggle">
          <Typography className={classes.registerText} variant="subtitle2">
            ¿Aún no tienes una cuenta?
          </Typography>
          <Link to="/register">Regístrate aquí</Link>
        </div>
        <CustomSnackbar
          open={loggingIn && auth.logInErrorMessage !== null}
          message={auth.logInErrorMessage!}
          handleClose={closeSnack}
          type={Status.ERROR}
        />
      </Paper>
    </div>
  );
}

export default connector(Login);
