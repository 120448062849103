import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { fetchIssuers, sendDesiredIssuers } from '../../../store/action_creators/commerces.actions';
import { AppActions } from '../../../store/config/ActionTypes';
import { Status } from '../../../store/config/enums';
import { DesiredIssuer, DesiredIssuersRequest, RootState } from '../../../store/config/types';
import colors from '../../../styles/_variables.module.scss';
import { CustomSnackbar } from '../../CustomSnackbar';
import { Loading } from '../../Loading';
import { ReturnButton } from '../../ReturnButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& > *': {
        marginTop: '1rem',
        color: colors.slateBlue,
      },
      '& .MuiCheckbox-root .MuiSvgIcon-root': {
        color: colors.slateBlue,
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        '& .MuiSvgIcon-root': {
          color: colors.primary,
        },
      },
    },
    text: { marginBottom: '1rem' },
    formActions: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '1rem',
      '& button': {
        width: '100px',
        [theme.breakpoints.down('sm')]: {
          margin: '2rem 0 0',
        },
      },
    },
    confirmButton: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  commerces: state.commerces,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchIssuers: () => dispatch(fetchIssuers()),
  sendDesiredIssuers: (businessId: number, { issuers }: DesiredIssuersRequest) =>
    dispatch(sendDesiredIssuers(businessId, { issuers })),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function IssuersForm({ auth, commerces, sendDesiredIssuers, fetchIssuers }: PropsFromRedux) {
  const classes = useStyles();

  const navigate = useNavigate();
  const [changingDesiredIssuers, setChangingDessiredIssuers] = useState<boolean>(false);
  const [desiredIssuers, setDessiredIssuers] = useState<DesiredIssuer[]>([]);
  const isRegister = window.location.pathname.startsWith('/register');

  useEffect(() => {
    if (!commerces.loadingIssuers && commerces.issuers === null && commerces.issuersErrorMessage === null) {
      fetchIssuers();
    }
  }, [commerces.loadingIssuers, commerces.issuers, commerces.issuersErrorMessage, fetchIssuers]);

  const submit = () => {
    setChangingDessiredIssuers(true);
    sendDesiredIssuers(auth.account?.business.id!, { issuers: desiredIssuers });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (desiredIssuers.some((i) => i.name === event.target.name)) {
      setDessiredIssuers(
        desiredIssuers.filter((i) => {
          return i.name !== event.target.name;
        }),
      );
    } else {
      setDessiredIssuers(
        desiredIssuers.concat([
          {
            name: event.target.name,
            id: event.target.id,
          },
        ]),
      );
    }
  };

  const closeSnack = () => {
    setChangingDessiredIssuers(false);
    if (commerces.desiredIssuersSuccess) {
      navigate('/first-payment-method');
    }
  };

  return (
    <div className={classes.container}>
      <Paper elevation={2}>
        <Typography variant="body1" className={classes.text}>
          Seleccioná los medios de pago que quieres habilitar para que el equipo comercial de Plexo te
          contacte y te ayude a obtener sus códigos de comercio.
        </Typography>
        <div className="form">
          {commerces.loadingIssuers ? (
            <Loading />
          ) : (
            <div className="issuers-list">
              {commerces.issuers &&
                commerces.issuers.map((issuer) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={desiredIssuers.some((i) => i.name === issuer.issuer)}
                        color="primary"
                        onChange={onChange}
                        name={issuer.issuer}
                        id={issuer.id}
                      />
                    }
                    label={issuer.issuer}
                  />
                ))}
            </div>
          )}
        </div>
      </Paper>
      <div className={classes.formActions} style={!isRegister ? { justifyContent: 'flex-end' } : {}}>
        {isRegister && (
          <Button variant="outlined" onClick={() => navigate('/users')}>
            Omitir
          </Button>
        )}
        <ReturnButton />
        <Button
          onClick={submit}
          color="primary"
          variant="contained"
          disabled={desiredIssuers.length < 1 || commerces.sendingDesiredIssuers}
          disableElevation
          className={classes.confirmButton}
        >
          {commerces.sendingDesiredIssuers ? <CircularProgress color="inherit" size={28} /> : 'Confirmar'}
        </Button>
      </div>
      <CustomSnackbar
        open={
          changingDesiredIssuers &&
          (commerces.desiredIssuersSuccess || commerces.desiredIssuersErrorMessage !== null)
        }
        message={
          commerces.desiredIssuersSuccess
            ? '¡Gracias! En breve nos comunicaremos contigo para habilitar los medios de pago seleccionados.'
            : 'Ocurrió un error al solicitar los códigos de comercio indicados.'
        }
        handleClose={closeSnack}
        type={commerces.desiredIssuersSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(IssuersForm);
