import BoltIcon from '../assets/BoltIcon';
import MultiUseLinkIcon from '../assets/MultiUseLinkIcon';
import OneTimeLinkIcon from '../assets/OneTimeLinkIcon';
import { LinkTypeEnum } from '../store/config/enums';

export const linkTypeEnumHelper = {
  toNiceString,
  getIcon,
};

function toNiceString(linkType: LinkTypeEnum): string {
  switch (linkType) {
    case LinkTypeEnum.ONETIME:
      return 'Link de 1 solo uso';
    case LinkTypeEnum.PERMANENT:
      return 'Link multiuso';
    case LinkTypeEnum.ONTHEFLY:
      return 'Pago rápido';
    default:
      return '';
  }
}

function getIcon(linkType: LinkTypeEnum): JSX.Element | null {
  switch (linkType) {
    case LinkTypeEnum.ONETIME:
      return <OneTimeLinkIcon />;
    case LinkTypeEnum.PERMANENT:
      return <MultiUseLinkIcon />;
    case LinkTypeEnum.ONTHEFLY:
      return <BoltIcon />;
    default:
      return null;
  }
}
