import { Typography } from '@material-ui/core';
import Lottie from 'lottie-react';
import emptyAnimation from '../../assets/plinks-empty.json';

interface EmptyMessageProps {
  title?: string;
  message?: string;
}

function EmptyMessage({ title, message }: EmptyMessageProps) {
  return (
    <div className="empty-message">
      <Lottie height="8rem" width="8rem" animationData={emptyAnimation} loop />
      <Typography variant="h6" className="title">
        {title}
      </Typography>
      <Typography variant="h6" className="message">
        {message}
      </Typography>
    </div>
  );
}

export default EmptyMessage;
