import { TextareaAutosize, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import options from '../../components/NewLink/inputData';
import { FormDateField } from '../../components/forms/FormDateField';
import { FormSelectField } from '../../components/forms/FormSelectField';
import { FormTextField } from '../../components/forms/FormTextField';
import { currencyHelper } from '../../helpers/currencyHelper';
import { LinkTypeEnum, ValidUntil } from '../../store/config/enums';
import { LinkValues } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import VATtooltip from './VATTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationCol: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      '& p': {
        margin: '0.8rem 0 -0.6rem 0 ',
      },
    },
    informationRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.9375rem',
      flexWrap: 'nowrap',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    textarea: {
      padding: '0.5625rem 1.5625rem 0.5625rem 0.625rem',
      margin: '1rem 0',
      color: styles.slateBlue,
      border: `1px solid ${styles.borderButtonSecondary}`,
      borderRadius: '4px',
      fontFamily: 'Rubik',
      fontSize: '1rem',
      '&::placeholder': {
        color: styles.slateBlue,
      },
      resize: 'none',
      '&:focus': {
        border: '1px solid transparent',
        outline: `${styles.primary} solid 2px`,
      },
    },
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0.8rem 0 -0.6rem 0 ',
      '& span': {
        display: 'flex',
        marginLeft: '0.375rem',
      },
      '& p': {
        margin: 0,
      },
    },
    infromationLabel: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& i': {
        fontSize: '0.875rem',
        margin: '0.8rem 0 -0.6rem 0 ',
        color: styles.almostGrey,
        textAlign: 'right',
      },
    },
  }),
);

interface InputsProps {
  values: LinkValues;
  linkType: LinkTypeEnum;
}

function Inputs({ values, linkType }: InputsProps) {
  const classes = useStyles();
  const currencyToken = currencyHelper.getCurrencyToken(values.currency);
  const vatAsDecimal = values.vatRate / 100;
  const subTotal = values.value / (1 + vatAsDecimal);
  const vatAmount = values.value - values.value / (1 + vatAsDecimal);

  return (
    <>
      <div className={classes.informationRow}>
        <div className={classes.informationCol}>
          <p>Moneda</p>
          <Field name="currency" component={FormSelectField} type="text" options={options.currency} />
        </div>
        <div className={classes.informationCol}>
          <div className={classes.infromationLabel}>
            <p>Importe Total</p>
            <i>
              Subtotal: {currencyToken} {subTotal.toFixed(2)} | IVA: {currencyToken} {vatAmount.toFixed(2)}
            </i>
          </div>
          <Field name="value" component={FormTextField} type="tel" noLeadingZeros />
        </div>
      </div>
      <div className={classes.informationRow}>
        <div className={classes.informationCol}>
          <div className={classes.tooltipContainer}>
            <p>IVA</p>
            <VATtooltip title="El IVA seleccionado se aplicará únicamente para este link de pago." />
          </div>
          <Field name="vatRate" component={FormSelectField} type="text" options={options.vatRate} />
        </div>
        <div className={classes.informationCol}>
          <p>Vencimiento</p>
          <Field name="validUntil" component={FormSelectField} type="text" options={options.validUntil} />
          {values.validUntil === ValidUntil.CUSTOM && (
            <Field
              label="validUntilDate"
              name="validUntilDate"
              component={FormDateField}
              placeholder="Fecha vencimiento"
              minDateMessage="La fecha de vencimiento no puede ser anterior a la actual"
              type="text"
            />
          )}
        </div>
      </div>
      {linkType === LinkTypeEnum.ONETIME && (
        <div className={classes.informationRow}>
          <div className={classes.informationCol}>
            <div className={classes.infromationLabel}>
              <p>Referencia o destinatario (Opcional)</p>
            </div>
            <Field className="form-row" name="reference" component={FormTextField} type="text" />
          </div>
        </div>
      )}
      <div className={classes.informationRow}>
        <div className={classes.informationCol}>
          <div className={classes.infromationLabel}>
            <p>Descripción del producto o servicio (Opcional)</p>
            <i>Restan {50 - values.description?.length!} caracteres</i>
          </div>
          <Field
            className="form-row"
            name="description"
            render={({ field }: any) => (
              <TextareaAutosize
                contentEditable={false}
                {...field}
                className={classes.textarea}
                rowsMin={3}
                defaultValue=""
                maxLength={50}
              />
            )}
            type="text"
          />
        </div>
      </div>
    </>
  );
}

export default Inputs;
