import { IconButton, Theme, Tooltip, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import EditIcon from '../../assets/EditIcon';
import ShareIcon from '../../assets/ShareIcon';
import DeleteIcon from '../../assets/deleteIcon';
import { linkHelper } from '../../helpers/linkHelper';
import { LinkTypeEnum } from '../../store/config/enums';
import { Link } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import LinkToShareTooltip from './LinkToShareTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '0.5rem',
    },
    creatorTitle: {
      fontSize: '0.75rem',
      fontWeight: Number(styles.titleTextWeight),
      color: styles.slateBlue,
    },
    creator: {
      fontSize: '0.75rem',
      fontWeight: Number(styles.normalTextWeight),
      color: styles.darkBlue,
      marginLeft: '0.25rem',
    },
    smallIconButton: {
      fontSize: '1rem',
      '&:hover': { backgroundColor: 'transparent' },
    },
    iconContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  }),
);

interface CardFooterProps {
  link: Link;
  setShareOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLinkToDelete(link: Link): void;
}

function CardFooter({ link, setShareOpen, setLinkToDelete }: CardFooterProps) {
  const classes = useStyles();
  const navigate = useNavigate();

  const isLinkExpired = link.validUntil ? linkHelper.verifyValidation(link.validUntil) : undefined;
  const noCommercesToPay = linkHelper.verifyCommerces(link.commercesDtos ?? []);

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShareOpen(true);
  };

  const handleEditClick = () => {
    navigate(`/new-${link.linkType === LinkTypeEnum.ONETIME ? 'one-use' : 'permanent'}-link`, {
      state: { link: link },
    });
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setLinkToDelete(link);
  };

  return (
    <div className={classes.footer}>
      <Typography className={classes.creatorTitle}>
        Creado por:<span className={classes.creator}>{link.creatorName}</span>
      </Typography>
      <div className={classes.iconContainer}>
        {isLinkExpired || noCommercesToPay ? (
          <LinkToShareTooltip reason={isLinkExpired ? 'expired' : 'noCommerces'} />
        ) : (
          <Tooltip title="Compartir" placement="top" enterDelay={500}>
            <IconButton
              onClick={handleShareClick}
              size="small"
              classes={{ sizeSmall: classes.smallIconButton }}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Editar" placement="top" enterDelay={500}>
          <IconButton onClick={handleEditClick} size="small" classes={{ sizeSmall: classes.smallIconButton }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar" placement="top" enterDelay={500}>
          <IconButton
            onClick={handleDeleteClick}
            size="small"
            classes={{ sizeSmall: classes.smallIconButton }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

export default CardFooter;
