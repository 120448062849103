import { Button } from '@material-ui/core';
import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Collaborator, RootState } from '../../store/config/types';
import { CustomSwitch } from '../CustomSwitch';
import { CustomTable } from '../CustomTable';
import { HeadCell } from '../CustomTable/CustomTable';
import { Loading } from '../Loading';
import { ScreenTitle } from '../ScreenTitle';
import { StatusPill } from '../StatusPill';
import UpdateButton from './UpdateButton';

interface UsersDesktopProps {
  emptyDataMessage: ReactNode;
  setEnablingCollaborator: (value: React.SetStateAction<boolean>) => void;
  handleEnableCollaborator: (collaborator: Collaborator) => void;
  enablingCollaborator: boolean;
}

function UsersDesktop({
  emptyDataMessage,
  setEnablingCollaborator,
  handleEnableCollaborator,
  enablingCollaborator,
}: UsersDesktopProps) {
  const navigate = useNavigate();
  const collaborators = useSelector((state: RootState) => state.collaborators);
  const [currentEnablingCollaborator, setCurrentEnablingCollaborator] = useState<string | null>(null);

  const orderedCollaborators = collaborators.collaborators?.sort((a, b) =>
    a.names.toUpperCase().localeCompare(b.names.toUpperCase()),
  );

  const headCells: HeadCell<any>[] = [
    { columnId: 'collaborator', label: 'Colaborador' },
    { columnId: 'email', label: 'Email' },
    { columnId: 'state', label: 'Estado' },
    { columnId: 'enabled', label: 'Habilitado' },
    { columnId: 'actions', label: 'Acciones' },
  ];

  const handleSwitchClick = (e: React.MouseEvent, row: Collaborator) => {
    e.stopPropagation();
    setEnablingCollaborator(true);
    setCurrentEnablingCollaborator(row.userId);
    handleEnableCollaborator(row);
  };

  const renderCustomTableCells = (row: Collaborator, columnId: string) => {
    const isSwitchDisabled =
      !row.emailConfirmed || (enablingCollaborator && currentEnablingCollaborator === row.userId);
    switch (columnId) {
      case 'collaborator':
        return `${row.names} ${row.surnames}`;
      case 'email':
        return row.email;
      case 'state':
        return (
          <StatusPill
            label={row.emailConfirmed ? 'Verificado' : 'Pendiente de verif'}
            color={row.emailConfirmed ? 'success' : 'error'}
          />
        );
      case 'enabled':
        return (
          <CustomSwitch
            color="primary"
            defaultChecked={row.active}
            onClick={(e) => handleSwitchClick(e, row)}
            disabled={isSwitchDisabled}
          />
        );
      case 'actions':
        return <UpdateButton collaborator={row} />;
      default:
        return row;
    }
  };

  return (
    <div className="screen-container users">
      <ScreenTitle
        title="Colaboradores"
        elementsAmount={collaborators.collaborators ? orderedCollaborators?.length : 0}
      >
        <Button
          variant="contained"
          color="primary"
          className="main-button"
          onClick={() => {
            navigate('/user');
          }}
        >
          Nuevo colaborador
        </Button>
      </ScreenTitle>
      {/* TODO: Add Search after backend implementation */}
      {collaborators.loadingCollaborators ? (
        <Loading />
      ) : (
        <CustomTable
          boxProps={{ width: '100%', marginTop: '1rem' }}
          moreTopMargin
          headCells={headCells}
          rowsData={orderedCollaborators || []}
          renderCell={renderCustomTableCells}
          emptyDataMessage={emptyDataMessage}
          hasRowHover={false}
        />
      )}
    </div>
  );
}

export default UsersDesktop;
