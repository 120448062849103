import {
  LOGOUT,
  LogoutActionTypes,
  NotificationsDataTypes,
  RESENDING_NOTIFICATION,
  RESEND_NOTIFICATION_ERROR,
  RESEND_NOTIFICATION_SUCCESS,
  SENDING_NOTIFICATION,
  SEND_NOTIFICATION_ERROR,
  SEND_NOTIFICATION_SUCCESS,
} from '../config/ActionTypes';
import { NotificationsState } from '../config/types';

export const initialState: NotificationsState = {
  sendingNotification: false,
  sendNotificationSuccess: false,
  sendNotificationErrorMessage: null,
  resendingNotification: false,
  notification: null,
  resendNotificationSuccess: false,
  resendNotificationErrorMessage: null,
};

export function notificationsReducer(
  state = initialState,
  action: NotificationsDataTypes | LogoutActionTypes,
): NotificationsState {
  switch (action.type) {
    case SENDING_NOTIFICATION:
      return {
        ...state,
        ...initialState,
        sendingNotification: true,
        sendNotificationSuccess: false,
        sendNotificationErrorMessage: null,
      };

    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...initialState,
        notification: action.notification,
        sendingNotification: false,
        sendNotificationSuccess: true,
        sendNotificationErrorMessage: null,
      };

    case SEND_NOTIFICATION_ERROR:
      return {
        ...state,
        ...initialState,
        sendingNotification: false,
        sendNotificationSuccess: false,
        sendNotificationErrorMessage: action.error,
      };

    case RESENDING_NOTIFICATION:
      return {
        ...state,
        ...initialState,
        resendingNotification: true,
        resendNotificationSuccess: false,
        resendNotificationErrorMessage: null,
      };

    case RESEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...initialState,
        resendingNotification: false,
        resendNotificationSuccess: true,
        resendNotificationErrorMessage: null,
      };

    case RESEND_NOTIFICATION_ERROR:
      return {
        ...state,
        ...initialState,
        resendingNotification: false,
        resendNotificationSuccess: false,
        resendNotificationErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
