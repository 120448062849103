import colors from '../../../styles/_variables.module.scss';

function Resend() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill={colors.white}
      xmlns="http://www.w3.org/2000/svg"
      className="table-action-icon"
    >
      <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke={colors.borderButtonSecondary} />
      <path
        d="M22.6731 13.345L17.7901 8.24005C17.6367 8.08045 17.4392 7.97031 17.2227 7.92377C17.0063 7.87722 16.7809 7.89638 16.5755 7.9788C16.37 8.06121 16.1939 8.20313 16.0696 8.38633C15.9454 8.56954 15.8787 8.78568 15.8781 9.00705V11.453C11.2881 11.508 7.00014 12.339 7.00014 17.086C6.99368 18.0325 7.215 18.9665 7.64542 19.8094C8.07583 20.6523 8.70271 21.3793 9.47314 21.929C9.62225 22.0377 9.8015 22.0971 9.98597 22.0992C10.1704 22.1013 10.351 22.0459 10.5025 21.9406C10.654 21.8354 10.769 21.6856 10.8315 21.512C10.894 21.3384 10.9008 21.1497 10.8511 20.972C9.80014 17.244 11.5941 16.83 15.8781 16.78V19.217C15.8787 19.4384 15.9454 19.6546 16.0696 19.8378C16.1939 20.021 16.37 20.1629 16.5755 20.2453C16.7809 20.3277 17.0063 20.3469 17.2227 20.3003C17.4392 20.2538 17.6367 20.1436 17.7901 19.984L22.6731 14.884C22.8706 14.6775 22.9808 14.4028 22.9808 14.117C22.9808 13.8313 22.8706 13.5566 22.6731 13.35V13.345ZM22.0311 14.266L17.1481 19.366C17.1175 19.3979 17.078 19.4199 17.0348 19.4292C16.9916 19.4385 16.9466 19.4347 16.9056 19.4182C16.8646 19.4018 16.8294 19.3735 16.8045 19.3369C16.7797 19.3004 16.7663 19.2572 16.7661 19.213V15.887C12.0111 15.887 8.57314 16.157 10.0001 21.206C9.34155 20.7409 8.80543 20.1232 8.43763 19.4057C8.06983 18.6882 7.88129 17.8923 7.88814 17.086C7.88814 12.647 16.7661 12.336 16.7661 12.336V9.00705C16.7661 8.80705 17.1481 8.85405 17.1481 8.85405L22.0311 13.954C22.0721 13.9954 22.0951 14.0513 22.0951 14.1095C22.0951 14.1678 22.0721 14.2247 22.0311 14.266Z"
        fill={colors.slateBlue}
      />
    </svg>
  );
}

export default Resend;
