import { IconProps } from '../../../data/icon.type';
import colors from '../../../styles/_variables.module.scss';

function ArrowDown({ color, className }: IconProps) {
  color = color ?? colors.veryDarkBlue;
  className = className ?? '';

  return (
    <svg
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.677246 0.528119L6.56883 6.39306L12.2416 0.521484"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowDown;
