import { CircularProgress } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import UploadIcon from '../../assets/UploadIcon';
import dummy from '../../assets/dummy.png';
import { urlHelper } from '../../helpers/urlHelper';
import { RootState } from '../../store/config/types';

interface ImageUploadProps {
  image: File | null;
  setImage: Dispatch<SetStateAction<File | null>>;
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
  imageRef: React.RefObject<HTMLImageElement>;
  isUpdating?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  isUpdating = false,
  image,
  setImage,
  error,
  setError,
  imageRef,
}) => {
  const auth = useSelector((state: RootState) => state.auth);
  const imageInput = useRef<HTMLInputElement>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  const onImageSelected = (files: FileList | null) => {
    if (files) {
      const file = files[0];
      const fileType = file?.type;
      if (file?.size > 5 * 1024 * 1024) {
        setError('*La imagen que intentas cargar supera los 5MB. Por favor, elige otra.');
        setImage(null);
        return;
      }
      if (!['image/jpeg', 'image/jpeg', 'image/png'].includes(fileType)) {
        setError('*La imagen que intentas cargar no está en formato JPG, JPEG o PNG. Por favor, elige otra.');
        setImage(null);
        return;
      }
      setError(null);
      setLoadingImage(false);
      setImage(file);

      if (FileReader) {
        const fr = new FileReader();
        fr.onload = function () {
          if (imageRef.current) imageRef.current.src = fr.result!.toString();
        };
        fr.readAsDataURL(file);
      }
    }
  };

  const onImageError = () => {
    if (imageRef.current) {
      imageRef.current.src = dummy;
    }
  };

  const businessExistingImage =
    auth.account && auth.account?.business?.id ? urlHelper.buildImageUrl(auth.account.business.id) : null;

  const imageSrc = error ? dummy : image ? image.name : businessExistingImage || dummy;

  return (
    <>
      <h3 className="upload-title">Logo de la empresa</h3>
      <p className="upload-helper">Debes proporcionar el logo de tu empresa para su identificación.</p>
      <div className="upload-btn-wrapper">
        <div className={`img-container ${(!image && !isUpdating) || error ? 'no-image' : ''}`}>
          <img ref={imageRef} src={imageSrc} onError={onImageError} alt="img" />
          {((!image && !isUpdating) || error) && (
            <p className="image-instructions">
              La imagen que incluyas debe estar en formato JPG, JPEG o PNG y no pesar más de 5MB
            </p>
          )}
        </div>
        <div className="actions">
          <label className={loadingImage ? 'disabled-label' : ''} htmlFor="imageUpload">
            {loadingImage ? (
              <CircularProgress size={20} thickness={6} />
            ) : (
              <span onClick={(e) => e.stopPropagation()} className="action buttonable-span">
                <UploadIcon />
                <span>Cargar imagen</span>
              </span>
            )}
          </label>
        </div>
        <input
          type="file"
          id="imageUpload"
          accept="image/*"
          ref={imageInput}
          onChange={() =>
            onImageSelected(imageInput && imageInput.current ? imageInput.current?.files : null)
          }
          style={{ display: 'none' }}
        />
      </div>
      {error && <p className="image-error">{error}</p>}
    </>
  );
};

export default ImageUpload;
