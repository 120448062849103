import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ReactElement } from 'react';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyItems: 'space-between',
      justifyContent: 'space-between',
      marginBottom: '0.875rem',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      '& :nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
        '& span': {
          display: 'flex',
          alignItems: 'center',
        },
        '& svg': {
          marginRight: '0.3125rem',
          height: '1.5rem',
          width: 'fit-content',
        },
      },
      '&:last-child': {
        display: 'flex',
        alignItems: 'start',
        textAlign: 'right',
      },
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        gap: '0.3rem',
        '& :nth-child(2)': {
          textAlign: 'left',
        },
      },
    },
    state: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyItems: 'space-between',
      justifyContent: 'space-between',
      marginBottom: '0.875rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
      },
    },
    rowTitle: {
      color: styles.slateBlue,
      fontSize: '0.875rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
    },
    rowDetail: {
      color: styles.blackText,
      fontSize: '0.875rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
    },
  }),
);

interface DetailRowProps {
  title: string | ReactElement;
  detail?: string | ReactElement | null;
  isState?: boolean;
}

function DetailRow({ title, detail, isState }: DetailRowProps) {
  const classes = useStyles();
  return (
    <div className={isState ? classes.state : classes.flex}>
      <p className={classes.rowTitle}>{title}</p>
      <p className={classes.rowDetail}>{detail ? detail : '-'}</p>
    </div>
  );
}

export default DetailRow;
