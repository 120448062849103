import { Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UserTypeEnum } from '../../../store/config/enums';
import { RootState } from '../../../store/config/types';
import Profile from '../../Icons/Profile';
import NavigationItem from './NavigationItem';

interface ProfileNavigationItemProps {
  setMobileMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
  profileExpanded: boolean;
  setProfileExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  handleOptionSelect: (path: string) => void;
}

function ProfileNavigationItem({
  setMobileMenuOpened,
  profileExpanded,
  setProfileExpanded,
  handleOptionSelect,
}: ProfileNavigationItemProps) {
  const location = useLocation();
  const isCollaborator = useSelector(
    (state: RootState) => state.auth.account?.type === UserTypeEnum.COLLABORATOR,
  );

  const path = location.pathname;
  const isProfileSelected = path === '/profile' || path === '/change-password';

  const toggleProfile = () => {
    setProfileExpanded(!profileExpanded);
  };

  return !isCollaborator ? (
    <NavigationItem
      path="/profile"
      setMobileMenuOpened={setMobileMenuOpened}
      icon={<Profile className="icon" />}
      title="Mi perfil"
      handleOptionSelect={handleOptionSelect}
    />
  ) : (
    <>
      <li className={`option ${isProfileSelected && 'selected-profile-option'}`} onClick={toggleProfile}>
        <Profile className="icon" /> Mi Perfil
        <ExpandMoreIcon className={`expand-icon ${profileExpanded ? 'expanded' : ''}`} />
      </li>
      <Collapse in={profileExpanded} timeout="auto" unmountOnExit>
        <NavigationItem
          path="/change-password"
          setMobileMenuOpened={setMobileMenuOpened}
          title="Cambiar contraseña"
          className="sub-option"
          handleOptionSelect={handleOptionSelect}
        />
      </Collapse>
    </>
  );
}

export default ProfileNavigationItem;
