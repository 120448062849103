import { IconProps } from '../../../data/icon.type';
import colors from '../../../styles/_variables.module.scss';

function Filters({ color, className }: IconProps) {
  color = color ?? colors.slateBlue;
  className = className ?? '';

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={colors.white}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.494 14.2344H1.021"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5552 14.2357C11.5552 16.2777 12.2362 16.9577 14.2772 16.9577C16.3182 16.9577 16.9992 16.2777 16.9992 14.2357C16.9992 12.1937 16.3182 11.5137 14.2772 11.5137C12.2362 11.5137 11.5552 12.1937 11.5552 14.2357Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5269 3.74609H16.9989"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.465 3.74544C6.465 1.70444 5.785 1.02344 3.744 1.02344C1.703 1.02344 1.021 1.70444 1.021 3.74544C1.021 5.78644 1.702 6.46744 3.744 6.46744C5.786 6.46744 6.465 5.78744 6.465 3.74544Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Filters;
