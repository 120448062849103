import { Dispatch } from 'redux';
import { clientsService } from '../../services/clients.service';
import {
  ADD_CLIENTS,
  FETCHING_CLIENTS,
  FETCH_CLIENTS_FAILED,
  FetchClientsTypes,
  TOGGLE_CLIENT_BILLING_FAILED,
  TOGGLE_CLIENT_BILLING_SUCCESS,
  TOGGLING_CLIENT_BILLING,
  ToggleClientBillingTypes,
} from '../config/ActionTypes';
import { Client, ToggleBillingRequest } from '../config/types';

export const fetchClients = (searchTerm?: string) => {
  return (dispatch: Dispatch<FetchClientsTypes>) => {
    dispatch(loadingClients());
    return clientsService.fetchClients(searchTerm).then(
      (response) => {
        dispatch(addClients(response!));
      },
      (error) => {
        dispatch(clientsFailed(error));
      },
    );
  };
};

export const loadingClients = (): FetchClientsTypes => ({
  type: FETCHING_CLIENTS,
});

export const addClients = (clients: Client[]): FetchClientsTypes => ({
  type: ADD_CLIENTS,
  clients,
});

export const clientsFailed = (error: string): FetchClientsTypes => ({
  type: FETCH_CLIENTS_FAILED,
  error,
});

export const toggleBilling = (request: ToggleBillingRequest) => {
  return (dispatch: Dispatch<ToggleClientBillingTypes>) => {
    dispatch(updatingBilling());
    return clientsService.toggleClientBilling(request).then(
      () => {
        dispatch(toggleBillingSuccess(request));
      },
      (error) => {
        dispatch(toggleBillingError(error));
      },
    );
  };
};

export const updatingBilling = (): ToggleClientBillingTypes => ({
  type: TOGGLING_CLIENT_BILLING,
});

export const toggleBillingSuccess = (request: ToggleBillingRequest): ToggleClientBillingTypes => ({
  type: TOGGLE_CLIENT_BILLING_SUCCESS,
  request,
});

export const toggleBillingError = (error: string): ToggleClientBillingTypes => ({
  type: TOGGLE_CLIENT_BILLING_FAILED,
  error,
});
