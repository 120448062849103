import colors from '../styles/_variables.module.scss';

interface OneTimeLinkIconProps {
  color?: string;
  transparent?: boolean;
}

function OneTimeLinkIcon({ color = colors.plexoBlue, transparent = false }: OneTimeLinkIconProps) {
  return (
    <svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 2046">
        <path
          id="Rectangle 616"
          d="M0.173828 14C0.173828 6.26802 6.44184 0 14.1738 0H16.1738C23.9058 0 30.1738 6.26802 30.1738 14V14.6364C30.1738 22.3683 23.9058 28.6364 16.1738 28.6364H14.1738C6.44184 28.6364 0.173828 22.3683 0.173828 14.6364V14Z"
          fill={color}
          fill-opacity={transparent ? `0` : `0.1`}
        />
        <g id="icon-one">
          <g id="noun-repeat-one-2447137">
            <path
              id="Uni&#195;&#179;n 3"
              d="M22.2869 17.5033C22.1712 17.4363 22.0751 17.3401 22.0083 17.2242C21.9415 17.1084 21.9064 16.977 21.9065 16.8433V12.5819C21.9066 12.2623 21.8438 11.9459 21.7217 11.6506C21.5995 11.3552 21.4203 11.0869 21.1944 10.8609C20.9685 10.6348 20.7002 10.4555 20.405 10.3332C20.1098 10.2108 19.7933 10.1478 19.4737 10.1478H12.571L12.7878 10.3647C12.8706 10.4612 12.928 10.577 12.9546 10.7014C12.9812 10.8258 12.9762 10.9549 12.9402 11.0769C12.9041 11.1989 12.8381 11.31 12.7481 11.3999C12.6582 11.4899 12.5471 11.5559 12.4251 11.5919C12.3178 11.6231 12.2051 11.6306 12.0947 11.6139C11.9842 11.5972 11.8788 11.5566 11.7855 11.4951H11.7665L11.6996 11.4283L10.1915 9.92148C10.0491 9.77861 9.96924 9.58518 9.96924 9.38352C9.96924 9.18187 10.0491 8.98844 10.1915 8.84557L11.6996 7.33739C11.7703 7.26665 11.8543 7.21059 11.9467 7.17244C12.0392 7.13429 12.1383 7.11481 12.2383 7.11512C12.3055 7.11511 12.3724 7.12382 12.4374 7.14102C12.5665 7.17623 12.6842 7.24478 12.7785 7.3398C12.8728 7.43482 12.9405 7.55297 12.9748 7.6824C13.009 7.81183 13.0087 7.948 12.9737 8.07724C12.9387 8.20648 12.8704 8.32425 12.7755 8.41875L12.5724 8.62193H19.4737C20.5225 8.62193 21.5284 9.03857 22.27 9.78019C23.0116 10.5218 23.4283 11.5277 23.4283 12.5765V16.8419C23.4275 16.9755 23.3918 17.1065 23.3246 17.222C23.2575 17.3375 23.1613 17.4334 23.0455 17.5001C22.9298 17.5669 22.7987 17.6022 22.6651 17.6025C22.5315 17.6028 22.4002 17.5681 22.2842 17.5019L22.2869 17.5033Z"
              fill={color}
            />
            <path
              id="Uni&#195;&#179;n 1"
              d="M17.6172 22.1114C17.5433 22.0376 17.4854 21.9494 17.4471 21.8523C17.4087 21.7553 17.3907 21.6513 17.3942 21.547C17.3976 21.4427 17.4225 21.3402 17.4672 21.2459C17.5118 21.1515 17.5754 21.0674 17.654 20.9986L17.8517 20.8009H10.9462C10.4267 20.8009 9.91224 20.6985 9.43228 20.4996C8.95231 20.3007 8.51624 20.0092 8.149 19.6417C7.78175 19.2742 7.49051 18.8379 7.29194 18.3578C7.09336 17.8777 6.99134 17.3632 6.9917 16.8436V12.5823C6.99197 12.4483 7.02737 12.3167 7.09436 12.2006C7.16136 12.0846 7.25761 11.9881 7.37352 11.9209C7.48988 11.8554 7.62114 11.821 7.75465 11.821C7.88817 11.821 8.01943 11.8554 8.13579 11.9209C8.25154 11.9882 8.34759 12.0847 8.41436 12.2008C8.48112 12.3168 8.51625 12.4484 8.51625 12.5823V16.8436C8.51625 17.4884 8.77218 18.1067 9.22781 18.5629C9.68345 19.019 10.3015 19.2756 10.9462 19.2764H17.8558L17.6362 19.0486C17.5538 18.9519 17.4967 18.8362 17.4703 18.7118C17.4439 18.5875 17.4489 18.4586 17.485 18.3367C17.521 18.2148 17.5869 18.1038 17.6766 18.0138C17.7664 17.9239 17.8772 17.8577 17.999 17.8214C18.1211 17.7852 18.2504 17.7802 18.375 17.8069C18.4996 17.8336 18.6155 17.8911 18.7122 17.9741L18.7244 17.9864L20.2312 19.4986C20.3736 19.6415 20.4535 19.8349 20.4535 20.0366C20.4535 20.2382 20.3736 20.4317 20.2312 20.5745L20.1631 20.6427L18.7299 22.0759C18.6613 22.1538 18.5776 22.2168 18.4838 22.2611C18.39 22.3055 18.2881 22.3301 18.1844 22.3336H18.1572C17.9549 22.3336 17.7608 22.2537 17.6172 22.1114Z"
              fill={color}
            />
            <path
              id="Uni&#195;&#179;n 2"
              d="M15.2064 17.6057C15.1069 17.606 15.0084 17.5866 14.9164 17.5487C14.8245 17.5108 14.7409 17.4551 14.6705 17.3848C14.5985 17.3134 14.5414 17.2284 14.5026 17.1348C14.4637 17.0411 14.4439 16.9407 14.4441 16.8393V14.3289C14.2876 14.3873 14.1163 14.3935 13.956 14.3466C13.8342 14.3103 13.7234 14.2441 13.6336 14.1541C13.5439 14.0641 13.478 13.9532 13.442 13.8313C13.4059 13.7094 13.4009 13.5805 13.4273 13.4561C13.4537 13.3318 13.5108 13.216 13.5932 13.1193L13.6069 13.1057L14.6719 12.0407C14.8151 11.8974 15.0092 11.8165 15.2119 11.8157C15.3132 11.8148 15.4137 11.8343 15.5074 11.873C15.6011 11.9116 15.6861 11.9686 15.7573 12.0407C15.9007 12.1859 15.9807 12.3821 15.9796 12.5862V16.8434C15.9792 17.0455 15.8988 17.2392 15.7559 17.382C15.6131 17.5249 15.4194 17.6053 15.2173 17.6057H15.2064Z"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OneTimeLinkIcon;
