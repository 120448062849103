import { Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/config/types';
import GoBackDialog from './GoBackDialog';

declare global {
  interface Window {
    attachEvent: any;
  }
}

window.attachEvent = window.attachEvent || {};

function PlexoPayment() {
  const navigate = useNavigate();
  const payments = useSelector((state: RootState) => state.payments);
  const business = useSelector((state: RootState) => state.business.business);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  // useEffect(() => {
  //   window.addEventListener('message', receiveMessage)

  //   return () => {
  //     window.removeEventListener('message', receiveMessage)
  //   }
  // })

  // function receiveMessage(e: MessageEventInit) {
  //   console.log(e.data)
  //   console.log(e.data.message)
  //   if (e.data.message === 'redirectError') {
  //     navigate(`/result?errorCode=${e.data.payload}`)
  //   } else if (e.data.message === 'redirectSuccess') {
  //     navigate(`/result?beginPaymentId=${e.data.payload}`)
  //   }
  // }

  function onMessage(event: MessageEventInit) {
    var data = event.data;
    console.log(event);
    if (data && data.plexo && iframeRef && iframeRef.current) {
      iframeRef.current.height = data.plexo.height;
    }
  }

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', onMessage, false);
    }

    return () => {
      window.removeEventListener('message', onMessage, false);
    };
  });

  useEffect(() => {
    if (payments.confirmPaymentSuccess) {
      navigate(`/result`);
    } else if (payments.confirmPaymentErrorMessage !== null) {
      navigate(`/result?errorCode=`);
    }
  });

  useEffect(() => {
    if (!business) navigate(-1);
  }, [business, navigate]);

  return (
    <div className="screen-container plexo-payment">
      <div className="plexo-payment-header">
        <Typography variant="h4">Ingresa los datos de tu tarjeta</Typography>
        <button color="secondary" onClick={() => setOpen(true)} className="secondary-button">
          Volver
        </button>
      </div>
      <div className="consumer-card">
        <iframe
          id="plexoIframe"
          plexo-payment-header
          ref={iframeRef}
          height={600}
          width={498}
          src={payments.plexoUrl ? payments.plexoUrl : 'https://google.com'}
          title="plexo"
        />
      </div>
      <GoBackDialog open={open} setOpen={setOpen} />
    </div>
  );
}

export default PlexoPayment;
