import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Loading } from '../../components/Loading';
import { ScreenTitle } from '../../components/ScreenTitle';
import { CommerceForm } from '../../components/forms/CommerceForm';
import { RootState } from '../../store/config/types';

function FirstCommerce() {
  const navigate = useNavigate();
  const { auth, business, commerces } = useSelector((state: RootState) => state);
  const hasBusinessCreated = auth.account?.business?.id || business.createdBusiness;

  useEffect(() => {
    const cookies = new Cookies();

    if (cookies.get('token')) {
      if (!hasBusinessCreated && auth.account) {
        navigate('/register?step=2');
      } else if (
        hasBusinessCreated &&
        auth.account?.business?.hasCommerces &&
        !commerces.createCommerceSuccess
      ) {
        navigate('/dashboard');
      }
    } else {
      navigate('/login');
    }
  }, [
    auth.account,
    auth.account?.business?.hasCommerces,
    commerces.createCommerceSuccess,
    hasBusinessCreated,
    navigate,
  ]);

  return (
    <>
      {commerces.loadingIssuers ? (
        <Loading />
      ) : (
        <div className="screen-container profile">
          <ScreenTitle title="Agrega tus medios de pago" />
          <CommerceForm
            handleSuccess={() => navigate('/dashboard')}
            commerce={null}
            firstCommerce
            isRegisterStep
          />
        </div>
      )}
    </>
  );
}

export default FirstCommerce;
