import { IconProps } from '../../../data/icon.type';
import colors from '../../../styles/_variables.module.scss';

function ArrowForwardOutlined({ color, className }: IconProps) {
  color = color ?? colors.slateBlue;
  className = className ?? '';

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="0.5"
        y="0.5"
        width="29"
        height="29"
        rx="3.5"
        fill={colors.white}
        stroke={colors.borderButtonSecondary}
      />
      <path d="M12.0079 22L19 14.8675L12 8" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowForwardOutlined;
