import { Dispatch } from 'redux';
import { paymentsService } from '../../services/payments.service';
import {
  ADD_DASHBOARD_PAYMENTS,
  ADD_PAYMENTS,
  BEGINNING_PAYMENT,
  BEGIN_PAYMENT_ERROR,
  BEGIN_PAYMENT_SUCCESS,
  BeginPaymentTypes,
  CANCELLING_PAYMENT,
  CANCEL_PAYMENT_ERROR,
  CANCEL_PAYMENT_SUCCESS,
  CLEAR_INSTRUMENT,
  CONFIRMING_PAYMENT,
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_SUCCESS,
  CancelPaymentTypes,
  ClearInstrumentTypes,
  ConfirmPaymentTypes,
  FETCHING_PAYMENTS,
  FETCH_PAYMENTS_ERROR,
  FetchPaymentsTypes,
  GETTING_PAYMENT,
  GET_PAYMENT_ERROR,
  GET_PAYMENT_SUCCESS,
  GetPaymentTypes,
  LOAD_PAYMENT_FILTERS,
  LoadPaymentsFiltersAction,
  REGISTER_CALLBACK_FAILED,
  RegisterCallbackFailedType,
  TOGGLE_RESET_CLIENT_FILTER,
  TRANSACTION_CALLBACK,
  TransactionCallbackType,
  VISA_REDIRECTED,
  VisaRedirectedType,
} from '../config/ActionTypes';
import {
  BeginPaymentRequest,
  BeginPaymentResponse,
  ConfirmPaymentResponse,
  FinalizePaymentRequest,
  Payment,
  PaymentResponse,
  PaymentsFilters,
  TransactionStatus,
} from '../config/types';

//#region Fetch payments

export const fetchPayments =
  (filters: PaymentsFilters, page: number, limit: number, businessId: number, isDashboard: boolean = false) =>
  (dispatch: Dispatch<FetchPaymentsTypes>) => {
    dispatch(fetchingPayments());

    return paymentsService.fetchPayments({ ...filters, page, limit }, businessId).then(
      (response) => {
        if (isDashboard) {
          dispatch(addDashboardPayments(response!));
        } else {
          dispatch(addPayments(response!));
        }
      },
      (error) => {
        dispatch(fetchPaymentsError(error));
      },
    );
  };

const fetchingPayments = (): FetchPaymentsTypes => ({
  type: FETCHING_PAYMENTS,
});

export const loadPaymentsFilters = (
  filters: PaymentsFilters,
  page: number,
  limit: number,
  businessId?: number,
) => {
  return (dispatch: Dispatch<FetchPaymentsTypes | LoadPaymentsFiltersAction>) => {
    dispatch(loadFilters(filters));

    return paymentsService
      .fetchPayments(
        {
          ...filters,
          page,
          limit,
        },
        businessId,
      )
      .then(
        (response) => {
          dispatch(addPayments(response!));
        },
        (error) => {
          dispatch(fetchPaymentsError(error));
        },
      );
  };
};

export const loadFilters = (filters: PaymentsFilters): LoadPaymentsFiltersAction => ({
  type: LOAD_PAYMENT_FILTERS,
  filters: filters,
});

const addPayments = (paymentResponse: PaymentResponse): FetchPaymentsTypes => ({
  type: ADD_PAYMENTS,
  paymentResponse,
});

const addDashboardPayments = (paymentResponse: PaymentResponse): FetchPaymentsTypes => ({
  type: ADD_DASHBOARD_PAYMENTS,
  paymentResponse,
});

const fetchPaymentsError = (error: string): FetchPaymentsTypes => ({
  type: FETCH_PAYMENTS_ERROR,
  error,
});

//#endregion

//#region Begin payment

export const beginPayment =
  (beginPaymentRequest: BeginPaymentRequest) => (dispatch: Dispatch<BeginPaymentTypes>) => {
    dispatch(beginningPayment());

    return paymentsService.beginPayment(beginPaymentRequest).then(
      (response) => {
        console.log(response);
        dispatch(beginPaymentSuccess(response!, beginPaymentRequest.paymentUrl));
      },
      (error) => {
        dispatch(beginPaymentError(error));
      },
    );
  };

const beginningPayment = (): BeginPaymentTypes => ({
  type: BEGINNING_PAYMENT,
});

const beginPaymentSuccess = (
  confirmPaymentResponse: BeginPaymentResponse,
  paymentUrl: string,
): BeginPaymentTypes => ({
  type: BEGIN_PAYMENT_SUCCESS,
  confirmPaymentResponse,
  paymentUrl,
});

const beginPaymentError = (error: string): BeginPaymentTypes => ({
  type: BEGIN_PAYMENT_ERROR,
  error,
});

//#endregion

//#region  Get payment

export const getPayment =
  (paymentId: string, businessId?: number) => (dispatch: Dispatch<GetPaymentTypes>) => {
    dispatch(gettingPayment());

    return paymentsService.getPayment({ paymentId, businessId }).then(
      (response) => {
        dispatch(getPaymentSuccess(response!));
      },
      (error) => {
        dispatch(getPaymentError(error));
      },
    );
  };

const gettingPayment = (): GetPaymentTypes => ({
  type: GETTING_PAYMENT,
});

const getPaymentSuccess = (paymentResponse: Payment): GetPaymentTypes => ({
  type: GET_PAYMENT_SUCCESS,
  paymentResponse,
});

const getPaymentError = (error: string): GetPaymentTypes => ({
  type: GET_PAYMENT_ERROR,
  error,
});

//#endregion

//#region Clear instrument

export const clearInstrument = (): ClearInstrumentTypes => ({
  type: CLEAR_INSTRUMENT,
});

//#endregion

//#region Confirm payment

export const confirmPayment =
  (finalizePaymentRequest: FinalizePaymentRequest) => (dispatch: Dispatch<ConfirmPaymentTypes>) => {
    dispatch(confirmingPayment());

    return paymentsService.confirmPayment(finalizePaymentRequest).then(
      (response) => {
        dispatch(confirmPaymentSuccess(response!));
      },
      (error) => {
        dispatch(confirmPaymentError(error));
      },
    );
  };

const confirmingPayment = (): ConfirmPaymentTypes => ({
  type: CONFIRMING_PAYMENT,
});

const confirmPaymentSuccess = (response: ConfirmPaymentResponse): ConfirmPaymentTypes => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  response,
});

const confirmPaymentError = (error: string): ConfirmPaymentTypes => ({
  type: CONFIRM_PAYMENT_ERROR,
  error,
});

//#endregion

//#region Cancel payment

export const cancelPayment =
  (plexoReferenceId: string, businessId: number) => (dispatch: Dispatch<CancelPaymentTypes>) => {
    dispatch(cancellingPayment());

    return paymentsService.cancelPayment(plexoReferenceId, businessId).then(
      (response) => {
        dispatch(cancelPaymentSuccess(plexoReferenceId));
      },
      (error) => {
        dispatch(cancelPaymentError(error));
      },
    );
  };

const cancellingPayment = (): CancelPaymentTypes => ({
  type: CANCELLING_PAYMENT,
});

const cancelPaymentSuccess = (plexoReferenceId: string): CancelPaymentTypes => ({
  type: CANCEL_PAYMENT_SUCCESS,
  plexoReferenceId,
});

const cancelPaymentError = (error: string): CancelPaymentTypes => ({
  type: CANCEL_PAYMENT_ERROR,
  error,
});

//#endregion

//#region Visa Redirect

export const visaRedirect = (paymentId: string): VisaRedirectedType => ({
  type: VISA_REDIRECTED,
  paymentId,
});

//#endregion

//#region TransactionCallback

export const transactionCallback = (response: TransactionStatus): TransactionCallbackType => ({
  type: TRANSACTION_CALLBACK,
  response,
});

//#endregion

//#region RegisterCallbackFailed

export const registerCallbackFailed = (response: boolean): RegisterCallbackFailedType => ({
  type: REGISTER_CALLBACK_FAILED,
  response,
});

//#endregion

//#region ResetClientFilerTrigger

export const toggleResetClientFilter = () => ({
  type: TOGGLE_RESET_CLIENT_FILTER,
});

//#endregion
