import { Button, Paper } from '@material-ui/core';
import axios from 'axios';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { ScreenTitle } from '../../components/ScreenTitle';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { Status } from '../../store/config/enums';

interface Values {
  name: string;
  email: string;
  message: string;
}

function Contact() {
  const navigate = useNavigate();
  const location = useLocation();

  const [serverState, setServerState] = useState<boolean | null>(null);
  const [serverMessage, setServerMessage] = useState<string | null>(null);
  const [snackOpened, setSnackOpened] = useState<boolean>(false);

  const handleServerResponse = (ok: boolean, msg: string) => {
    setServerState(ok);
    setServerMessage(msg);
    setSnackOpened(true);
  };

  const handleOnSubmit = (values: Values, actions: FormikHelpers<Values>) => {
    axios({
      method: 'POST',
      url: 'https://formspree.io/f/moqpblzg',
      data: values,
    })
      .then((response: any) => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, 'Gracias por contactarnos');
      })
      .catch((error: any) => {
        actions.setSubmitting(false);
        handleServerResponse(false, error.response.data.error);
      });
  };

  const closeSnack = () => {
    setSnackOpened(false);

    setTimeout(() => {
      if (serverState) {
        if (location.pathname === '/contact') {
          navigate('/faqs');
        } else {
          navigate('/user-faqs');
        }
      }

      setServerState(null);
      setServerMessage(null);
    }, 200);
  };

  return (
    <div className="screen-container contact-form">
      <ScreenTitle title="Contacto" />
      <Paper elevation={2} style={{ paddingTop: '1rem' }}>
        <Formik
          initialValues={{ name: '', email: '', message: '' }}
          onSubmit={handleOnSubmit}
          validationSchema={schemas.ContactSchema}
        >
          {({ isSubmitting }) => (
            <Form id="fs-frm" noValidate>
              <Field id="name" component={FormTextField} type="text" name="name" placeholder="Nombre" />
              <Field id="email" component={FormTextField} type="email" name="email" placeholder="Email" />
              <Field
                id="message"
                component={FormTextField}
                name="message"
                placeholder="Mensaje"
                multiline
                rows={5}
              />
              <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                Enviar solicitud
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
      <CustomSnackbar
        open={snackOpened}
        message={serverMessage !== null ? serverMessage : ''}
        handleClose={closeSnack}
        type={serverState ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default Contact;
