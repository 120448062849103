import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Loading } from '../../components/Loading';
import { urlHelper } from '../../helpers/urlHelper';
import { visaRedirect } from '../../store/action_creators/payments.actions';
import { AppActions } from './../../store/config/ActionTypes';
import { RootState } from './../../store/config/types';

const mapStateToProps = (state: RootState) => ({
  payments: state.payments,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  visaRedirected: (paymentId: string) => dispatch(visaRedirect(paymentId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Redirect({ payments, visaRedirected }: PropsFromRedux) {
  const [init, setInit] = useState<boolean>(false);
  const navigate = useNavigate();

  const isOnIframe = window.location !== window.parent.location;
  const urlVars = urlHelper.parseQueryString();

  useEffect(() => {
    //Visa and Sistarbank redirect should reopen SignalR connection
    if (!isOnIframe && !init) {
      setInit(true);
      visaRedirected(urlVars.beginPaymentId);
    }
  }, [isOnIframe, urlVars, visaRedirected, init, setInit]);

  useEffect(() => {
    if (!isOnIframe) {
      if (payments.confirmPaymentSuccess) {
        navigate(`/result`);
      } else if (payments.confirmPaymentErrorMessage !== null) {
        navigate(`/result?errorCode=`);
      }
    }
  });

  // useEffect(() => {
  //   if (window.location === window.parent.location) {
  //     if (urlVars.errorcode) {
  //       setHasError(true);
  //     }
  //   }
  // }, [urlVars, setHasError]);

  // useEffect(() => {
  //   if ((!payments.confirmingPayment && payments.confirmPaymentSuccess) || payments.confirmPaymentErrorMessage !== "") {
  //     navigate("/result");
  //   }
  // }, [payments.confirmingPayment, payments.confirmPaymentSuccess, payments.confirmPaymentErrorMessage, history]);

  if (isOnIframe) {
    console.log('creating redirect message');
    const object = {
      message: urlVars.errorcode || urlVars.errormessage ? 'redirectError' : 'redirectSuccess',
      payload: urlVars.errorcode || urlVars.errormessage ? urlVars.errorcode : urlVars.beginPaymentId,
    };
    console.log(object);
    window.parent.postMessage(object, `${window.location.origin}/redirect`);

    return <Loading />;
  } else {
    // if (hasError) {
    //   console.log("hasError?" + hasError);
    //   navigate(`/result?errorCode=${urlVars.errorcode}`);
    // }

    //Visa redirect should reopen SignalR connection
    // visaRedirected(urlVars.paymentId);

    return <Loading />;
  }
}

export default connector(Redirect);
