import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import React, { ReactNode } from 'react';
import { getColumnIdFromColumnMap, getSortDirectionFromEnum } from '../../helpers/filteringHelper';
import { paymentColumnMap } from '../../helpers/tableHelper';
import { SortEnum } from '../../store/config/enums';
import { EmptyMessage } from '../EmptyMessage';

const useStyles = makeStyles({
  noHoverRow: {
    '&.MuiTableRow-root': {
      cursor: 'auto',
    },
    '&.MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export interface CustomTableProps<T> {
  headCells: HeadCell<T>[];
  rowsData: T[];
  emptyDataMessage?: string | ReactNode;
  loading?: boolean;
  loader?: ReactNode;
  boxProps?: React.ComponentProps<typeof Box>;
  paperProps?: React.ComponentProps<typeof Paper>;
  tableContainerProps?: React.ComponentProps<typeof TableContainer>;
  tableProps?: React.ComponentProps<typeof Table>;
  tableHeadProps?: React.ComponentProps<typeof TableHead>;
  tableRowProps?: React.ComponentProps<typeof TableRow>;
  sortable?: Sortable<T>;
  renderCell?: (row: T, columnId: keyof T) => ReactNode;
  hasRowHover?: boolean;
  moreTopMargin?: boolean;
}

export interface Sortable<T> {
  direction: SortEnum;
  order: any;
  onRequestSort: (property: keyof T) => void;
}

export interface HeadCell<T> {
  columnId: keyof T;
  label: string;
  disableSorting?: boolean;
}

const CustomTable = <T extends object>({
  rowsData,
  headCells,
  emptyDataMessage = 'No hay resultados para esta búsqueda',
  loading,
  loader = 'Cargando...',
  boxProps,
  paperProps,
  tableContainerProps,
  tableProps,
  tableHeadProps,
  tableRowProps,
  sortable,
  renderCell,
  hasRowHover = true,
  moreTopMargin = false,
}: CustomTableProps<T>) => {
  const classes = useStyles();

  const createSortHandler = (columnId: keyof T) => {
    if (sortable && sortable.onRequestSort) {
      sortable.onRequestSort(columnId);
    }
  };

  return (
    <Box {...boxProps}>
      <Paper {...paperProps}>
        <TableContainer {...tableContainerProps}>
          <Table {...tableProps}>
            <TableHead {...tableHeadProps}>
              <TableRow {...tableRowProps}>
                {headCells.map(({ columnId, label, disableSorting }, index) => (
                  <TableCell
                    key={index}
                    style={{
                      minWidth: !label ? '3rem' : undefined,
                    }}
                  >
                    {!disableSorting && sortable ? (
                      <TableSortLabel
                        active={getColumnIdFromColumnMap(paymentColumnMap, sortable.order) === columnId}
                        direction={getSortDirectionFromEnum(sortable.direction)}
                        onClick={() => createSortHandler(columnId)}
                      >
                        {label}
                      </TableSortLabel>
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow {...tableRowProps}>
                  <TableCell colSpan={headCells.length}>{loader}</TableCell>
                </TableRow>
              ) : rowsData.length > 0 ? (
                rowsData.map((row, rowIndex) => (
                  <TableRow key={rowIndex} className={!hasRowHover ? classes.noHoverRow : ''}>
                    {headCells.map(({ columnId, label }, cellIndex) => (
                      <TableCell
                        key={`${rowIndex}-${cellIndex}`}
                        style={{
                          wordBreak: cellIndex === 0 ? 'unset' : undefined,
                          paddingTop: rowIndex === 0 && moreTopMargin ? '9px' : '',
                          maxWidth: cellIndex === 4 ? 'fit-content' : 0,
                        }}
                      >
                        {renderCell ? renderCell(row, columnId) : row[columnId]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow {...tableRowProps}>
                  <TableCell colSpan={headCells.length}>
                    {typeof emptyDataMessage === 'string' ? (
                      <EmptyMessage title={emptyDataMessage} />
                    ) : (
                      emptyDataMessage
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CustomTable;
