import { Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Values } from '../../components/dialogs/ChangePasswordDialog/ChangePasswordDialog';
import { Loading } from '../../components/Loading';
import PasswordField from '../../components/PasswordField/PasswordField';
import { ScreenTitle } from '../../components/ScreenTitle';
import { SubmitButton } from '../../components/SubmitButton';
import schemas from '../../data/schemas';
import { changeCredentials } from '../../store/action_creators/auth.actions';
import { Status } from '../../store/config/enums';
import { ChangeCredentialsRequest, RootState } from '../../store/config/types';

function ChangePassword() {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [changingPassword, setChangingPassword] = useState(false);
  const formikRef = useRef<FormikProps<Values>>(null);

  const submitPassword = (values: Values) => {
    if (auth.account) {
      const changeCredentialsRequest: ChangeCredentialsRequest = {
        userId: auth.account.userId,
        email: auth.account.email,
        oldPassword: values.currentPassword,
        newPassword: values.password,
      };
      setChangingPassword(true);
      dispatch(changeCredentials(changeCredentialsRequest));
    }
  };

  const closeSnack = () => {
    setChangingPassword(false);

    if (auth.changeCredentialsSuccess) {
      formikRef.current?.resetForm();
    }
  };

  const submitButton = (
    <SubmitButton formId="changePasswordForm" loading={changingPassword} className="change-password-button" />
  );

  return (
    <div className="screen-container profile">
      <ScreenTitle title="Cambiar contraseña">{!isMobile && submitButton}</ScreenTitle>
      <Paper elevation={2} className="profile-paper">
        {auth.account ? (
          <Formik
            innerRef={formikRef}
            initialValues={{ currentPassword: '', password: '', repeatPassword: '' }}
            validationSchema={schemas.ChangePasswordSchema}
            onSubmit={submitPassword}
          >
            <Form id="changePasswordForm">
              <PasswordField inputName="currentPassword" placeholder="Contraseña actual" />
              <PasswordField inputName="password" placeholder="Nueva contraseña" />
              <PasswordField inputName="repeatPassword" placeholder="Repetir nueva contraseña" />
              {isMobile && submitButton}
              <CustomSnackbar
                open={
                  changingPassword &&
                  (auth.changeCredentialsSuccess ||
                    auth.changeCredentialsErrorMessage !== null ||
                    auth.changeUserPasswordSuccess ||
                    auth.changeUserPasswordErrorMessage !== null)
                }
                message={
                  auth.changeCredentialsSuccess || auth.changeUserPasswordSuccess
                    ? 'Se actualizó la contraseña correctamente'
                    : auth.changeCredentialsErrorMessage! || auth.changeUserPasswordErrorMessage!
                }
                handleClose={closeSnack}
                type={
                  auth.changeCredentialsSuccess || auth.changeUserPasswordSuccess
                    ? Status.SUCCESS
                    : Status.ERROR
                }
              />
            </Form>
          </Formik>
        ) : (
          <Loading />
        )}
      </Paper>
    </div>
  );
}

export default ChangePassword;
