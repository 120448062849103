import { useSelector } from 'react-redux';
import useFilterLogic from '../../hooks/useFilterLogic';
import { loadOneUseLinksFilters } from '../../store/action_creators/links.actions';
import { LinkTypeEnum } from '../../store/config/enums';
import { RootState } from '../../store/config/types';
import LinksFilterLayout from './LinksFiltersLayout';

interface OneUseLinksFiltersProps {
  businessId: number;
  linkType: LinkTypeEnum;
}

function OneUseLinksFilters({ businessId, linkType }: OneUseLinksFiltersProps) {
  const { links } = useSelector((state: RootState) => state);

  const {
    openFilters,
    setOpenFilters,
    searchTerm,
    setSearchTerm,
    loadFilters,
    searchLinks,
    closeFiltersDialog,
  } = useFilterLogic({
    businessId,
    linkType,
    loadFiltersAction: loadOneUseLinksFilters,
    filtersState: links.oneUseLinksFilters,
  });

  return (
    <LinksFilterLayout
      openFilters={openFilters}
      setOpenFilters={setOpenFilters}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchLinks={searchLinks}
      closeFiltersDialog={closeFiltersDialog}
      loadFilters={loadFilters}
      filtersState={links.oneUseLinksFilters}
    />
  );
}

export default OneUseLinksFilters;
