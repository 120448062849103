import { Theme } from '@material-ui/core/styles';
import { PaymentStatusEnum } from '../store/config/enums';

export const paymentStatusEnumHelper = {
  useBackgroundColorByStatus,
  useIconColorByStatus,
  getStatusText,
  getStatusColorVariant,
};

function useBackgroundColorByStatus(paymentStatus: PaymentStatusEnum, theme: Theme): string {
  switch (paymentStatus) {
    case PaymentStatusEnum.PENDING:
      return theme.palette.warning.light;
    case PaymentStatusEnum.APPROVED:
      return theme.palette.success.light;
    case PaymentStatusEnum.REJECTED:
      return theme.palette.error.light;
    case PaymentStatusEnum.CANCELLED:
      return theme.palette.grey[200];
    default:
      return '';
  }
}

function useIconColorByStatus(paymentStatus: PaymentStatusEnum, theme: Theme) {
  switch (paymentStatus) {
    case PaymentStatusEnum.PENDING:
      return theme.palette.warning.dark;
    case PaymentStatusEnum.APPROVED:
      return theme.palette.success.dark;
    case PaymentStatusEnum.REJECTED:
      return theme.palette.error.dark;
    case PaymentStatusEnum.CANCELLED:
      return theme.palette.grey[500];
    default:
      return '';
  }
}

function getStatusText(paymentStatus: PaymentStatusEnum): string {
  switch (paymentStatus) {
    case PaymentStatusEnum.PENDING:
      return 'Pendiente';
    case PaymentStatusEnum.APPROVED:
      return 'Aprobado';
    case PaymentStatusEnum.REJECTED:
      return 'Rechazado';
    case PaymentStatusEnum.CANCELLED:
      return 'Cancelado';
    default:
      return '';
  }
}

function getStatusColorVariant(paymentStatus: PaymentStatusEnum) {
  switch (paymentStatus) {
    case PaymentStatusEnum.PENDING:
      return 'warning';
    case PaymentStatusEnum.APPROVED:
      return 'success';
    case PaymentStatusEnum.REJECTED:
      return 'error';
    case PaymentStatusEnum.CANCELLED:
      return 'info';
    default:
      return 'info';
  }
}
