import { Button, CircularProgress } from '@material-ui/core';
import { Commerce } from '../../../store/config/types';

interface SubmitButtonProps {
  isRegisterStep: boolean;
  creatingCommerce: boolean;
  updatingCommerce: boolean;
  commerce: Commerce | null;
  disabled?: boolean;
}

function SubmitButton({
  isRegisterStep,
  creatingCommerce,
  updatingCommerce,
  commerce,
  disabled,
}: SubmitButtonProps) {
  return (
    <div className="button-row-field">
      <Button
        type="submit"
        color="primary"
        variant="contained"
        form="commerce-form"
        fullWidth={!isRegisterStep}
        disabled={creatingCommerce || updatingCommerce || disabled}
      >
        {creatingCommerce || updatingCommerce ? (
          <CircularProgress size={28} color="inherit" />
        ) : commerce ? (
          'Guardar cambios'
        ) : (
          'Agregar medio de pago'
        )}
      </Button>
    </div>
  );
}

export default SubmitButton;
