export const errorHelper = {
  getErrorString,
};

function getErrorString(code: number, message: string): string {
  switch (code) {
    //Businesses
    case 300:
      return 'La empresa que buscas no existe.';
    case 301:
      return 'El nombre seleccionado está siendo usado por otra empresa.';
    //Identity
    case 461:
      return 'Ya existe una cuenta con el email seleccionado.';
    case 462:
      return 'El email o la contraseña ingresados no son correctos.';
    case 463:
      return 'Error al cambiar la contraseña.';
    case 465:
      return message.includes('Invalid date')
        ? 'La hora de vencimiento del link no puede ser previa a la actual'
        : 'El link ingresado no es válido.';
    case 466:
      return 'El código ingresado no es válido.';
    case 468:
      return 'Este email ya ha sido verificado.';
    case 469:
      return 'No existe el usuario indicado.';
    case 470:
      return 'El email indicado no se ha verificado aún.';
    //Images
    case 510:
      return 'Se ingresó un archivo vacío.';
    case 511:
      return 'Solo se admiten imágenes en formatos .png y .jpeg.';
    case 512:
      return 'El tamaño de la imagen debe ser menor a 5MB.';
    //Users
    case 601:
      return 'El usuario ya existe.';
    case 602:
      return 'El usuario no existe.';
    case 603:
      return 'El usuario no tiene permisos para realizar esta operación.';
    //Links
    case 700:
      return 'El estado del link no es válido.';
    case 701:
      return 'No pueden crearse links de un solo uso para esta empresa.';
    //Commerces
    case 800:
      return 'El comercio seleccionado no existe.';
    case 801:
      return 'Ya existe un comercio con este medio de pago para esta empresa.';
    //Issuer
    case 900:
      return 'El medio de pago seleccionado no existe.';
    //Payments
    case 1000:
      return 'No se encontró el pago seleccionado.';
    case 1001:
      return 'El valor de IVA indicado no es válido.';

    default:
      return 'Ha ocurrido un error inesperado';
  }
}
