import { Button, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { filteringHelper } from '../../helpers/filteringHelper';
import { toggleResetClientFilter } from '../../store/action_creators/payments.actions';
import { DateSpan } from '../../store/config/enums';
import { Business, PaymentsFilters } from '../../store/config/types';
import CollaboratorFilters from './CollaboratorFilters';
import CurrencyFilters from './CurrencyFilters';
import DateFilters from './DateFilters';
import IssuerFilters from './IssuerFilters';
import Search from './Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outlinedRoot: {
      borderRadius: '2rem',
      backgroundColor: theme.palette.background.paper,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      width: '100%',
    },
    filtersContainer: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: '12px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
        flexDirection: 'column',
        gap: 8,
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.75rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      '& button': {
        minWidth: 'fit-content',
        width: 'fit-content',
        height: '2.3125rem',
        padding: '0.4375rem 1rem',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        margin: '1rem 0',
        '& button': {
          width: '50%',
          minWidth: 'unset',
        },
      },
    },
  }),
);

interface FiltersComponentProps {
  hasDateFilter?: boolean;
  hasIssuerFilter?: boolean;
  hasCollaboratorFilter?: boolean;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  filters: PaymentsFilters | null;
  setFilters: (filters: PaymentsFilters) => void;
  triggerSearch: () => void;
  triggerTime?: number;
  clientsFilter?: React.ReactNode;
  selectedBusiness?: Business | null;
  closeDialog?: () => void;
  isPaymentFilter?: boolean;
}

function FiltersComponent({
  hasDateFilter = false,
  hasIssuerFilter = false,
  hasCollaboratorFilter = false,
  searchTerm,
  setSearchTerm,
  filters,
  selectedBusiness,
  setFilters,
  triggerSearch,
  clientsFilter,
  closeDialog,
  isPaymentFilter,
}: FiltersComponentProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilters: PaymentsFilters = {
    dateSpan: DateSpan.LAST7DAYS,
    currency: { pesos: false, dollars: false },
    issuer: '',
    collaborator: '',
  };
  const [localFilters, setLocalFilters] = useState<PaymentsFilters>({ ...filters } || initialFilters);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const applyFilters = () => {
    if (localFilters !== filters) {
      setSearchParams(filteringHelper.getFiltersForPayments(localFilters));
      setFilters(localFilters);
    }

    if (!isDesktop && closeDialog) {
      closeDialog();
    }
  };

  const resetFilters = () => {
    setSearchParams('');
    setLocalFilters(initialFilters);
    dispatch(toggleResetClientFilter());
  };

  useEffect(() => {
    const urlFilters = filteringHelper.getFiltersFromSearchParams(searchParams, filters);
    if (searchParams.size > 0) setLocalFilters(urlFilters);
  }, [filters, searchParams]);

  return (
    <div className={classes.root}>
      <div className={classes.filtersContainer}>
        {hasDateFilter && (
          <DateFilters
            isPaymentFilter={isPaymentFilter}
            filters={localFilters}
            setFilters={setLocalFilters}
          />
        )}
        <CurrencyFilters filters={localFilters} setFilters={setLocalFilters} hasDateFilters />
        {hasIssuerFilter && <IssuerFilters filters={localFilters} setFilters={setLocalFilters} />}
        {clientsFilter}
        {hasCollaboratorFilter && (
          <CollaboratorFilters
            filters={localFilters}
            setFilters={setLocalFilters}
            selectedBusiness={selectedBusiness}
          />
        )}
        <div className={classes.actions}>
          <Button onClick={applyFilters} variant="contained" color="primary">
            Aplicar
          </Button>
          <Button onClick={resetFilters} variant="outlined" color="secondary">
            Restablecer
          </Button>
        </div>
      </div>
      {isDesktop && (
        <Search
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          triggerSearch={triggerSearch}
          placeholder="Buscar por descripción, referencia o importe"
        />
      )}
    </div>
  );
}

export default FiltersComponent;
