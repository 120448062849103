import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Commerce } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { CustomSwitch } from '../CustomSwitch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '0.625rem 0.8125rem',
      boxShadow: styles.boxShadowSmall,
      boxSizing: 'border-box',
      flexBasis: '49%',
      marginTop: '1.1875rem',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    image: {
      padding: '0.5rem 1rem',
      width: '30%',
      border: `1px solid ${styles.greyTableHead}`,
    },
    issuerNameText: {
      color: styles.darkBlue,
      fontSize: '0.9375rem',
      margin: 0,
    },
    maxInstallmentsText: {
      color: styles.slateBlue,
      fontSize: '0.875rem',
      margin: 0,
    },
  }),
);

interface PaymentMethodCardProps {
  commerce: Commerce;
  commerceIds: number[];
  setCommerceIds(value: number[]): void;
}

function PaymentMethodCard({ commerce, commerceIds, setCommerceIds }: PaymentMethodCardProps) {
  const classes = useStyles();
  const maxInstallments = commerce.installments.sort((a: number, b: number) => b - a)[0];

  const handleTogglePaymentMethod = (commerce: Commerce) => {
    const existingIndex = commerceIds.findIndex((id) => id === Number(commerce.id));

    if (existingIndex !== -1) {
      const newIds = [...commerceIds];
      newIds.splice(existingIndex, 1);
      setCommerceIds(newIds);
    } else {
      setCommerceIds([...commerceIds, Number(commerce.id)]);
    }
  };

  return (
    <div className={classes.card}>
      <div className={classes.details}>
        <img className={classes.image} src={commerce.issuerImageUrl} alt={commerce.issuerName} />
        <div>
          <p className={classes.issuerNameText}>{commerce.issuerName}</p>
          <p className={classes.maxInstallmentsText}>{`Hasta en ${maxInstallments} ${
            maxInstallments > 1 ? 'cuotas' : 'cuota'
          }`}</p>
        </div>
        <CustomSwitch
          onClick={() => handleTogglePaymentMethod(commerce)}
          checked={commerceIds.includes(Number(commerce.id))}
        />
      </div>
    </div>
  );
}

export default PaymentMethodCard;
