import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIssuers } from '../../../store/action_creators/commerces.actions';
import { Commerce, RootState } from '../../../store/config/types';
import { Client } from '../../../store/config/types/clients.types';
import LogoutDialog from '../../Header/LogoutDialog';
import { Loading } from '../../Loading';
import { CommerceForm } from '../../forms/CommerceForm';

interface CommerceDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  commerce: Commerce | null;
  firstCommerce?: boolean;
  client?: Client;
}

function CommerceDialog({ open, setOpen, commerce, firstCommerce = false, client }: CommerceDialogProps) {
  const dispatch = useDispatch();
  const commerces = useSelector((state: RootState) => state.commerces);
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);

  const closeDialog = () => setOpen(false);

  useEffect(() => {
    if (!commerces.loadingIssuers && commerces.issuers === null && commerces.issuersErrorMessage === null) {
      dispatch(fetchIssuers());
    }
  }, [commerces.loadingIssuers, commerces.issuers, commerces.issuersErrorMessage, dispatch]);

  const openLogout = () => {
    setLogoutOpen(true);
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <CloseIcon fontSize="default" className="icon" onClick={firstCommerce ? openLogout : closeDialog} />
      </DialogTitle>
      {commerces.issuers ? (
        <>
          <DialogContent classes={{ root: 'dialog-content' }}>
            <CommerceForm
              commerce={commerce}
              firstCommerce={firstCommerce}
              handleSuccess={closeDialog}
              client={client}
              isDialog
            />
          </DialogContent>
        </>
      ) : (
        <Loading />
      )}
      <LogoutDialog open={logoutOpen} setOpen={setLogoutOpen} />
    </Dialog>
  );
}

export default CommerceDialog;
