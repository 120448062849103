import { Button } from '@material-ui/core';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Collaborator, RootState } from '../../store/config/types';
import { Loading } from '../Loading';
import UserCard from './UserCard';

interface UsersMobileProps {
  emptyDataMessage: ReactNode;
  setEnablingCollaborator: (value: React.SetStateAction<boolean>) => void;
  handleEnableCollaborator: (collaborator: Collaborator) => void;
}

function UsersMobile({
  emptyDataMessage,
  setEnablingCollaborator,
  handleEnableCollaborator,
}: UsersMobileProps) {
  const navigate = useNavigate();
  const collaborators = useSelector((state: RootState) => state.collaborators);

  const orderedCollaborators = collaborators.collaborators?.sort((a, b) =>
    a.names.toUpperCase().localeCompare(b.names.toUpperCase()),
  );

  return (
    <div className="users-mobile">
      <div className="screen-title-mobile">
        <h5>Colaboradores</h5>
        <Button
          variant="contained"
          color="primary"
          className="main-button"
          onClick={() => {
            navigate('/user');
          }}
        >
          Nuevo colaborador
        </Button>
        {/* TODO: Add Search after backend implementation */}
      </div>
      {collaborators.loadingCollaborators ? (
        <Loading />
      ) : orderedCollaborators?.length && orderedCollaborators?.length > 0 ? (
        orderedCollaborators.map((collaborator) => (
          <UserCard
            collaborator={collaborator}
            handleEnableCollaborator={handleEnableCollaborator}
            setEnablingCollaborator={setEnablingCollaborator}
          />
        ))
      ) : (
        emptyDataMessage
      )}
    </div>
  );
}

export default UsersMobile;
