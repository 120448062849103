import { createStyles, makeStyles, Theme, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Payment } from '../../store/config/types';
import styles from '.././../styles/_variables.module.scss';
import HelpIcon from '../Icons/HelpIcon';
import DetailRow from './DetailRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoIcon: {
      color: styles.slateBlue,
      width: '1rem',
      height: '1rem',
    },
    linkExternalId: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '0.25rem',
    },
  }),
);

interface LinkExternalIdRowProps {
  payment: Payment;
}

function LinkExternalIdRow({ payment }: LinkExternalIdRowProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const tooltipRef: MutableRefObject<HTMLSpanElement | null> = useRef(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (tooltipOpen && tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
    // eslint-disable-next-line
  }, [isMobile, tooltipOpen]);

  const handleTooltipToggle = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (isMobile) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const popperStyles: CSSProperties = {
    pointerEvents: tooltipOpen ? 'auto' : 'none',
    width: '8.75rem',
  };

  return (
    <DetailRow
      title={
        <div className={classes.linkExternalId}>
          <span>Link external id</span>
          <Tooltip
            title="ID utilizado para armar la URL del link"
            open={isMobile ? tooltipOpen : undefined}
            disableFocusListener={isMobile}
            disableHoverListener={isMobile}
            disableTouchListener={isMobile}
            onClose={() => setTooltipOpen(false)}
            key={isMobile ? 'mobile' : 'desktop'}
            PopperProps={{ style: popperStyles }}
          >
            <span
              onClick={handleTooltipToggle}
              ref={isMobile ? (ref) => (tooltipRef.current = ref) : undefined}
              className={classes.infoIcon}
            >
              <HelpIcon />
            </span>
          </Tooltip>
        </div>
      }
      detail={payment?.linkExternalId}
    />
  );
}

export default LinkExternalIdRow;
