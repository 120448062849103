import colors from '../styles/_variables.module.scss';

function UploadIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
      <g id="Componente 47 â 1">
        <g id="RectÃ¡ngulo 746">
          <path
            id="Vector"
            d="M2.36371 16.302L10.6364 16.302C11.6808 16.302 12.5273 15.4552 12.5273 14.4106L12.5273 2.58914C12.5273 1.54454 11.6808 0.697716 10.6364 0.697716L2.36371 0.697715C1.31939 0.697715 0.4728 1.54454 0.4728 2.58914L0.472799 14.4106C0.472798 15.4552 1.31939 16.302 2.36371 16.302Z"
            stroke={colors.slateBlue}
            strokeWidth="0.8"
          />
        </g>
        <g id="PolÃ­gono 1">
          <path
            id="Vector_2"
            d="M6.50001 4.06681L4.13637 7.61324L8.86364 7.61324L6.50001 4.06681ZM6.50001 3.12109C6.81612 3.12109 7.11133 3.27912 7.28667 3.54222L9.65031 7.08865C9.84373 7.37884 9.86175 7.75197 9.69723 8.05948C9.5327 8.36698 9.21233 8.55895 8.86364 8.55895L4.13637 8.55895C3.78769 8.55895 3.46732 8.36698 3.30279 8.05948C3.13827 7.75197 3.15629 7.37884 3.34971 7.08865L5.71334 3.54222C5.88869 3.27912 6.1839 3.12109 6.50001 3.12109Z"
            fill={colors.slateBlue}
          />
        </g>
        <path id="LÃ­nea 57" d="M6.5 8.20429L6.5 12.9329" stroke={colors.slateBlue} strokeWidth="0.8" />
      </g>
    </svg>
  );
}

export default UploadIcon;
