import { Card, Divider, useMediaQuery } from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import dummy from '../../../assets/dummy.png';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { numberHelper } from '../../../helpers/numberHelper';
import { urlHelper } from '../../../helpers/urlHelper';
import { Amount, Business, LandingParams } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardLeft: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '45%',
      padding: '2.5625rem',
      height: 'fit-content',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    divider: {
      width: '100%',
      height: '0.0625rem',
    },
    imgContainerWithoutImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1.875rem',
      marginBottom: '1.3125rem',
      width: '100%',
      border: `1px dashed ${styles.grey}`,
    },
    imgContainerWithImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      marginBottom: '1.3125rem',
      width: '100%',
    },
    image: {
      maxWidth: '9.375rem',
      maxHeight: '9.375rem',
      [theme.breakpoints.down('md')]: {
        marginRight: '0.75rem',
      },
    },
    paymentDetailHeader: {
      backgroundColor: styles.greyTableHead,
      width: '100%',
      marginTop: '1.375rem',
      textAlign: 'center',
      '& p': {
        margin: '0.75rem',
        color: styles.slateBlue,
        fontWeight: 600,
        fontSize: '0.75rem',
      },
    },
    descriptionRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '1.5625rem 0',
      '& p': {
        color: styles.slateBlue,
        fontSize: '0.875rem',
        fontWeight: Number(styles.normalTextWeight),
        margin: 0,
      },
      '&:last-child': {
        margin: '1.5625rem 0 0 0',
      },
      '& :last-child': {
        fontWeight: Number(styles.semiBoldTextWeight),
      },
    },
    total: {
      color: styles.secondaryMain,
      fontSize: '1rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
      '& span': {
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        color: styles.slateBlue,
      },
    },
    totalAmount: {
      color: styles.secondaryMain,
      fontSize: '1.375rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    businessData: {
      '& p': {
        color: styles.slateBlue,
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        margin: 0,
      },
      '& h1': {
        color: styles.slateBlue,
        fontSize: '1.0625rem',
        fontWeight: Number(styles.semiBoldTextWeight),
        margin: '0.5625rem 0 0 0',
      },
    },
  }),
);

interface OnTheFlyDetailCardProps {
  business: Business;
  params: LandingParams;
  amount?: Amount | null;
}

function OnTheFlyDetailCard({ business, params, amount }: OnTheFlyDetailCardProps) {
  const classes = useStyles();
  const [src, setSrc] = useState(urlHelper.buildImageUrl(business?.id!));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onImageError = () => {
    setSrc(dummy);
  };

  return (
    <Card className={classes.cardLeft}>
      {!isMobile ? (
        <>
          <div className={src === dummy ? classes.imgContainerWithoutImage : classes.imgContainerWithImage}>
            <img className={classes.image} src={src} onError={onImageError} alt="commerce logo" />
          </div>
          <div className={classes.paymentDetailHeader}>
            <p>Detalle del pago</p>
          </div>
          <div className={classes.descriptionRow}>
            <p>Empresa</p>
            <p>{params.companyId}</p>
          </div>
          <Divider className={classes.divider} />
          {amount && (
            <div className={classes.descriptionRow}>
              <h2 className={classes.total}>
                Total {business.vatRate && business.vatRate > 0 && <span>IVA inc.</span>}
              </h2>
              <h3 className={classes.totalAmount}>
                {currencyHelper.getCurrencyToken(amount.currency)}
                {numberHelper.getNumberWithDots(+amount.value)}
              </h3>
            </div>
          )}
        </>
      ) : (
        <div className={classes.header}>
          <img className={classes.image} src={src} onError={onImageError} alt="commerce logo" />
          <div className={classes.businessData}>
            <p>Empresa emisora</p>
            <h1>{business?.prettyName}</h1>
          </div>
        </div>
      )}
    </Card>
  );
}

export default OnTheFlyDetailCard;
