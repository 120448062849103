import { Switch, SwitchProps, Theme, makeStyles } from '@material-ui/core';
import colors from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'unset',
    },
  },
  switchBase: {
    padding: 6,
    top: '50%',
    transform: 'translateY(-50%)',
    color: colors.slateBlue,
    '&$checked': {
      transform: 'translateX(0.75rem) translateY(-50%)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: colors.primary,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: colors.white,
      border: `0.375rem solid ${colors.white}`,
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${colors.slateBlue}`,
    backgroundColor: colors.white,
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
  disabled: {
    opacity: 0.5,
    '& .MuiSwitch-thumb': {
      backgroundColor: colors.slateBlue,
    },
    '& + $track': {
      opacity: '0.5 !important',
      backgroundColor: `${colors.white} !important`,
      border: `1px solid ${colors.slateBlue} !important`,
    },
  },
}));

function CustomSwitch(props: SwitchProps) {
  const classes = useStyles();

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...props}
    />
  );
}

export default CustomSwitch;
