import styles from '../../../styles/_variables.module.scss';

function QuestionMark() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 11.379C7.96998 11.379 7.54032 11.8087 7.54032 12.3387C7.54032 12.8687 7.96998 13.2984 8.5 13.2984C9.03002 13.2984 9.45968 12.8687 9.45968 12.3387C9.45968 11.8087 9.03002 11.379 8.5 11.379ZM8.76288 10.5565H8.2145C7.98736 10.5565 7.80321 10.3723 7.80321 10.1452V10.1321C7.80321 7.72115 10.4574 7.95161 10.4574 6.45078C10.4574 5.76475 9.84866 5.07258 8.48869 5.07258C7.4898 5.07258 6.97154 5.40329 6.45928 6.05598C6.32534 6.22666 6.08041 6.26145 5.90239 6.13741L5.45224 5.8238C5.25944 5.68948 5.21712 5.42036 5.36158 5.23507C6.08909 4.30189 6.95225 3.70161 8.48869 3.70161C10.2819 3.70161 11.8284 4.7213 11.8284 6.45078C11.8284 8.76134 9.17417 8.63915 9.17417 10.1321V10.1452C9.17417 10.3723 8.99002 10.5565 8.76288 10.5565ZM8.5 1.09677C12.5656 1.09677 15.9032 4.38967 15.9032 8.5C15.9032 12.5886 12.592 15.9032 8.5 15.9032C4.41301 15.9032 1.09677 12.5936 1.09677 8.5C1.09677 4.41441 4.40773 1.09677 8.5 1.09677ZM8.5 0C3.80591 0 0 3.80728 0 8.5C0 13.1955 3.80591 17 8.5 17C13.1941 17 17 13.1955 17 8.5C17 3.80728 13.1941 0 8.5 0Z"
        fill={styles.slateBlue}
      />
    </svg>
  );
}

export default QuestionMark;
