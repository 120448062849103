import { makeStyles } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FieldProps } from 'formik';

const useDateFieldStyles = makeStyles({
  date: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%',
  },
});

interface CustomProps {
  placeholder: string;
  minDateMessage?: string;
}

type FormDateFieldProps = FieldProps & CustomProps;

function FormDateField({ field, form, placeholder, minDateMessage, ...props }: FormDateFieldProps) {
  const classes = useDateFieldStyles();

  const onChange = (date: MaterialUiPickersDate) => {
    if (date) {
      const newDate = new Date(date.toISOString());
      form.setFieldValue(field.name, newDate);
    }
  };

  return (
    <DateTimePicker
      label={placeholder}
      format="DD/MM/YYYY HH:mm"
      inputVariant="outlined"
      {...field}
      className={classes.date}
      onChange={onChange}
      disablePast={!!minDateMessage}
      minDateMessage={minDateMessage}
    />
  );
}

export default FormDateField;
