import { IconProps } from '../../../data/icon.type';
import colors from '../../../styles/_variables.module.scss';

function LongArrowLeft({ color, className }: IconProps) {
  color = color ?? colors.slateBlue;
  className = className ?? '';

  return (
    <svg
      width="22"
      height="13"
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.68117 12.1606L7.01617 11.8256C7.12146 11.7191 7.1805 11.5753 7.1805 11.4256C7.1805 11.2758 7.12146 11.1321 7.01617 11.0256L3.03717 7.06459H20.8582C21.0091 7.06459 21.1538 7.00464 21.2605 6.89793C21.3672 6.79123 21.4272 6.6465 21.4272 6.49559V6.02159C21.4272 5.87068 21.3672 5.72596 21.2605 5.61925C21.1538 5.51254 21.0091 5.45259 20.8582 5.45259H3.03717L7.01617 1.4966C7.12146 1.39009 7.1805 1.24635 7.1805 1.09659C7.1805 0.946825 7.12146 0.803106 7.01617 0.696594L6.68117 0.361588C6.57466 0.256306 6.43094 0.197266 6.28117 0.197266C6.13141 0.197266 5.98769 0.256306 5.88117 0.361588L0.358174 5.86159C0.252892 5.9681 0.193848 6.11183 0.193848 6.2616C0.193848 6.41136 0.252892 6.55508 0.358174 6.66159L5.88117 12.1616C5.98782 12.2667 6.13162 12.3256 6.28138 12.3254C6.43114 12.3252 6.57479 12.266 6.68117 12.1606Z"
        fill={color}
      />
    </svg>
  );
}

export default LongArrowLeft;
