import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavigationBorder from '../../Icons/NavigationBorder';

interface NavigationItemProps {
  path: string;
  secondaryPath?: string;
  setMobileMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
  icon?: ReactNode;
  title: string;
  isActive?: boolean;
  handleOptionSelect: (path: string) => void;
  className?: string;
}

function NavigationItem({
  path,
  secondaryPath,
  setMobileMenuOpened,
  icon,
  title,
  isActive,
  handleOptionSelect,
  className,
}: NavigationItemProps) {
  const location = useLocation();

  const isSelected = location.pathname === path || location.pathname === secondaryPath || isActive;

  const handleClick = () => {
    handleOptionSelect(path);
    setMobileMenuOpened(false);
  };

  return (
    <li>
      <Link
        className={`${title === 'Hidden' ? 'hidden-option' : ''}  ${
          isSelected ? 'selected-option' : 'option'
        } ${className}`}
        to={path}
        onClick={handleClick}
      >
        {isSelected && <NavigationBorder />}
        {icon ? icon : <div className="no-icon" />}
        {title}
      </Link>
    </li>
  );
}

export default NavigationItem;
