import { Divider, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { linkTypeEnumHelper } from '../../helpers/linkTypeEnumHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { Payment } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { StatusPill } from '../StatusPill';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1rem',
      gap: '0.5rem',
    },
    paperBorder: {
      border: `0.5px solid ${styles.dividerHorizontal}`,
      boxShadow: 'none',
      padding: '0.6rem 1rem',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    rowDescription: {
      maxWidth: '50%',
      color: styles.iconColor,
      fontSize: '0.75rem',
    },
    rowAmount: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '0.5rem',
      '& h6': {
        fontSize: '0.875rem',
        fontWeight: styles.boldTextWeight,
      },
    },
    rowDate: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '0.5rem',
    },
    linkTypeIcon: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface PaymentCardProps {
  payment: Payment;
  isDashboard?: boolean;
}

function PaymentCard({ payment, isDashboard }: PaymentCardProps) {
  const classes = useStyles();

  return (
    <Paper className={`${classes.root} ${isDashboard ? classes.paperBorder : ''}`}>
      <div className={classes.row}>
        <div className={classes.rowDate}>
          {!isDashboard && (
            <span className={classes.linkTypeIcon}>{linkTypeEnumHelper.getIcon(payment.linkType)}</span>
          )}
          <span>{dateHelper.toShorterNiceString(payment.date)}</span>
        </div>
        <StatusPill
          label={paymentStatusEnumHelper.getStatusText(payment.status)}
          color={paymentStatusEnumHelper.getStatusColorVariant(payment.status)}
        />
      </div>
      <Divider />
      <div className={classes.row}>
        <div className={classes.rowDescription}>{payment.description || '-'}</div>
        <div className={classes.rowAmount}>
          <Typography variant="h6">
            <span>{currencyHelper.getCurrencyToken(payment.amount.currency)}</span>
            <span>{numberHelper.getNumberWithDots(payment.amount.value)}</span>
          </Typography>
          {!isDashboard && (
            <>
              <Divider orientation="vertical" flexItem />
              <img src={payment.issuerLogoUrl} alt={payment.issuerName} style={{ width: '3rem' }} />
            </>
          )}
        </div>
      </div>
    </Paper>
  );
}

export default PaymentCard;
