import moment from 'moment';
import {
  CurrencyEnum,
  DateSpan,
  LinkTypeEnum,
  PaymentsColumnSortEnum,
  SortEnum,
} from '../store/config/enums';
import { FetchLinksRequest, PaymentsFilters } from '../store/config/types';

export const filteringHelper = {
  getFiltersForLinks,
  getFiltersForPayments,
  getPaymentsFiltersString,
  getFiltersFromSearchParams,
};

function getFiltersForLinks(fetchRequest: FetchLinksRequest, type?: LinkTypeEnum): string {
  let filterString = getFiltersString(fetchRequest, type);
  filterString += getDatesFilterToAntiquity(fetchRequest.dateSpan, fetchRequest.from, fetchRequest.to);

  return filterString;
}

function getFiltersForPayments(fetchRequest: PaymentsFilters, type?: LinkTypeEnum): string {
  let filterString = getPaymentsFiltersString(fetchRequest, type);
  filterString += getDatesFilterToAntiquity(fetchRequest.dateSpan, fetchRequest.from, fetchRequest.to);

  return filterString;
}

function getFiltersString(fetchLinksRequest: FetchLinksRequest, type?: LinkTypeEnum): string {
  let filterString = '';

  if (fetchLinksRequest.currency !== undefined) {
    let currencyId = 0;

    if (fetchLinksRequest.currency.pesos && !fetchLinksRequest.currency.dollars) {
      currencyId = 1;
    } else if (!fetchLinksRequest.currency.pesos && fetchLinksRequest.currency.dollars) {
      currencyId = 2;
    }

    filterString += `&currency=${currencyId}`;
  }

  if (fetchLinksRequest.term !== undefined) {
    filterString += `&filter=${fetchLinksRequest.term}`;
  }

  if (type) {
    filterString += `&idLinkType=${type}`;
  }

  if (fetchLinksRequest.issuer !== undefined) {
    filterString += `&issuerId=${fetchLinksRequest.issuer}`;
  }

  if (fetchLinksRequest.columnSort !== undefined) {
    filterString += `&columnSort=${fetchLinksRequest.columnSort}`;
  }

  if (fetchLinksRequest.sortDirection !== undefined) {
    filterString += `&sort=${fetchLinksRequest.sortDirection}`;
  }

  if (fetchLinksRequest.collaborator !== undefined) {
    filterString += `&creatorUserId=${fetchLinksRequest.collaborator}`;
  }

  if (fetchLinksRequest.onlyActive !== undefined) {
    filterString += `&onlyActive=${fetchLinksRequest.onlyActive}`;
  }

  return filterString;
}

function getPaymentsFiltersString(fetchPaymentsRequest: PaymentsFilters, type?: LinkTypeEnum): string {
  let filterString = '';

  if (fetchPaymentsRequest.currency !== undefined) {
    let currencyId = CurrencyEnum.All;

    if (fetchPaymentsRequest.currency.pesos && !fetchPaymentsRequest.currency.dollars) {
      currencyId = CurrencyEnum.PESO;
    } else if (!fetchPaymentsRequest.currency.pesos && fetchPaymentsRequest.currency.dollars) {
      currencyId = CurrencyEnum.DOLLAR;
    }

    filterString += `&currency=${currencyId}`;
  }

  if (fetchPaymentsRequest.term !== undefined) {
    filterString += `&filter=${fetchPaymentsRequest.term}`;
  }

  if (type) {
    filterString += `&idLinkType=${type}`;
  }

  if (fetchPaymentsRequest.issuer !== undefined) {
    filterString += `&issuerId=${fetchPaymentsRequest.issuer}`;
  }

  if (fetchPaymentsRequest.paymentStatus !== undefined) {
    filterString += `&state=${fetchPaymentsRequest.paymentStatus}`;
  }

  if (fetchPaymentsRequest.columnSort !== undefined) {
    filterString += `&columnSort=${fetchPaymentsRequest.columnSort}`;
  }

  if (fetchPaymentsRequest.sortDirection !== undefined) {
    filterString += `&sort=${fetchPaymentsRequest.sortDirection}`;
  }

  if (fetchPaymentsRequest.collaborator !== undefined) {
    filterString += `&linkCreatorUserId=${fetchPaymentsRequest.collaborator}`;
  }

  return filterString;
}

function getDatesFilterToAntiquity(dateSpan?: DateSpan, from?: string, to?: string): string {
  const today = moment();

  switch (dateSpan) {
    case DateSpan.TODAY:
      return `&dateSpan=${DateSpan.TODAY}&from=${moment()
        .startOf('day')
        .toISOString()}&until=${today.toISOString()}`;

    case DateSpan.LAST7DAYS:
      return `&dateSpan=${DateSpan.LAST7DAYS}&from=${moment()
        .subtract(7, 'days')
        .toISOString()}&until=${today.toISOString()}`;

    case DateSpan.LAST15DAYS:
      return `&dateSpan=${DateSpan.LAST15DAYS}&from=${moment()
        .subtract(15, 'days')
        .toISOString()}&until=${today.toISOString()}`;

    case DateSpan.LAST30DAYS:
      return `&dateSpan=${DateSpan.LAST30DAYS}&from=${moment()
        .subtract(30, 'days')
        .toISOString()}&until=${today.toISOString()}`;

    case DateSpan.ALL:
      return '';

    case DateSpan.CUSTOM:
      return `&from=${from}&until=${to}`;

    default:
      return '';
  }
}

function getDateSpanFromAntiquity(from: string): DateSpan {
  const today = moment();
  const days = today.diff(from, 'days');
  let dateSpan: DateSpan;

  switch (days) {
    case 0:
      dateSpan = DateSpan.TODAY;
      break;
    case 7:
      dateSpan = DateSpan.LAST7DAYS;
      break;
    case 15:
      dateSpan = DateSpan.LAST15DAYS;
      break;
    case 30:
      dateSpan = DateSpan.LAST30DAYS;
      break;
    default:
      dateSpan = DateSpan.LAST7DAYS;
  }

  return dateSpan;
}

function getFiltersFromSearchParams(
  searchParams: URLSearchParams,
  previousFilters: PaymentsFilters | null,
): PaymentsFilters {
  const filters: PaymentsFilters = previousFilters || {};

  if (searchParams.has('currency')) {
    const currency = searchParams.get('currency');
    filters.currency = {
      pesos: currency === '1' || currency === '0',
      dollars: currency === '2' || currency === '0',
    };
  }

  if (searchParams.has('issuerId') && searchParams.get('issuerId') !== '') {
    filters.issuer = Number(searchParams.get('issuerId'));
  }

  if (searchParams.has('state')) {
    filters.paymentStatus = Number(searchParams.get('state'));
  }

  if (searchParams.has('columnSort')) {
    filters.columnSort = searchParams.get('columnSort') as unknown as PaymentsColumnSortEnum;
  }

  if (searchParams.has('sort')) {
    filters.sortDirection = searchParams.get('sort') as unknown as SortEnum;
  }

  if (searchParams.has('linkCreatorUserId')) {
    filters.collaborator = searchParams.get('linkCreatorUserId') || '';
  }

  if (searchParams.has('from') && searchParams.has('until') && !searchParams.has('dateSpan')) {
    filters.dateSpan = DateSpan.CUSTOM;
    filters.from = searchParams.get('from') || '';
    filters.to = searchParams.get('until') || '';
  } else if (searchParams.has('from')) {
    filters.dateSpan = getDateSpanFromAntiquity(searchParams.get('from')!);
  }

  return filters;
}

export const getColumnMap = <K extends object, T extends string>(columnMap: K, columnId: T) => {
  if (columnId in columnMap) {
    const columnEnum = columnMap[columnId as unknown as keyof K];
    return columnEnum;
  }
  return undefined;
};

export const getColumnIdFromColumnMap = <K extends Record<string, T>, T extends string | number>(
  columnMap: K,
  columnEnum: T,
): keyof K | undefined => {
  for (const key in columnMap) {
    if (Object.hasOwnProperty.call(columnMap, key) && columnMap[key] === columnEnum) {
      return key as keyof K;
    }
  }
  return undefined;
};

export const getSortDirectionFromEnum = (sortEnum: SortEnum) => {
  return sortEnum === SortEnum.ASC ? 'asc' : 'desc';
};
