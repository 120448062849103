import { Button, Link, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ClientSettings } from '../../components/ClientSettings';
import { ClientUsersTable } from '../../components/ClientUsersTable';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import Search from '../../components/FiltersComponent/Search';
import { ArrowForwardOutlined } from '../../components/Icons/ArrowForwardOutlined';
import { Loading } from '../../components/Loading';
import { ScreenTitle } from '../../components/ScreenTitle';
import { CommerceDialog } from '../../components/dialogs/CommerceDialog';
import { DeleteDialog } from '../../components/dialogs/DeleteDialog';
import { fetchClients, toggleBilling } from '../../store/action_creators/clients.actions';
import { deleteCommerce, fetchCommerces } from '../../store/action_creators/commerces.actions';
import { Status } from '../../store/config/enums';
import { Client, Commerce, RootState } from '../../store/config/types';
import colors from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '2rem 0',
      maxWidth: '60rem',
      margin: '0 auto',
    },
    divider: {
      margin: '0.8rem 0',
      width: '100%',
    },
    screenTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%',
      marginBottom: '1rem',
    },
    button: {
      color: colors.slateBlue,
    },
    paperRoot: {
      margin: '1rem 0',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: colors.slateBlue,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        '& svg': {
          '& rect': {
            fill: theme.palette.action.hover,
          },
        },
      },
    },
  }),
);

type CommerceWithIsLast = Commerce & { isLast: boolean };

function ManageClients() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { clients, commerces } = useSelector((state: RootState) => state);
  const client = location?.state?.client || null;
  const [selectedClient, setSelectedClient] = useState<Client | null>(client);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedCommerce, setSelectedCommerce] = useState<Commerce | null>(null);
  const [deletingCommerce, setDeletingCommerce] = useState<boolean>(false);
  const [commerceToDelete, setCommerceToDelete] = useState<CommerceWithIsLast | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  useEffect(() => {
    selectedClient?.id && dispatch(fetchCommerces(+selectedClient?.id));
  }, [selectedClient?.id, dispatch]);

  useEffect(() => {
    if (commerces.deleteCommerceSuccess || commerces.deleteCommerceErrorMessage !== null) {
      setDeletingCommerce(false);
      setDeleteOpen(false);
      setSnackbarOpen(true);
    }
  }, [commerces.deleteCommerceSuccess, commerces.deleteCommerceErrorMessage]);

  const showClientDetails = (client: Client) => {
    setSelectedClient(client);
  };

  const handleBillingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleBilling({ id: selectedClient!.id, enabled: !selectedClient!.billingEnabled }));
  };

  const createCommerce = () => {
    setSelectedCommerce(null);
    setOpen(true);
  };

  const updateCommerce = (commerce: Commerce) => {
    setSelectedCommerce(commerce);
    setOpen(true);
  };

  const beginDeleteCommerce = (commerce: Commerce, isLast: boolean) => {
    setCommerceToDelete({ ...commerce, isLast });
    setDeleteOpen(true);
  };

  const confirmDeleteCommerce = () => {
    setDeletingCommerce(true);

    if (commerceToDelete && selectedClient?.id)
      dispatch(deleteCommerce(selectedClient?.id, commerceToDelete?.id, commerceToDelete.isLast));
  };

  const closeSnack = () => {
    setSnackbarOpen(false);
  };

  const triggerSearch = () => {
    if (searchTerm.length > 3 || searchTerm.length === 0) {
      dispatch(fetchClients(searchTerm));
    }
  };

  useEffect(() => {
    if (!clients.loadingClients && clients.clients === null && clients.loadingClientsErrorMessage === null) {
      dispatch(fetchClients(searchTerm));
    }
  });

  return (
    <div className={classes.container}>
      <div className={classes.screenTitle}>
        <ScreenTitle title={selectedClient === null ? 'Clientes' : selectedClient.prettyName}>
          {selectedClient && (
            <Link className={classes.button} onClick={() => setSelectedClient(null)}>
              <Button variant="outlined" color="secondary">
                Volver
              </Button>
            </Link>
          )}
        </ScreenTitle>
      </div>
      {selectedClient === null && (
        <Search
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          triggerSearch={triggerSearch}
          placeholder="Buscar por cliente"
        />
      )}

      {clients.loadingClients ? (
        <Loading />
      ) : (
        selectedClient === null &&
        clients.clients &&
        clients.clients.map((client) => {
          return (
            <Paper
              key={client.id}
              elevation={0}
              variant="outlined"
              classes={{ root: classes.paperRoot }}
              onClick={() => showClientDetails(client)}
            >
              <Typography variant="body1">{client.prettyName}</Typography>
              <ArrowForwardOutlined />
            </Paper>
          );
        })
      )}
      {selectedClient !== null && (
        <>
          <ClientSettings
            selectedClient={selectedClient!}
            handleBillingChange={handleBillingChange}
            createCommerce={createCommerce}
            updateCommerce={updateCommerce}
            beginDeleteCommerce={beginDeleteCommerce}
          />
          <ClientUsersTable selectedClient={selectedClient} isForDashboard={true} />
        </>
      )}
      <CommerceDialog
        open={open}
        setOpen={setOpen}
        commerce={selectedCommerce}
        client={selectedClient ?? undefined}
      />
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        message={`¿Seguro que deseas eliminar el medio de pago ${commerceToDelete?.number}?`}
        deleteAction={confirmDeleteCommerce}
        deleteDisabled={deletingCommerce}
      />
      <CustomSnackbar
        open={snackbarOpen}
        message={
          commerces.deleteCommerceSuccess
            ? 'Se eliminó el medio de pago correctamente'
            : commerces.deleteCommerceErrorMessage!
        }
        handleClose={closeSnack}
        type={commerces.deleteCommerceSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default ManageClients;
