import {
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UserTypeEnum } from '../../store/config/enums';
import { AuthState, Business, Collaborator, PaymentsFilters, RootState } from '../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('sm')]: {
        maxHeight: '50vh',
        overflow: 'auto',
      },
    },
  }),
);
interface CollaboratorFiltersProps {
  filters: PaymentsFilters | null;
  setFilters: (filters: PaymentsFilters) => void;
  hasDateFilters?: boolean;
  selectedBusiness?: Business | null;
}

function CollaboratorFilters({
  filters,
  setFilters,
  hasDateFilters,
  selectedBusiness,
}: CollaboratorFiltersProps) {
  const classes = useStyles();

  const collaborators: Collaborator[] | null = useSelector(
    (state: RootState) => state.collaborators.collaborators,
  );
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const isSuperAdmin = auth.account?.type === UserTypeEnum.SUPERADMIN;
  const isCollaborator = auth.account?.type === UserTypeEnum.COLLABORATOR;
  const sortedCollaborators = collaborators?.sort((a: Collaborator, b: Collaborator) =>
    a.names.toUpperCase().localeCompare(b.names.toUpperCase()),
  );

  useEffect(() => {
    let newPaymentsFilters: PaymentsFilters = {
      ...filters,
      collaborator: isCollaborator || isSuperAdmin ? '' : filters?.collaborator || '',
    };

    setFilters(newPaymentsFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const handleCollaboratorChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const targetValue = String(event.target.value);

    const selectedCollaborator = targetValue ? targetValue : undefined;
    var filterCollaborator;

    filterCollaborator = selectedCollaborator === filters?.collaborator ? undefined : selectedCollaborator;

    let newPaymentsFilters: PaymentsFilters = {
      ...filters,
      collaborator: filterCollaborator,
    };

    setFilters(newPaymentsFilters);
  };

  const selectValue = isSuperAdmin
    ? (selectedBusiness && filters?.collaborator) || ''
    : isCollaborator
    ? ''
    : filters?.collaborator || '';

  return (
    <div className="filter-container">
      <FormControl component="fieldset">
        <FormLabel component="legend" classes={{ root: 'form-label' }} focused={false}>
          Creado por
        </FormLabel>
        <FormGroup classes={{ root: 'group' }}>
          <Select
            displayEmpty
            input={<OutlinedInput />}
            className={`select ${isCollaborator ? 'disabled' : ''}`}
            onChange={handleCollaboratorChange}
            value={selectValue}
            disabled={isCollaborator}
            MenuProps={{
              classes: { paper: classes.paper },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            <MenuItem disabled value="select">
              <em>Selecciona</em>
            </MenuItem>
            <MenuItem value="" className="select-element">
              {isCollaborator ? `${auth.account?.names} ${auth.account?.surnames}` : 'Todos'}
            </MenuItem>
            {sortedCollaborators &&
              sortedCollaborators.map((collaborator: Collaborator, index) => {
                return (
                  <MenuItem value={collaborator.userId} key={collaborator.userId} className="select-element">
                    {`${collaborator.names} ${collaborator.surnames}`}
                  </MenuItem>
                );
              })}
          </Select>
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default CollaboratorFilters;
