import { Divider, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styles from '../../styles/_variables.module.scss';
import { numberHelper } from './../../helpers/numberHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.8125rem 1.5625rem',
      border: 'none',
      boxShadow: styles.boxShadow,
    },
    flexRowDescription: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
      },
      display: 'flex',
      '& *': {
        paddingRight: '5px',
      },
    },
    flexRowAmounts: {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
      alignItems: 'flex-start',
    },
    flexRowBaseline: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      '& *': {
        paddingRight: '5px',
      },
    },
    divider: {
      [theme.breakpoints.up('sm')]: {
        visibility: 'hidden',
      },
      margin: '0.4rem 1rem',
    },
    description: {
      fontWeight: Number(styles.normalTextWeight),
      fontSize: '0.875rem',
      color: styles.slateBlue,
    },
    txtPayments: {
      fontSize: '1.375rem',
      fontWeight: Number(styles.semiBoldTextWeight),
    },
  }),
);

interface ColoredCardWithTotalsProps {
  description: string;
  pesosAmount: number;
  usdAmount: number;
  pesosCount: number;
  usdCount: number;
  color: 'error' | 'success' | 'warning';
  className: string;
}

function ColoredCardWithTotals({
  description,
  pesosAmount,
  usdAmount,
  pesosCount,
  usdCount,
  color,
  className,
}: ColoredCardWithTotalsProps) {
  const theme = useTheme();
  const classes = useStyles();

  const paletteColor = theme.palette[color];
  const txtColor = color === 'warning' ? theme.palette.text.primary : paletteColor.main;

  pesosAmount = pesosAmount ?? 0;
  usdAmount = usdAmount ?? 0;
  pesosCount = pesosCount ?? 0;
  usdCount = usdCount ?? 0;

  const pesos = numberHelper.getNumberWithDots(pesosAmount);
  const usd = numberHelper.getNumberWithDots(usdAmount);

  return (
    <Paper
      variant="outlined"
      elevation={2}
      className={`${classes.paper} ${className}`}
      style={{ backgroundColor: styles.withe }}
    >
      <div className={classes.flexRowDescription}>
        <Typography className={classes.description}>{description}</Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.flexRowAmounts}>
        <div className={classes.flexRowBaseline}>
          <Typography variant="h5" style={{ color: txtColor }} className={classes.txtPayments}>
            $
          </Typography>
          <Typography variant="h3" style={{ color: txtColor }} className={classes.txtPayments}>
            {pesos}
          </Typography>
        </div>
        <div style={{ width: '20px' }} />
        <div className={classes.flexRowBaseline}>
          <Typography variant="h5" style={{ color: txtColor }} className={classes.txtPayments}>
            USD
          </Typography>
          <Typography variant="h3" style={{ color: txtColor }} className={classes.txtPayments}>
            {usd}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

export default ColoredCardWithTotals;
