import { Button, Card, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import schemas from '../../../data/schemas';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { Currency } from '../../../store/config/enums';
import { Amount, Business, BusinessIssuer, LandingParams } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';
import { IssuersCardList } from '../../IssuersCardList';
import { FormSelectField } from '../../forms/FormSelectField';
import { FormTextField } from '../../forms/FormTextField';
import { FloatingButtons } from '../MobileNavigation';
import { OnTheFlyDetailCard } from '../OnTheFlyDetailCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0 auto',
      gap: '3rem',
      width: '70%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
        gap: '1rem',
      },
    },
    cardLeft: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '45%',
      padding: '2.5625rem',
      height: 'fit-content',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      '& p': {
        fontSize: '0.875rem',
        fontWeight: Number(styles.normalTextWeight),
        color: styles.slateBlue,
        margin: '1.5625rem 0 0.3125rem 0',
      },
      '& h2': {
        fontSize: '0.875rem',
        fontWeight: Number(styles.boldTextWeight),
        color: styles.slateBlue,
        margin: 0,
      },
    },
    divider: {
      width: '100%',
      height: '0.0625rem',
    },
    image: {
      maxWidth: '9.375rem',
      maxHeight: '9.375rem',
    },
    cardRight: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      flexGrow: 1,
      padding: '2.5625rem',
      height: 'max-content',
      '& h3': {
        color: styles.secondaryMain,
        fontSize: '1.125rem',
        fontWeight: Number(styles.semiBoldTextWeight),
        margin: '0 0 1.375rem 0',
      },
      marginBottom: '9.375rem',
    },
    fieldsContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1.0625rem',
    },
    currencyField: {
      maxWidth: '33%',
    },
    submitButton: {
      float: 'right',
      padding: '0.5625rem 3.125rem',
    },
  }),
);

interface OnTheFlyLandingProps {
  params: LandingParams;
  businessIssuers: BusinessIssuer[] | null;
  selectedIssuer: BusinessIssuer | null;
  setSelectedIssuer: (issuer: BusinessIssuer) => void;
  business: Business;
  beginningPayment: boolean;
  setAmount: (amount: Amount) => void;
  selectedAmount: Amount | null;
  step: number;
  setStep: (step: number) => void;
}

interface Values {
  currency: Currency;
  amount: number;
  issuer: number | string;
}

function OnTheFlyLanding({
  params,
  businessIssuers,
  selectedIssuer,
  setSelectedIssuer,
  business,
  beginningPayment,
  setAmount,
  selectedAmount,
  setStep,
  step,
}: OnTheFlyLandingProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const submit = (values: Values) => {
    setAmount({ value: +values.amount, currency: values.currency });
    setStep(2);
  };

  useEffect(() => {
    if (businessIssuers && businessIssuers.length === 1) {
      setSelectedIssuer(businessIssuers[0]);
    }
  }, [businessIssuers, setSelectedIssuer]);

  return (
    <div className={classes.container}>
      <OnTheFlyDetailCard params={params} business={business} amount={selectedAmount!} />
      <Card className={classes.cardRight}>
        <Typography variant="subtitle2"></Typography>
        <Formik
          initialValues={{
            currency: params.currency
              ? currencyHelper.isValidCurrency(Number(params.currency))
                ? Number(params.currency)
                : Currency.PESO
              : selectedAmount?.currency ?? Currency.PESO,
            amount: params.amount ? Number(params.amount) : selectedAmount?.value ?? 0,
            issuer: businessIssuers !== null ? Number(businessIssuers[0].id) : '',
          }}
          validationSchema={schemas.OnTheFlySchema}
          onSubmit={submit}
        >
          {({ values }) => (
            <Form className="form amount-form" key="amount" id="amount">
              <h3>Importe a pagar</h3>
              <div className={classes.fieldsContainer}>
                <Field
                  className={classes.currencyField}
                  name="currency"
                  component={FormSelectField}
                  label="Moneda"
                  options={currencyHelper.mapCurrency(Currency, (i: Currency) => {
                    return {
                      id: i,
                      value: currencyHelper.getCurrencyToken(i),
                    };
                  })}
                />
                <Field
                  name="amount"
                  component={FormTextField}
                  type="tel"
                  placeholder="Importe"
                  noLeadingZeros
                />
              </div>
              {businessIssuers && (
                <IssuersCardList
                  issuers={businessIssuers}
                  selectedIssuer={selectedIssuer}
                  disabled={false}
                  clickAction={(issuer) => {
                    setSelectedIssuer(issuer);
                  }}
                  setStep={setStep}
                  hasButton={false}
                />
              )}
              {isMobile ? (
                <FloatingButtons
                  formId="amount"
                  step={step}
                  setStep={setStep}
                  selectedIssuer={selectedIssuer}
                  amount={values.amount}
                  currency={values.currency}
                  isOntheFly
                />
              ) : (
                <Button
                  className={classes.submitButton}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={beginningPayment || values.amount <= 0 || selectedIssuer === null}
                  classes={{ root: 'continue-button' }}
                >
                  Continuar
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}

export default OnTheFlyLanding;
