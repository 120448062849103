import { Button, Typography } from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import successAnimation from '../../assets/plinks-success.json';
import { ErrorCard } from '../../components/ErrorCard';
import { LandingLayout } from '../../components/Landing';
import { Loading } from '../../components/Loading';
import { PDFReceipt } from '../../components/PDFReceipt';
import { urlHelper } from '../../helpers/urlHelper';
import { RootState } from '../../store/config/types';

function Result() {
  const { payments, business } = useSelector((state: RootState) => ({
    payments: state.payments,
    business: state.business,
  }));
  const [ready, setReady] = useState<boolean>(false);
  const navigate = useNavigate();
  let urlVars = urlHelper.parseQueryString();
  const transactionPayment = payments.transactionPayment;

  const isAbitabPayment = transactionPayment?.instrument.issuerName === 'Abitab';

  const suscessTitle = isAbitabPayment ? '¡Pago iniciado!' : '¡Tu pago ha sido procesado con éxito!';
  const successSubtitle = 'Su proceso ha finalizado, para salir solo debes cerrar la ventana.';
  const animation = <Lottie animationData={successAnimation} loop={false} />;
  const successContent = (
    <>
      {isAbitabPayment ? (
        <>
          <Typography className="reference" variant="h6">
            {`El código a presentar en Abitab es ${transactionPayment?.abitabCode}`}
          </Typography>
          <Typography className="reference" variant="body2">
            {`También puede realizar el pago con su número de CI ${transactionPayment?.instrument?.issuerName}`}
          </Typography>
        </>
      ) : (
        <Typography variant="subtitle2" className="content-subtitle">
          Referencia de pago: {payments.confirmedPayment?.plexoReferenceId}
        </Typography>
      )}
      {transactionPayment && payments.confirmedPayment && ready && (
        <PDFDownloadLink
          document={
            <PDFReceipt
              type="selectedPayment"
              {...transactionPayment}
              confirmedPayment={payments.confirmedPayment}
              businessVatRate={business.business?.vatRate!}
            />
          }
          fileName={`Pago ${payments.confirmedPayment.plexoReferenceId}`}
          style={{ textDecoration: 'none' }}
        >
          {({ loading }) => (
            <Button variant="contained" color="primary" disabled={loading} className="download-button">
              Descargar comprobante
            </Button>
          )}
        </PDFDownloadLink>
      )}
    </>
  );

  useEffect(() => {
    if (
      !payments.confirmingPayment &&
      !payments.confirmPaymentSuccess &&
      payments.confirmPaymentErrorMessage === null &&
      !urlVars.beginPaymentId
    ) {
      navigate('/');
    }
  }, [
    payments.confirmingPayment,
    payments.confirmPaymentSuccess,
    payments.confirmPaymentErrorMessage,
    urlVars.beginPaymentId,
    navigate,
  ]);

  useEffect(() => {
    if (!ready) {
      setTimeout(() => {
        setReady(true);
      }, 500);
    }
  }, [ready, setReady]);

  return payments.confirmingPayment ? (
    <Loading />
  ) : payments.confirmPaymentErrorMessage !== null ? (
    <ErrorCard
      title="¡Ha sucedido un error!"
      subtitle={`El pago no pudo ser realizado. ${
        payments.confirmedPayment?.plexoResultText || 'Pago fallido'
      }`}
      paymentReference={payments.confirmedPayment?.plexoReferenceId}
    />
  ) : (
    <div className="screen-container landing">
      <LandingLayout
        title={suscessTitle}
        subtitle={successSubtitle}
        content={successContent}
        animation={animation}
      />
    </div>
  );
}

export default Result;
