import { Divider, Theme, createStyles, makeStyles } from '@material-ui/core';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { numberHelper } from '../../../helpers/numberHelper';
import { LinkToPay } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';
import PaymentDetails from './PaymentDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentDetailHeader: {
      backgroundColor: styles.greyTableHead,
      width: '100%',
      marginTop: '1.375rem',
      textAlign: 'center',
      '& p': {
        margin: '0.75rem',
        color: styles.slateBlue,
        fontWeight: 600,
        fontSize: '0.75rem',
      },
    },
    descriptionRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '1.5625rem 0',
      '& p': {
        color: styles.slateBlue,
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        margin: 0,
      },
      '&:last-child': {
        margin: '1.5625rem 0 0 0',
      },
    },
    total: {
      color: styles.secondaryMain,
      fontSize: '1rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
      '& span': {
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        color: styles.slateBlue,
      },
    },
    totalAmount: {
      color: styles.secondaryMain,
      fontSize: '1.375rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
    },
  }),
);

interface LinkDetailContentProps {
  linkToPay: LinkToPay;
}

function LinkDetailContent({ linkToPay }: LinkDetailContentProps) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.paymentDetailHeader}>
        <p>Detalle del pago</p>
      </div>
      <PaymentDetails linkToPay={linkToPay} />
      <Divider />
      <div className={classes.descriptionRow}>
        <h2 className={classes.total}>Total {linkToPay.vatRate > 0 && <span>IVA inc.</span>}</h2>
        <h3 className={classes.totalAmount}>
          {currencyHelper.getCurrencyToken(linkToPay.amount.currency)}
          {numberHelper.getNumberWithDots(linkToPay.amount.value)}
        </h3>
      </div>
    </>
  );
}

export default LinkDetailContent;
