import { Button, CircularProgress, Paper, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import generator from 'generate-password';
import { ChangeEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import schemas from '../../../data/schemas';
import { ivas } from '../../../data/values';
import { urlHelper } from '../../../helpers/urlHelper';
import { createBusiness } from '../../../store/action_creators/business.actions';
import { Status } from '../../../store/config/enums';
import { RootState } from '../../../store/config/types';
import variables from '../../../styles/_variables.module.scss';
import { ClientSecret } from '../../ClientSecret';
import { CustomSnackbar } from '../../CustomSnackbar';
import { ImageUpload } from '../../ImageUpload';
import { BusinessConfirmationDialog } from '../../dialogs/BusinessConfirmationDialog';
import { FormSelectField } from '../FormSelectField';
import { FormTextField } from '../FormTextField';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      padding: '0.2rem',
      paddingLeft: 0,
    },
    title: {
      color: variables.slateBlue,
      fontSize: '1.0625rem',
      fontWeight: +variables.semiBoldTextWeight,
    },
    text: {
      color: variables.slateBlue,
    },
    imageInstructions: {
      color: variables.slateBlue,
      textAlign: 'center',
      width: '70%',
      fontSize: '0.75rem',
      marginTop: '0.5rem',
    },
  }),
);

interface Values {
  prettyName: string;
  urlName: string;
  legalId: string;
  description: string;
  vat: number;
}

interface BusinessFormProps {
  handleSuccess?: () => void;
}

function generateSecretText() {
  return generator.generate({ length: 12, numbers: true, uppercase: true });
}

function BusinessForm({ handleSuccess }: BusinessFormProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { auth, business } = useSelector((state: RootState) => state);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Values | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageError, setImageError] = useState<string | null>(null);
  const [secret, setSecret] = useState<string>(generateSecretText());
  const [creatingBusiness, setCreatingBusiness] = useState<boolean>(false);

  const handlePrettyNameChange = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<Values>['setFieldValue'],
  ) => {
    const webAddressName = urlHelper.generateUrlBusinessName(event.target.value);
    setFieldValue('urlName', webAddressName);
  };

  const businessConfirmation = () => {
    setOpenConfirmationDialog(false);
    setCreatingBusiness(true);
    if (formValues && selectedImage && auth.account?.userId) {
      dispatch(
        createBusiness(
          {
            prettyName: formValues.prettyName,
            urlName: formValues.urlName,
            legalId: formValues.legalId,
            userId: auth.account?.userId!,
            softDescriptor: formValues.description,
            vatRate: formValues.vat,
            clientSecret: secret,
          },
          selectedImage!,
        ),
      );
    }
  };

  const handleFormSubmit = (values: Values) => {
    setFormValues(values);
    setOpenConfirmationDialog(true);
  };

  let allIvas = ivas.map((i) => {
    return {
      id: i,
      value: i.toString(),
    };
  });
  allIvas.unshift({ id: 99, value: 'Selecciona una opción' });

  const closeSnack = () => {
    setCreatingBusiness(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          prettyName: '',
          urlName: '',
          legalId: '',
          description: '',
          vat: 99,
        }}
        validationSchema={schemas.BusinessSchema}
        onSubmit={handleFormSubmit}
        isInitialValid={false}
      >
        {({ setFieldValue, isValid }) => {
          return (
            <Form className="form">
              <Paper elevation={2}>
                <Typography className={classes.title}>Tu empresa</Typography>
                <Field
                  max={30}
                  className="row-field"
                  name="prettyName"
                  component={FormTextField}
                  type="text"
                  placeholder="Nombre"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handlePrettyNameChange(event, setFieldValue)
                  }
                />
                <Field
                  className="row-field-negative-margin"
                  name="urlName"
                  component={FormTextField}
                  type="text"
                  placeholder="Nombre para la dirección web"
                  startAdornment={`${window.location.origin}/`}
                />
                <div className="row-field">
                  <Typography variant="body2" className={classes.text}>
                    Por ejemplo: mvd-donuts
                  </Typography>
                  <Typography variant="body2" className={classes.text}>
                    No incluir la palabra "empresa". Ingresar solo letras y guiones "-". El nombre debe tener
                    entre 3 y 30 caracteres.
                  </Typography>
                </div>
                <Field
                  className="row-field"
                  name="legalId"
                  component={FormTextField}
                  type="text"
                  placeholder="RUT"
                  max={12}
                />
                <Field
                  className="row-field"
                  name="vat"
                  component={FormSelectField}
                  options={allIvas}
                  label="IVA"
                />
                <Field
                  className="row-field"
                  name="description"
                  component={FormTextField}
                  type="text"
                  placeholder="Razón social"
                />
                <ClientSecret secret={secret} setSecret={setSecret} creating />
                <ImageUpload
                  image={selectedImage}
                  setImage={setSelectedImage}
                  error={imageError}
                  setError={setImageError}
                  imageRef={imageRef}
                />
              </Paper>
              <div className="form-row">
                <div className="button-row-field">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={!isValid || business.creatingBusiness || !!imageError || !selectedImage}
                  >
                    {business.creatingBusiness ? (
                      <CircularProgress style={{ color: '#FFF' }} size={20} thickness={6} />
                    ) : (
                      'Confirmar'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <BusinessConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        message="¿Deseas crear tu cuenta con esta información?"
        action={businessConfirmation}
      />
      <CustomSnackbar
        open={creatingBusiness && business.createBusinessErrorMessage !== null}
        message={business.createBusinessErrorMessage || 'Ocurrió un error al crear tu empresa'}
        handleClose={closeSnack}
        type={Status.ERROR}
      />
    </>
  );
}

export default BusinessForm;
