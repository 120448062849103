import { SVGProps } from 'react';
import variables from '../../styles/_variables.module.scss';

function Profile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0C3.58065 0 0 3.58065 0 8C0 12.4194 3.58065 16 8 16C12.4194 16 16 12.4194 16 8C16 3.58065 12.4194 0 8 0ZM12.129 13.6C10.971 14.4548 9.54516 14.9677 8 14.9677C6.45484 14.9677 5.02903 14.4548 3.87097 13.6V13.1613C3.87097 12.0226 4.79677 11.0968 5.93548 11.0968C6.29355 11.0968 6.82258 11.4645 8 11.4645C9.18064 11.4645 9.70323 11.0968 10.0645 11.0968C11.2032 11.0968 12.129 12.0226 12.129 13.1613V13.6ZM13.1161 12.7129C12.8968 11.2161 11.6226 10.0645 10.0645 10.0645C9.40323 10.0645 9.08387 10.4323 8 10.4323C6.91613 10.4323 6.6 10.0645 5.93548 10.0645C4.37742 10.0645 3.10323 11.2161 2.88387 12.7129C1.73871 11.471 1.03226 9.81936 1.03226 8C1.03226 4.15806 4.15806 1.03226 8 1.03226C11.8419 1.03226 14.9677 4.15806 14.9677 8C14.9677 9.81936 14.2613 11.471 13.1161 12.7129ZM8 3.6129C6.43226 3.6129 5.16129 4.88387 5.16129 6.45161C5.16129 8.01935 6.43226 9.29032 8 9.29032C9.56774 9.29032 10.8387 8.01935 10.8387 6.45161C10.8387 4.88387 9.56774 3.6129 8 3.6129ZM8 8.25806C7.00323 8.25806 6.19355 7.44839 6.19355 6.45161C6.19355 5.45484 7.00323 4.64516 8 4.64516C8.99677 4.64516 9.80645 5.45484 9.80645 6.45161C9.80645 7.44839 8.99677 8.25806 8 8.25806Z"
        fill={variables.slateBlue}
      />
    </svg>
  );
}

export default Profile;
