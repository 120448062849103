import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { EmailSent } from '../../components/EmailSent';
import RegisterHeader from '../../components/Register/RegisterHeader';
import { BusinessForm } from '../../components/forms/BusinessForm';
import { UserForm } from '../../components/forms/UserForm';
import { urlHelper } from '../../helpers/urlHelper';
import { RootState } from '../../store/config/types';

function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, business } = useSelector((state: RootState) => state);
  const [email, setEmail] = useState<string>('');
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (step === 0) {
      let urlVars = urlHelper.parseQueryString();

      if (urlVars.step) {
        setStep(Number(urlVars.step));
      } else {
        setStep(1);
      }
    }
  }, [step, setStep]);

  const goBack = () => {
    navigate('/login');
  };

  const emailSentText = (
    <>
      <p>Te enviamos un mail de confirmación a {email}</p>
      <p>Allí encontrarás las instrucciones para ingresar a tu cuenta.</p>
      <p>Si no recibiste este correo después de unos minutos, por favor revisa tu casilla de spam.</p>
    </>
  );

  const renderStepOne = () => {
    if (!auth.registerUserSuccess) {
      return (
        <div className="screen-container profile">
          <RegisterHeader step={step} />
          <UserForm setEmail={setEmail} goBack={goBack} />
        </div>
      );
    } else {
      return <EmailSent text={emailSentText} />;
    }
  };

  const renderStepTwo = () => {
    return (
      <div className="screen-container profile">
        <RegisterHeader step={step} goBack={goBack} disableBackButton />
        <BusinessForm />
      </div>
    );
  };

  useEffect(() => {
    const cookies = new Cookies();
    const hasBusinessCreated = auth.account?.business?.id || business.createdBusiness;
    if (cookies.get('token') && location.pathname.includes('/register')) {
      if (hasBusinessCreated && !auth.account?.business?.hasCommerces) {
        navigate('/first-payment-method');
      } else if (hasBusinessCreated && auth.account?.business?.hasCommerces) {
        navigate('/dashboard');
      } else {
        navigate('/register?step=2');
      }
    }
  }, [
    auth.account?.business?.hasCommerces,
    auth.account?.business?.id,
    business.createdBusiness,
    location.pathname,
    navigate,
  ]);

  return (
    <>
      {step === 1 && renderStepOne()}
      {step === 2 && renderStepTwo()}
    </>
  );
}

export default Register;
