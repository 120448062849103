import { Button } from '@material-ui/core';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import errorAnimation from '../../assets/plinks-error.json';
import successAnimation from '../../assets/plinks-success.json';
import alertAnimation from '../../assets/plinks-cancelled.json';
import { LandingLayout } from '../../components/Landing';
import { Loading } from '../../components/Loading';
import { getAccount, validateRegister } from '../../store/action_creators/auth.actions';
import { UserTypeEnum } from '../../store/config/enums';
import { AuthState, RootState } from '../../store/config/types';
import { urlHelper } from './../../helpers/urlHelper';
import { useSelector, useDispatch } from 'react-redux';

function ConfirmEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const errorAnimationData = <Lottie animationData={errorAnimation} loop={false} />;
  const alertAnimationData = <Lottie animationData={alertAnimation} loop={false} />;
  const successAnimationData = <Lottie animationData={successAnimation} loop={false} />;
  const errorMessage = auth.validateRegisterErrorMessage;
  const alreadyValidated = errorMessage?.includes('ha sido verificado');

  useEffect(() => {
    if (
      !auth.validatingRegister &&
      !auth.validateRegisterSuccess &&
      auth.validateRegisterErrorMessage === null
    ) {
      let urlVars = urlHelper.parseQueryString();
      if (urlVars.id && urlVars.token) {
        dispatch(validateRegister({ userId: urlVars.id, token: urlVars.token }));
      }
    }
  }, [auth.validatingRegister, auth.validateRegisterSuccess, auth.validateRegisterErrorMessage, dispatch]);

  useEffect(() => {
    if (auth.validateRegisterSuccess && !auth.account && !auth.loggingIn && !auth.loggedIn) {
      dispatch(getAccount());
    }
  }, [auth.validateRegisterSuccess, auth.account, auth.loggingIn, auth.loggedIn, dispatch]);

  return (
    <div className="screen-container landing confirm-email">
      {auth.validatingRegister ? (
        <Loading />
      ) : !auth.validateRegisterSuccess ? (
        <LandingLayout
          title={
            alreadyValidated ? 'Este email ya ha sido verificado.' : 'Ocurrió un error al verificar tu email.'
          }
          animation={alreadyValidated ? alertAnimationData : errorAnimationData}
          children={
            <div className="subtitle paragraph">
              {alreadyValidated ? (
                <>
                  <span>
                    El email que intentas validar fue verificado anteriormente. Por favor, inicia sesión con
                    tus datos para acceder a tu cuenta.{' '}
                  </span>
                  <Button
                    onClick={() => navigate('/login')}
                    color="primary"
                    variant="contained"
                    disableElevation
                  >
                    Ir al login
                  </Button>
                </>
              ) : (
                <>
                  <span>Por favor, intenta de nuevo en unos minutos o contáctanos a </span>
                  <a href="mailto:soporte@plexo.com.uy">soporte@plexo.com.uy</a>
                  <span> para poder ayudarte. Si deseas salir solo debes cerrar la ventana.</span>
                </>
              )}
            </div>
          }
        />
      ) : (
        <LandingLayout
          title="Tu email ha sido verificado con éxito."
          animation={successAnimationData}
          children={
            <Button
              onClick={() =>
                navigate(auth.account!.type === UserTypeEnum.OWNER ? '/register?step=2' : '/login')
              }
              color="primary"
              variant="contained"
              disableElevation
            >
              {auth.account?.type === UserTypeEnum.OWNER ? 'Continuar con el registro' : 'Ir al login'}
            </Button>
          }
        />
      )}
    </div>
  );
}

export default ConfirmEmail;
