import colors from '../../../styles/_variables.module.scss';

function ShareByEmail() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.641" height="19.981" viewBox="0 0 26.641 19.981">
      <path
        id="envelope"
        d="M24.143,64H2.5A2.5,2.5,0,0,0,0,66.5V81.483a2.5,2.5,0,0,0,2.5,2.5H24.143a2.5,2.5,0,0,0,2.5-2.5V66.5A2.5,2.5,0,0,0,24.143,64ZM2.5,65.665H24.143a.835.835,0,0,1,.833.833v2.154c-1.14.963-2.768,2.289-7.836,6.312-.879.7-2.612,2.378-3.819,2.357-1.207.021-2.945-1.66-3.819-2.357-5.068-4.022-6.7-5.349-7.836-6.312V66.5A.835.835,0,0,1,2.5,65.665ZM24.143,82.316H2.5a.835.835,0,0,1-.833-.833V70.816c1.186.973,3.06,2.477,6.8,5.448,1.067.853,2.95,2.732,4.855,2.721,1.894.016,3.762-1.847,4.855-2.721,3.741-2.971,5.614-4.475,6.8-5.448V81.483A.835.835,0,0,1,24.143,82.316Z"
        transform="translate(0 -64)"
        fill={colors.white}
      />
    </svg>
  );
}

export default ShareByEmail;
