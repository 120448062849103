import { HeadCell } from '../components/CustomTable/CustomTable';
import { PaymentsColumnSortEnum } from '../store/config/enums';
import { Payment } from '../store/config/types';

export interface ExtraColumns {
  actions?: JSX.Element;
}

export type PaymentColumn = Payment & ExtraColumns;

export const getPaymentsHeadCells = (
  isDashboardTable: boolean,
  isSuperAdmin: boolean,
): HeadCell<Payment>[] => {
  const paymentsColumns: HeadCell<PaymentColumn>[] = [
    { columnId: 'date', label: 'Fecha y hora' },
    { columnId: 'description', label: 'Descripción', disableSorting: true },
    { columnId: 'userDto', label: 'Creado por' },
    { columnId: 'linkType', label: 'Tipo' },
    { columnId: 'issuerLogoUrl', label: 'Medio de Pago', disableSorting: true },
    { columnId: 'status', label: 'Estado' },
  ];

  const businessColumn: HeadCell<Payment> = { columnId: 'businessName', label: 'Empresa' };
  const invoiceNumberColumn: HeadCell<Payment> = { columnId: 'invoiceNumber', label: 'N° de factura' };

  const amountColumn: HeadCell<Payment> = { columnId: 'amount', label: 'Importe' };
  const actionsColumn: HeadCell<ExtraColumns> = { columnId: 'actions', label: '' };

  paymentsColumns.push(amountColumn, actionsColumn);

  if (isSuperAdmin) {
    paymentsColumns.splice(2, 0, businessColumn);
    paymentsColumns.push(invoiceNumberColumn);
  }

  return paymentsColumns;
};

export const paymentColumnMap = {
  date: PaymentsColumnSortEnum.Date,
  description: PaymentsColumnSortEnum.Description,
  userDto: PaymentsColumnSortEnum.Username,
  linkType: PaymentsColumnSortEnum.LinkType,
  issuerLogoUrl: PaymentsColumnSortEnum.IssuerName,
  amount: PaymentsColumnSortEnum.Amount,
};
