import { IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  getUnconfirmedUsers,
  resendConfirmation,
  verifyUser,
} from '../../store/action_creators/auth.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { Status } from '../../store/config/enums';
import { RootState, User } from '../../store/config/types';
import { CustomSnackbar } from '../CustomSnackbar';
import CustomTable, { HeadCell } from '../CustomTable/CustomTable';
import { EmptyMessage } from '../EmptyMessage';
import Search from '../FiltersComponent/Search';
import { Check } from '../Icons/Check';
import { Resend } from '../Icons/Resend';
import { Loading } from '../Loading';
import { ScreenTitle } from '../ScreenTitle';
import { StatusPill } from '../StatusPill';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      margin: '1rem 0',
    },
    iconButton: {
      padding: '0.5rem 0',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    container: {
      padding: '2rem 0',
      maxWidth: '60rem',
      margin: '0 auto',
    },
    screenTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => {
  return {
    getUnconfirmedUsers: (searchTerm?: string) => dispatch(getUnconfirmedUsers(searchTerm)),
    verifyUser: (userId: string) => dispatch(verifyUser(userId)),
    resendConfirmation: (email: string) => dispatch(resendConfirmation(email)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function UsersTable({ auth, getUnconfirmedUsers, verifyUser, resendConfirmation }: PropsFromRedux) {
  const classes = useStyles();
  const [resendingConfirmation, setResendingConfirmation] = useState<boolean>(false);
  const [verifyingUser, setVerifyingUser] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    if (
      !auth.gettingUnconfirmedUsers &&
      auth.unconfirmedUsers === null &&
      auth.getUnconfirmedUsersErrorMessage === null
    ) {
      getUnconfirmedUsers();
    }
  }, [
    auth.getUnconfirmedUsersErrorMessage,
    auth.gettingUnconfirmedUsers,
    auth.unconfirmedUsers,
    getUnconfirmedUsers,
  ]);

  type Icons = { icons?: JSX.Element };

  type Column = User & Icons;

  const columns: HeadCell<Column>[] = [
    { columnId: 'names', label: 'Nombre de usuario' },
    { columnId: 'email', label: 'Email' },
    { columnId: 'active', label: 'Estado' },
    { columnId: 'icons', label: '' },
  ];

  const renderCustomTableCells = (row: User, columnId: keyof Column) => {
    switch (columnId) {
      case 'names':
        return row.names + ' ' + row.surnames;
      case 'email':
        return row.email;
      case 'active':
        return row.active ? 'Verificado' : <StatusPill label="Pendiente de verif" color="info" />;
      case 'icons':
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                setVerifyingUser(true);
                verifyUser(row.userId);
              }}
            >
              <Check />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                setResendingConfirmation(true);
                resendConfirmation(row.userId);
              }}
            >
              <Resend />
            </IconButton>
          </div>
        );
      default:
        return row[columnId];
    }
  };

  const triggerSearch = () => {
    if (searchTerm.length > 3 || searchTerm.length === 0) {
      getUnconfirmedUsers(searchTerm);
    }
  };

  const isOpened: boolean =
    resendingConfirmation && (auth.resendConfirmationSuccess || auth.resendConfirmationErrorMessage !== null);

  const closeSnack = () => {
    if (resendingConfirmation) setResendingConfirmation(false);
  };

  useEffect(() => {
    if (verifyingUser && auth.verifyUserSuccess) {
      getUnconfirmedUsers();
      setVerifyingUser(false);
    }
  }, [auth.verifyUserSuccess, verifyingUser, getUnconfirmedUsers]);

  return (
    <div className={classes.container}>
      <div className={classes.screenTitle}>
        <ScreenTitle
          title="Verificación de nuevos administradores"
          elementsAmount={auth.unconfirmedUsers?.length}
        />
      </div>
      <div className={classes.search}>
        <Search
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          triggerSearch={triggerSearch}
          placeholder="Buscar por nombre de usuario o email"
        />
      </div>
      {auth.unconfirmedUsers && auth.unconfirmedUsers.length === 0 && !verifyingUser ? (
        <EmptyMessage message="No hay nuevos administradores por verificar" />
      ) : auth.unconfirmedUsers ? (
        <CustomTable<Column>
          headCells={columns}
          rowsData={auth.unconfirmedUsers}
          renderCell={renderCustomTableCells}
          hasRowHover={false}
        />
      ) : (
        <Loading />
      )}
      <CustomSnackbar
        open={isOpened}
        message={
          auth.resendConfirmationSuccess
            ? 'Se ha enviado la confirmación correctamente'
            : auth.resendConfirmationErrorMessage!
        }
        handleClose={closeSnack}
        type={auth.resendConfirmationSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(UsersTable);
