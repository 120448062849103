import { Button, Typography } from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import cancelledAnimation from '../../assets/plinks-cancelled.json';
import successAnimation from '../../assets/plinks-success.json';
import { ConfirmedPaymentPDFInfo, LinkDataPayment, Payment } from '../../store/config/types';
import { LandingLayout } from '../Landing';
import { PDFReceipt } from '../PDFReceipt';

interface PaidLinkCardProps {
  payment?: LinkDataPayment | Payment;
  businessId?: number;
  businessVatRate?: number;
}

function isPayment(payment: LinkDataPayment | Payment): payment is LinkDataPayment {
  return (payment as Payment).issuerName === undefined;
}

function PaidLinkCard({ payment, businessId, businessVatRate }: PaidLinkCardProps) {
  const title: string = payment ? '¡Tu pago ha sido confirmado!' : '¡Tu pago está cancelado!';
  const subtitle = (
    <p className="subtitle">
      {payment
        ? 'Hemos verificado que tu pago ya se ha recibido y procesado correctamente. No es necesario efectuar un segundo pago.'
        : 'Hemos verificado que tu pago fue cancelado correctamente, para salir solo debes cerrar la ventana.'}
    </p>
  );
  const animationData = payment ? successAnimation : cancelledAnimation;
  const animation = <Lottie animationData={animationData} loop={false} />;
  let confirmedPaymentInfo: ConfirmedPaymentPDFInfo | undefined;
  const [generatePdf, setGeneratePdf] = useState(false);

  if (payment) {
    confirmedPaymentInfo = {
      plexoReferenceId: payment.plexoReferenceId,
      currency: payment.amount.currency,
      amountValue: payment.amount.value,
      installments: payment.installments,
    };
  }

  useEffect(() => {
    if (payment && confirmedPaymentInfo) {
      setGeneratePdf(true);
    }
  }, [payment, confirmedPaymentInfo]);

  const content: JSX.Element | undefined = payment && confirmedPaymentInfo && (
    <>
      <Typography variant="subtitle2" className="content-subtitle">
        Referencia de pago: {payment!.plexoReferenceId}
      </Typography>
      {generatePdf && (
        <PDFDownloadLink
          document={
            isPayment(payment!) ? (
              <PDFReceipt
                type="linkDataPayment"
                {...payment}
                businessId={businessId!}
                businessVatRate={businessVatRate}
                confirmedPayment={confirmedPaymentInfo}
              />
            ) : (
              <PDFReceipt
                type="payment"
                {...payment}
                businessId={businessId!}
                businessVatRate={businessVatRate}
                confirmedPayment={confirmedPaymentInfo}
              />
            )
          }
          fileName={`Pago ${payment!.plexoReferenceId}`}
          style={{ textDecoration: 'none' }}
        >
          {({ loading }) => (
            <Button variant="contained" color="primary" disabled={loading} className="download-button">
              Descargar comprobante
            </Button>
          )}
        </PDFDownloadLink>
      )}
    </>
  );

  return (
    <div className="screen-container landing">
      <LandingLayout title={title} subtitle={subtitle} animation={animation} content={content} />
    </div>
  );
}

export default PaidLinkCard;
