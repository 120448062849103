import { BASE_URL } from '../services/config';

export const urlHelper = {
  parseQueryString,
  isPublicPage,
  isPrivatePage,
  buildImageUrl,
  getHubUrl,
  generateUrlBusinessName,
  isRegisterStep,
};

function parseQueryString(queryString?: string): any {
  if (!queryString) {
    queryString = window.location.search.substring(1);
  }

  const params: any = {};

  const queries = queryString.split('&');

  queries.forEach((indexQuery: string) => {
    const indexPair = indexQuery.split('=');

    const queryKey = indexPair[0];
    const queryValue = indexPair.length > 1 ? indexPair[1] : '';

    params[queryKey] = queryValue;
  });

  return params;
}

const publicPages = [
  '/confirm',
  '/landing',
  '/loading',
  '/login',
  '/redirect',
  '/register',
  '/reset',
  '/result',
  '/user-faqs',
  '/request-issuers',
  '/first-payment-method',
];

const privatePages = [
  '/business',
  '/configuration',
  '/dashboard',
  '/links',
  '/new-permanent-link',
  '/new-one-use-link',
  '/one-use-links',
  '/on-the-fly-payments',
  '/payments',
  '/profile',
  '/users',
  '/user-contact',
  '/manage-clients',
  '/manage-users',
  '/verifications',
  '/user',
  '/change-password',
];

function isPublicPage(url: string): boolean {
  return publicPages.indexOf(url) > -1;
}

function isPrivatePage(url: string): boolean {
  if (url === '/register') {
    let urlVars = urlHelper.parseQueryString();
    return urlVars.step > 1;
  }

  if (url.includes('/payments')) {
    return true;
  }

  return privatePages.indexOf(url) > -1;
}

function buildImageUrl(businessId: number): string {
  return `${BASE_URL}/business/${businessId}/picture`;
}

function getHubUrl() {
  return `${BASE_URL}/plexoHub`;
}

function generateUrlBusinessName(name: string) {
  return name
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .replace(/[^a-z/\- ]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
    .replace(/empresa/g, '') // Remove the word "empresa"
    .substring(0, 30) // Limit to 30 characters
    .replace(/^-|-$/g, ''); // Remove leading and trailing hyphens
}

function isRegisterStep(url: string): boolean {
  return url.includes('register') || url.includes('first-payment-method') || url.includes('request-issuers');
}
