import { Client, ToggleBillingRequest } from '../store/config/types';
import { get, put } from './base.service';
import { endpoints } from './endpoints';

export const clientsService = {
  fetchClients,
  toggleClientBilling,
};

async function fetchClients(searchTerm?: string): Promise<Client[] | undefined> {
  return await get<Client[]>(`${endpoints['clients']}${searchTerm ? `/?filter=${searchTerm}` : ''}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function toggleClientBilling(request: ToggleBillingRequest): Promise<string | undefined> {
  return await put<string>(`${endpoints['billing']}/${request.id}`, {
    electronicBillingEnabled: request.enabled,
  })
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
