import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';
import { authService } from '../../services/auth.service';
import {
  CHANGE_CREDENTIALS_ERROR,
  CHANGE_CREDENTIALS_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGING_CREDENTIALS,
  CHANGING_USER_PASSWORD,
  CONFIRMING_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_ERROR,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  ChangeCredentialsActionTypes,
  ChangeUserPasswordActionTypes,
  ConfirmResetPasswordActionTypes,
  GETTING_ACCOUNT,
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
  GetAccountActionTypes,
  GetUnconfirmedUsersActionTypes,
  LOGGING_IN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LoginActionTypes,
  LogoutActionTypes,
  REGISTERING_BUSINESS,
  REGISTERING_USER,
  REGISTER_BUSINESS_ERROR,
  REGISTER_BUSINESS_SUCCESS,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  RESENDING_CONFIRMATION,
  RESEND_CONFIRMATION_FAILED,
  RESEND_CONFIRMATION_SUCCESS,
  RESETING_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RegisterBusinessActionTypes,
  RegisterUserActionTypes,
  ResendConfirmationTypes,
  ResetPasswordActionTypes,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATING_ACCOUNT,
  UpdateAccountActionTypes,
  VALIDATE_REGISTER_ERROR,
  VALIDATE_REGISTER_SUCCESS,
  VALIDATING_REGISTER,
  VERIFYING_USER,
  VERIFY_USER_ERROR,
  VERIFY_USER_SUCCESS,
  ValidateRegisterActionTypes,
  VerifyUserActionTypes,
} from '../config/ActionTypes';
import {
  Account,
  Business,
  ChangeCredentialsRequest,
  ChangeUserPasswordRequest,
  ConfirmEmailRequest,
  ConfirmResetPasswordRequest,
  ConfirmResetPasswordSuccessResponse,
  ResetPasswordRequest,
  UnconfirmedUsersResponse,
  User,
  UserCredentials,
  UserRequest,
} from '../config/types';

//#region Login

export const login = (user: UserCredentials) => (dispatch: Dispatch<LoginActionTypes>) => {
  dispatch(loggingIn());

  return authService.login({ user }).then(
    (response) => {
      const cookies = new Cookies();
      cookies.set('token', response!.token, { path: '/' });

      dispatch(loginSuccess(response!.user));
    },
    (error) => {
      dispatch(loginError(error));
    },
  );
};

const loggingIn = (): LoginActionTypes => ({
  type: LOGGING_IN,
});

const loginSuccess = (account: User): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
  account,
});

const loginError = (error: string): LoginActionTypes => ({
  type: LOGIN_ERROR,
  error,
});

//#endregion

//#region Logout

export const logout = (): LogoutActionTypes => ({
  type: LOGOUT,
});

//#endregion

//#region Reset password

export const resetPassword =
  ({ email }: ResetPasswordRequest) =>
  (dispatch: Dispatch<ResetPasswordActionTypes>) => {
    dispatch(resetingPassword());

    const resetPasswordRequest: ResetPasswordRequest = {
      email,
    };

    return authService.resetPassword(resetPasswordRequest).then(
      (response) => {
        dispatch(resetPasswordSuccess());
      },
      (error) => {
        dispatch(resetPasswordError(error));
      },
    );
  };

const resetingPassword = (): ResetPasswordActionTypes => ({
  type: RESETING_PASSWORD,
});

const resetPasswordSuccess = (): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_SUCCESS,
});

const resetPasswordError = (error: string): ResetPasswordActionTypes => ({
  type: RESET_PASSWORD_ERROR,
  error,
});

//#endregion

//#region Confirm reset password

export const confirmResetPassword =
  ({ id, token }: ConfirmResetPasswordRequest) =>
  (dispatch: Dispatch<ConfirmResetPasswordActionTypes>) => {
    dispatch(confirmingResetPassword());

    return authService.confirmResetPassword({ id, token }).then(
      (response) => {
        dispatch(confirmResetPasswordSuccess(response!));
      },
      (error) => {
        dispatch(confirmResetPasswordError(error));
      },
    );
  };

const confirmingResetPassword = (): ConfirmResetPasswordActionTypes => ({
  type: CONFIRMING_RESET_PASSWORD,
});

const confirmResetPasswordSuccess = (
  response: ConfirmResetPasswordSuccessResponse,
): ConfirmResetPasswordActionTypes => ({
  type: CONFIRM_RESET_PASSWORD_SUCCESS,
  response,
});

const confirmResetPasswordError = (error: string): ConfirmResetPasswordActionTypes => ({
  type: CONFIRM_RESET_PASSWORD_ERROR,
  error,
});

//#endregion

//#region Register user

export const registerUser = (user: UserRequest) => (dispatch: Dispatch<RegisterUserActionTypes>) => {
  dispatch(registeringUser());

  return authService.registerUser({ user }).then(
    (response) => {
      dispatch(registerUserSuccess(response!));
    },
    (error) => {
      dispatch(registerUserError(error));
    },
  );
};

const registeringUser = (): RegisterUserActionTypes => ({
  type: REGISTERING_USER,
});

const registerUserSuccess = (user: UserCredentials): RegisterUserActionTypes => ({
  type: REGISTER_USER_SUCCESS,
  user,
});

const registerUserError = (error: string): RegisterUserActionTypes => ({
  type: REGISTER_USER_ERROR,
  error,
});

//#endregion

//#region Validate register

export const validateRegister =
  ({ userId, token }: ConfirmEmailRequest) =>
  (dispatch: Dispatch<ValidateRegisterActionTypes>) => {
    dispatch(validatingRegister());

    return authService.validateRegister({ userId, token }).then(
      (response) => {
        if (response) {
          const cookies = new Cookies();
          cookies.set('token', response!.token, { path: '/' });

          dispatch(validateRegisterSuccess());
        } else {
          dispatch(validateRegisterError('Email ya existente.'));
        }
      },
      (error) => {
        dispatch(validateRegisterError(error));
      },
    );
  };

const validatingRegister = (): ValidateRegisterActionTypes => ({
  type: VALIDATING_REGISTER,
});

const validateRegisterSuccess = (): ValidateRegisterActionTypes => ({
  type: VALIDATE_REGISTER_SUCCESS,
});

const validateRegisterError = (error: string): ValidateRegisterActionTypes => ({
  type: VALIDATE_REGISTER_ERROR,
  error,
});

//#endregion

//#region Register business

export const registerBusiness = (business: Business) => (dispatch: Dispatch<RegisterBusinessActionTypes>) => {
  dispatch(registeringBusiness());

  return authService.registerBusiness(business).then(
    (response) => {
      dispatch(registerBusinessSuccess(response!));
    },
    (error) => {
      dispatch(registerBusinessError(error));
    },
  );
};

const registeringBusiness = (): RegisterBusinessActionTypes => ({
  type: REGISTERING_BUSINESS,
});

const registerBusinessSuccess = (business: Business): RegisterBusinessActionTypes => ({
  type: REGISTER_BUSINESS_SUCCESS,
  business,
});

const registerBusinessError = (error: string): RegisterBusinessActionTypes => ({
  type: REGISTER_BUSINESS_ERROR,
  error,
});

//#endregion

//#region Get account

export const getAccount = () => (dispatch: Dispatch<GetAccountActionTypes>) => {
  dispatch(gettingAccount());

  return authService.getAccount().then(
    (response) => {
      dispatch(getAccountSuccess(response!));
    },
    (error) => {
      dispatch(getAccountError(error));
    },
  );
};

const gettingAccount = (): GetAccountActionTypes => ({
  type: GETTING_ACCOUNT,
});

const getAccountSuccess = (account: User): GetAccountActionTypes => ({
  type: GET_ACCOUNT_SUCCESS,
  account,
});

const getAccountError = (error: string): GetAccountActionTypes => ({
  type: GET_ACCOUNT_ERROR,
  error,
});

//#endregion

//#region Update account

export const updateAccount = (account: Account) => (dispatch: Dispatch<UpdateAccountActionTypes>) => {
  dispatch(updatingAccount());

  return authService.updateAccount(account).then(
    (response) => {
      dispatch(updateAccountSuccess(response!));
    },
    (error) => {
      dispatch(updateAccountError(error));
    },
  );
};

const updatingAccount = (): UpdateAccountActionTypes => ({
  type: UPDATING_ACCOUNT,
});

const updateAccountSuccess = (account: User): UpdateAccountActionTypes => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  account,
});

const updateAccountError = (error: string): UpdateAccountActionTypes => ({
  type: UPDATE_ACCOUNT_ERROR,
  error,
});

//#endregion

//#region Change credentials

export const changeCredentials =
  (changeCredentialsRequest: ChangeCredentialsRequest) =>
  (dispatch: Dispatch<ChangeCredentialsActionTypes>) => {
    dispatch(changingCredentials());

    return authService.modifyCredentials(changeCredentialsRequest).then(
      (response) => {
        dispatch(changeCredentialsSuccess());
      },
      (error) => {
        dispatch(changeCredentialsError(error));
      },
    );
  };

const changingCredentials = (): ChangeCredentialsActionTypes => ({
  type: CHANGING_CREDENTIALS,
});

const changeCredentialsSuccess = (): ChangeCredentialsActionTypes => ({
  type: CHANGE_CREDENTIALS_SUCCESS,
});

const changeCredentialsError = (error: string): ChangeCredentialsActionTypes => ({
  type: CHANGE_CREDENTIALS_ERROR,
  error,
});

//#endregion

//#endregion

//#region Change credentials

export const changeUserPassword =
  (changeUserPasswordRequest: ChangeUserPasswordRequest) =>
  (dispatch: Dispatch<ChangeUserPasswordActionTypes>) => {
    dispatch(changingUserPassword());

    return authService.changeUserPassword(changeUserPasswordRequest).then(
      (response) => {
        dispatch(changeUserPasswordSuccess());
      },
      (error) => {
        dispatch(changeUserPasswordError(error));
      },
    );
  };

const changingUserPassword = (): ChangeUserPasswordActionTypes => ({
  type: CHANGING_USER_PASSWORD,
});

const changeUserPasswordSuccess = (): ChangeUserPasswordActionTypes => ({
  type: CHANGE_USER_PASSWORD_SUCCESS,
});

const changeUserPasswordError = (error: string): ChangeUserPasswordActionTypes => ({
  type: CHANGE_USER_PASSWORD_ERROR,
  error,
});

//#endregion

//#region Unconfirmed users

export const getUnconfirmedUsers = (searchTerm?: string) => {
  return (dispatch: Dispatch<GetUnconfirmedUsersActionTypes>) => {
    dispatch(gettingUnconfirmedUsers());

    return authService.getUnconfirmedUsers(searchTerm).then(
      (response) => {
        dispatch(getUnconfirmedUsersSuccess(response!));
      },
      (error) => {
        dispatch(getUnconfirmedUsersError(error));
      },
    );
  };
};

const gettingUnconfirmedUsers = (): GetUnconfirmedUsersActionTypes => ({
  type: 'GETTING_UNCONFIRMED_USERS',
});

const getUnconfirmedUsersSuccess = (users: UnconfirmedUsersResponse): GetUnconfirmedUsersActionTypes => ({
  type: 'GET_UNCONFIRMED_USERS_SUCCESS',
  users: users,
});

const getUnconfirmedUsersError = (error: string): GetUnconfirmedUsersActionTypes => ({
  type: 'GET_UNCONFIRMED_USERS_ERROR',
  error,
});

//#endregion

//#region Resend confirmation

export const resendConfirmation = (email: string) => {
  return (dispatch: Dispatch<ResendConfirmationTypes>) => {
    dispatch(resendingConfirmation());

    return authService.resendConfirmation(email).then(
      (response) => {
        dispatch(resendConfirmationSuccess(response!));
      },
      (error) => {
        dispatch(resendConfirmationError(error));
      },
    );
  };
};

export const resendingConfirmation = (): ResendConfirmationTypes => ({
  type: RESENDING_CONFIRMATION,
});

export const resendConfirmationSuccess = (confirmation: string): ResendConfirmationTypes => ({
  type: RESEND_CONFIRMATION_SUCCESS,
  confirmation,
});

export const resendConfirmationError = (error: string): ResendConfirmationTypes => ({
  type: RESEND_CONFIRMATION_FAILED,
  error,
});

//#endregion

//#region Verify User

export const verifyUser = (userId: string) => {
  return (dispatch: Dispatch<VerifyUserActionTypes>) => {
    dispatch(verifyingUser());

    return authService.verifyUser(userId).then(
      (response) => {
        dispatch(verifyUserSuccess(response!));
      },
      (error) => {
        dispatch(verifyUserError(error));
      },
    );
  };
};

export const verifyingUser = (): VerifyUserActionTypes => ({
  type: VERIFYING_USER,
});

export const verifyUserSuccess = (verification: string): VerifyUserActionTypes => ({
  type: VERIFY_USER_SUCCESS,
  verification,
});

export const verifyUserError = (error: string): VerifyUserActionTypes => ({
  type: VERIFY_USER_ERROR,
  error,
});

//#endregion
