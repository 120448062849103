import { createStyles, FormControl, makeStyles, Theme, Typography } from '@material-ui/core';
import { FieldProps } from 'formik';
import React, { ChangeEvent } from 'react';
import { CustomSwitch } from '../../CustomSwitch';

const useSwitchStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

type FormSwitchProps = FieldProps & React.HTMLProps<HTMLOptionElement>;

function FormSwitchField({ field, form, label, ...props }: FormSwitchProps) {
  const classes = useSwitchStyles();

  const onSwitchChange = (event: ChangeEvent<{ name?: string | undefined; checked: boolean }>): void => {
    form.setFieldValue(field.name, event.target.checked);
  };

  return (
    <FormControl
      classes={{ root: classes.root }}
      className={props.className}
      margin="normal"
      variant="outlined"
    >
      <Typography variant="subtitle2">{label}</Typography>
      <CustomSwitch
        checked={field.value}
        onChange={onSwitchChange}
        disabled={props.disabled}
        color="primary"
      />
    </FormControl>
  );
}

export default FormSwitchField;
