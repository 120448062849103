import { useSelector } from 'react-redux';
import { Status } from '../../../store/config/enums';
import { RootState } from '../../../store/config/types';
import { CustomSnackbar } from '../../CustomSnackbar';

interface SnackbarManagerProps {
  creatingCommerce: boolean;
  updatingCommerce: boolean;
  handleClose: () => void;
  firstCommerce?: boolean;
}

function SnackbarManager({
  creatingCommerce,
  updatingCommerce,
  handleClose,
  firstCommerce,
}: SnackbarManagerProps) {
  const { commerces } = useSelector((state: RootState) => state);

  return (
    <>
      <CustomSnackbar
        open={
          creatingCommerce &&
          (commerces.createCommerceSuccess || commerces.createCommerceErrorMessage !== null)
        }
        message={
          commerces.createCommerceSuccess
            ? firstCommerce
              ? 'Tu primer medio de pago ha sido añadido exitosamente'
              : 'Se creó el medio de pago correctamente'
            : commerces.createCommerceErrorMessage!
        }
        handleClose={handleClose}
        type={commerces.createCommerceSuccess ? Status.SUCCESS : Status.ERROR}
      />
      <CustomSnackbar
        open={
          updatingCommerce &&
          (commerces.updateCommerceSuccess || commerces.updateCommerceErrorMessage !== null)
        }
        message={
          commerces.updateCommerceSuccess
            ? 'Se actualizó el medio de pago correctamente'
            : commerces.updateCommerceErrorMessage!
        }
        handleClose={handleClose}
        type={commerces.updateCommerceSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default SnackbarManager;
