import { SVGProps } from 'react';
import variables from '../../../styles/_variables.module.scss';

function Dashboard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.9045 7.16256L9.6281 1.22423C9.4498 1.07918 9.22698 1 8.99715 1C8.76732 1 8.5445 1.07918 8.3662 1.22423L1.09179 7.16256C1.04059 7.20441 1.00805 7.26485 1.00131 7.33064C0.994559 7.39642 1.01415 7.46221 1.05579 7.51358L1.37176 7.9016C1.41344 7.95267 1.4736 7.9852 1.53914 7.99213C1.60468 7.99906 1.67031 7.97983 1.72174 7.93861L2.99863 6.89555V14.491C2.99863 14.6236 3.05131 14.7508 3.14507 14.8445C3.23883 14.9383 3.366 14.991 3.49859 14.991H7.49827C7.63087 14.991 7.75804 14.9383 7.8518 14.8445C7.94556 14.7508 7.99823 14.6236 7.99823 14.491V10.4907L9.99807 10.4997V14.5C9.99807 14.6326 10.0507 14.7598 10.1445 14.8535C10.2383 14.9473 10.3654 15 10.498 15L14.4977 14.99C14.6303 14.99 14.7575 14.9373 14.8512 14.8435C14.945 14.7498 14.9977 14.6226 14.9977 14.49V6.88955L16.2746 7.93261C16.3 7.95338 16.3293 7.96893 16.3608 7.97837C16.3923 7.9878 16.4253 7.99094 16.458 7.98759C16.4907 7.98425 16.5224 7.97449 16.5513 7.95888C16.5803 7.94327 16.6058 7.9221 16.6265 7.8966L16.9445 7.51458C16.9652 7.48899 16.9806 7.45958 16.9899 7.42802C16.9991 7.39646 17.0021 7.36339 16.9986 7.33069C16.995 7.29798 16.9851 7.2663 16.9693 7.23745C16.9535 7.2086 16.9322 7.18315 16.9065 7.16256H16.9045ZM13.9948 13.9949L10.995 14.0039V9.99772C10.995 9.8651 10.9423 9.73792 10.8486 9.64415C10.7548 9.55037 10.6276 9.49769 10.495 9.49769L7.49527 9.48969C7.36267 9.48969 7.23551 9.54237 7.14175 9.63615C7.04799 9.72992 6.99531 9.85711 6.99531 9.98972V13.9899H3.99555V6.0795L8.99515 1.99827L13.9948 6.0795V13.9949Z"
        fill={variables.slateBlue}
        stroke="inherit"
        stroke-width="0.4"
      />
    </svg>
  );
}

export default Dashboard;
