import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import React, { CSSProperties, MutableRefObject, useEffect, useRef, useState } from 'react';
import ShareIcon from '../../assets/ShareIcon';

interface LinkToShareTooltipProps {
  reason: string;
}

function LinkToShareTooltip({ reason }: LinkToShareTooltipProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const tooltipRef: MutableRefObject<HTMLSpanElement | null> = useRef(null);
  const title =
    reason === 'expired'
      ? 'Actualiza la fecha de vencimiento del link para volver a compartirlo'
      : 'Este link no tiene configurado ningún medio de pago, añade uno para poder compartirlo';

  const handleOutsideClick = (event: MouseEvent) => {
    if (tooltipOpen && tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
    // eslint-disable-next-line
  }, [isMobile, tooltipOpen]);

  const handleTooltipToggle = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (isMobile) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const popperStyles: CSSProperties = {
    pointerEvents: tooltipOpen ? 'auto' : 'none',
    width: '8.75rem',
  };
  const iconStyles: CSSProperties = {
    padding: '3px',
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <Tooltip
      title={title}
      placement="top"
      open={isMobile ? tooltipOpen : undefined}
      disableFocusListener={isMobile}
      disableHoverListener={isMobile}
      disableTouchListener={isMobile}
      onClose={() => setTooltipOpen(false)}
      key={isMobile ? 'mobile' : 'desktop'}
      PopperProps={{ style: popperStyles }}
    >
      <span
        onClick={handleTooltipToggle}
        ref={isMobile ? (ref) => (tooltipRef.current = ref) : undefined}
        style={iconStyles}
      >
        <ShareIcon disabled />
      </span>
    </Tooltip>
  );
}

export default LinkToShareTooltip;
