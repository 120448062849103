import { Button, Collapse, Slide } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UserTypeEnum } from '../../../store/config/enums';
import { RootState } from '../../../store/config/types';
import Close from '../../Icons/Close';
import Hamburguer from '../../Icons/Hamburguer';
import PlexoLinks from '../../Icons/PlexoLinks';
import Question from '../../Icons/Question';
import Settings from '../../Icons/SettingsIcon';
import LogoutDialog from '../LogoutDialog';
import { getActiveTabIndex, getOptionsFromRole, settingsOptions } from './../navigationOptions';
import NavigationItem from './NavigationItem';
import ProfileNavigationItem from './ProfileNavigationItem';

interface MobileNavigationProps {
  mobileMenuOpened: boolean;
  role?: UserTypeEnum;
  setMobileMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function MobileNavigation({ mobileMenuOpened, role, setMobileMenuOpened }: MobileNavigationProps) {
  const location = useLocation();
  const options = getOptionsFromRole(role);
  const fromRoute = location.state?.from || '/payments';
  const isCollaborator = useSelector(
    (state: RootState) => state.auth.account?.type === UserTypeEnum.COLLABORATOR,
  );
  const [open, setOpen] = useState<boolean>(false);
  const [settingsExpanded, setSettingsExpanded] = useState<boolean>(false);
  const [profileExpanded, setProfileExpanded] = useState<boolean>(false);

  const activeTab = getActiveTabIndex(location.pathname, fromRoute);
  const isSettingSelected = settingsOptions.some((option) => location.pathname.includes(option.route));

  const toggleSettings = () => {
    setSettingsExpanded(!settingsExpanded);
  };

  const handleOptionSelect = (path: string) => {
    const isSettingOption = settingsOptions.some((option) => option.route === path);
    if (!isSettingOption) {
      setSettingsExpanded(false);
    }

    if (path !== '/change-password') {
      setProfileExpanded(false);
    }
  };

  return (
    <>
      {mobileMenuOpened && <div className="navigation-mobile-overlay" />}
      <nav className="navigation-mobile">
        {!mobileMenuOpened && (
          <button onClick={() => setMobileMenuOpened(true)} className="navigation-toggle">
            <Hamburguer />
          </button>
        )}
        <Slide timeout={280} direction="right" in={mobileMenuOpened}>
          <div className="navigation-container">
            <div className="navigation-header">
              <PlexoLinks />
              <button onClick={() => setMobileMenuOpened(false)}>
                <Close />
              </button>
            </div>
            <ul className="navigation-options">
              <ProfileNavigationItem
                setMobileMenuOpened={setMobileMenuOpened}
                handleOptionSelect={handleOptionSelect}
                profileExpanded={profileExpanded}
                setProfileExpanded={setProfileExpanded}
              />
              <hr className="divider" />
              {options.map((option, index) => {
                return (
                  <NavigationItem
                    key={index}
                    path={option.route}
                    secondaryPath={option.secondaryRoute}
                    setMobileMenuOpened={setMobileMenuOpened}
                    icon={option.icon}
                    title={option.name}
                    isActive={activeTab === index}
                    handleOptionSelect={handleOptionSelect}
                  />
                );
              })}
              <hr className="divider" />
              {!isCollaborator && (
                <>
                  <li>
                    <ul className={`${isSettingSelected && 'selected-setting-option'}`}>
                      <li className="option" onClick={toggleSettings}>
                        <Settings className="icon" /> Ajustes
                        <ExpandMoreIcon className={`expand-icon ${settingsExpanded ? 'expanded' : ''}`} />
                      </li>
                      <Collapse in={settingsExpanded} timeout="auto" unmountOnExit>
                        {role === UserTypeEnum.OWNER &&
                          settingsOptions.map((option, index) => {
                            return (
                              <NavigationItem
                                key={index}
                                path={option.route}
                                setMobileMenuOpened={setMobileMenuOpened}
                                title={option.name}
                                className="sub-option"
                                handleOptionSelect={handleOptionSelect}
                              />
                            );
                          })}
                      </Collapse>
                    </ul>
                  </li>
                  <hr className="divider" />
                </>
              )}
              <NavigationItem
                path="/user-faqs"
                setMobileMenuOpened={setMobileMenuOpened}
                icon={<Question className="icon" />}
                title="FAQs"
                handleOptionSelect={handleOptionSelect}
              />
            </ul>
            <div className="logout-button-container">
              <Button
                variant="outlined"
                fullWidth
                color="secondary"
                onClick={() => setOpen(true)}
                className="logout-button"
              >
                Cerrar sesión
              </Button>
            </div>
          </div>
        </Slide>
        <LogoutDialog open={open} setOpen={setOpen} />
      </nav>
    </>
  );
}

export default MobileNavigation;
