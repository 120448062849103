import {
  Accordion,
  AccordionDetails,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useState } from 'react';
import styles from '../../styles/_variables.module.scss';
import ColoredCardsWithTotals from './ColoredCardsWithTotals';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      backgroundColor: 'transparent',
      '&:before': {
        display: 'none',
      },
    },
    accordionSummary: {
      padding: '0',
    },
    accordionDetails: {
      flexDirection: 'row',
      padding: '0',
      width: '100%',
      gap: '1rem',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    collapseButton: {
      display: 'flex',
    },
    paddingRight: {
      paddingRight: '0.6em',
      fontSize: '0.75rem',
      fontWeight: Number(styles.normalTextWeight),
    },
    coloredCard: {
      marginBottom: '1rem',
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      alignItems: 'flex-start',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    secondDivider: {
      margin: '1rem 0',
    },
  }),
);

const AccordionSummary = withStyles({
  content: {
    display: 'block',
  },
})(MuiAccordionSummary);

function TotalsAccordion() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>('totals');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      square
      expanded={expanded === 'totals'}
      onChange={handleChange('totals')}
      elevation={0}
      className={classes.accordion}
    >
      <AccordionSummary className={classes.accordionSummary}>
        <div className="flex-row">
          <Typography variant="h6">Historial de pagos</Typography>
          <div className={classes.collapseButton}>
            <Typography variant="subtitle2" className={classes.paddingRight}>
              {expanded === 'totals' ? 'Colapsar' : 'Ampliar'}
            </Typography>
            {expanded === 'totals' ? (
              <RemoveIcon fontSize="small" color="action" />
            ) : (
              <AddIcon fontSize="small" color="action" />
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <ColoredCardsWithTotals />
      </AccordionDetails>
    </Accordion>
  );
}

export default TotalsAccordion;
