import { Card, useMediaQuery } from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { Business, LinkIssuer, LinkToPay } from '../../../store/config/types';
import { IssuersCardList } from '../../IssuersCardList';
import { LinkDetailCard } from '../LinkDetailCard';
import { FloatingButtons } from '../MobileNavigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0 auto',
      gap: '3rem',
      width: '70%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
        gap: '1rem',
      },
    },
    cardRight: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      flexGrow: 1,
      padding: '2.5625rem',
      height: 'max-content',
      marginBottom: '9.375rem',
    },
  }),
);
interface PermanentLinkLandingProps {
  linkToPay: LinkToPay;
  business: Business | null;
  step: number;
  setStep: (step: number) => void;
  selectedIssuer: LinkIssuer | null;
  setSelectedIssuer: (issuer: LinkIssuer) => void;
}

function PermanentLinkLanding({
  linkToPay,
  business,
  step,
  setStep,
  selectedIssuer,
  setSelectedIssuer,
}: PermanentLinkLandingProps) {
  const classes = useStyles();
  const theme = useTheme();
  const linkIssuers = linkToPay.commercesDtos;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (linkIssuers && linkIssuers.length === 1) {
      setSelectedIssuer(linkIssuers[0]);
    }
  }, [linkIssuers, setSelectedIssuer]);

  return (
    <div className={classes.container}>
      <LinkDetailCard linkToPay={linkToPay} business={business} />
      <Card className={classes.cardRight}>
        {linkIssuers && (
          <IssuersCardList
            setStep={setStep}
            issuers={linkIssuers}
            selectedIssuer={selectedIssuer}
            disabled={false}
            clickAction={(issuer) => {
              setSelectedIssuer(issuer);
            }}
            hasButton={!isMobile}
          />
        )}
      </Card>
      {isMobile && (
        <FloatingButtons
          step={step}
          setStep={setStep}
          selectedIssuer={selectedIssuer}
          amount={linkToPay.amount.value}
          currency={linkToPay.amount.currency}
          vatRate={linkToPay.vatRate}
        />
      )}
    </div>
  );
}

export default PermanentLinkLanding;
