import colors from '../styles/_variables.module.scss';

interface MultiUseLinkIconProps {
  color?: string;
  transparent?: boolean;
}

function MultiUseLinkIcon({ color = colors.plexoBlue, transparent = false }: MultiUseLinkIconProps) {
  return (
    <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 14C0 6.26802 6.26801 0 14 0H16C23.732 0 30 6.26802 30 14V14.6364C30 22.3683 23.732 28.6364 16 28.6364H14C6.26801 28.6364 0 22.3683 0 14.6364V14Z"
        fill={color}
        fill-opacity={transparent ? `0` : `0.1`}
      />
      <path
        d="M22.4968 16.2594L21.2518 14.1184C21.1916 14.0118 21.1107 13.9182 21.0139 13.8432C20.9171 13.7681 20.8064 13.7131 20.6881 13.6812C20.5699 13.6494 20.4465 13.6414 20.3251 13.6578C20.2037 13.6741 20.0868 13.7144 19.9812 13.7764C19.8755 13.8384 19.7833 13.9208 19.7098 14.0188C19.6364 14.1167 19.5831 14.2283 19.5532 14.3471C19.5233 14.4659 19.5173 14.5894 19.5356 14.7105C19.5539 14.8316 19.5961 14.9478 19.6598 15.0524L20.9048 17.1934C20.9788 17.3216 21.0178 17.4671 21.0176 17.6151C21.0175 17.7632 20.9783 17.9086 20.9041 18.0366C20.8299 18.1647 20.7232 18.271 20.5948 18.3447C20.4664 18.4184 20.3209 18.4569 20.1728 18.4564H17.2808L17.8808 17.8564C18.0532 17.684 18.15 17.4502 18.15 17.2064C18.15 16.9626 18.0532 16.7288 17.8808 16.5564C17.7084 16.384 17.4746 16.2872 17.2308 16.2872C16.987 16.2872 16.7532 16.384 16.5808 16.5564L14.3808 18.7164C14.291 18.8006 14.22 18.9028 14.1726 19.0164C14.1252 19.13 14.1024 19.2524 14.1058 19.3754C14.1043 19.4982 14.1279 19.62 14.1752 19.7334C14.2225 19.8467 14.2924 19.9492 14.3808 20.0344L16.5808 22.1944C16.7502 22.3664 16.9805 22.4648 17.2218 22.4684C17.4044 22.4689 17.5829 22.4152 17.735 22.3143C17.8871 22.2134 18.0059 22.0696 18.0764 21.9013C18.1469 21.7329 18.1659 21.5474 18.1311 21.3682C18.0963 21.189 18.0092 21.0242 17.8808 20.8944L17.2808 20.2944H20.1728C20.6442 20.2997 21.1084 20.1786 21.5171 19.9436C21.9259 19.7087 22.2642 19.3685 22.4968 18.9584C22.7349 18.5502 22.8603 18.086 22.8603 17.6134C22.8603 17.1408 22.7349 16.6767 22.4968 16.2684V16.2594Z"
        fill={color}
      />
      <path
        d="M12.357 18.4354H9.88604C9.738 18.4359 9.59244 18.3974 9.46405 18.3237C9.33566 18.25 9.22897 18.1437 9.15473 18.0156C9.0805 17.8876 9.04135 17.7422 9.04123 17.5941C9.04111 17.4461 9.08002 17.3006 9.15404 17.1724L10.582 14.6834L10.782 15.4884C10.8077 15.6074 10.8577 15.7199 10.9289 15.8186C11.0001 15.9174 11.091 16.0003 11.1958 16.0622C11.3006 16.1241 11.4171 16.1636 11.538 16.1783C11.6589 16.193 11.7814 16.1824 11.898 16.1474C12.1338 16.086 12.3364 15.9351 12.4628 15.7267C12.5892 15.5184 12.6295 15.269 12.575 15.0314L11.825 12.0314C11.7634 11.795 11.6125 11.5918 11.404 11.4644C11.2982 11.3998 11.1799 11.3582 11.0568 11.3424C10.9338 11.3265 10.8088 11.3368 10.69 11.3724L7.69004 12.1594C7.45214 12.2226 7.24906 12.3776 7.12548 12.5904C7.00189 12.8033 6.96792 13.0565 7.03104 13.2944C7.09416 13.5323 7.2492 13.7354 7.46205 13.859C7.67491 13.9826 7.92814 14.0165 8.16604 13.9534L9.02604 13.7334L7.56204 16.2774C7.3196 16.6776 7.19142 17.1365 7.19142 17.6044C7.19142 18.0723 7.3196 18.5312 7.56204 18.9314C7.79646 19.3401 8.13527 19.6792 8.5438 19.914C8.95232 20.1487 9.41589 20.2707 9.88704 20.2674H12.358C12.6007 20.2674 12.8334 20.171 13.005 19.9994C13.1766 19.8278 13.273 19.5951 13.273 19.3524C13.273 19.1097 13.1766 18.877 13.005 18.7054C12.8334 18.5338 12.6007 18.4374 12.358 18.4374L12.357 18.4354Z"
        fill={color}
      />
      <path
        d="M11.789 10.7674C12.0002 10.891 12.2518 10.9257 12.4886 10.864C12.7253 10.8024 12.928 10.6493 13.052 10.4384L14.315 8.26042C14.3896 8.13238 14.4964 8.02612 14.6249 7.95227C14.7533 7.87842 14.8989 7.83955 15.0471 7.83955C15.1952 7.83955 15.3408 7.87842 15.4692 7.95227C15.5977 8.02612 15.7045 8.13238 15.7791 8.26042L17.2621 10.8224L16.3831 10.6024C16.2652 10.5681 16.1416 10.5578 16.0196 10.5722C15.8977 10.5865 15.7798 10.6252 15.6731 10.686C15.5664 10.7467 15.473 10.8283 15.3984 10.9258C15.3238 11.0234 15.2696 11.1349 15.2389 11.2538C15.2082 11.3727 15.2017 11.4965 15.2198 11.618C15.2379 11.7394 15.2802 11.856 15.3442 11.9608C15.4082 12.0656 15.4926 12.1565 15.5924 12.228C15.6921 12.2996 15.8053 12.3504 15.925 12.3774L18.925 13.1464C18.9966 13.1676 19.0705 13.1801 19.145 13.1834C19.3522 13.186 19.5541 13.1181 19.7176 12.991C19.8811 12.8638 19.9966 12.6848 20.045 12.4834L20.7771 9.46342C20.8353 9.22553 20.7966 8.97424 20.6695 8.76486C20.5425 8.55547 20.3374 8.40514 20.0995 8.34692C19.8616 8.28871 19.6104 8.32738 19.401 8.45444C19.1916 8.5815 19.0413 8.78652 18.983 9.02442L18.8 9.79342L17.3721 7.34042C17.1374 6.9268 16.7965 6.58343 16.3845 6.34589C15.9725 6.10835 15.5046 5.98528 15.0291 5.98942C14.5576 5.98413 14.0934 6.10527 13.6847 6.34023C13.276 6.57519 12.9377 6.91539 12.705 7.32542L11.442 9.50342C11.3792 9.60849 11.3382 9.72521 11.3217 9.84654C11.3052 9.96787 11.3135 10.0913 11.346 10.2093C11.3785 10.3274 11.4347 10.4376 11.511 10.5333C11.5873 10.6291 11.6822 10.7084 11.7901 10.7664L11.789 10.7674Z"
        fill={color}
      />
    </svg>
  );
}

export default MultiUseLinkIcon;
