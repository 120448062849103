import {
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIssuers } from '../../store/action_creators/commerces.actions';
import { UserTypeEnum } from '../../store/config/enums';
import { PaymentsFilters, RootState } from '../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('sm')]: {
        maxHeight: '50vh',
        overflow: 'auto',
      },
    },
  }),
);

interface IssuerFiltersProps {
  filters: PaymentsFilters | null;
  setFilters: (filters: PaymentsFilters) => void;
  hasDateFilters?: boolean;
}

function IssuerFilters({ filters, setFilters, hasDateFilters }: IssuerFiltersProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { commerces, auth } = useSelector((state: RootState) => state);
  const isSuperAdmin = auth.account?.type === UserTypeEnum.SUPERADMIN;
  const issuersToSelect = isSuperAdmin ? commerces.issuers : commerces.commerces;

  useEffect(() => {
    if (!commerces.loadingIssuers && commerces.issuers === null && commerces.issuersErrorMessage === null) {
      dispatch(fetchIssuers());
    }
  }, [commerces.loadingIssuers, commerces.issuers, commerces.issuersErrorMessage, dispatch]);

  const handleIssuerChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const targetValue = String(event.target.value);

    const selectedIssuer = event.target.value ? parseInt(targetValue) : undefined;
    var filterIssuer;

    filterIssuer = selectedIssuer === filters?.issuer ? undefined : selectedIssuer;

    let newPaymentsFilters: PaymentsFilters = {
      ...filters,
      issuer: filterIssuer,
    };

    setFilters(newPaymentsFilters);
  };

  return (
    <div className="filter-container">
      <FormControl component="fieldset">
        <FormLabel component="legend" classes={{ root: 'form-label' }} focused={false}>
          Medio de pago
        </FormLabel>
        <FormGroup classes={{ root: 'group' }}>
          <Select
            displayEmpty
            input={<OutlinedInput />}
            className="select"
            onChange={handleIssuerChange}
            value={filters?.issuer || ''}
            MenuProps={{
              classes: { paper: classes.paper },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            <MenuItem disabled value="select">
              <em>Selecciona</em>
            </MenuItem>
            <MenuItem value="" className="select-element">
              Todos
            </MenuItem>
            {issuersToSelect &&
              issuersToSelect.map((issuer) => (
                <MenuItem value={issuer.issuerId} key={issuer.issuerId} className="select-element">
                  {'issuer' in issuer ? issuer.issuer : issuer.issuerName}
                </MenuItem>
              ))}
          </Select>
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default IssuerFilters;
