import { useMediaQuery } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import PaymentDetailActions from '../../components/PaymentDetail/PaymentDetailActions';
import PaymentDetailContent from '../../components/PaymentDetail/PaymentDetailContent';
import PaymentDetailHeader from '../../components/PaymentDetail/PaymentDetailHeader';
import { resendNotification, sendNotification } from '../../store/action_creators/notifications.actions';
import { cancelPayment, getPayment } from '../../store/action_creators/payments.actions';
import { RootState } from '../../store/config/types';
import { theme } from '../../styles/theme';

function PaymentDetail() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const params = useParams();
  const paymentId = params.paymentId;
  const paymentsState = useSelector((state: RootState) => state.payments);
  const businessId = useSelector((state: RootState) => state.auth.account?.business?.id);
  const payment = paymentsState.selectedPayment;

  const cancelPaymentForBusiness = (plexoReferenceId: string) => {
    if (payment) dispatch(cancelPayment(plexoReferenceId, payment.businessId));
  };

  const handleSendNotification = (paymentId: number) => {
    dispatch(sendNotification(paymentId));
  };

  const handleResendNotification = (notificationId: string) => {
    dispatch(resendNotification(notificationId));
  };

  useEffect(() => {
    if (paymentId) dispatch(getPayment(paymentId, businessId ?? undefined));
  }, [businessId, dispatch, paymentId]);

  if (paymentsState.gettingPayment) return <Loading />;

  return (
    <>
      {payment && (
        <div className="screen-container">
          <PaymentDetailHeader
            cancelPayment={cancelPaymentForBusiness}
            sendNotification={handleSendNotification}
            resendNotification={handleResendNotification}
          />
          <PaymentDetailContent />
          {isMobile && (
            <PaymentDetailActions
              cancelPayment={cancelPaymentForBusiness}
              sendNotification={handleSendNotification}
              resendNotification={handleResendNotification}
            />
          )}
        </div>
      )}
    </>
  );
}

export default PaymentDetail;
