import { Button, Menu, MenuItem, Theme, createStyles, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { urlHelper } from '../../helpers/urlHelper';
import { UserTypeEnum } from '../../store/config/enums';
import { RootState } from '../../store/config/types';
import colors from '../../styles/_variables.module.scss';
import { ChangePasswordDialog } from '../dialogs/ChangePasswordDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      '&:hover': {
        color: colors.secondaryMain,
        cursor: 'pointer',
        userSelect: 'none',
      },
    },
    selected: {
      color: colors.secondaryMain,
    },
    menu: {
      marginTop: 32,
      '& .MuiMenuItem-root': {
        color: colors.slateBlue,
        margin: '0.5rem 0',
        justifyContent: 'center',
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: colors.borderButtonSecondary,
        },
      },
    },
    logoutMenuItem: {
      '&.MuiMenuItem-root': {
        '&:hover': {
          backgroundColor: colors.white,
        },
      },
    },
  }),
);

interface UserMenuProps {
  setOpen(value: boolean): void;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & UserMenuProps;

function UserMenu({ auth, setOpen }: PropsType) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState<boolean>(false);

  const isFAQsPage = location.pathname === '/faqs' || location.pathname === '/user-faqs';
  const isRegisterStep = urlHelper.isRegisterStep(location.pathname);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOption = (option: string) => {
    if (option === 'logout') {
      setOpen(true);
    } else if (option === '/user-faqs') {
      window.open('/user-faqs', '_blank');
    } else {
      navigate(option);
    }
    setAnchorEl(null);
  };

  return (
    <div className="user-menu">
      <span className="name">{auth && auth.account && auth.account.names}</span>
      <button onClick={handleClick} className="user-menu">
        <ExpandMoreIcon className={`${classes.icon} ${anchorEl !== null ? classes.selected : null}`} />
      </button>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        className={classes.menu}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {auth.account?.type === UserTypeEnum.OWNER && (
          <>
            {!isRegisterStep && <MenuItem onClick={() => selectOption('/profile')}>Editar perfil</MenuItem>}
            {!isFAQsPage && <MenuItem onClick={() => selectOption('/user-faqs')}>FAQs</MenuItem>}
          </>
        )}
        {auth.account?.type === UserTypeEnum.COLLABORATOR && auth.account?.business?.id && (
          <MenuItem onClick={() => selectOption('/change-password')}>Modificar contraseña</MenuItem>
        )}
        <MenuItem className={classes.logoutMenuItem}>
          <Button variant="outlined" fullWidth color="secondary" onClick={() => selectOption('logout')}>
            Cerrar sesión
          </Button>
        </MenuItem>
      </Menu>
      <ChangePasswordDialog open={passwordDialogOpen} setOpen={setPasswordDialogOpen} />
    </div>
  );
}

export default connector(UserMenu);
