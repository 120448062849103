import {
  Button,
  CircularProgress,
  Divider,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Link } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { theme } from '../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: '0 0.8rem',
      backgroundColor: styles.slateBlue,
      opacity: '0.2',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 'max-content',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        width: '100%',
        margin: '1.5rem 0 0.5rem 0',
      },
    },
    primaryButton: {
      marginLeft: '0.8rem',
      '& span': {
        '& a': {
          color: styles.white,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    secondaryButton: {
      minWidth: '10.625rem',
      color: styles.textButtonSecondary,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0.5rem 0',
      },
    },
  }),
);

interface LinkActionsProps {
  setClickedCopy: React.Dispatch<React.SetStateAction<boolean | null>>;
  link: Link;
  updatingLink: boolean;
  creatingLink: boolean;
  clickedCopy: boolean | null;
}

function LinkActions({ setClickedCopy, link, creatingLink, updatingLink, clickedCopy }: LinkActionsProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.buttonsContainer}>
      {!isMobile && (
        <>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(-1)}
            className={classes.secondaryButton}
          >
            Volver
          </Button>
          <Divider className={classes.divider} orientation="vertical" flexItem />{' '}
        </>
      )}
      <Button
        type="submit"
        variant="outlined"
        color="secondary"
        onClick={() => setClickedCopy(true)}
        disabled={(creatingLink! || updatingLink!) && clickedCopy!}
        className={classes.secondaryButton}
      >
        {(creatingLink || updatingLink) && clickedCopy ? (
          <CircularProgress size={28} color="inherit" />
        ) : link ? (
          'Editar y copiar link'
        ) : (
          'Crear y copiar link'
        )}
      </Button>
      <Button
        className={classes.primaryButton}
        type="submit"
        variant="contained"
        color="primary"
        onClick={() => setClickedCopy(false)}
        disabled={(creatingLink || updatingLink) && !clickedCopy}
      >
        {(creatingLink || updatingLink) && !clickedCopy ? (
          <CircularProgress size={26} color="inherit" />
        ) : link ? (
          'Guardar'
        ) : (
          'Crear link'
        )}
      </Button>
    </div>
  );
}

export default LinkActions;
