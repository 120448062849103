function Hamburguer() {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 1.80859H18.5" stroke="#00B2B8" stroke-width="2" stroke-linecap="round" />
      <path d="M1.5 9H18.5" stroke="#00B2B8" stroke-width="2" stroke-linecap="round" />
      <path d="M1.5 16.8086H18.5" stroke="#00B2B8" stroke-width="2" stroke-linecap="round" />
    </svg>
  );
}

export default Hamburguer;
