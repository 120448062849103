import { filteringHelper } from '../helpers/filteringHelper';
import {
  BaseResponse,
  BeginPaymentRequest,
  BeginPaymentResponse,
  ConfirmPaymentResponse,
  FetchPaymentRequest,
  FetchPaymentsRequest,
  FinalizePaymentRequest,
  Payment,
  PaymentResponse,
} from '../store/config/types';
import { get, post } from './base.service';
import { endpoints } from './endpoints';

export const paymentsService = {
  fetchPayments,
  beginPayment,
  getPayment,
  confirmPayment,
  cancelPayment,
};

async function fetchPayments(
  request: FetchPaymentsRequest,
  businessId?: number,
): Promise<PaymentResponse | undefined> {
  const filterString = filteringHelper.getFiltersForPayments(request);
  return await get<PaymentResponse>(
    `${endpoints['payments']}?${businessId && businessId > 0 ? `businessId=${businessId}&` : ''}page=${
      request.page
    }&limit=${request.limit}&filter=${request.term ?? ''}${filterString}`,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function beginPayment(
  beginPaymentRequest: BeginPaymentRequest,
): Promise<BeginPaymentResponse | undefined> {
  return await post<BeginPaymentResponse>(`${endpoints['beginPayment']}`, beginPaymentRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function getPayment({ paymentId, businessId }: FetchPaymentRequest): Promise<Payment | undefined> {
  const additionalHeaders: Record<string, string> = {};

  if (businessId) {
    additionalHeaders.businessId = businessId.toString();
  }

  return await get<Payment>(`${endpoints['payments']}/${paymentId}`, {}, additionalHeaders)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function confirmPayment(
  finalizePaymentRequest: FinalizePaymentRequest,
): Promise<ConfirmPaymentResponse | undefined> {
  return await post<ConfirmPaymentResponse>(endpoints['payments'], finalizePaymentRequest)
    .then((response) => {
      return response.parsedBody!;
    })
    .catch((error) => {
      throw error;
    });
}

async function cancelPayment(plexoReferenceId: string, businessId: number): Promise<BaseResponse> {
  return await post<BaseResponse>(`${endpoints['payments']}/cancel`, { plexoReferenceId, businessId })
    .then((response) => {
      return response.parsedBody!;
    })
    .catch((error) => {
      throw error;
    });
}
