import { IconProps } from '../../../data/icon.type';
import colors from '../../../styles/_variables.module.scss';

function ChevronLeft({ color, className }: IconProps) {
  color = color ?? colors.slateBlue;
  className = className ?? '';

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21 12.141L15 18.141L21 24.141"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChevronLeft;
