import {
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinesses } from '../../store/action_creators/business.actions';
import { toggleResetClientFilter } from '../../store/action_creators/payments.actions';
import { fetchAllUsers } from './../../store/action_creators/collaborators.actions';
import { Business, BusinessState, RootState } from './../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('sm')]: {
        maxHeight: '50vh',
        overflow: 'auto',
      },
    },
  }),
);
interface ClientsFilterProps {
  filterByBusiness(business: Business | null): void;
  resetTrigger?: boolean;
  setResetTrigger?(value: boolean): void;
}

function ClientsFilter({ filterByBusiness, resetTrigger, setResetTrigger }: ClientsFilterProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const business: BusinessState = useSelector((state: RootState) => state.business);
  const resetClientFilter = useSelector((state: RootState) => state.payments.resetClientFilter);
  const [selectedBusiness, setSelectedBusiness] = useState<string>('');

  useEffect(() => {
    if (
      !business.loadingBusinesses &&
      business.businesses === null &&
      business.businessesErrorMessage === null
    ) {
      dispatch(fetchBusinesses());
    }
  }, [business.loadingBusinesses, business.businesses, business.businessesErrorMessage, dispatch]);

  useEffect(() => {
    if (resetClientFilter) {
      handleClientChange(null);
      dispatch(toggleResetClientFilter());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetClientFilter]);

  const handleClientChange = async (business: Business | null) => {
    setSelectedBusiness(business ? business.clientId.toString() : '');
    dispatch(fetchAllUsers(business?.id ?? 0));
    filterByBusiness(business ?? null);
  };

  return (
    <div className="filter-container">
      <FormControl component="fieldset">
        <FormLabel component="legend" classes={{ root: 'form-label' }} focused={false}>
          Empresa
        </FormLabel>
        <FormGroup classes={{ root: 'group' }}>
          <Select
            displayEmpty
            input={<OutlinedInput />}
            className="select"
            defaultValue=""
            MenuProps={{
              classes: { paper: classes.paper },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            value={selectedBusiness}
          >
            <MenuItem value="" className="select-element" onClick={() => handleClientChange(null)}>
              Todas
            </MenuItem>
            {business.businesses &&
              business.businesses.map((business) => {
                return (
                  <MenuItem
                    value={business.clientId}
                    key={business.urlName}
                    onClick={() => handleClientChange(business)}
                    className="select-element"
                  >
                    {business.prettyName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default ClientsFilter;
