import { LinkTypeEnum } from './enums';
import {
  BeginPaymentResponse,
  Business,
  BusinessIssuer,
  Client,
  Collaborator,
  CollaboratorResponse,
  Commerce,
  ConfirmPaymentResponse,
  ConfirmResetPasswordSuccessResponse,
  Issuer,
  Link,
  LinkDataResponse,
  LinkToPay,
  LinksResponse,
  NotificationsResponse,
  Payment,
  PaymentResponse,
  PaymentsFilters,
  ToggleBillingRequest,
  TransactionStatus,
  UnconfirmedUsersResponse,
  User,
  UserCredentials,
} from './types';

//#region Auth actions

export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export interface LoggingInAction {
  type: typeof LOGGING_IN;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  account: User;
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR;
  error: string;
}

export type LoginActionTypes = LoggingInAction | LoginSuccessAction | LoginErrorAction;

export const LOGOUT = 'LOGOUT';

export interface LogoutAction {
  type: typeof LOGOUT;
}

export type LogoutActionTypes = LogoutAction;

export const RESETING_PASSWORD = 'RESETING_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export interface ResetingPassword {
  type: typeof RESETING_PASSWORD;
}
export interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
}
export interface ResetPasswordError {
  type: typeof RESET_PASSWORD_ERROR;
  error: string;
}

export type ResetPasswordActionTypes = ResetingPassword | ResetPasswordSuccess | ResetPasswordError;

export const CONFIRMING_RESET_PASSWORD = 'CONFIRMING_RESET_PASSWORD';
export const CONFIRM_RESET_PASSWORD_SUCCESS = 'CONFIRM_RESET_PASSWORD_SUCCESS';
export const CONFIRM_RESET_PASSWORD_ERROR = 'CONFIRM_RESET_PASSWORD_ERROR';

export interface ConfirmingResetPassword {
  type: typeof CONFIRMING_RESET_PASSWORD;
}
export interface ConfirmResetPasswordSuccess {
  type: typeof CONFIRM_RESET_PASSWORD_SUCCESS;
  response: ConfirmResetPasswordSuccessResponse;
}
export interface ConfirmResetPasswordError {
  type: typeof CONFIRM_RESET_PASSWORD_ERROR;
  error: string;
}

export type ConfirmResetPasswordActionTypes =
  | ConfirmingResetPassword
  | ConfirmResetPasswordSuccess
  | ConfirmResetPasswordError;

export const REGISTERING_USER = 'REGISTERING_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export interface RegisteringUserAction {
  type: typeof REGISTERING_USER;
}

export interface RegisterUserSuccessAction {
  type: typeof REGISTER_USER_SUCCESS;
  user: UserCredentials;
}

export interface RegisterUserErrorAction {
  type: typeof REGISTER_USER_ERROR;
  error: string;
}

export type RegisterUserActionTypes =
  | RegisteringUserAction
  | RegisterUserSuccessAction
  | RegisterUserErrorAction;

export const VALIDATING_REGISTER = 'VALIDATING_REGISTER';
export const VALIDATE_REGISTER_SUCCESS = 'VALIDATE_REGISTER_SUCCESS';
export const VALIDATE_REGISTER_ERROR = 'VALIDATE_REGISTER_ERROR';

export interface ValidatingRegister {
  type: typeof VALIDATING_REGISTER;
}

export interface ValidateRegisterSuccess {
  type: typeof VALIDATE_REGISTER_SUCCESS;
}

export interface ValidateRegisterError {
  type: typeof VALIDATE_REGISTER_ERROR;
  error: string;
}

export type ValidateRegisterActionTypes =
  | ValidatingRegister
  | ValidateRegisterSuccess
  | ValidateRegisterError;

export const REGISTERING_BUSINESS = 'REGISTERING_BUSINESS';
export const REGISTER_BUSINESS_SUCCESS = 'REGISTER_BUSINESS_SUCCESS';
export const REGISTER_BUSINESS_ERROR = 'REGISTER_BUSINESS_ERROR';

export interface RegisteringBusinessAction {
  type: typeof REGISTERING_BUSINESS;
}

export interface RegisterBusinessSuccessAction {
  type: typeof REGISTER_BUSINESS_SUCCESS;
  business: Business;
}

export interface RegisterBusinessErrorAction {
  type: typeof REGISTER_BUSINESS_ERROR;
  error: string;
}

export type RegisterBusinessActionTypes =
  | RegisteringBusinessAction
  | RegisterBusinessSuccessAction
  | RegisterBusinessErrorAction;

export const GETTING_ACCOUNT = 'GETTING_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export interface GettingAccount {
  type: typeof GETTING_ACCOUNT;
}

export interface GetAccountSuccess {
  type: typeof GET_ACCOUNT_SUCCESS;
  account: User;
}

export interface GetAccountError {
  type: typeof GET_ACCOUNT_ERROR;
  error: string;
}

export type GetAccountActionTypes = GettingAccount | GetAccountSuccess | GetAccountError;

export const UPDATING_ACCOUNT = 'UPDATING_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';

export interface UpdatingAccount {
  type: typeof UPDATING_ACCOUNT;
}

export interface UpdateAccountSuccess {
  type: typeof UPDATE_ACCOUNT_SUCCESS;
  account: User;
}

export interface UpdateAccountError {
  type: typeof UPDATE_ACCOUNT_ERROR;
  error: string;
}

export type UpdateAccountActionTypes = UpdatingAccount | UpdateAccountSuccess | UpdateAccountError;

export const CHANGING_CREDENTIALS = 'CHANGING_CREDENTIALS';
export const CHANGE_CREDENTIALS_SUCCESS = 'CHANGE_CREDENTIALS_SUCCESS';
export const CHANGE_CREDENTIALS_ERROR = 'CHANGE_CREDENTIALS_ERROR';

export interface ChangingCredentials {
  type: typeof CHANGING_CREDENTIALS;
}

export interface ChangeCredentialsSuccess {
  type: typeof CHANGE_CREDENTIALS_SUCCESS;
}

export interface ChangeCredentialsError {
  type: typeof CHANGE_CREDENTIALS_ERROR;
  error: string;
}

export type ChangeCredentialsActionTypes =
  | ChangingCredentials
  | ChangeCredentialsSuccess
  | ChangeCredentialsError;

export const CHANGING_USER_PASSWORD = 'CHANGING_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_ERROR = 'CHANGE_USER_PASSWORD_ERROR';

export interface ChangingUserPassword {
  type: typeof CHANGING_USER_PASSWORD;
}

export interface ChangeUserPasswordSuccess {
  type: typeof CHANGE_USER_PASSWORD_SUCCESS;
}

export interface ChangeUserPasswordError {
  type: typeof CHANGE_USER_PASSWORD_ERROR;
  error: string;
}

export type ChangeUserPasswordActionTypes =
  | ChangingUserPassword
  | ChangeUserPasswordSuccess
  | ChangeUserPasswordError;

export const GETTING_UNCONFIRMED_USERS = 'GETTING_UNCONFIRMED_USERS';
export const GET_UNCONFIRMED_USERS_SUCCESS = 'GET_UNCONFIRMED_USERS_SUCCESS';
export const GET_UNCONFIRMED_USERS_ERROR = 'GET_UNCONFIRMED_USERS_ERROR';

export interface GettingUnconfirmedUsers {
  type: typeof GETTING_UNCONFIRMED_USERS;
}

export interface GetUnconfirmedUsersSuccess {
  type: typeof GET_UNCONFIRMED_USERS_SUCCESS;
  users: UnconfirmedUsersResponse;
}

export interface GetUnconfirmedUsersError {
  type: typeof GET_UNCONFIRMED_USERS_ERROR;
  error: string;
}

export type GetUnconfirmedUsersActionTypes =
  | GettingUnconfirmedUsers
  | GetUnconfirmedUsersSuccess
  | GetUnconfirmedUsersError;

export const VERIFYING_USER = 'VERIFYING_USER';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_ERROR = 'VERIFY_USER_ERROR';

export interface VerifyingUser {
  type: typeof VERIFYING_USER;
}

export interface VerifyUserSuccess {
  type: typeof VERIFY_USER_SUCCESS;
  verification: string;
}

export interface VerifyUserError {
  type: typeof VERIFY_USER_ERROR;
  error: string;
}

export type VerifyUserActionTypes = VerifyingUser | VerifyUserSuccess | VerifyUserError;

export const RESENDING_CONFIRMATION = 'RESENDING_CONFIRMATION';
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS';
export const RESEND_CONFIRMATION_FAILED = 'RESEND_CONFIRMATION_FAILED';

export interface ResendingConfirmationAction {
  type: typeof RESENDING_CONFIRMATION;
}

export interface ResendConfirmationSuccess {
  type: typeof RESEND_CONFIRMATION_SUCCESS;
  confirmation: string;
}

export interface ResendConfirmationFailedAction {
  type: typeof RESEND_CONFIRMATION_FAILED;
  error: string;
}

export type ResendConfirmationTypes =
  | ResendingConfirmationAction
  | ResendConfirmationSuccess
  | ResendConfirmationFailedAction;

//#endregion

export type AuthDataTypes =
  | LoginActionTypes
  | LogoutActionTypes
  | ResetPasswordActionTypes
  | ConfirmResetPasswordActionTypes
  | RegisterUserActionTypes
  | ValidateRegisterActionTypes
  | RegisterBusinessActionTypes
  | GetAccountActionTypes
  | UpdateAccountActionTypes
  | ChangeCredentialsActionTypes
  | ChangeUserPasswordActionTypes
  | ResendConfirmationTypes
  | VerifyUserActionTypes
  | GetUnconfirmedUsersActionTypes;

//#endregion

//#region Clients

export const FETCHING_CLIENTS = 'FETCHING_CLIENTS';
export const ADD_CLIENTS = 'ADD_CLIENTS';
export const FETCH_CLIENTS_FAILED = 'FETCH_CLIENTS_FAILED';

export interface FetchingClientsAction {
  type: typeof FETCHING_CLIENTS;
}

export interface AddClientsAction {
  type: typeof ADD_CLIENTS;
  clients: Client[];
}

export interface FetchClientsailedAction {
  type: typeof FETCH_CLIENTS_FAILED;
  error: string;
}

export type FetchClientsTypes = FetchingClientsAction | AddClientsAction | FetchClientsailedAction;

export const TOGGLING_CLIENT_BILLING = 'TOGGLING_CLIENT_BILLING';
export const TOGGLE_CLIENT_BILLING_SUCCESS = 'TOGGLE_CLIENT_BILLING_SUCCESS';
export const TOGGLE_CLIENT_BILLING_FAILED = 'TOGGLE_CLIENT_BILLING_FAILED';

export interface TogglingClientBillingAction {
  type: typeof TOGGLING_CLIENT_BILLING;
}

export interface ToggleClientBillingSuccessAction {
  type: typeof TOGGLE_CLIENT_BILLING_SUCCESS;
  request: ToggleBillingRequest;
}

export interface ToggleClientBillingFailedAction {
  type: typeof TOGGLE_CLIENT_BILLING_FAILED;
  error: string;
}

export type ToggleClientBillingTypes =
  | TogglingClientBillingAction
  | ToggleClientBillingSuccessAction
  | ToggleClientBillingFailedAction;

export type ClientDataTypes = FetchClientsTypes | ToggleClientBillingTypes;

//#endregion

//#region Business

export const FETCHING_BUSINESSES = 'FETCHING_BUSINESSES';
export const ADD_BUSINESSES = 'ADD_BUSINESSES';
export const FETCH_BUSINESSES_FAILED = 'FETCH_BUSINESSES_FAILED';

export interface FetchingBusinessesAction {
  type: typeof FETCHING_BUSINESSES;
}

export interface AddBusinessesAction {
  type: typeof ADD_BUSINESSES;
  businesses: Business[];
}

export interface FetchBusinessesFailedAction {
  type: typeof FETCH_BUSINESSES_FAILED;
  error: string;
}

export type FetchBusinessesTypes =
  | FetchingBusinessesAction
  | AddBusinessesAction
  | FetchBusinessesFailedAction;

export const CREATING_BUSINESS = 'CREATING_BUSINESS';
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS';
export const CREATE_BUSINESS_FAILED = 'CREATE_BUSINESS_FAILED';

export interface CreatingBusinessAction {
  type: typeof CREATING_BUSINESS;
}

export interface CreateBusinessSuccessAction {
  type: typeof CREATE_BUSINESS_SUCCESS;
  business: Business;
}

export interface CreateBusinessFailedAction {
  type: typeof CREATE_BUSINESS_FAILED;
  error: string;
}

export type CreateBusinessTypes =
  | CreatingBusinessAction
  | CreateBusinessSuccessAction
  | CreateBusinessFailedAction;

export const UPDATING_BUSINESS = 'UPDATING_BUSINESS';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_FAILED = 'UPDATE_BUSINESS_FAILED';

export interface UpdatingBusinessAction {
  type: typeof UPDATING_BUSINESS;
}

export interface UpdateBusinessSuccessAction {
  type: typeof UPDATE_BUSINESS_SUCCESS;
  business: Business;
}

export interface UpdateBusinessFailedAction {
  type: typeof UPDATE_BUSINESS_FAILED;
  error: string;
}

export type UpdateBusinessTypes =
  | UpdatingBusinessAction
  | UpdateBusinessSuccessAction
  | UpdateBusinessFailedAction;

export const GETTING_BUSINESS = 'GETTING_BUSINESS';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_FAILED = 'GET_BUSINESS_FAILED';

export interface GettingBusinessAction {
  type: typeof GETTING_BUSINESS;
}

export interface GetBusinessSuccessAction {
  type: typeof GET_BUSINESS_SUCCESS;
  business: Business;
}

export interface GetBusinessFailedAction {
  type: typeof GET_BUSINESS_FAILED;
  error: string;
}

export type GetBusinessTypes = GettingBusinessAction | GetBusinessSuccessAction | GetBusinessFailedAction;

export const UPDATING_SECRET = 'UPDATING_SECRET';
export const UPDATE_SECRET_SUCCESS = 'UPDATE_SECRET_SUCCESS';
export const UPDATE_SECRET_FAILED = 'UPDATE_SECRET_FAILED';

export interface UpdatingSecretAction {
  type: typeof UPDATING_SECRET;
}

export interface UpdateSecretSuccessAction {
  type: typeof UPDATE_SECRET_SUCCESS;
}

export interface UpdateSecretFailedAction {
  type: typeof UPDATE_SECRET_FAILED;
  error: string;
}

export type UpdateSecretTypes = UpdatingSecretAction | UpdateSecretSuccessAction | UpdateSecretFailedAction;

export type BusinessDataTypes =
  | FetchBusinessesTypes
  | CreateBusinessTypes
  | UpdateBusinessTypes
  | GetBusinessTypes
  | UpdateSecretTypes;

//#endregion

//#region Collaborators

export const FETCHING_COLLABORATORS = 'FETCHING_COLLABORATORS';
export const ADD_COLLABORATORS = 'ADD_COLLABORATORS';
export const FETCH_COLLABORATORS_FAILED = 'FETCH_COLLABORATORS_FAILED';

export interface FetchingCollaboratorsAction {
  type: typeof FETCHING_COLLABORATORS;
}

export interface AddCollaboratorsAction {
  type: typeof ADD_COLLABORATORS;
  collaboratorsResponse: Collaborator[] | CollaboratorResponse;
}

export interface FetchCollaboratorsFailedAction {
  type: typeof FETCH_COLLABORATORS_FAILED;
  error: string;
}

export type FetchCollaboratorsTypes =
  | FetchingCollaboratorsAction
  | AddCollaboratorsAction
  | FetchCollaboratorsFailedAction;

export const CREATING_COLLABORATOR = 'CREATING_COLLABORATOR';
export const CREATE_COLLABORATOR_SUCCESS = 'CREATE_COLLABORATOR_SUCCESS';
export const CREATE_COLLABORATOR_FAILED = 'CREATE_COLLABORATOR_FAILED';

export interface CreatingCollaboratorAction {
  type: typeof CREATING_COLLABORATOR;
}

export interface CreateCollaboratorSuccessAction {
  type: typeof CREATE_COLLABORATOR_SUCCESS;
  collaborator: Collaborator;
}

export interface CreateCollaboratorFailedAction {
  type: typeof CREATE_COLLABORATOR_FAILED;
  error: string;
}

export type CreateCollaboratorTypes =
  | CreatingCollaboratorAction
  | CreateCollaboratorSuccessAction
  | CreateCollaboratorFailedAction;

export const UPDATING_COLLABORATOR = 'UPDATING_COLLABORATOR';
export const UPDATE_COLLABORATOR_SUCCESS = 'UPDATE_COLLABORATOR_SUCCESS';
export const UPDATE_COLLABORATOR_FAILED = 'UPDATE_COLLABORATOR_FAILED';

export interface UpdatingCollaboratorAction {
  type: typeof UPDATING_COLLABORATOR;
}

export interface UpdateCollaboratorSuccessAction {
  type: typeof UPDATE_COLLABORATOR_SUCCESS;
  collaborator: Collaborator;
}

export interface UpdateCollaboratorFailedAction {
  type: typeof UPDATE_COLLABORATOR_FAILED;
  error: string;
}

export type UpdateCollaboratorTypes =
  | UpdatingCollaboratorAction
  | UpdateCollaboratorSuccessAction
  | UpdateCollaboratorFailedAction;

export const DELETING_COLLABORATOR = 'DELETING_COLLABORATOR';
export const DELETE_COLLABORATOR_SUCCESS = 'DELETE_COLLABORATOR_SUCCESS';
export const DELETE_COLLABORATOR_FAILED = 'DELETE_COLLABORATOR_FAILED';

export interface DeletingCollaboratorAction {
  type: typeof DELETING_COLLABORATOR;
}

export interface DeleteCollaboratorSuccessAction {
  type: typeof DELETE_COLLABORATOR_SUCCESS;
  collaboratorId: string;
}

export interface DeleteCollaboratorFailedAction {
  type: typeof DELETE_COLLABORATOR_FAILED;
  error: string;
}

export type DeleteCollaboratorTypes =
  | DeletingCollaboratorAction
  | DeleteCollaboratorSuccessAction
  | DeleteCollaboratorFailedAction;

export const ENABLING_COLLABORATOR = 'ENABLING_COLLABORATOR';
export const ENABLE_COLLABORATOR_SUCCESS = 'ENABLE_COLLABORATOR_SUCCESS';
export const ENABLE_COLLABORATOR_FAILED = 'ENABLE_COLLABORATOR_FAILED';

export interface EnablingCollaboratorAction {
  type: typeof ENABLING_COLLABORATOR;
}

export interface EnableCollaboratorSuccessAction {
  type: typeof ENABLE_COLLABORATOR_SUCCESS;
  userId: string;
}

export interface EnableCollaboratorFailedAction {
  type: typeof ENABLE_COLLABORATOR_FAILED;
  error: string;
}

export type EnableCollaboratorTypes =
  | EnablingCollaboratorAction
  | EnableCollaboratorSuccessAction
  | EnableCollaboratorFailedAction;

export type CollaboratorsDataTypes =
  | FetchCollaboratorsTypes
  | CreateCollaboratorTypes
  | UpdateCollaboratorTypes
  | DeleteCollaboratorTypes
  | EnableCollaboratorTypes;

//#endregion

//#region Commerces

export const FETCHING_COMMERCES = 'FETCHING_COMMERCES';
export const ADD_COMMERCES = 'ADD_COMMERCES';
export const FETCH_COMMERCES_FAILED = 'FETCH_COMMERCES_FAILED';

export interface FetchingCommercesAction {
  type: typeof FETCHING_COMMERCES;
}

export interface AddCommercesAction {
  type: typeof ADD_COMMERCES;
  commerces: Commerce[];
}

export interface FetchCommercesFailedAction {
  type: typeof FETCH_COMMERCES_FAILED;
  error: string;
}

export type FetchCommercesTypes = FetchingCommercesAction | AddCommercesAction | FetchCommercesFailedAction;

export const CREATING_COMMERCE = 'CREATING_COMMERCE';
export const CREATE_COMMERCE_SUCCESS = 'CREATE_COMMERCE_SUCCESS';
export const CREATE_COMMERCE_FAILED = 'CREATE_COMMERCE_FAILED';

export interface CreatingCommerceAction {
  type: typeof CREATING_COMMERCE;
}

export interface CreateCommerceSuccessAction {
  type: typeof CREATE_COMMERCE_SUCCESS;
  commerce: Commerce;
}

export interface CreateCommerceFailedAction {
  type: typeof CREATE_COMMERCE_FAILED;
  error: string;
}

export type CreateCommerceTypes =
  | CreatingCommerceAction
  | CreateCommerceSuccessAction
  | CreateCommerceFailedAction;

export const CREATING_BUSINESS_COMMERCE = 'CREATING_BUSINESS_COMMERCE';
export const CREATE_BUSINESS_COMMERCE_SUCCESS = 'CREATE_BUSINESS_COMMERCE_SUCCESS';
export const CREATE_BUSINESS_COMMERCE_FAILED = 'CREATE_BUSINESS_COMMERCE_FAILED';

export interface CreatingBusinessCommerceAction {
  type: typeof CREATING_BUSINESS_COMMERCE;
}

export interface CreateBusinessCommerceSuccessAction {
  type: typeof CREATE_BUSINESS_COMMERCE_SUCCESS;
  commerce: Commerce;
  businessId: number;
}

export interface CreateBusinessCommerceFailedAction {
  type: typeof CREATE_BUSINESS_COMMERCE_FAILED;
  error: string;
}

export type CreateBusinessCommerceTypes =
  | CreatingBusinessCommerceAction
  | CreateBusinessCommerceSuccessAction
  | CreateBusinessCommerceFailedAction;

export const UPDATING_COMMERCE = 'UPDATING_COMMERCE';
export const UPDATE_COMMERCE_SUCCESS = 'UPDATE_COMMERCE_SUCCESS';
export const UPDATE_COMMERCE_FAILED = 'UPDATE_COMMERCE_FAILED';

export interface UpdatingCommerceAction {
  type: typeof UPDATING_COMMERCE;
}

export interface UpdateCommerceSuccessAction {
  type: typeof UPDATE_COMMERCE_SUCCESS;
  commerce: Commerce;
}

export interface UpdateCommerceFailedAction {
  type: typeof UPDATE_COMMERCE_FAILED;
  error: string;
}

export type UpdateCommerceTypes =
  | UpdatingCommerceAction
  | UpdateCommerceSuccessAction
  | UpdateCommerceFailedAction;

export const UPDATING_BUSINESS_COMMERCE = 'UPDATING_BUSINESS_COMMERCE';
export const UPDATE_BUSINESS_COMMERCE_SUCCESS = 'UPDATE_BUSINESS_COMMERCE_SUCCESS';
export const UPDATE_BUSINESS_COMMERCE_FAILED = 'UPDATE_BUSINESS_COMMERCE_FAILED';

export interface UpdatingBusinessCommerceAction {
  type: typeof UPDATING_BUSINESS_COMMERCE;
}

export interface UpdateBusinessCommerceSuccessAction {
  type: typeof UPDATE_BUSINESS_COMMERCE_SUCCESS;
  commerce: Commerce;
  businessId: number;
}

export interface UpdateBusinessCommerceFailedAction {
  type: typeof UPDATE_BUSINESS_COMMERCE_FAILED;
  error: string;
}

export type UpdateBusinessCommerceTypes =
  | UpdatingBusinessCommerceAction
  | UpdateBusinessCommerceSuccessAction
  | UpdateBusinessCommerceFailedAction;

export const DELETING_COMMERCE = 'DELETING_COMMERCE';
export const DELETE_COMMERCE_SUCCESS = 'DELETE_COMMERCE_SUCCESS';
export const DELETE_COMMERCE_FAILED = 'DELETE_COMMERCE_FAILED';

export interface DeletingCommerceAction {
  type: typeof DELETING_COMMERCE;
}

export interface DeleteCommerceSuccessAction {
  type: typeof DELETE_COMMERCE_SUCCESS;
  commerceId: string;
  isLast: boolean;
}

export interface DeleteCommerceFailedAction {
  type: typeof DELETE_COMMERCE_FAILED;
  error: string;
}

export type DeleteCommerceTypes =
  | DeletingCommerceAction
  | DeleteCommerceSuccessAction
  | DeleteCommerceFailedAction;

export const DELETING_BUSINESS_COMMERCE = 'DELETING_BUSINESS_COMMERCE';
export const DELETE_BUSINESS_COMMERCE_SUCCESS = 'DELETE_BUSINESS_COMMERCE_SUCCESS';
export const DELETE_BUSINESS_COMMERCE_FAILED = 'DELETE_BUSINESS_COMMERCE_FAILED';

export interface DeletingBusinessCommerceAction {
  type: typeof DELETING_BUSINESS_COMMERCE;
}

export interface DeleteBusinessCommerceSuccessAction {
  type: typeof DELETE_BUSINESS_COMMERCE_SUCCESS;
  commerceId: string;
  businessId: number;
}

export interface DeleteBusinessCommerceFailedAction {
  type: typeof DELETE_BUSINESS_COMMERCE_FAILED;
  error: string;
}

export type DeleteBusinessCommerceTypes =
  | DeletingBusinessCommerceAction
  | DeleteBusinessCommerceSuccessAction
  | DeleteBusinessCommerceFailedAction;

export const FETCHING_ISSUERS = 'FETCHING_ISSUERS';
export const ADD_ISSUERS = 'ADD_ISSUERS';
export const FETCH_ISSUERS_FAILED = 'FETCH_ISSUERS_FAILED';

export interface FetchingIssuersAction {
  type: typeof FETCHING_ISSUERS;
}

export interface AddIssuersAction {
  type: typeof ADD_ISSUERS;
  issuers: Issuer[];
}

export interface FetchIssuersFailedAction {
  type: typeof FETCH_ISSUERS_FAILED;
  error: string;
}

export type FetchIssuersTypes = FetchingIssuersAction | AddIssuersAction | FetchIssuersFailedAction;

export const FETCHING_BUSINESS_ISSUERS = 'FETCHING_BUSINESS_ISSUERS';
export const ADD_BUSINESS_ISSUERS = 'ADD_BUSINESS_ISSUERS';
export const FETCH_BUSINESS_ISSUERS_FAILED = 'FETCH_BUSINESS_ISSUERS_FAILED';

export interface FetchingBusinessIssuersAction {
  type: typeof FETCHING_BUSINESS_ISSUERS;
}

export interface AddBusinessIssuersAction {
  type: typeof ADD_BUSINESS_ISSUERS;
  issuers: BusinessIssuer[];
}

export interface FetchBusinessIssuersFailedAction {
  type: typeof FETCH_BUSINESS_ISSUERS_FAILED;
  error: string;
}

export type FetchBusinessIssuersTypes =
  | FetchingBusinessIssuersAction
  | AddBusinessIssuersAction
  | FetchBusinessIssuersFailedAction;

export const SENDING_DESIRED_ISSUERS = 'SENDING_DESIRED_ISSUERS';
export const DESIRED_ISSUERS_SUCCESS = 'DESIRED_ISSUERS_SUCCESS';
export const DESIRED_ISSUERS_FAILED = 'DESIRED_ISSUERS_FAILED';

export interface SendingDesiredIssuersAction {
  type: typeof SENDING_DESIRED_ISSUERS;
}

export interface DesiredIssuersSuccessAction {
  type: typeof DESIRED_ISSUERS_SUCCESS;
}

export interface DesiredIssuersFailedAction {
  type: typeof DESIRED_ISSUERS_FAILED;
  error: string;
}

export type DesiredIssuersTypes =
  | SendingDesiredIssuersAction
  | DesiredIssuersSuccessAction
  | DesiredIssuersFailedAction;

export const FETCHING_INSTALLMENTS = 'FETCHING_INSTALLMENTS';
export const FETCH_INSTALLMENTS_SUCCESS = 'FETCH_INSTALLMENTS_SUCCESS';
export const FETCH_INSTALLMENTS_FAILED = 'FETCH_INSTALLMENTS_FAILED';

export interface FetchingInstallmentsAction {
  type: typeof FETCHING_INSTALLMENTS;
}

export interface FetchInstallmentsSuccessAction {
  type: typeof FETCH_INSTALLMENTS_SUCCESS;
  installments: number[];
}

export interface FetchInstallmentsFailedAction {
  type: typeof FETCH_INSTALLMENTS_FAILED;
  error: string;
}

export type InstallmentsTypes =
  | FetchingInstallmentsAction
  | FetchInstallmentsSuccessAction
  | FetchInstallmentsFailedAction;

export type CommercesDataTypes =
  | FetchCommercesTypes
  | CreateCommerceTypes
  | CreateBusinessCommerceTypes
  | UpdateCommerceTypes
  | UpdateBusinessCommerceTypes
  | DeleteCommerceTypes
  | DeleteBusinessCommerceTypes
  | FetchIssuersTypes
  | FetchBusinessIssuersTypes
  | DesiredIssuersTypes
  | InstallmentsTypes;

//#endregion

//#region Notifications actions

export const SENDING_NOTIFICATION = 'SENDING_NOTIFICATION';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR';

export interface SendingNotificationAction {
  type: typeof SENDING_NOTIFICATION;
}

export interface SendNotificationSuccessAction {
  type: typeof SEND_NOTIFICATION_SUCCESS;
  notification: NotificationsResponse;
}

export interface SendNotificationErrorAction {
  type: typeof SEND_NOTIFICATION_ERROR;
  error: string;
}

export type SendNotificationActionTypes =
  | SendingNotificationAction
  | SendNotificationSuccessAction
  | SendNotificationErrorAction;

export const RESENDING_NOTIFICATION = 'RESENDING_NOTIFICATION';
export const RESEND_NOTIFICATION_SUCCESS = 'RESEND_NOTIFICATION_SUCCESS';
export const RESEND_NOTIFICATION_ERROR = 'RESEND_NOTIFICATION_ERROR';

export interface ResendingNotificationAction {
  type: typeof RESENDING_NOTIFICATION;
  notificationId: string;
}

export interface ResendNotificationSuccessAction {
  type: typeof RESEND_NOTIFICATION_SUCCESS;
}

export interface ResendNotificationErrorAction {
  type: typeof RESEND_NOTIFICATION_ERROR;
  error: string;
}

export type ResendNotificationActionTypes =
  | ResendingNotificationAction
  | ResendNotificationSuccessAction
  | ResendNotificationErrorAction;

export type NotificationsDataTypes = ResendNotificationActionTypes | SendNotificationActionTypes;

//#endregion

//#region Payments actions

export const FETCHING_PAYMENTS = 'FETCHING_PAYMENTS';
export const ADD_PAYMENTS = 'ADD_PAYMENTS';
export const ADD_DASHBOARD_PAYMENTS = 'ADD_DASHBOARD_PAYMENTS';
export const FETCH_PAYMENTS_ERROR = 'FETCH_PAYMENTS_ERROR';
export const VISA_REDIRECTED = 'VISA_REDIRECTED';

export interface FetchingPayments {
  type: typeof FETCHING_PAYMENTS;
}

export interface AddPayments {
  type: typeof ADD_PAYMENTS;
  paymentResponse: PaymentResponse;
}

export interface AddDashboardPayments {
  type: typeof ADD_DASHBOARD_PAYMENTS;
  paymentResponse: PaymentResponse;
}

export interface FetchPaymentsError {
  type: typeof FETCH_PAYMENTS_ERROR;
  error: string;
}

export type FetchPaymentsTypes = FetchingPayments | AddPayments | AddDashboardPayments | FetchPaymentsError;

export const BEGINNING_PAYMENT = 'BEGINNING_PAYMENT';
export const BEGIN_PAYMENT_SUCCESS = 'BEGIN_PAYMENT_SUCCESS';
export const BEGIN_PAYMENT_ERROR = 'BEGIN_PAYMENT_ERROR';

export interface BeginningPayment {
  type: typeof BEGINNING_PAYMENT;
}

export interface BeginPaymentSuccess {
  type: typeof BEGIN_PAYMENT_SUCCESS;
  confirmPaymentResponse: BeginPaymentResponse;
  paymentUrl: string;
}

export interface BeginPaymentError {
  type: typeof BEGIN_PAYMENT_ERROR;
  error: string;
}

export type BeginPaymentTypes = BeginningPayment | BeginPaymentSuccess | BeginPaymentError;

export const GETTING_PAYMENT = 'GETTING_PAYMENT';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';

export interface GettingPayment {
  type: typeof GETTING_PAYMENT;
}

export interface GetPaymentSuccess {
  type: typeof GET_PAYMENT_SUCCESS;
  paymentResponse: Payment;
}

export interface GetPaymentError {
  type: typeof GET_PAYMENT_ERROR;
  error: string;
}

export type GetPaymentTypes = GettingPayment | GetPaymentSuccess | GetPaymentError;

export const LOAD_PAYMENT_FILTERS = 'LOAD_PAYMENT_FILTERS';

export interface LoadPaymentsFiltersAction {
  type: typeof LOAD_PAYMENT_FILTERS;
  filters: PaymentsFilters;
}

export const TOGGLE_RESET_CLIENT_FILTER = 'RESET_CLIENT_FILTER';

export interface ToggleResetClientFilter {
  type: typeof TOGGLE_RESET_CLIENT_FILTER;
}

export const CLEAR_INSTRUMENT = 'CLEAR_INSTRUMENT';

export interface ClearInstrument {
  type: typeof CLEAR_INSTRUMENT;
}

export const CANCELLING_PAYMENT = 'CANCELLING_PAYMENT';
export const CANCEL_PAYMENT_SUCCESS = 'CANCEL_PAYMENT_SUCCESS';
export const CANCEL_PAYMENT_ERROR = 'CANCEL_PAYMENT_ERROR';

export interface CancellingPayment {
  type: typeof CANCELLING_PAYMENT;
}

export interface CancelPaymentSuccess {
  type: typeof CANCEL_PAYMENT_SUCCESS;
  plexoReferenceId: string;
}

export interface CancelPaymentError {
  type: typeof CANCEL_PAYMENT_ERROR;
  error: string;
}

export type CancelPaymentTypes = CancellingPayment | CancelPaymentSuccess | CancelPaymentError;

export type ClearInstrumentTypes = ClearInstrument;

export const CONFIRMING_PAYMENT = 'CONFIRMING_PAYMENT';
export const CONFIRM_PAYMENT_SUCCESS = 'CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'CONFIRM_PAYMENT_ERROR';
export const TRANSACTION_CALLBACK = 'TRANSACTION_CALLBACK';
export const REGISTER_CALLBACK_FAILED = 'REGISTER_CALLBACK_FAILED';

export interface ConfirmingPayment {
  type: typeof CONFIRMING_PAYMENT;
}

export interface ConfirmPaymentSuccess {
  type: typeof CONFIRM_PAYMENT_SUCCESS;
  response: ConfirmPaymentResponse;
}

export interface ConfirmPaymentError {
  type: typeof CONFIRM_PAYMENT_ERROR;
  error: string;
}

export type ConfirmPaymentTypes = ConfirmingPayment | ConfirmPaymentSuccess | ConfirmPaymentError;

export type RegisterCallbackFailedType = {
  type: typeof REGISTER_CALLBACK_FAILED;
  response: boolean;
};

export type TransactionCallbackType = {
  type: typeof TRANSACTION_CALLBACK;
  response: TransactionStatus;
};

export type VisaRedirectedType = {
  type: typeof VISA_REDIRECTED;
  paymentId: string;
};

export type PaymentDataTypes =
  | FetchPaymentsTypes
  | BeginPaymentTypes
  | GetPaymentTypes
  | ClearInstrumentTypes
  | ConfirmPaymentTypes
  | LoadPaymentsFiltersAction
  | ToggleResetClientFilter
  | VisaRedirectedType
  | TransactionCallbackType
  | RegisterCallbackFailedType
  | CancelPaymentTypes;

//#endregion

//#region Links actions

export const FETCHING_LINKS = 'FETCHING_LINKS';
export const ADD_LINKS = 'ADD_LINKS';
export const ADD_DASHBOARD_LINKS = 'ADD_DASHBOARD_LINKS';
export const FETCH_LINKS_FAILED = 'FETCH_LINKS_FAILED';

export interface FetchingLinksAction {
  type: typeof FETCHING_LINKS;
  kind: LinkTypeEnum;
  reset: boolean;
}

export interface AddLinksAction {
  type: typeof ADD_LINKS;
  links: LinksResponse;
}

export interface AddDashboardLinksAction {
  type: typeof ADD_DASHBOARD_LINKS;
  links: LinksResponse;
}

export interface FetchLinksFailedAction {
  type: typeof FETCH_LINKS_FAILED;
  error: string;
}

export type FetchLinksTypes =
  | FetchingLinksAction
  | AddLinksAction
  | AddDashboardLinksAction
  | FetchLinksFailedAction;

//
export const FETCHING_ONE_USE_LINKS = 'FETCHING_ONE_USE_LINKS';
export const ADD_ONE_USE_LINKS = 'ADD_ONE_USE_LINKS';
export const FETCH_ONE_USE_LINKS_FAILED = 'FETCH_ONE_USELINKS_FAILED';
export interface FetchingOneUseLinksAction {
  type: typeof FETCHING_ONE_USE_LINKS;
  kind: LinkTypeEnum;
  reset: boolean;
}

export interface AddOneUseLinksAction {
  type: typeof ADD_ONE_USE_LINKS;
  links: LinksResponse;
}

export interface FetchOneUseLinksFailedAction {
  type: typeof FETCH_ONE_USE_LINKS_FAILED;
  error: string;
}

export type FetchOneUseLinksTypes =
  | FetchingOneUseLinksAction
  | AddOneUseLinksAction
  | FetchOneUseLinksFailedAction;
//

export const CREATING_LINK = 'CREATING_LINK';
export const CREATE_LINK_SUCCESS = 'CREATE_LINK_SUCCESS';
export const CREATE_LINK_FAILED = 'CREATE_LINK_FAILED';

export interface CreatingLinkAction {
  type: typeof CREATING_LINK;
}

export interface CreateLinkSuccessAction {
  type: typeof CREATE_LINK_SUCCESS;
  link: Link;
}

export interface CreateLinkFailedAction {
  type: typeof CREATE_LINK_FAILED;
  error: string;
}

export type CreateLinkTypes = CreatingLinkAction | CreateLinkSuccessAction | CreateLinkFailedAction;

export const UPDATING_LINK = 'UPDATING_LINK';
export const UPDATE_LINK_SUCCESS = 'UPDATE_LINK_SUCCESS';
export const UPDATE_LINK_FAILED = 'UPDATE_LINK_FAILED';

export interface UpdatingLinkAction {
  type: typeof UPDATING_LINK;
}

export interface UpdateLinkSuccessAction {
  type: typeof UPDATE_LINK_SUCCESS;
  link: Link;
}

export interface UpdateLinkFailedAction {
  type: typeof UPDATE_LINK_FAILED;
  error: string;
}

export type UpdateLinkTypes = UpdatingLinkAction | UpdateLinkSuccessAction | UpdateLinkFailedAction;

export const DELETING_LINK = 'DELETING_LINK';
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS';
export const DELETE_LINK_FAILED = 'DELETE_LINK_FAILED';

export interface DeletingLinkAction {
  type: typeof DELETING_LINK;
}

export interface DeleteLinkSuccessAction {
  type: typeof DELETE_LINK_SUCCESS;
  linkId: string;
}

export interface DeleteLinkFailedAction {
  type: typeof DELETE_LINK_FAILED;
  error: string;
}

export type DeleteLinkTypes = DeletingLinkAction | DeleteLinkSuccessAction | DeleteLinkFailedAction;

export const SHARING_LINK = 'SHARING_LINK';
export const SHARE_LINK_SUCCESS = 'SHARE_LINK_SUCCESS';
export const SHARE_LINK_FAILED = 'SHARE_LINK_FAILED';

export interface SharingLinkAction {
  type: typeof SHARING_LINK;
}

export interface ShareLinkSuccessAction {
  type: typeof SHARE_LINK_SUCCESS;
}

export interface ShareLinkFailedAction {
  type: typeof SHARE_LINK_FAILED;
  error: string;
}

export const LOAD_LINK_FILTERS = 'LOAD_LINKS_FILTERS';

export interface LoadLinksFiltersAction {
  type: typeof LOAD_LINK_FILTERS;
  filters: PaymentsFilters;
}

export const LOAD_ONE_USE_LINK_FILTERS = 'LOAD_ONE_USE_LINK_FILTERS';
export interface LoadOneUseLinksFiltersAction {
  type: typeof LOAD_ONE_USE_LINK_FILTERS;
  filters: PaymentsFilters;
}

export type ShareLinkTypes = SharingLinkAction | ShareLinkSuccessAction | ShareLinkFailedAction;

export const GETTING_LINK_DATA = 'GETTING_LINK_DATA';
export const GET_LINK_DATA_SUCCESS = 'GET_LINK_DATA_SUCCESS';
export const GET_LINK_DATA_FAILED = 'GET_LINK_DATA_FAILED';

export interface GettingLinkDataAction {
  type: typeof GETTING_LINK_DATA;
}

export interface GetLinkDataSuccessAction {
  type: typeof GET_LINK_DATA_SUCCESS;
  link: LinkDataResponse;
}

export interface GetLinkDataFailedAction {
  type: typeof GET_LINK_DATA_FAILED;
  error: string;
}

export type GetLinkDataTypes = GettingLinkDataAction | GetLinkDataSuccessAction | GetLinkDataFailedAction;

export const VALIDATING_LINK = 'VALIDATING_LINK';
export const VALIDATE_LINK_SUCCESS = 'VALIDATE_LINK_SUCCESS';
export const VALIDATE_LINK_FAILED = 'VALIDATE_LINK_FAILED';

export interface ValidatingLinkAction {
  type: typeof VALIDATING_LINK;
}

export interface ValidateLinkSuccessAction {
  type: typeof VALIDATE_LINK_SUCCESS;
  link: LinkToPay;
}

export interface ValidateLinkFailedAction {
  type: typeof VALIDATE_LINK_FAILED;
  error: string;
}

export type ValidateLinkTypes = ValidatingLinkAction | ValidateLinkSuccessAction | ValidateLinkFailedAction;

export type LinksDataTypes =
  | FetchLinksTypes
  | FetchOneUseLinksTypes
  | LoadLinksFiltersAction
  | LoadOneUseLinksFiltersAction
  | CreateLinkTypes
  | UpdateLinkTypes
  | DeleteLinkTypes
  | ShareLinkTypes
  | GetLinkDataTypes
  | ValidateLinkTypes;

//#endregion

export type AppActions =
  | AuthDataTypes
  | CollaboratorsDataTypes
  | CommercesDataTypes
  | PaymentDataTypes
  | LinksDataTypes;
