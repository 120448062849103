import { IconProps } from '../data/icon.type';
import colors from '../styles/_variables.module.scss';

interface BoltIconProps extends IconProps {
  transparent?: boolean;
}

function BoltIcon({ color = colors.plexoBlue, className, transparent = false }: BoltIconProps) {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.725 11.3974H15.425L16.64 7.68141C16.6666 7.57961 16.6697 7.47306 16.6489 7.36991C16.6281 7.26675 16.5839 7.16972 16.5198 7.08625C16.4558 7.00277 16.3734 6.93506 16.2792 6.88829C16.1849 6.84152 16.0812 6.81693 15.976 6.81641H11.853C11.686 6.81673 11.5248 6.87779 11.3995 6.98818C11.2742 7.09858 11.1933 7.25078 11.172 7.41641L10.26 14.2834C10.247 14.3805 10.2549 14.4792 10.2832 14.573C10.3115 14.6668 10.3595 14.7535 10.4239 14.8272C10.4884 14.901 10.5679 14.9601 10.657 15.0007C10.7462 15.0412 10.843 15.0623 10.941 15.0624H14.341L13.021 20.6314C12.9823 20.7921 13.003 20.9612 13.0792 21.1079C13.1553 21.2545 13.2818 21.3687 13.4354 21.4296C13.5891 21.4904 13.7595 21.4937 13.9154 21.4389C14.0713 21.3842 14.2021 21.275 14.284 21.1314L19.323 12.4314C19.3846 12.3267 19.4173 12.2075 19.4178 12.086C19.4183 11.9645 19.3865 11.8451 19.3256 11.7399C19.2648 11.6348 19.1771 11.5476 19.0716 11.4875C18.9661 11.4273 18.8464 11.3962 18.725 11.3974ZM14.267 19.3254L15.226 15.2714L15.492 14.1434H11.197L12.054 7.73241H15.66L14.55 11.1114L14.16 12.3164H18.327L14.267 19.3254Z"
        fill={color}
        stroke={color}
        strokeWidth="0.4"
      />
      <path
        d="M0 14C0 6.26801 6.26801 0 14 0H16C23.732 0 30 6.26801 30 14V15C30 22.732 23.732 29 16 29H14C6.26801 29 0 22.732 0 15V14Z"
        fill={color}
        fill-opacity={transparent ? `0` : `0.1`}
      />
    </svg>
  );
}

export default BoltIcon;
