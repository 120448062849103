import { Button, IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditIcon from '../../assets/EditIcon';
import { resendConfirmation, verifyUser } from '../../store/action_creators/auth.actions';
import { Status, UserTypeEnum } from '../../store/config/enums';
import {
  AuthState,
  Client,
  Collaborator,
  CollaboratorsState,
  EnableCollaboratorRequest,
  RootState,
} from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { CustomSnackbar } from '../CustomSnackbar';
import { CustomSwitch } from '../CustomSwitch';
import CustomTable, { HeadCell } from '../CustomTable/CustomTable';
import { EmptyMessage } from '../EmptyMessage';
import { AdminCrown } from '../Icons/AdminCrown';
import { Check } from '../Icons/Check';
import Resend from '../Icons/Resend/Resend';
import { Loading } from '../Loading';
import { ScreenTitle } from '../ScreenTitle';
import { StatusPill } from '../StatusPill';
import { enableCollaborator, fetchAllUsers } from './../../store/action_creators/collaborators.actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%',
      marginTop: '1.25rem',
    },
    button: {
      color: styles.slateBlue,
      textDecoration: 'none !important',
    },
    centeredCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        margin: '0 0 0 0.25rem',
      },
    },
    iconButton: {
      padding: '0',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  }),
);

export interface ClientUsersTableProps {
  selectedClient: Client;
  isForDashboard: boolean;
}

export interface ExtraColumns {
  icons?: JSX.Element;
  enabled?: JSX.Element;
}

type Column = Collaborator & ExtraColumns;

function ClientUsersTable({ selectedClient, isForDashboard }: ClientUsersTableProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users: CollaboratorsState = useSelector((state: RootState) => state.collaborators);
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const [resendingConfirmation, setResendingConfirmation] = useState<boolean>(false);

  const orderedUsers = users.collaborators?.sort((a: Collaborator, b: Collaborator) => {
    return a.type - b.type;
  });

  const slicedUsers = orderedUsers?.slice(0, 3);

  const columns: HeadCell<Column>[] = [
    { columnId: 'names', label: 'Nombre de usuario' },
    { columnId: 'email', label: 'Email' },
    { columnId: 'type', label: 'Rol' },
    { columnId: 'emailConfirmed', label: 'Estado' },
    { columnId: 'enabled', label: 'Habilitado' },
    { columnId: 'icons', label: 'Acciones' },
  ];

  const renderCustomTableCells = (row: Collaborator, columnId: keyof Column) => {
    switch (columnId) {
      case 'names':
        return row.names + ' ' + row.surnames;
      case 'email':
        return row.email;
      case 'type':
        return row.type === UserTypeEnum.OWNER ? (
          <div className={classes.centeredCell}>
            <AdminCrown />
            <p>Administrador</p>
          </div>
        ) : (
          'Colaborador'
        );
      case 'emailConfirmed':
        return (
          <StatusPill
            label={row.emailConfirmed ? 'Verificado' : 'Pendiente de verif'}
            color={row.emailConfirmed ? 'success' : 'error'}
          />
        );
      case 'enabled': {
        return (
          row.type !== UserTypeEnum.OWNER && (
            <CustomSwitch
              defaultChecked={row.active}
              onChange={() => handleEnableCollaborator(row)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )
        );
      }
      case 'icons':
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: 8, margin: '0 auto', width: '60%' }}>
            {row.emailConfirmed && (
              <IconButton
                disableRipple
                className={classes.iconButton}
                onClick={() => updateCollaborator(row)}
              >
                <EditIcon />
              </IconButton>
            )}
            {!row.emailConfirmed && (
              <>
                <IconButton
                  disableRipple
                  className={classes.iconButton}
                  onClick={async () => {
                    dispatch(verifyUser(row.userId));
                    dispatch(fetchAllUsers(selectedClient.id));
                  }}
                >
                  <Check />
                </IconButton>
                <IconButton
                  disableRipple
                  className={classes.iconButton}
                  onClick={async () => {
                    setResendingConfirmation(true);
                    dispatch(resendConfirmation(row.userId));
                  }}
                >
                  <Resend />
                </IconButton>
              </>
            )}
          </div>
        );
      default:
        return row[columnId];
    }
  };

  useEffect(() => {
    dispatch(fetchAllUsers(selectedClient.id));
  }, [dispatch, selectedClient]);

  const handleEnableCollaborator = (collaborator: Collaborator) => {
    const enableCollaboratorRequest: EnableCollaboratorRequest = {
      userId: collaborator.userId,
      active: !collaborator.active,
    };
    dispatch(enableCollaborator(enableCollaboratorRequest));
  };

  const updateCollaborator = (collaborator: Collaborator) => {
    navigate('/user', { state: { collaborator, client: selectedClient } });
  };

  const closeSnack = () => {
    if (resendingConfirmation) setResendingConfirmation(false);
  };

  const isOpened: boolean =
    resendingConfirmation && (auth.resendConfirmationSuccess || auth.resendConfirmationErrorMessage !== null);

  return (
    <>
      {isForDashboard && (
        <div className={classes.screenTitle}>
          <ScreenTitle title="Usuarios">
            {selectedClient && (
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={() => navigate('/manage-users', { state: { client: selectedClient } })}
              >
                Ver Todos
              </Button>
            )}
          </ScreenTitle>
        </div>
      )}
      {users.collaborators === undefined ? (
        <EmptyMessage title="No se encontraron usuarios" />
      ) : users.collaborators !== null ? (
        <CustomTable
          headCells={columns}
          rowsData={isForDashboard ? slicedUsers || [] : orderedUsers || []}
          renderCell={renderCustomTableCells}
          boxProps={{ marginTop: '0.8125rem' }}
          hasRowHover={false}
        />
      ) : (
        <Loading />
      )}
      <CustomSnackbar
        open={isOpened}
        message={
          auth.resendConfirmationSuccess
            ? 'Se ha enviado correctamente el email de confirmación'
            : auth.resendConfirmationErrorMessage!
        }
        handleClose={closeSnack}
        type={auth.resendConfirmationSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default ClientUsersTable;
