import { Menu, MenuItem, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from '../../styles/_variables.module.scss';
import SettingsIcon from '../Icons/SettingsIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: 'none',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      '&:hover': {
        color: colors.secondaryMain,
        cursor: 'pointer',
        userSelect: 'none',
        '& path': {
          fill: colors.secondaryMain,
        },
      },
    },
    selected: {
      '& path': {
        fill: colors.secondaryMain,
      },
    },
    menu: {
      marginTop: 32,
      '& .MuiMenuItem-root': {
        width: '10rem',
        color: colors.slateBlue,
        margin: '0.5rem 0',
        padding: '0.5rem 0',
        justifyContent: 'center',
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: colors.borderButtonSecondary,
        },
      },
    },
  }),
);

function CogMenu() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOption = (option: string) => {
    navigate(option);
    setAnchorEl(null);
  };

  return (
    <>
      <button onClick={handleClick} className={`${classes.button}`}>
        <SettingsIcon className={`${anchorEl !== null ? classes.selected : null}`} />
      </button>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        className={classes.menu}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => selectOption('/business')}>Empresa</MenuItem>
        <MenuItem onClick={() => selectOption('/configuration')}>Configuración</MenuItem>
        <MenuItem onClick={() => selectOption('/users')}>Colaboradores</MenuItem>
      </Menu>
    </>
  );
}

export default CogMenu;
