import { Divider, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import MultiUseLinkIcon from '../../assets/MultiUseLinkIcon';
import OneTimeLinkIcon from '../../assets/OneTimeLinkIcon';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { LinkTypeEnum } from '../../store/config/enums';
import { Link } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { ShareLinkDialog } from '../dialogs/ShareLinkDialog';
import CardFooter from './CardFooter';
import LinkStatusPill from './LinkStatusPill';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem',
    },
    header: {
      color: theme.palette.secondary.main,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    date: {
      fontSize: '0.75rem',
      fontWeight: Number(styles.normalTextWeight),
      color: styles.darkBlue,
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.8rem',
      '& svg': {
        color: theme.palette.primary.main,
        fontSize: '1.3rem',
      },
    },
    amountContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.35rem',
    },
    text: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      fontSize: '0.875rem',
      fontWeight: Number(styles.normalTextWeight),
      color: styles.darkBlue,
    },
    descriptionText: {
      maxWidth: '70%',
      textAlign: 'center',
      height: '2.5rem',
      display: 'flex',
      alignItems: 'center',
    },
    expirationText: {
      fontWeight: 600,
      color: styles.slateBlue,
    },
    amount: {
      color: styles.darkBlue,
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  }),
);

interface LinkCardProps {
  link: Link;
  setLinkToDelete(link: Link): void;
}

function LinkCard({ link, setLinkToDelete }: LinkCardProps) {
  const classes = useStyles();
  const [shareOpen, setShareOpen] = useState<boolean>(false);

  return (
    <>
      <Paper elevation={1} className={classes.container}>
        <div className={classes.header}>
          {link.linkType && link.linkType === LinkTypeEnum.PERMANENT ? (
            <MultiUseLinkIcon />
          ) : (
            <OneTimeLinkIcon />
          )}
          <LinkStatusPill link={link} />
        </div>
        <div className={classes.body}>
          <div className={classes.amountContainer}>
            <Typography className={classes.amount}>
              {currencyHelper.getCurrencyToken(link.amount.currency)}{' '}
              {numberHelper.getNumberWithDots(link.amount.value)}
            </Typography>
            <p className={`${classes.text} ${classes.descriptionText}`}>
              {link.description || 'Sin descripción'}
            </p>

            <p className={classes.text}>
              <span className={classes.expirationText}>Vencimiento&nbsp;</span>
              <span>
                {link.validUntil ? dateHelper.toShorterNiceString(link.validUntil) : 'Sin vencimiento'}
              </span>
            </p>
          </div>
        </div>
        <Divider />
        <CardFooter link={link} setShareOpen={setShareOpen} setLinkToDelete={setLinkToDelete} />
      </Paper>
      <ShareLinkDialog open={shareOpen} setOpen={setShareOpen} link={link} />
    </>
  );
}

export default LinkCard;
