import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PaymentNotificationTypeEnum } from '../store/config/enums';
import { NotificationsState, Payment, RootState } from '../store/config/types';

const useNotificationId = () => {
  const payment: Payment | null = useSelector((state: RootState) => state.payments.selectedPayment);
  const notifications: NotificationsState = useSelector((state: RootState) => state.notifications);

  const notificationId = useMemo(() => {
    if (!payment) {
      return null;
    }

    let notificationIdToResend = payment.notifications.find(
      (notification) => notification.notificationType === PaymentNotificationTypeEnum.PAYMENT_CANCELLATION,
    )?.id;

    if (!notificationIdToResend) {
      notificationIdToResend = payment.notifications.find(
        (notification) => notification.notificationType === PaymentNotificationTypeEnum.PAYMENT_OK,
      )?.id;
    }

    if (
      notifications.notification &&
      payment.id === Number(notifications.notification?.notification.paymentId) &&
      !notificationIdToResend
    ) {
      notificationIdToResend = notifications.notification.notification.id;
    }

    return notificationIdToResend;
  }, [notifications.notification, payment]);

  return notificationId;
};

export default useNotificationId;
