import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const siteId = process.env.HOTJAR_SITE_ID ? +process.env.HOTJAR_SITE_ID : 0;
const hotjarVersion = process.env.HOTJAR_VERSION ? +process.env.HOTJAR_VERSION : 0;
const isProd = process.env.REACT_APP_ENV_NAME === 'prod';

if (['prod', 'qa'].includes(process.env.REACT_APP_ENV_NAME || '')) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.REACT_APP_SENTRY_DSN || 'local',
  });
}

isProd &&
  Hotjar.init(siteId, hotjarVersion, {
    debug: false,
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
