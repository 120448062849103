import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import React, { CSSProperties, MutableRefObject, useEffect, useRef, useState } from 'react';
import { QuestionMark } from '../Icons/QuestionMark';

interface VATTooltipProps {
  title: string;
}

function VATTooltip({ title }: VATTooltipProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const tooltipRef: MutableRefObject<HTMLSpanElement | null> = useRef(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (tooltipOpen && tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
    // eslint-disable-next-line
  }, [isMobile, tooltipOpen]);

  const handleTooltipToggle = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (isMobile) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const popperStyles: CSSProperties = {
    pointerEvents: tooltipOpen ? 'auto' : 'none',
    width: '8.75rem',
  };

  return (
    <Tooltip
      title={title}
      placement="bottom-start"
      open={isMobile ? tooltipOpen : undefined}
      disableFocusListener={isMobile}
      disableHoverListener={isMobile}
      disableTouchListener={isMobile}
      onClose={() => setTooltipOpen(false)}
      key={isMobile ? 'mobile' : 'desktop'}
      PopperProps={{ style: popperStyles }}
    >
      <span onClick={handleTooltipToggle} ref={isMobile ? (ref) => (tooltipRef.current = ref) : undefined}>
        <QuestionMark />
      </span>
    </Tooltip>
  );
}

export default VATTooltip;
