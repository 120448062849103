import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LinkTypeEnum } from '../store/config/enums';
import { Filters } from '../store/config/types';

interface UseFilterLogicParams {
  businessId: number;
  linkType: LinkTypeEnum;
  loadFiltersAction: (
    filters: Filters,
    page: number,
    pageSize: number,
    businessId: number,
    linkType: LinkTypeEnum,
  ) => void;
  filtersState: Filters;
}

function useFilterLogic({ businessId, linkType, loadFiltersAction, filtersState }: UseFilterLogicParams) {
  const dispatch = useDispatch();
  const [openFilters, setOpenFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(filtersState.term || '');

  const loadFilters = (filters: Filters) => {
    dispatch(
      loadFiltersAction(
        { ...filters, sortDirection: 2, columnSort: 1 }, // sortDirection = 2 means DESC and columSort = 1 means sorting by date
        1, // page
        10, // pageSize
        businessId,
        linkType,
      ),
    );
  };

  const searchLinks = () => {
    if ((searchTerm.length > 3 || searchTerm.length === 0) && searchTerm !== filtersState.term) {
      const filters = { ...filtersState, term: searchTerm };
      dispatch(loadFiltersAction(filters, 1, 10, businessId, linkType));
    }
  };

  const closeFiltersDialog = () => setOpenFilters(false);

  return {
    openFilters,
    setOpenFilters,
    searchTerm,
    setSearchTerm,
    loadFilters,
    searchLinks,
    closeFiltersDialog,
  };
}

export default useFilterLogic;
