import {
  Button,
  CircularProgress,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../../styles/_variables.module.scss';
import { ReturnButton } from '../ReturnButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenTitle: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    screenTitleWithSpaceBetween: {
      justifyContent: 'space-between',
    },
    screenInfo: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '1rem',
      gap: '0.8rem',
      '& h5': {
        fontWeight: 500,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingRight: '0.5rem',
        '& h5': { maxWidth: 'unset', width: 'max-content' },
      },
    },
    infoIcon: {
      color: styles.slateBlue,
      fontSize: '2.3rem',
      width: '1.25rem',
      height: '1.25rem',
    },
    titleWithChild: {
      [theme.breakpoints.down('sm')]: {
        gap: '1.5rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    submitButton: {
      fontSize: '0.875rem',
      fontWeight: Number(styles.normalTextWeight),
    },
    extraInfo: {
      fontSize: '0.75rem',
      color: styles.slateBlue,
      height: '1.0625rem',
    },
    divider: {
      height: '1.25rem',
      width: '1px',
      backgroundColor: styles.slateBlue,
      opacity: '20%',
    },
    buttonContainer: {
      display: 'flex',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    returnButton: {
      width: 'fit-content',
      minWidth: 'unset',
    },
  }),
);

interface ScreenTitleProps {
  title: string;
  elementsAmount?: number | string;
  tooltip?: string;
  children?: ReactNode;
  formId?: string;
  submitButtonText?: string;
  loading?: boolean;
  hasReturnButton?: boolean;
}

function ScreenTitle({
  title,
  elementsAmount,
  tooltip,
  children,
  formId,
  submitButtonText,
  loading,
  hasReturnButton,
}: ScreenTitleProps) {
  const classes = useStyles();

  const buttonClasses = clsx({
    [classes.screenTitle]: true,
    [classes.screenTitleWithSpaceBetween]: hasReturnButton || children,
    [classes.titleWithChild]: children,
  });

  return (
    <div className={buttonClasses}>
      <div className={classes.screenInfo}>
        <Typography variant="h5">{title}</Typography>
        {tooltip && (
          <Tooltip title={tooltip} disableFocusListener>
            <HelpOutlineIcon className={classes.infoIcon} />
          </Tooltip>
        )}
        {elementsAmount !== null && elementsAmount !== undefined && (
          <>
            <div className={classes.divider} />
            <Typography variant="subtitle2" className={classes.extraInfo}>
              {elementsAmount} elemento{elementsAmount !== 1 ? 's' : ''}
            </Typography>
          </>
        )}
      </div>
      <div className={`${children || hasReturnButton || formId ? classes.buttonContainer : ''}`}>
        {hasReturnButton && <ReturnButton />}
        {formId ? (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.submitButton}
            form={formId}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : submitButtonText || 'Guardar cambios'}
          </Button>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default ScreenTitle;
