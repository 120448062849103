import { Hidden, Theme, createStyles, makeStyles } from '@material-ui/core';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../store/config/types';
import ColoredCardWithTotals from './ColoredCardWithTotals';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coloredCard: {
      marginBottom: '1rem',
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      alignItems: 'flex-start',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    secondDivider: {
      margin: '1rem 0',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  payments: state.payments,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function ColoredCardsWithTotals({ payments }: PropsFromRedux) {
  const classes = useStyles();

  return (
    <>
      <Hidden xsUp>
        <ColoredCardWithTotals
          className={classes.coloredCard}
          color="warning"
          description="Links de 1 uso pendientes"
          pesosAmount={
            payments.paymentsTotals.oneTimeOnlyTotals
              ? payments.paymentsTotals.oneTimeOnlyTotals.uruguayanPesoAmount
              : 0
          }
          usdAmount={
            payments.paymentsTotals.oneTimeOnlyTotals
              ? payments.paymentsTotals.oneTimeOnlyTotals.dollarAmount
              : 0
          }
          pesosCount={
            payments.paymentsTotals.oneTimeOnlyTotals
              ? payments.paymentsTotals.oneTimeOnlyTotals.uruguayanPesoCount
              : 0
          }
          usdCount={
            payments.paymentsTotals.oneTimeOnlyTotals
              ? payments.paymentsTotals.oneTimeOnlyTotals.dollarCount
              : 0
          }
        />
      </Hidden>
      <ColoredCardWithTotals
        className={classes.coloredCard}
        color="success"
        description="Pagos aprobados"
        pesosAmount={
          payments.paymentsTotals.approvedTotals
            ? payments.paymentsTotals.approvedTotals.uruguayanPesoAmount
            : 0
        }
        usdAmount={
          payments.paymentsTotals.approvedTotals ? payments.paymentsTotals.approvedTotals.dollarAmount : 0
        }
        pesosCount={
          payments.paymentsTotals.approvedTotals
            ? payments.paymentsTotals.approvedTotals.uruguayanPesoCount
            : 0
        }
        usdCount={
          payments.paymentsTotals.approvedTotals ? payments.paymentsTotals.approvedTotals.dollarCount : 0
        }
      />
      <ColoredCardWithTotals
        className={classes.coloredCard}
        color="error"
        description="Pagos fallidos"
        pesosAmount={
          payments.paymentsTotals.rejectedTotals
            ? payments.paymentsTotals.rejectedTotals.uruguayanPesoAmount
            : 0
        }
        usdAmount={
          payments.paymentsTotals.rejectedTotals ? payments.paymentsTotals.rejectedTotals.dollarAmount : 0
        }
        pesosCount={
          payments.paymentsTotals.rejectedTotals
            ? payments.paymentsTotals.rejectedTotals.uruguayanPesoCount
            : 0
        }
        usdCount={
          payments.paymentsTotals.rejectedTotals ? payments.paymentsTotals.rejectedTotals.dollarCount : 0
        }
      />
    </>
  );
}

export default connector(ColoredCardsWithTotals);
