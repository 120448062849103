import styles from '../styles/_variables.module.scss';

function AddIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="xmark-solid"
        opacity="0.797"
        d="M14.3388 2.54824C14.3387 2.23841 14.3997 1.93161 14.5182 1.64536C14.6367 1.3591 14.8105 1.09899 15.0295 0.879885C15.2486 0.660775 15.5086 0.486954 15.7948 0.368346C16.0811 0.249739 16.3878 0.188666 16.6977 0.18862C17.0075 0.188574 17.3143 0.249554 17.6005 0.368075C17.8868 0.486596 18.1469 0.660337 18.366 0.879382C18.5851 1.09843 18.7589 1.35848 18.8776 1.6447C18.9962 1.93092 19.0572 2.23771 19.0573 2.54753L19.0636 13.5437L30.0542 13.5473C30.6804 13.5473 31.2809 13.796 31.7237 14.2388C32.1665 14.6816 32.4152 15.2821 32.4152 15.9083C32.4152 16.5345 32.1665 17.135 31.7237 17.5778C31.2809 18.0206 30.6804 18.2693 30.0542 18.2693L19.0573 18.2693L19.0559 29.2606C19.0411 29.8768 18.7859 30.4628 18.3449 30.8934C17.9039 31.324 17.3119 31.5651 16.6955 31.5651C16.0792 31.5651 15.4872 31.324 15.0462 30.8934C14.6051 30.4628 14.35 29.8768 14.3352 29.2606L14.3388 18.2616L3.34183 18.2616C2.71564 18.2616 2.11511 18.0128 1.67233 17.57C1.22955 17.1273 0.980799 16.5267 0.980798 15.9005C0.980798 15.2744 1.22955 14.6738 1.67233 14.231C2.11511 13.7883 2.71565 13.5395 3.34183 13.5395L14.3388 13.5395L14.3388 2.54824Z"
        fill={styles.slateBlue}
      />
    </svg>
  );
}

export default AddIcon;
