import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { FormTextField } from '../FormTextField';

function SoftDescriptor() {
  return (
    <>
      <Grid item xs={12}>
        <Field name="softDescriptor" component={FormTextField} type="text" placeholder="Soft descriptor" />
      </Grid>
      <Grid item xs={12} className="soft-descriptor">
        El "Soft descriptor" es el texto que se le muestra al cliente en el estado de cuenta de la tarjeta
      </Grid>
    </>
  );
}

export default SoftDescriptor;
