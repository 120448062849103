import { SVGProps } from 'react';
import variables from '../../../styles/_variables.module.scss';

function SingleLink(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.0762 10.7382C14.9622 10.6723 14.8674 10.5775 14.8015 10.4633C14.7356 10.3492 14.7009 10.2197 14.7008 10.0879V5.88759C14.6998 5.2522 14.4467 4.64317 13.9971 4.19422C13.5474 3.74527 12.938 3.49311 12.3026 3.49311H5.50012L5.71327 3.70716C5.79482 3.8024 5.85126 3.91651 5.87746 4.03913C5.90366 4.16174 5.89877 4.28896 5.86326 4.4092C5.82774 4.52945 5.76272 4.63891 5.67411 4.72761C5.5855 4.81632 5.47612 4.88146 5.35591 4.9171C5.25022 4.94788 5.1391 4.95531 5.03025 4.93886C4.9214 4.92242 4.81743 4.8825 4.72554 4.82187H4.7065L4.64029 4.75112L3.15462 3.26545C3.01432 3.12469 2.93555 2.93405 2.93555 2.73531C2.93555 2.53657 3.01432 2.34593 3.15462 2.20517L4.64029 0.71859C4.70999 0.649034 4.79275 0.593921 4.8838 0.556411C4.97485 0.518901 5.07241 0.499732 5.17088 0.500003C5.23735 0.500199 5.30351 0.50904 5.3677 0.526306C5.49505 0.561058 5.61102 0.628684 5.70398 0.7224C5.79694 0.816117 5.86362 0.93263 5.89734 1.06025C5.93106 1.18787 5.93063 1.32212 5.8961 1.44952C5.86156 1.57693 5.79413 1.69301 5.70057 1.78613L5.50012 1.98658H12.3026C13.337 1.98658 14.329 2.39748 15.0604 3.12889C15.7918 3.86031 16.2027 4.85231 16.2027 5.88669V10.087C16.2018 10.2187 16.1665 10.3478 16.1002 10.4616C16.0339 10.5753 15.939 10.6698 15.825 10.7355C15.7109 10.8013 15.5816 10.836 15.4499 10.8364C15.3183 10.8367 15.1888 10.8025 15.0744 10.7373L15.0762 10.7382Z"
        fill={variables.slateBlue}
      />
      <path
        d="M10.4732 15.2804C10.4004 15.2077 10.3433 15.1209 10.3055 15.0252C10.2677 14.9295 10.2499 14.8271 10.2533 14.7243C10.2567 14.6215 10.2812 14.5205 10.3253 14.4275C10.3693 14.3345 10.432 14.2516 10.5094 14.1839L10.7044 13.9889H3.90011C3.38786 13.9889 2.88063 13.888 2.40739 13.6919C1.93415 13.4959 1.50416 13.2085 1.14199 12.8462C0.779824 12.484 0.492563 12.0539 0.296618 11.5806C0.100672 11.1074 -0.000119021 10.6001 1.05475e-07 10.0879V5.88844C0.000310431 5.75646 0.0352326 5.62686 0.101281 5.51259C0.167329 5.39832 0.262194 5.30337 0.376406 5.23722C0.491175 5.17261 0.620655 5.13867 0.752358 5.13867C0.884061 5.13867 1.01354 5.17261 1.12831 5.23722C1.24242 5.30341 1.33715 5.3984 1.40304 5.51268C1.46894 5.62695 1.50368 5.75653 1.50381 5.88844V10.0879C1.50405 10.7234 1.75656 11.3329 2.20588 11.7824C2.65519 12.2318 3.26456 12.4846 3.90011 12.4851H10.7026L10.4858 12.2665C10.4045 12.1712 10.3483 12.057 10.3222 11.9345C10.2962 11.8119 10.3011 11.6848 10.3366 11.5647C10.3721 11.4445 10.437 11.3351 10.5255 11.2464C10.6139 11.1576 10.7231 11.0924 10.8432 11.0565C10.9636 11.0209 11.0911 11.0159 11.2139 11.0423C11.3367 11.0686 11.4509 11.1252 11.5461 11.2071L11.5588 11.2189L13.0445 12.7046C13.1848 12.8453 13.2636 13.036 13.2636 13.2347C13.2636 13.4334 13.1848 13.6241 13.0445 13.7648L12.9774 13.832L11.5634 15.246C11.4962 15.3226 11.414 15.3847 11.322 15.4285C11.23 15.4723 11.1301 15.4969 11.0282 15.5008H10.9992C10.8018 15.4988 10.613 15.4197 10.4732 15.2804Z"
        fill={variables.slateBlue}
      />
      <path
        d="M8.09749 10.8416C7.99951 10.8418 7.90246 10.8227 7.81189 10.7853C7.72131 10.748 7.63898 10.6931 7.56961 10.6239C7.49909 10.554 7.44307 10.4708 7.40478 10.3791C7.36649 10.2874 7.34668 10.1891 7.34649 10.0897V7.6118C7.1924 7.66953 7.02378 7.6759 6.86578 7.62994C6.74557 7.59429 6.63619 7.52915 6.54758 7.44045C6.45897 7.35174 6.39395 7.24228 6.35843 7.12204C6.32292 7.00179 6.31804 6.87457 6.34423 6.75196C6.37043 6.62935 6.42687 6.51523 6.50842 6.41999L6.52021 6.40458L7.56961 5.35699C7.71076 5.21549 7.90217 5.1356 8.10202 5.13477C8.20131 5.13433 8.29968 5.15367 8.39141 5.19167C8.48313 5.22967 8.56636 5.28556 8.63625 5.35608C8.77683 5.49865 8.85539 5.69099 8.85483 5.89121V10.0906C8.85459 10.29 8.7753 10.4811 8.63434 10.622C8.49338 10.763 8.30227 10.8423 8.10293 10.8425L8.09749 10.8416Z"
        fill={variables.slateBlue}
      />
    </svg>
  );
}

export default SingleLink;
