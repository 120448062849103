import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import errorAnimation from '../../assets/plinks-error.json';
import { EmailSent } from '../../components/EmailSent';
import { LandingLayout } from '../../components/Landing';
import { Loading } from '../../components/Loading';
import { urlHelper } from '../../helpers/urlHelper';
import { confirmResetPassword } from '../../store/action_creators/auth.actions';
import { RootState } from '../../store/config/types';

function ConfirmResetPassword() {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  const errorAnimationData = <Lottie animationData={errorAnimation} loop={false} />;

  const emailSentText = (
    <>
      <p>Enviamos un mail a {auth.confirmResetPasswordSuccess?.email}</p>
      <p>Allí encontrarás las instrucciones para reiniciar tu contraseña.</p>
      <p>Si no recibiste este correo después de unos minutos, por favor revisa tu casilla de spam.</p>
    </>
  );

  useEffect(() => {
    if (
      !auth.confirmingResetPassword &&
      !auth.confirmResetPasswordSuccess &&
      auth.confirmResetPasswordErrorMessage === null
    ) {
      let urlVars = urlHelper.parseQueryString();
      if (urlVars.id && urlVars.token) {
        dispatch(confirmResetPassword({ id: urlVars.id, token: urlVars.token }));
      }
    }
  }, [
    auth.confirmResetPasswordErrorMessage,
    auth.confirmResetPasswordSuccess,
    auth.confirmingResetPassword,
    dispatch,
  ]);

  return (
    <>
      {auth.confirmingResetPassword ? (
        <Loading />
      ) : !auth.confirmResetPasswordSuccess ? (
        <div className="screen-container landing confirm-email">
          <LandingLayout
            title="Ocurrió un error al verificar tu email."
            animation={errorAnimationData}
            children={
              <div className="subtitle paragraph">
                <span>Por favor, intenta de nuevo en unos minutos o contáctanos a </span>
                <a href="mailto:soporte@plexo.com.uy">soporte@plexo.com.uy</a>
                <span> para poder ayudarte. Si deseas salir solo debes cerrar la ventana.</span>
              </div>
            }
          />
        </div>
      ) : (
        <EmailSent text={emailSentText} />
      )}
    </>
  );
}

export default ConfirmResetPassword;
