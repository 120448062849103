import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import Cookies from 'universal-cookie';
import { logout } from '../../store/action_creators/auth.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { RootState } from '../../store/config/types';

export interface LogoutDialogProps {
  open: boolean;
  setOpen(open: boolean): void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  logout: () => dispatch(logout()),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & LogoutDialogProps;

function LogoutDialog({ logout, open, setOpen }: PropsType) {
  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOpen(false);
  };

  const confirmLogout = () => {
    setOpen(false);
    logout();
    navigate('/login');

    const cookies = new Cookies();
    cookies.remove('token');
  };

  return (
    <Dialog className="logout" fullWidth maxWidth="xs" open={open} onClose={handleDialogClose}>
      <DialogTitle className="title">
        <CloseIcon className="close-icon" onClick={handleDialogClose} />
      </DialogTitle>
      <DialogContent className="content">
        <span className="modal-title">¿Estás seguro que deseas cerrar sesión?</span>
        <div className="actions">
          <Button className="cancel-button" color="secondary" variant="outlined" onClick={handleDialogClose}>
            Cancelar
          </Button>
          <Button className="close-sesion-button" color="primary" variant="contained" onClick={confirmLogout}>
            Si, cerrar sesión
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default connector(LogoutDialog);
