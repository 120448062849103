import { useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { EmptyMessage } from '../../components/EmptyMessage';
import UsersDesktop from '../../components/Users/UsersDesktop';
import UsersMobile from '../../components/Users/UsersMobile';
import { enableCollaborator, fetchCollaborators } from '../../store/action_creators/collaborators.actions';
import { AppActions } from '../../store/config/ActionTypes';
import { Status } from '../../store/config/enums';
import { Collaborator, EnableCollaboratorRequest, RootState } from '../../store/config/types';
import { theme } from '../../styles/theme';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  collaborators: state.collaborators,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchCollaborators: (businessId: number) => dispatch(fetchCollaborators(businessId)),
  enableCollaborator: (enableCollaboratorRequest: EnableCollaboratorRequest) =>
    dispatch(enableCollaborator(enableCollaboratorRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Users({ auth, collaborators, fetchCollaborators, enableCollaborator }: PropsFromRedux) {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [enablingCollaborator, setEnablingCollaborator] = useState(false);

  useEffect(() => {
    if (
      auth.account &&
      !collaborators.loadingCollaborators &&
      collaborators.collaborators === null &&
      collaborators.collaboratorsErrorMessage === null
    ) {
      fetchCollaborators(auth.account.business.id!);
    }
  }, [
    auth.account,
    collaborators.loadingCollaborators,
    collaborators.collaborators,
    collaborators.collaboratorsErrorMessage,
    fetchCollaborators,
  ]);

  const handleEnableCollaborator = (collaborator: Collaborator) => {
    const enableCollaboratorRequest: EnableCollaboratorRequest = {
      userId: collaborator.userId,
      active: !collaborator.active,
    };
    enableCollaborator(enableCollaboratorRequest);
  };

  useEffect(() => {
    fetchCollaborators(auth.account?.business.id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaborators.enableCollaboratorErrorMessage]);

  const emptyDataMessage = (
    <EmptyMessage
      title="Aún no has agregado ningún colaborador."
      message="Para visualizarlos aquí, agrega un nuevo colaborador."
    />
  );

  return (
    <>
      {isMobile ? (
        <UsersMobile
          emptyDataMessage={emptyDataMessage}
          handleEnableCollaborator={handleEnableCollaborator}
          setEnablingCollaborator={setEnablingCollaborator}
        />
      ) : (
        <UsersDesktop
          emptyDataMessage={emptyDataMessage}
          setEnablingCollaborator={setEnablingCollaborator}
          handleEnableCollaborator={handleEnableCollaborator}
          enablingCollaborator={enablingCollaborator}
        />
      )}

      <CustomSnackbar
        open={
          enablingCollaborator &&
          (collaborators.enableCollaboratorSuccess || collaborators.enableCollaboratorErrorMessage !== null)
        }
        message={
          collaborators.enableCollaboratorSuccess
            ? 'Se actualizó correctamente el estado del colaborador'
            : 'Ocurrió un error al cambiar de estado el colaborador'
        }
        handleClose={() => setEnablingCollaborator(false)}
        type={collaborators.enableCollaboratorSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default connector(Users);
