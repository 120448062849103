import { Divider, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { Business, LinkDataResponse } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { LandingLayout } from '../Landing';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '1.25rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.15rem',
      background: styles.white,
      maxWidth: '34.375rem',
      boxShadow: styles.boxShadow,
      border: `1px solid ${styles.borderButtonSecondary}`,
    },
    detailTitle: {
      fontSize: '1.125rem',
      color: styles.slateBlue,
      fontWeight: Number(styles.semiBoldTextWeight),
    },
  }),
);

interface ExpiredLinkCardProps {
  link: LinkDataResponse;
  business: Business;
}

function ExpiredLinkCard({ link, business }: ExpiredLinkCardProps) {
  const classes = useStyles();

  const content: JSX.Element = (
    <Paper className={classes.paper}>
      <Typography className={classes.detailTitle}>Detalle</Typography>
      <div className="expired-link-row">
        <Typography variant="subtitle2">Vencimiento del link</Typography>
        <Typography variant="body1">{dateHelper.toShorterNiceString(link?.link.validUntil!)}</Typography>
      </div>
      <Divider className="divider" />
      <div className="expired-link-row">
        <Typography variant="subtitle2">Empresa emisora</Typography>
        <Typography variant="body1">{business.prettyName}</Typography>
      </div>
      <Divider className="divider" />
      <div className="expired-link-row">
        <Typography variant="subtitle2">Descripción</Typography>
        <Typography variant="body1">{link?.link.description || '-'}</Typography>
      </div>
      <div className="expired-link-row amount-row">
        <Typography variant="subtitle2">
          Importe
          {link.link.vatRate > 0 && <span className="iva">IVA inc.</span>}
        </Typography>
        <Typography variant="h4" className="amount">
          <span>{currencyHelper.getCurrencyToken(link.link.amount.currency!)}</span>
          {numberHelper.getNumberWithDots(link.link.amount.value!)}
        </Typography>
      </div>
      <Divider className="divider" />
      <div className="expired-link-row">
        <Typography variant="subtitle2">Referencia</Typography>
        <Typography variant="body1">{link?.link.reference || '-'}</Typography>
      </div>
    </Paper>
  );

  return (
    <div className="screen-container landing">
      <LandingLayout
        title="¡Lo sentimos!"
        subtitle="El link de pago expiró, para salir solo debes cerrar la ventana"
        content={content}
      />
    </div>
  );
}

export default ExpiredLinkCard;
