import { SVGProps } from 'react';
import variables from '../../../styles/_variables.module.scss';

function PermanentLink(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.4968 11.0298L14.2518 8.88884C14.1916 8.78221 14.1107 8.68866 14.0139 8.6136C13.9171 8.53854 13.8064 8.48347 13.6881 8.45164C13.5699 8.41981 13.4465 8.41183 13.3251 8.42817C13.2037 8.44451 13.0868 8.48485 12.9812 8.54683C12.8755 8.6088 12.7833 8.69119 12.7098 8.78917C12.6364 8.88714 12.5831 8.99874 12.5532 9.11751C12.5233 9.23627 12.5173 9.35978 12.5356 9.48088C12.5539 9.60197 12.5961 9.71821 12.6598 9.82283L13.9048 11.9638C13.9788 12.0921 14.0178 12.2375 14.0176 12.3855C14.0175 12.5336 13.9783 12.679 13.9041 12.8071C13.8299 12.9351 13.7232 13.0414 13.5948 13.1151C13.4664 13.1888 13.3209 13.2273 13.1728 13.2269H10.2808L10.8808 12.6268C11.0532 12.4545 11.15 12.2206 11.15 11.9769C11.15 11.7331 11.0532 11.4992 10.8808 11.3268C10.7084 11.1544 10.4746 11.0576 10.2308 11.0576C9.987 11.0576 9.7532 11.1544 9.5808 11.3268L7.38082 13.4868C7.29097 13.571 7.22001 13.6732 7.1726 13.7868C7.1252 13.9004 7.10241 14.0228 7.1058 14.1458C7.10425 14.2686 7.12787 14.3904 7.17517 14.5038C7.22246 14.6171 7.29245 14.7196 7.38082 14.8049L9.5808 16.9648C9.75019 17.1368 9.98047 17.2352 10.2218 17.2388C10.4044 17.2393 10.5829 17.1857 10.735 17.0847C10.8871 16.9838 11.0059 16.8401 11.0764 16.6717C11.1469 16.5033 11.1659 16.3178 11.1311 16.1386C11.0963 15.9594 11.0092 15.7946 10.8808 15.6648L10.2808 15.0648H13.1728C13.6442 15.0701 14.1084 14.949 14.5171 14.714C14.9259 14.4791 15.2642 14.1389 15.4968 13.7288C15.7349 13.3206 15.8603 12.8564 15.8603 12.3838C15.8603 11.9112 15.7349 11.4471 15.4968 11.0388V11.0298Z"
        fill={variables.slateBlue}
      />
      <path
        d="M5.35704 13.2058H2.88604C2.738 13.2063 2.59244 13.1677 2.46405 13.094C2.33566 13.0203 2.22897 12.9141 2.15473 12.786C2.0805 12.6579 2.04135 12.5125 2.04123 12.3645C2.04111 12.2164 2.08002 12.071 2.15404 11.9428L3.58204 9.45379L3.78204 10.2588C3.80767 10.3778 3.85766 10.4902 3.92887 10.589C4.00008 10.6877 4.09096 10.7707 4.1958 10.8326C4.30063 10.8945 4.41714 10.934 4.538 10.9486C4.65885 10.9633 4.78144 10.9528 4.89804 10.9178C5.13385 10.8563 5.33644 10.7054 5.46283 10.4971C5.58922 10.2887 5.62947 10.0393 5.57504 9.80178L4.82504 6.80178C4.76344 6.56538 4.61252 6.36211 4.40404 6.23478C4.29817 6.17015 4.17986 6.12856 4.05684 6.11271C3.93382 6.09686 3.80883 6.10711 3.69004 6.14278L0.690035 6.92979C0.452136 6.99291 0.249062 7.14795 0.125476 7.3608C0.00188906 7.57365 -0.0320827 7.82688 0.0310389 8.06478C0.0941604 8.30268 0.2492 8.50575 0.462054 8.62934C0.674908 8.75293 0.928142 8.7869 1.16604 8.72378L2.02604 8.50377L0.562037 11.0478C0.3196 11.448 0.191424 11.9069 0.191424 12.3748C0.191424 12.8427 0.3196 13.3016 0.562037 13.7018C0.796457 14.1105 1.13527 14.4496 1.5438 14.6843C1.95232 14.9191 2.41589 15.0411 2.88704 15.0378H5.35804C5.60072 15.0378 5.83344 14.9414 6.00504 14.7698C6.17663 14.5982 6.27304 14.3655 6.27304 14.1228C6.27304 13.8801 6.17663 13.6474 6.00504 13.4758C5.83344 13.3042 5.60072 13.2078 5.35804 13.2078L5.35704 13.2058Z"
        fill={variables.slateBlue}
      />
      <path
        d="M4.78905 5.53793C5.00023 5.66149 5.2518 5.69623 5.48857 5.63456C5.72534 5.57288 5.92798 5.41982 6.05205 5.20893L7.31505 3.03093C7.38959 2.90288 7.49641 2.79663 7.62486 2.72278C7.75331 2.64893 7.89889 2.61006 8.04706 2.61006C8.19522 2.61006 8.34079 2.64893 8.46924 2.72278C8.59768 2.79663 8.70451 2.90288 8.77905 3.03093L10.2621 5.59293L9.38306 5.37293C9.26516 5.33862 9.14156 5.32833 9.01961 5.34269C8.89766 5.35704 8.77984 5.39574 8.67313 5.45649C8.56642 5.51724 8.473 5.5988 8.39841 5.69634C8.32381 5.79387 8.26957 5.9054 8.23889 6.02429C8.20821 6.14319 8.20171 6.26704 8.2198 6.38849C8.23789 6.50994 8.2802 6.62652 8.34419 6.73131C8.40819 6.83611 8.49258 6.92699 8.59236 6.99855C8.69214 7.07012 8.80527 7.12092 8.92505 7.14793L11.925 7.91693C11.9966 7.93814 12.0705 7.95056 12.145 7.95393C12.3522 7.95651 12.5541 7.88865 12.7176 7.76147C12.8811 7.63429 12.9966 7.45533 13.045 7.25393L13.7771 4.23393C13.8353 3.99603 13.7966 3.74475 13.6695 3.53537C13.5425 3.32598 13.3374 3.17565 13.0995 3.11743C12.8616 3.05922 12.6104 3.09789 12.401 3.22495C12.1916 3.352 12.0413 3.55703 11.983 3.79493L11.8 4.56393L10.3721 2.11093C10.1374 1.69731 9.79646 1.35394 9.38451 1.1164C8.97255 0.878862 8.50457 0.755788 8.02905 0.759932C7.55764 0.754642 7.09342 0.875779 6.68471 1.11074C6.27599 1.3457 5.9377 1.6859 5.70505 2.09593L4.44205 4.27393C4.37917 4.379 4.33825 4.49572 4.32175 4.61705C4.30524 4.73838 4.3135 4.86179 4.34602 4.97983C4.37855 5.09788 4.43467 5.2081 4.51099 5.30385C4.5873 5.39961 4.68224 5.4789 4.79005 5.53693L4.78905 5.53793Z"
        fill={variables.slateBlue}
      />
    </svg>
  );
}

export default PermanentLink;
