import { Typography } from '@material-ui/core';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/plinks-error.json';
import { LandingLayout } from '../Landing';

interface ErrorCardProps {
  title?: string;
  subtitle?: string;
  paymentReference?: string;
}

function ErrorCard({ title, subtitle, paymentReference }: ErrorCardProps) {
  const titleToShow = title ? title : 'Ha ocurrido un error';

  const subTitleElement = <p className="subtitle">{subtitle ? subtitle : 'Vuelve a intentarlo más tarde'}</p>;

  const animation = <Lottie animationData={errorAnimation} loop={false} />;

  const content: JSX.Element | undefined = (
    <>
      {paymentReference && (
        <Typography variant="subtitle2" className="content-subtitle">
          Referencia de pago: <br />
          {paymentReference}
        </Typography>
      )}
    </>
  );

  return (
    <div className="screen-container landing">
      <LandingLayout title={titleToShow} subtitle={subTitleElement} animation={animation} content={content} />
    </div>
  );
}

export default ErrorCard;
